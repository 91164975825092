import styled from 'styled-components'
import { ButtonBack } from 'ui-kit/ButtonBack/ButtonBack.component'
import { LogoFundid } from 'ui-kit/LogoFundid/LogoFundid.component'

export const StyledLogoContainer = styled.div`
  height: 1.5rem;

  ${({ theme }) => theme.media.minTablet} {
    height: 2rem;
  }
`

export const StyledHeader = styled.header`
  width: 100%;
  padding: 1.25rem 1.5rem;
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;

  position: relative;
`

export const StyledNav = styled.nav`
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
`

export interface AppHeaderProps {
  onBackButtonClick?: () => void
}

export const AppHeader = (props: AppHeaderProps) => {
  const { onBackButtonClick } = props
  return (
    <StyledHeader>
      {onBackButtonClick && (
        <StyledNav>
          <ButtonBack onClick={onBackButtonClick} />
        </StyledNav>
      )}
      <StyledLogoContainer>
        <LogoFundid url='/' />
      </StyledLogoContainer>
    </StyledHeader>
  )
}
