import { ReactNode } from 'react'
import { StyledLink } from './SidebarListItemLink.styles'

type SidebarListItemLinkProps = {
  path: string
  children: ReactNode
  onClick?: () => void
}

function SidebarListItemLink({
  path,
  children,
  onClick,
}: SidebarListItemLinkProps) {
  // href is a relative link if it starts with a /
  const isExternalLink = !/^\/(?!\/)/.test(path)

  return isExternalLink ? (
    <StyledLink
      as='a'
      href={path}
      target='_blank'
      rel='noopener noreferrer'
      onClick={onClick}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledLink to={path} onClick={onClick}>
      {children}
    </StyledLink>
  )
}

export { SidebarListItemLink }
