import { useMutation, useQueryClient } from 'react-query'
import {
  PreliminaryQuestionsTypes,
  StatusApi,
  StatusValues,
} from 'api-connectors'
import { USER_STATUS_QUERY_KEY } from '../userStatus/UserStatus.service'

const getFlagsData = async () => {
  const { getStatus } = StatusApi()

  const data = await getStatus()

  return data.data
}

export const useProcessFlags = async () => {
  const { data, mutateAsync } = useMutation(getFlagsData, {
    retry: true,
  })
  return {
    processFlags: data,
    checkProcessFlags: mutateAsync,
  }
}

const setAndUnsetFlags = async (payload: {
  setFlags: StatusValues[]
  unsetFlags: StatusValues[]
  failedScreenData?: { screen: string; data: object } | Record<string, never>
  preliminaryQuestions?: PreliminaryQuestionsTypes
}) => {
  const { setFlags, unsetFlags, failedScreenData, preliminaryQuestions } =
    payload

  const { modifyFlags } = StatusApi()

  const data = await modifyFlags({
    setFlags,
    unsetFlags,
    failedScreenData,
    preliminaryQuestions,
  })

  return data.data
}

export const useSetFlags = () => {
  const client = useQueryClient()
  const { mutateAsync } = useMutation(setAndUnsetFlags, {
    onSuccess: () => client.removeQueries(USER_STATUS_QUERY_KEY),
  })

  return {
    setProcessFlags: mutateAsync,
  }
}
