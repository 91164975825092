import styled from 'styled-components'

const StyledLink = styled.a`
  left: 50%;
  position: absolute;
  padding: 5px;
  transform: translateY(-100%);
  transition: transform 0.3s;
  text-decoration: underline;
  &:focus {
    transform: translateY(0%);
  }
`

export const SkipToContentLink = () => {
  return <StyledLink href='#main'>Skip to content</StyledLink>
}
