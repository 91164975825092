import { useQuery, UseQueryResult } from 'react-query'
import { ApiError, GetLoansRespData, Loan } from 'api'
import { useApiClient } from 'lib/context'
import { loanKeys } from './queryKeys'

function useLoans<T = Loan[]>(select?: (resp: GetLoansRespData) => T) {
  const apiClient = useApiClient()

  return useQuery({
    queryKey: loanKeys.all,
    queryFn: () => apiClient.getLoans(),
    select: resp => (select ? select(resp) : resp.loans),
  }) as UseQueryResult<typeof select extends undefined ? Loan[] : T, ApiError>
}

export { useLoans }
