import * as Sentry from '@sentry/react'
import { getEnvVariable } from 'utils'

function captureSentryException(err: Error) {
  if (getEnvVariable('REACT_APP_ENVIRONMENT') === 'development') {
    return
  }

  Sentry.captureException(err)
}

export { captureSentryException }
