import MUITextField, {
  TextFieldProps as MUITextFieldProps,
} from '@mui/material/TextField'
import styled, { DefaultTheme } from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

function getDefaultColor(theme: DefaultTheme) {
  return theme.color.green[700]
}

function getDisabledColor(theme: DefaultTheme) {
  return theme.color.grey[700]
}

function getErrorColor(theme: DefaultTheme) {
  return theme.color.red.error
}

function getFocusedColor(theme: DefaultTheme) {
  return theme.color.primary
}

export const StyledTextField = styled(
  (props: Omit<MUITextFieldProps, 'variant' | 'size' | 'fullWidth'>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MUITextField {...props} variant='outlined' size='small' fullWidth />
  )
)`
  margin-bottom: 1.5rem;

  // Label
  .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fontFamily.body};
    color: ${({ theme }) => getDefaultColor(theme)};
  }

  // Value
  .MuiOutlinedInput-root {
    ${typographyStyles.body1};
    color: ${({ theme }) => getDefaultColor(theme)};
  }

  // Border
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => getDefaultColor(theme)};
  }

  // "required" asterisk
  .MuiFormLabel-asterisk {
    color: ${({ theme }) => getErrorColor(theme)};
  }

  // Focused
  .Mui-focused,
  &:hover {
    // Label
    color: ${({ theme }) => getFocusedColor(theme)};
    .MuiFormLabel-root {
      color: ${({ theme }) => getFocusedColor(theme)};
    }

    // Value
    .MuiOutlinedInput-input {
      color: ${({ theme }) => getFocusedColor(theme)};
    }

    // Border
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => getFocusedColor(theme)};
    }
  }

  // Disabled
  .Mui-disabled {
    // Label
    &.MuiInputLabel-outlined {
      color: ${({ theme }) => getDisabledColor(theme)};
    }

    // Value
    .MuiInputBase-input {
      -webkit-text-fill-color: ${({ theme }) => getDisabledColor(theme)};
    }

    // Border
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => getDisabledColor(theme)};
    }
  }

  // Error
  .Mui-error {
    // Label
    color: ${({ theme }) => getErrorColor(theme)};
    &.MuiInputLabel-outlined {
      color: ${({ theme }) => getErrorColor(theme)};
    }

    // Value
    .MuiInputBase-input {
      -webkit-text-fill-color: ${({ theme }) => getErrorColor(theme)};
    }

    // Border
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => getErrorColor(theme)};
    }
  }
`
