import { Industry } from 'api'

const INDUSTRY_TO_READABLE: Record<Industry, string> = {
  [Industry.AutomobilesAndComponents]: 'Automobiles and Components',
  [Industry.CapitalGoods]: 'Capital Goods',
  [Industry.CommercialAndProfessionalServices]:
    'Commercial and Professional Services',
  [Industry.ConsumerDurablesAndApparel]: 'Consumer Durables and Apparel',
  [Industry.ConsumerServices]: 'Consumer Services',
  [Industry.DiversifiedFinancials]: 'Diversified Financials',
  [Industry.Energy]: 'Energy',
  [Industry.FinancialServices]: 'Financial Services',
  [Industry.FoodBeverageAndTobacco]: 'Food, Beverage and Tobacco',
  [Industry.FoodAndStaplesRetailing]: 'Food and Staples Retailing',
  [Industry.HealthCareEquipmentAndServices]:
    'Health Care Equipment and Services',
  [Industry.HouseholdAndPersonalProducts]: 'Household and Personal Products',
  [Industry.Insurance]: 'Insurance',
  [Industry.Materials]: 'Materials',
  [Industry.MediaAndEntertainment]: 'Media and Entertainment',
  [Industry.PharmaceuticalsBiotechnologyAndLifeSciences]:
    'Pharmaceuticals, Biotechnology and Life Sciences',
  [Industry.RealEstate]: 'Real Estate',
  [Industry.Retailing]: 'Retailing',
  [Industry.SemiconductorsAndSemiconductorEquipment]:
    'Semiconductors and Semiconductor Equipment',
  [Industry.SoftwareAndServices]: 'Software and Services',
  [Industry.TechnologyHardwareAndEquipment]:
    'Technology Hardware and Equipment',
  [Industry.TelecommunicationServices]: 'Telecommunication Services',
  [Industry.Transportation]: 'Transportation',
  [Industry.Utilities]: 'Utilities',
}

const INDUSTRY_OPTIONS = Object.values(Industry).map(value => ({
  label: INDUSTRY_TO_READABLE[value],
  value,
}))

export { Industry, INDUSTRY_OPTIONS }
