import { ReactNode } from 'react'
import styled from 'styled-components'
import { paragraphCaptions } from 'ui-kit/Typography/Typography.styles'

const StyledErrorMessage = styled.p`
  ${paragraphCaptions};
  padding-top: 0.25rem;
  padding-left: 1rem;
  color: ${({ theme }) => theme.color.red.error};
`

interface ErrorMessageProps {
  children: ReactNode
  className?: string
}
export const ErrorMessage = (props: ErrorMessageProps) => {
  const { children, className } = props

  return (
    <StyledErrorMessage className={className}>{children}</StyledErrorMessage>
  )
}
