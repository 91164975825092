import MUIStack from '@mui/material/Stack'
import MUIButton from '@mui/material/Button'
import styled, { css, DefaultTheme } from 'styled-components'
import { StyledLoader } from 'ui/Loader/Loader.styles'

// primary = green background, white label
// secondary = orange background, white label
// tertiary = green border+label, transparent background
// destructive = red border+label, transparent background
enum ButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Destructive = 'Destructive',
  Text = 'Text',
}

type ButtonSizeVariant = 'default' | 'large'

function getBackgroundColor(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Primary:
      return theme.color.primary
    case ButtonVariant.Secondary:
      return theme.color.accent
    default:
      return theme.color.transparent
  }
}

function getLabelColor(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Primary:
    case ButtonVariant.Secondary:
      return theme.color.white
    case ButtonVariant.Tertiary:
    case ButtonVariant.Text:
      return theme.color.primary
    case ButtonVariant.Destructive:
      return theme.color.red.error
    default:
      return theme.color.transparent
  }
}

function getBorder(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Tertiary:
      return `1px solid ${theme.color.primary}`
    case ButtonVariant.Destructive:
      return `1px solid ${theme.color.red.error}`
    default:
      return 'none'
  }
}

function getHoverBackgroundColor(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Primary:
      return theme.color.green[600]
    case ButtonVariant.Secondary:
      return theme.color.pumpkin[600]
    case ButtonVariant.Tertiary:
    case ButtonVariant.Text:
      return theme.color.green[50]
    case ButtonVariant.Destructive:
      return theme.color.red[200]
    default:
      return getBackgroundColor(theme, variant)
  }
}

function getActiveBackgroundColor(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Primary:
      return theme.color.green[400]
    case ButtonVariant.Secondary:
      return theme.color.pumpkin[400]
    case ButtonVariant.Tertiary:
    case ButtonVariant.Text:
      return theme.color.green[100]
    case ButtonVariant.Destructive:
      return theme.color.red[300]
    default:
      return theme.color.transparent
  }
}

function getDisabledBackgroundColor(
  theme: DefaultTheme,
  variant: ButtonVariant
) {
  switch (variant) {
    case ButtonVariant.Primary:
      return theme.color.green[100]
    case ButtonVariant.Secondary:
      return theme.color.pumpkin[100]
    default:
      return theme.color.transparent
  }
}

function getDisabledLabelColor(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Tertiary:
    case ButtonVariant.Text:
      return theme.color.green[400]
    case ButtonVariant.Destructive:
      return theme.color.red[500]
    default:
      return theme.color.white
  }
}

function getDisabledBorderColor(theme: DefaultTheme, variant: ButtonVariant) {
  switch (variant) {
    case ButtonVariant.Tertiary:
    case ButtonVariant.Destructive:
    case ButtonVariant.Text:
      return getDisabledLabelColor(theme, variant)
    default:
      return theme.color.transparent
  }
}

function getPadding(variant: ButtonVariant, sizeVariant: ButtonSizeVariant) {
  const verticalPadding = sizeVariant === 'default' ? 0.75 : 1
  const horizontalPadding = sizeVariant === 'default' ? 1.5 : 2

  switch (variant) {
    case ButtonVariant.Tertiary:
    case ButtonVariant.Destructive:
      return `calc(${verticalPadding}rem - 1px) calc(${horizontalPadding}rem - 1px)`
    default:
      return `${verticalPadding}rem ${horizontalPadding}rem`
  }
}

function getLoadingIndicatorSize(sizeVariant: ButtonSizeVariant) {
  return sizeVariant === 'default' ? 17 : 20
}

function getLoadingIndicatorPos(sizeVariant: ButtonSizeVariant) {
  return `calc(50% - ${getLoadingIndicatorSize(sizeVariant) / 2.0}px)`
}

const StyledButton = styled(MUIButton)<{
  $variant: ButtonVariant
  $sizeVariant: ButtonSizeVariant
  $isLoading: boolean
}>`
  border-radius: 0.25rem;
  text-transform: none !important;

  ${({ theme, $variant, $sizeVariant, $isLoading }) => {
    return css`
      background-color: ${getBackgroundColor(theme, $variant)};
      color: ${getLabelColor(theme, $variant)};
      border: ${getBorder(theme, $variant)};
      padding: ${getPadding($variant, $sizeVariant)};
      width: ${$sizeVariant === 'default' ? 'auto' : '100%'};

      &:hover {
        background-color: ${getHoverBackgroundColor(theme, $variant)};
      }

      &:active {
        background-color: ${getActiveBackgroundColor(theme, $variant)};
      }

      &:disabled {
        ${!$isLoading &&
        css`
          background-color: ${getDisabledBackgroundColor(theme, $variant)};
          color: ${getDisabledLabelColor(theme, $variant)};
          border-color: ${getDisabledBorderColor(theme, $variant)};
        `}
      }
    `
  }}
`

const StyledButtonLoader = styled(StyledLoader)<{
  $buttonVariant: ButtonVariant
  $sizeVariant: ButtonSizeVariant
}>`
  color: ${({ theme, $buttonVariant }) => getLabelColor(theme, $buttonVariant)};
  position: absolute;
  top: ${({ $sizeVariant }) => getLoadingIndicatorPos($sizeVariant)};
  left: ${({ $sizeVariant }) => getLoadingIndicatorPos($sizeVariant)};
`

const StyledStack = styled(MUIStack)<{ $isLoading: boolean }>`
  visibility: ${({ $isLoading }) => ($isLoading ? 'hidden' : undefined)};
`

export type { ButtonSizeVariant }
export {
  StyledButton,
  ButtonVariant,
  StyledButtonLoader,
  StyledStack,
  getLoadingIndicatorSize,
  getLabelColor,
}
