import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesPaths } from 'routing'

export const useBrowserUrl = () => {
  const navigate = useNavigate()

  const navigateOutsideApp = useCallback((href: string) => {
    window.open(href, '_blank')
  }, [])

  const navigateOutsideAppSameTab = useCallback((href: string) => {
    window.open(href, '_self')
  }, [])

  const navigateClientRouting = useCallback(
    (pathname: string, state?: Record<string, unknown>) => {
      navigate(pathname, {
        state,
      })
    },
    [navigate]
  )

  const goToLoginPage = useCallback(() => {
    navigateClientRouting(RoutesPaths.Login)
  }, [navigateClientRouting])

  const goToDashboardPage = useCallback(() => {
    navigateClientRouting(RoutesPaths.Dashboard)
  }, [navigateClientRouting])

  return {
    navigateOutsideApp,
    navigateClientRouting,
    goToLoginPage,
    goToDashboardPage,
    navigateOutsideAppSameTab,
  }
}
