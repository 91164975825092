import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { RoutesPaths, useBrowserUrl } from 'routing'
import { useTranslations } from 'translations'
import { Button, ButtonVariant } from 'ui'
import {
  Box,
  FormGrid,
  LayoutOneColumnAndHeader,
  Link,
  Typography,
  useSnackbars,
  Wrappers,
} from 'ui-kit'
import {
  ForgotPasswordFormFieldSet,
  forgotPasswordFormInitialValues,
  forgotPasswordFormValidationSchema,
  ForgotPasswordFormValues,
} from 'form-kit'
import { AuthenticationApi } from 'api-connectors'
import { isAxiosError, isServerError } from 'error-handling'
import { useLogger } from 'logger'
import { hasPropertyBusinessEmail } from 'utils'
import { ForgotPasswordSeo } from './ForgotPasswordSeo.component'

const StyledBox = styled(Box)`
  margin-top: 2rem;

  ${({ theme }) => theme.media.minTablet} {
    margin-top: 3rem;
  }
`

export const ForgotPasswordPage = () => {
  const [status, setStatus] = useState<'IDLE' | 'LOADING'>('IDLE')
  const { goToLoginPage, navigateClientRouting } = useBrowserUrl()
  const t = useTranslations()
  const { forgotPassword } = AuthenticationApi()
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const { state } = useLocation()

  const businessEmailState =
    hasPropertyBusinessEmail(state) && state.businessEmail

  const formValues: ForgotPasswordFormValues = businessEmailState
    ? { businessEmail: businessEmailState }
    : forgotPasswordFormInitialValues

  const headerCopy = businessEmailState
    ? t('pages.forgotPasswordPage.headerReset')
    : t('pages.forgotPasswordPage.header')

  const handleSubmit = async (values: ForgotPasswordFormValues) => {
    setStatus('LOADING')

    try {
      await forgotPassword(values)

      navigateClientRouting(`${RoutesPaths.ForgotPasswordCheckEmail}`, {
        businessEmail: values.businessEmail,
      })
    } catch (err) {
      let uiMessage = ''
      if (isAxiosError(err)) {
        if (isServerError(err)) {
          uiMessage = t('errorMessages.SYSTEM_FAILURE')
          logger.error(uiMessage, { error: err.response?.data })
        } else {
          uiMessage = t('errorMessages.UNEXPECTED_ERROR')
          logger.error(uiMessage, { error: err.message })
        }
        showErrorSnackbar(uiMessage)
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
        logger.error(uiMessage, { error: err })
        showErrorSnackbar(uiMessage)
      }

      setStatus('IDLE')
    }
  }

  return (
    <>
      <ForgotPasswordSeo />
      <LayoutOneColumnAndHeader onBackButtonClick={goToLoginPage}>
        <Typography.H1>{headerCopy}</Typography.H1>
        <Typography.P>{t('pages.forgotPasswordPage.content')}</Typography.P>
        <Wrappers.FormWrapperAuth>
          <Formik<ForgotPasswordFormValues>
            validateOnChange={false}
            validationSchema={forgotPasswordFormValidationSchema}
            initialValues={formValues}
            onSubmit={values => {
              if (status === 'IDLE') {
                handleSubmit(values)
              }
            }}
          >
            {() => (
              <Form>
                <ForgotPasswordFormFieldSet />
                <FormGrid.CTAWrapper>
                  <Button
                    label={t('pages.forgotPasswordPage.button')}
                    variant={ButtonVariant.Primary}
                    sizeVariant='large'
                    type='submit'
                    isLoading={status === 'LOADING'}
                  />
                </FormGrid.CTAWrapper>
              </Form>
            )}
          </Formik>
        </Wrappers.FormWrapperAuth>
        <StyledBox>
          <Link to={RoutesPaths.Login}>
            {t('pages.forgotPasswordPage.signInLink')}
          </Link>
        </StyledBox>
      </LayoutOneColumnAndHeader>
    </>
  )
}
