import { useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { RoutesPaths } from 'routing'
import { ReactComponent as FundidSVG } from 'assets/svgs/fundid.svg'
import { IconButton, IconType } from 'ui'
import { StyledTopBar } from './LogoPanelMobile.styles'
import { useNav } from '../../NavProvider/NavProvider.component'

function LogoPanelMobile() {
  const { isNavOpen, toggleNav } = useNav()
  const { color } = useTheme()

  return (
    <StyledTopBar>
      <Link to={RoutesPaths.Dashboard}>
        <FundidSVG width='6rem' fill={color.background} />
      </Link>
      <IconButton
        type={isNavOpen ? IconType.Close : IconType.Hamburger}
        color={color.background}
        onClick={toggleNav}
      />
    </StyledTopBar>
  )
}

export { LogoPanelMobile }
