import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import { ReactNode } from 'react'
import { IconType } from 'ui/Icon/Icon.component'
import { IconButton } from 'ui/IconButton/IconButton.component'

type ModalTitleProps = {
  children: ReactNode
  onClose?: () => void
}

function ModalTitle({ children, onClose }: ModalTitleProps) {
  const { color } = useTheme()

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      spacing={1}
      mb={2}
    >
      {children}
      {onClose && (
        <IconButton
          type={IconType.Close}
          color={color.grey[900]}
          onClick={onClose}
        />
      )}
    </Stack>
  )
}

export { ModalTitle }
