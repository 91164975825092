import { ReactNode } from 'react'
import { HintMessage } from 'ui-kit'

interface AdapterHintMessageProps {
  hint: ReactNode
}

export const AdapterHintMessage = (props: AdapterHintMessageProps) => {
  const { hint } = props

  return <HintMessage>{hint}</HintMessage>
}
