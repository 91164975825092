import MUITablePagination from '@mui/material/TablePagination'
import styled from 'styled-components'
import { Paper } from 'ui/Paper/Paper.component'
import { Typography, TypographyProps } from 'ui/Typography/Typography.component'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const StyledPaper = styled(Paper)`
  padding: 0;
  padding-top: 0.5rem;
`

const EmptyPaper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
`

const TableHeader = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h3' />
))`
  margin-bottom: 0.5rem;
`

const TablePagination = styled(MUITablePagination)<{ component: string }>`
  .MuiToolbar-root {
    flex-wrap: wrap;
    padding: 1rem;
    justify-content: center;

    .MuiTablePagination-actions {
      font-size: 2rem;
      width: 100%;
      margin: 0;
      text-align: center;
    }

    ${({ theme }) => theme.media.minTablet} {
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      padding: 0 0.25rem 0 0;

      .MuiTablePagination-actions {
        width: auto;
        margin-left: 1.25rem;
      }
    }
  }

  .MuiToolbar-root,
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    ${typographyStyles.caption};
  }
`

export { EmptyPaper, StyledPaper, TableHeader, TablePagination }
