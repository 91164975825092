import MUIMenuItem, {
  MenuItemProps as MUIMenuItemProps,
} from '@mui/material/MenuItem'

type MenuItemProps = Pick<MUIMenuItemProps, 'children' | 'onClick'>

function MenuItem({ onClick, children }: MenuItemProps) {
  return <MUIMenuItem onClick={onClick}>{children}</MUIMenuItem>
}

export { MenuItem }
