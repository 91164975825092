import { FieldProps } from 'formik'
import { HTMLInputTypeAttribute } from 'react'
import {
  InputCardNumber,
  InputCurrency,
  InputText,
  StyledInputHint,
} from 'ui-kit'
import { InputPassword } from 'ui-kit/form-elements/InputPassword/InputPassword.component'
import { AdapterErrorMessage } from '../AdapterErrorMessage/AdapterErrorMessage.component'
import { AdapterHintMessage } from '../AdapterHintMessage/AdapterHintMessage.component'
import { InputDate } from '../../../ui-kit/form-elements/InputDate/InputDate.component'

type CustomInputType = 'currency' | 'card-number'

interface AdapterInputTextProps extends FieldProps {
  type: CustomInputType | HTMLInputTypeAttribute
  isRequired?: boolean
  ariaLabel: string
  testId?: string
  label: string
  hint?: string
  max?: Date
}

const getCustomInput = (
  type: CustomInputType | string
): typeof InputCurrency | typeof InputCardNumber => {
  if (type === 'currency') {
    return InputCurrency
  }
  return InputCardNumber
}

// Handles both Text and Password inputs based on the type props

export const AdapterInput = (props: AdapterInputTextProps) => {
  const {
    field,
    label,
    form,
    meta,
    type,
    isRequired,
    ariaLabel,
    hint,
    testId,
    ...rest
  } = props
  const { name, value, onChange } = field

  const hasError = Boolean(form.errors[name])

  if (type === 'password') {
    return (
      <>
        <InputPassword
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          hasError={hasError}
          isRequired={isRequired}
          ariaLabel={ariaLabel}
        />
        {hasError ? (
          <AdapterErrorMessage name={name} />
        ) : (
          hint && <StyledInputHint>{hint}</StyledInputHint>
        )}
      </>
    )
  }

  if (type === 'currency' || type === 'card-number') {
    return (
      <>
        <InputText
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          hasError={hasError}
          isRequired={isRequired}
          type='text'
          testId={testId}
          InputProps={{
            inputComponent: getCustomInput(type),
          }}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          inputProps={rest}
        />
        {hasError ? (
          <AdapterErrorMessage name={name} />
        ) : (
          <AdapterHintMessage hint={hint} />
        )}
      </>
    )
  }

  if (type === 'date') {
    const { max } = props
    return (
      <>
        <InputDate
          label={label}
          field={field}
          form={form}
          meta={meta}
          max={max}
          isRequired={isRequired}
          hasError={hasError}
          testId={testId}
        />
        {hasError ? (
          <AdapterErrorMessage name={name} />
        ) : (
          <AdapterHintMessage hint={hint} />
        )}
      </>
    )
  }

  return (
    <>
      <InputText
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        hasError={hasError}
        isRequired={isRequired}
        type={type}
        testId={testId}
      />
      {hasError ? (
        <AdapterErrorMessage name={name} />
      ) : (
        <AdapterHintMessage hint={hint} />
      )}
    </>
  )
}
