/* eslint-disable no-console */

import { Logger, LogLevel, LogParams } from '../../types/Logger.types'

export class ConsoleLogger implements Logger {
  static methodMap = {
    debug: console.debug,
    info: console.info,
    warn: console.warn,
    error: console.error,
  }

  send(level: LogLevel, message: string, params?: LogParams) {
    ConsoleLogger.methodMap[level](`${level}: ${message}`, params || '')
  }

  debug(message: string, params?: LogParams) {
    console.debug(`DEBUG: ${message}`, params || '')
  }

  info(message: string, params?: LogParams) {
    console.info(`INFO: ${message}`, params || '')
  }

  warn(message: string, params?: LogParams) {
    console.warn(`WARN: ${message}`, params || '')
  }

  error(message: string, params?: LogParams) {
    console.error(`ERROR: ${message}`, params || '')
  }
}
