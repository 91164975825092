import { useCallback } from 'react'
import { Row } from '@tanstack/react-table'
import { PageContainer } from 'shared'
import { useTranslations } from 'translations'
import { Loader, Table } from 'ui'
import {
  OfferRow,
  useGrowthPartnersTable,
} from './hooks/useGrowthPartnersTable'
import { OfferDetailsModal } from './components/OfferDetailsModal.component'

function GrowthPartnersPage() {
  const t = useTranslations()
  const {
    columns,
    data,
    selectedOffer,
    setSelectedOffer,
    clearSelectedOffer,
    isLoading,
  } = useGrowthPartnersTable()

  const onClickRow = useCallback(
    (row: Row<OfferRow>) => {
      setSelectedOffer(row.original.id)
    },
    [setSelectedOffer]
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <PageContainer
      title={t('pages.growthPartners.title')}
      subtitle={t('pages.growthPartners.subtitle')}
      helmetTitle={t('pages.growthPartners.seoTitle')}
    >
      <Table
        columns={columns}
        data={data}
        emptyMessage={t('pages.growthPartners.table.emptyMessage')}
        defaultPageSize={25}
        defaultSorting={{ id: 'partner', desc: false }}
        searchBarProps={{
          placeholder: t('pages.growthPartners.table.searchBarPlaceholder'),
        }}
        onClickRow={onClickRow}
      />

      <OfferDetailsModal offer={selectedOffer} onClose={clearSelectedOffer} />
    </PageContainer>
  )
}

export { GrowthPartnersPage }
