import { useEffect, useState } from 'react'
import { useLogger } from 'logger'
import { HeaderSpacingWrapperSmall, StyledMain } from 'shared'
import { useTranslations } from 'translations'
import { ErrorScreen, Icon, IconType, Typography, KnowledgeBase } from 'ui-kit'
import { createAndAppendScript } from 'utils'
import { StyledWrapper } from './ContactSupportPage.styles'
import { ContactSupportSeo } from './ContactSupportSeo.component'

const FORM_TARGET_ID = 'hubspotForm'

const ContactSupportFormConfig = {
  region: 'na1',
  portalId: '6737579',
  formId: '3d3a6442-d000-45a4-a76e-aa6e4925a412',
  target: `#${FORM_TARGET_ID}`,
}

let formInstanceId = 1

export const ContactSupportPage = () => {
  const [scriptLoaded, setScriptLoaded] = useState(true)
  const { logger } = useLogger()

  const t = useTranslations()

  useEffect(() => {
    const script = createAndAppendScript({
      src: '//js.hsforms.net/forms/v2.js',
    })

    const createForm = () => {
      window.hbspt.forms.create({
        ...ContactSupportFormConfig,
        formInstanceId: formInstanceId.toString(),
      })
    }
    const handleError = () => {
      logger.error('Could not load the contact form script')
      setScriptLoaded(false)
    }

    script.addEventListener('load', createForm)
    script.addEventListener('error', handleError)

    return () => {
      if (script) {
        script.removeEventListener('load', createForm)
        script.removeEventListener('error', handleError)
        document.body.removeChild(script)
        formInstanceId += 1
      }
    }
  }, [logger])

  if (!scriptLoaded) {
    return (
      <StyledMain id='main'>
        <ErrorScreen
          leadContent={t('pages.contactSupportPage.errorScreen.lead')}
          title={t('pages.contactSupportPage.errorScreen.title')}
          icon={<Icon type={IconType.error} />}
        />
      </StyledMain>
    )
  }

  return (
    <>
      <ContactSupportSeo />
      <StyledMain id='main'>
        <HeaderSpacingWrapperSmall>
          <Typography.H1MainLayout>
            {t('pages.contactSupportPage.header')}
          </Typography.H1MainLayout>
        </HeaderSpacingWrapperSmall>
        <KnowledgeBase />
        <StyledWrapper>
          <div id={FORM_TARGET_ID} />
        </StyledWrapper>
      </StyledMain>
    </>
  )
}
