import MUITableCell from '@mui/material/TableCell'
import MUITableRow from '@mui/material/TableRow'
import styled, { css } from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const ExpandableTableCell = styled(MUITableCell)`
  border: unset;
  padding-top: 0;
  padding-bottom: 0;
`

const ExpandableTableRow = styled(MUITableRow)`
  background-color: ${({ theme }) => theme.color.grey[300]};
`

const StyledTableRow = styled(MUITableRow)<{ $isClickable: boolean }>`
  ${({ $isClickable }) => {
    return $isClickable
      ? css`
          transition: background-color 0.3s ease;
          :hover {
            background-color: ${({ theme }) => theme.color.grey[400]};
          }
        `
      : css``
  }}
`

const StyledTableCell = styled(MUITableCell)`
  ${typographyStyles.caption}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export {
  StyledTableRow,
  ExpandableTableCell,
  ExpandableTableRow,
  StyledTableCell,
}
