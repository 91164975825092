import { OfferCategory } from 'api'

const OFFER_CATEGORY_TO_READABLE: Record<OfferCategory, string> = {
  [OfferCategory.Accounting]: 'Accounting',
  [OfferCategory.Banking]: 'Banking',
  [OfferCategory.BusinessFormation]: 'Business Formation',
  [OfferCategory.CustomerManagement]: 'Customer Management',
  [OfferCategory.Design]: 'Design',
  [OfferCategory.Insurance]: 'Insurance',
  [OfferCategory.Marketing]: 'Marketing',
  [OfferCategory.Other]: 'Other',
  [OfferCategory.Payments]: 'Payments',
  [OfferCategory.Payroll]: 'Payroll',
  [OfferCategory.Retirement]: 'Retirement',
  [OfferCategory.Website]: 'Website',
}

const OFFER_CATEGORY_OPTIONS = Object.values(OfferCategory)
  .sort((a, b) => (a < b ? -1 : 1))
  .map(value => ({
    label: OFFER_CATEGORY_TO_READABLE[value],
    value,
  }))

export { OFFER_CATEGORY_TO_READABLE, OFFER_CATEGORY_OPTIONS }
