import { ElementType, ReactNode } from 'react'
import { StyledTypography, TypographyVariant } from './Typography.styles'

export type TypographyProps = {
  children: ReactNode
  variant: TypographyVariant
  bold?: boolean
  className?: string
  component?: ElementType
}

export function Typography({
  children,
  variant,
  bold,
  className,
  component,
}: TypographyProps) {
  return (
    <StyledTypography
      variant={variant}
      $bold={bold}
      className={className}
      component={component}
    >
      {children}
    </StyledTypography>
  )
}
