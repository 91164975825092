/* eslint-disable no-unused-vars,react/prop-types,no-shadow */
import { forwardRef, ReactNode } from 'react'
import { ButtonProps as ButtonPropsMui } from '@mui/material/Button'
import Loader from '@mui/material/CircularProgress'
import { StyledButton } from './Button.styles'

export enum ButtonSize {
  BIG_WIDE = 'BIG_WIDE',
  BIG = 'BIG',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
  XXSMALL = 'XXSMALL',
}

export enum ButtonColor {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  INFO = 'INFO',
}
export interface ButtonProps extends ButtonPropsMui, Record<string, unknown> {
  children: ReactNode
  sizeVariant?: ButtonSize
  colorVariant?: ButtonColor
  isLoading?: boolean
  className?: string
  testId?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const {
      children,
      sizeVariant = ButtonSize.BIG,
      colorVariant = ButtonColor.PRIMARY,
      disabled = false,
      isLoading = false,
      variant,
      id,
      fullWidth,
      type,
      onClick,
      className,
      startIcon,
      endIcon,
      testId,
      ...rest
    } = props

    return (
      <StyledButton
        ref={ref}
        className={className}
        disableFocusRipple
        disableTouchRipple
        id={id}
        disableRipple
        aria-disabled={disabled}
        variant={variant}
        $sizeVariant={sizeVariant}
        $colorVariant={colorVariant}
        fullWidth={fullWidth}
        type={type}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        data-testid={
          testId
        } /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
      >
        {isLoading ? <Loader color='inherit' /> : children}
      </StyledButton>
    )
  }
)
