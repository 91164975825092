import { Address, FundidDate } from './shared'

enum CompaniesEndpoints {
  GetCompany = '/companies',
  CreateCompany = '/companies',
}

enum BusinessEntity {
  Corporation = 'CORPORATION',
  LLC = 'LLC',
  NonProfit = 'NON_PROFIT',
  Partnership = 'PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  Other = 'OTHER',
}

enum Industry {
  AutomobilesAndComponents = 'AUTOMOBILES_AND_COMPONENTS',
  CapitalGoods = 'CAPITAL_GOODS',
  CommercialAndProfessionalServices = 'COMMERCIAL_AND_PROFESSIONAL_SERVICES',
  ConsumerDurablesAndApparel = 'CONSUMER_DURABLES_AND_APPAREL',
  ConsumerServices = 'CONSUMER_SERVICES',
  DiversifiedFinancials = 'DIVERSIFIED_FINANCIALS',
  Energy = 'ENERGY',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodBeverageAndTobacco = 'FOOD_BEVERAGE_AND_TOBACCO',
  FoodAndStaplesRetailing = 'FOOD_AND_STAPLES_RETAILING',
  HealthCareEquipmentAndServices = 'HEALTHCARE_EQUIPMENT_AND_SERVICES',
  HouseholdAndPersonalProducts = 'HOUSEHOLD_AND_PERSONAL_PRODUCTS',
  Insurance = 'INSURANCE',
  Materials = 'MATERIALS',
  MediaAndEntertainment = 'MEDIA_AND_ENTERTAINMENT',
  PharmaceuticalsBiotechnologyAndLifeSciences = 'PHARMACEUTICALS_BIOTECHNOLOGY_AND_LIFE_SCIENCES',
  RealEstate = 'REAL_ESTATE',
  Retailing = 'RETAILING',
  SemiconductorsAndSemiconductorEquipment = 'SEMICONDUCTORS_AND_SEMICONDUCTOR_EQUIPMENT',
  SoftwareAndServices = 'SOFTWARE_AND_SERVICES',
  TechnologyHardwareAndEquipment = 'TECHNOLOGY_HARDWARE_AND_EQUIPMENT',
  TelecommunicationServices = 'TELECOMMUNICATION_SERVICES',
  Transportation = 'TRANSPORTATION',
  Utilities = 'UTILITIES',
}

type Company = {
  id: number
  name: string
  dba?: string
  ein: string
  entity: BusinessEntity
  dateEstablished: FundidDate
  industry: Industry
  monthlyRevenue: number
  address: Address
  finicityCustomerID?: string // If Finicity linked
  accountID?: number // If has credit account
}

type GetCompanyQueryParams = {
  userID: number
}

type GetCompanyRespData = {
  company: Company
}

type CreateCompanyReqBody = {
  userID: number
  name: string
  dba?: string
  ein: string
  entity: BusinessEntity
  dateEstablished: FundidDate
  industry: Industry
  monthlyRevenue: number
  address: Address
  accountID?: number
}

type CreateCompanyRespData = {
  id: number
}

export type {
  GetCompanyQueryParams,
  GetCompanyRespData,
  Company,
  CreateCompanyReqBody,
  CreateCompanyRespData,
}
export { CompaniesEndpoints, BusinessEntity, Industry }
