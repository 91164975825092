import { FormControl, ToggleButtonGroup } from '@mui/material'
import styled from 'styled-components'
import { FormGrid, VisuallyHiddenFormLegend } from 'ui-kit'
import { useTranslations } from 'translations'
import {
  RevenueRadioField,
  RevenueValue,
} from '../../fields/RevenueRadioField/RevenueRadioField.component'

export interface RevenueFormValues {
  revenue: RevenueValue | undefined
}

export const revenueFormInitialValues = {
  revenue: undefined,
}

export const StyledWrapper = styled(ToggleButtonGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }

  .MuiFormControlLabel-label {
    display: none;
  }
`

export const RevenueFieldSet = () => {
  const t = useTranslations()

  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.revenueFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <FormControl fullWidth>
          <StyledWrapper>
            <RevenueRadioField
              label={t('formKit.revenueFieldSet.UNDER_50_K')}
              name='revenue'
              id='UNDER_50_K'
              value={{ slug: 'UNDER_50_K', value: true }}
            />
            <RevenueRadioField
              label={t('formKit.revenueFieldSet.50_100_K')}
              name='revenue'
              id='50_100_K'
              value={{ slug: '50_TO_100_K', value: true }}
            />
            <RevenueRadioField
              label={t('formKit.revenueFieldSet.100_250_K')}
              name='revenue'
              id='100_TO_250_K'
              value={{ slug: '100_TO_250_K', value: true }}
            />
            <RevenueRadioField
              label={t('formKit.revenueFieldSet.OVER_250_K')}
              name='revenue'
              id='OVER_250_K'
              value={{ slug: 'OVER_250_K', value: true }}
            />
          </StyledWrapper>
        </FormControl>
      </FormGrid.Grid>
    </fieldset>
  )
}
