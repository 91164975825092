import { MenuProps as MUIMenuProps } from '@mui/material/Menu'
import { StyledMenu } from './Menu.styles'

type MenuProps = {
  isOpen: boolean
} & Pick<
  MUIMenuProps,
  'onClose' | 'anchorEl' | 'anchorOrigin' | 'transformOrigin' | 'children'
>

function Menu({
  isOpen,
  onClose,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  children,
}: MenuProps) {
  return (
    <StyledMenu
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {children}
    </StyledMenu>
  )
}

export { Menu }
