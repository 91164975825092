import { useMemo } from 'react'
import { Loan, formatAPR, useFormatAmount } from 'lib/models'
import {
  PartnerDetailsModal,
  PartnerDetailsModalLabelProps,
  PartnerDetailsModalSectionProps,
} from 'shared'
import { useTranslations } from 'translations'
import { IconType } from 'ui'

type LoanDetailsModalProps = {
  loan: Loan | undefined
  onClose: () => void
}

function LoanDetailsModal({ loan, onClose }: LoanDetailsModalProps) {
  const t = useTranslations()
  const formatAmount = useFormatAmount()

  const labels: PartnerDetailsModalLabelProps[] = useMemo(() => {
    if (!loan) {
      return []
    }

    const { amountMin, amountMax, aprMin, aprMax, creditScoreMin, partner } =
      loan

    return [
      {
        label: `${t('pages.capitalMarketplace.modal.amount')}: ${formatAmount(
          amountMin,
          amountMax
        )}`,
        iconType: IconType.DollarSign,
      },
      {
        label: `${t('pages.capitalMarketplace.modal.apr')}: ${formatAPR(
          aprMin,
          aprMax
        )}`,
        iconType: IconType.Bank,
      },
      {
        label: `${t('pages.capitalMarketplace.modal.lender')}: ${partner.name}`,
        iconType: IconType.House,
      },
      {
        label: `${t('pages.capitalMarketplace.modal.minimumCreditScore')}: ${
          creditScoreMin ? `${creditScoreMin} FICO` : 'N/A'
        }`,
        iconType: IconType.Card,
      },
    ]
  }, [loan, formatAmount, t])

  const sections: PartnerDetailsModalSectionProps[] = useMemo(() => {
    if (!loan) {
      return []
    }

    const { description, qualifications, partner } = loan

    const secs: PartnerDetailsModalSectionProps[] = [
      {
        header: `${t('pages.capitalMarketplace.modal.aboutTheLoan')}`,
        iconType: IconType.Disclosure,
        description,
      },
      {
        header: `${t('pages.capitalMarketplace.modal.aboutTheLender')}`,
        iconType: IconType.House,
        description: partner.about,
      },
    ]

    if (qualifications.length) {
      secs.splice(1, 0, {
        header: `${t('pages.capitalMarketplace.modal.qualifications')}`,
        iconType: IconType.Checkmark,
        description: qualifications,
      })
    }

    return secs
  }, [loan, t])

  return (
    <PartnerDetailsModal
      isOpen={!!loan}
      onClose={onClose}
      title={loan?.name || ''}
      logoUrl={loan?.partner.logoURL || ''}
      labels={labels}
      sections={sections}
      button={{
        label: t('pages.capitalMarketplace.modal.applyNow'),
        externalUrl: loan?.url || '',
      }}
    />
  )
}

export { LoanDetailsModal }
