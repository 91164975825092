import { useLocation } from 'react-router-dom'
import { MouseEvent, useState } from 'react'
import { Stack } from '@mui/material'
import { useTranslations } from 'translations'
import { useUser } from 'lib/queries'
import { RoutesPaths, useBrowserUrl } from 'routing'
import { IconType, Menu, MenuItem } from 'ui'
import { useLogout } from 'shared/LogoutButton/LogoutButton.component'
import { SidebarListItemContent } from './SidebarListItem.component'
import { SidebarListItemButton } from './SidebarExpandableListItem.styles'
import { useNav } from '../NavProvider/NavProvider.component'

function ProfileSidebarListItem() {
  const t = useTranslations()
  const { isNavOpen, isMobile, toggleNav } = useNav()
  const { pathname } = useLocation()
  const { navigateClientRouting } = useBrowserUrl()
  const { data: user } = useUser()
  const logout = useLogout()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    onClose()
    logout()
  }

  const onClickProfile = () => {
    onClose()

    if (isMobile) {
      toggleNav()
    }

    navigateClientRouting(RoutesPaths.Profile)
  }

  if (!user) {
    return null
  }

  return (
    <>
      <Menu
        isOpen={!!anchorEl}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onClickProfile}>
          {t('shared.navbar.profile')}
        </MenuItem>
        <MenuItem onClick={onLogout}>{t('shared.navbar.logout')}</MenuItem>
      </Menu>

      <Stack alignItems='center'>
        <SidebarListItemButton
          isNavOpen={isNavOpen}
          isActive={pathname === RoutesPaths.Profile}
          onClick={openMenu}
        >
          <SidebarListItemContent
            label={`${user.name.first} ${user.name.last}`}
            iconType={IconType.Avatar}
            isNavOpen={isNavOpen}
          />
        </SidebarListItemButton>
      </Stack>
    </>
  )
}

export { ProfileSidebarListItem }
