import styled from 'styled-components'
import { Card, Typography, TypographyProps } from 'ui'

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
`

const StyledCardTitle = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h3' />
))`
  margin: 1.5rem 0;
  text-align: center;
`

export { StyledCard, StyledCardTitle }
