import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { PaymentAmountType, PaymentFrequency, PaymentSchedule } from 'api'
import {
  DateFormat,
  dateFormatter,
  useFormatCurrency,
  useFormatDate,
} from 'lib/formatter'
import { useTranslations } from 'translations'
import {
  getPaymentAmountTypeTranslation,
  getPaymentFrequencyTranslation,
} from 'lib/models'
import { PaymentsTableDescriptionCell } from '../components/PaymentsTableDescriptionCell'

type UpcomingPaymentRow = {
  amount: number
  amountType: PaymentAmountType
  date: Date
  description: string
  frequency: PaymentFrequency
}

const columnHelper = createColumnHelper<UpcomingPaymentRow>()

function useUpcomingPaymentsTable(upcomingPayments: PaymentSchedule[]) {
  const t = useTranslations()
  const formatCurrency = useFormatCurrency()
  const formatDate = useFormatDate()

  const columns = useMemo(
    () => [
      columnHelper.accessor('description', {
        header: t('pages.payments.upcomingPaymentsTable.upcomingPayments'),
        cell: info => <PaymentsTableDescriptionCell label={info.getValue()} />,
        sortingFn: 'text',
      }),
      columnHelper.accessor('date', {
        header: t('pages.payments.upcomingPaymentsTable.date'),
        cell: info => formatDate(info.getValue(), DateFormat.MonthDayYear),
        sortingFn: 'datetime',
      }),

      columnHelper.accessor('amount', {
        header: t('pages.payments.upcomingPaymentsTable.amount'),
        cell: info => {
          const { amountType } = info.row.original
          return amountType === PaymentAmountType.Fixed
            ? formatCurrency(info.getValue())
            : getPaymentAmountTypeTranslation(t, amountType)
        },
        sortingFn: 'alphanumeric',
        meta: {
          align: 'right',
        },
      }),
    ],
    [t, formatCurrency, formatDate]
  )

  const data: UpcomingPaymentRow[] = useMemo(
    () =>
      upcomingPayments.map(({ date, fixedAmount, amountType, frequency }) => ({
        amount: fixedAmount,
        amountType,
        date: dateFormatter.toDate(date), // use Date instead of FundidDate so rows are easily sortable
        description: getPaymentFrequencyTranslation(t, frequency),
        frequency,
      })),
    [t, upcomingPayments]
  )

  return {
    columns,
    data,
  }
}

export { useUpcomingPaymentsTable }
