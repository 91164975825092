import { ChangeEventHandler, forwardRef } from 'react'
import NumberFormat from 'react-number-format'

interface InputCardNumberProps {
  onChange?: ChangeEventHandler<HTMLInputElement>
  name?: string
  maxlength?: number
}

export const InputCardNumber = forwardRef<
  HTMLInputElement,
  InputCardNumberProps
>((props, ref) => {
  const { onChange, maxlength, ...other } = props

  return (
    <NumberFormat
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
      isAllowed={({ value }) => {
        if (maxlength && value.length > maxlength) {
          return false
        }
        return true
      }}
      onValueChange={(values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
          return
        }
        onChange?.(sourceInfo.event)
      }}
      allowNegative={false}
      decimalScale={0}
      getInputRef={ref}
      isNumericString
      allowLeadingZeros
    />
  )
})
