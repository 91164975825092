import { ReactNode } from 'react'
import { HelmetProvider as HelmetProviderComponent } from 'react-helmet-async'

interface HelmetProviderProps {
  children: ReactNode
}

export const HelmetProvider = (props: HelmetProviderProps) => {
  const { children } = props
  return <HelmetProviderComponent>{children}</HelmetProviderComponent>
}
