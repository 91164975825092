import styled, { css } from 'styled-components'
import { Link as ReactRouterDomLink, NavLink } from 'react-router-dom'

import {
  paragraphCaptions,
  paragraphBody,
} from '../Typography/Typography.styles'

export const sharedLinkStyles = css`
  ${paragraphCaptions};
  text-decoration: underline;
`

export const ActionLink = styled(ReactRouterDomLink)`
  font-weight: bold;
`

export const Link = styled(ReactRouterDomLink)`
  ${sharedLinkStyles}
  color: ${({ theme }) => theme.color.primary};
  ${({ theme }) => theme.media.minTablet} {
    ${paragraphBody}
    text-align: center;
  }
`
export const LocalRouteLink = styled(NavLink)`
  ${sharedLinkStyles}
  color: ${({ theme }) => theme.color.primary};
  ${({ theme }) => theme.media.minTablet} {
    ${paragraphBody}
    text-align: center;
  }
`
