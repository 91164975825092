import styled, { css } from 'styled-components'
import { HEIGHT_TOP_NAV } from 'shared/Navbar/NavMobile/NavMobile.styles'
import {
  WIDTH_CLOSED_SIDEBAR,
  WIDTH_OPENED_SIDEBAR,
} from '../Navbar/NavDesktop/NavDesktop.styles'

export const StyledMain = styled.main`
  padding: 1rem;
  max-width: 71.875rem;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.minTablet} {
    padding: 2rem;
  }
`

export const HeaderSpacingWrapper = styled.div`
  margin-bottom: 3rem;
  ${({ theme }) => theme.media.minTablet} {
    margin-bottom: 5rem;
  }
`

export const HeaderSpacingWrapperSmall = styled.div`
  margin-bottom: 2rem;
`
export const StyledContainer = styled.div<{ isNavOpen: boolean }>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${HEIGHT_TOP_NAV} auto;
  height: 100%;
  transition: margin 0.2s;

  ${({ theme }) => theme.media.minTablet} {
    display: grid;
    grid-template-rows: auto;

    ${({ isNavOpen }) => {
      if (isNavOpen) {
        return css`
          margin-left: ${WIDTH_OPENED_SIDEBAR};
        `
      }
      return css`
        margin-left: ${WIDTH_CLOSED_SIDEBAR};
      `
    }}
  }
`

export const StyledOutletContainer = styled.div`
  grid-row: 2;
  ${({ theme }) => theme.media.minTablet} {
    grid-row: unset;
  }
`
