import Grid from '@mui/material/Grid'
import { getAutopayAmount } from 'lib/models'
import {
  useAccount,
  useAutopaySchedule,
  useCards,
  useIsCardFunctionalityEnabled,
  useTransactions,
  useUser,
} from 'lib/queries'
import { CardStatus } from 'api'
import { RoutesPaths, useBrowserUrl } from 'routing'
import {
  ActivateCardAlert,
  ActivateCardEntrypoint,
  CreditSummaryCards,
  TransactionsTable,
} from 'shared'
import { useTranslations } from 'translations'
import { Button, ButtonVariant, Loader } from 'ui'
import { TableFooterContainer } from './CreditAccountHolderDashboard.styles'
import { PaymentsCard } from './PaymentsCard.component'
import { useDashboardCreditSummaryCards } from '../hooks/useDashboardCreditSummaryCards'

const TRANSACTIONS_TABLE_RECENT_ITEMS_COUNT = 10

function CreditAccountHolderDashboard() {
  const t = useTranslations()
  const { navigateClientRouting } = useBrowserUrl()
  const { data: user } = useUser()
  const { data: account } = useAccount()
  const { data: cards } = useCards()
  const {
    isCardFunctionalityEnabled,
    isLoading: isLoadingIsCardFunctionalityEnabled,
  } = useIsCardFunctionalityEnabled()
  const { data: creditSummaryCards } = useDashboardCreditSummaryCards()
  const { data: transactions } = useTransactions()
  const { data: autopaySchedule } = useAutopaySchedule()

  const isLoading =
    !user ||
    !account ||
    !cards ||
    !creditSummaryCards ||
    isLoadingIsCardFunctionalityEnabled ||
    !transactions
  if (isLoading) {
    return <Loader />
  }

  const { physical: physicalCard } = cards

  // If card functionality not yet enabled, user needs to complete activate card flow
  if (!isCardFunctionalityEnabled) {
    // There's a ~3 second gap in real time between credit account creation + physical
    // card creation which can trigger this loading state
    return !physicalCard ? (
      <Loader />
    ) : (
      <ActivateCardEntrypoint cardId={physicalCard.id} />
    )
  }

  return (
    <>
      {physicalCard?.status === CardStatus.NotYetActive && (
        <ActivateCardAlert cardId={physicalCard.id} />
      )}

      <Grid container spacing={2} mb={2}>
        <Grid item>
          <CreditSummaryCards cards={creditSummaryCards} />
        </Grid>
        {autopaySchedule && (
          <Grid item>
            <PaymentsCard
              autopayDate={autopaySchedule.date}
              autopayAmount={getAutopayAmount(autopaySchedule, account)}
            />
          </Grid>
        )}
      </Grid>

      <TransactionsTable
        header={t('pages.dashboard.creditAccountHolder.recentTransactions')}
        transactions={transactions.slice(
          0,
          TRANSACTIONS_TABLE_RECENT_ITEMS_COUNT
        )}
        isPaginated={false}
        Footer={
          <TableFooterContainer>
            <Button
              label={t('pages.dashboard.creditAccountHolder.allTransactions')}
              variant={ButtonVariant.Tertiary}
              onClick={() => navigateClientRouting(RoutesPaths.Transactions)}
            />
          </TableFooterContainer>
        }
      />
    </>
  )
}

export { CreditAccountHolderDashboard }
