import { Field } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { AdapterInput } from 'form-kit'
import { useTranslations } from 'translations'

const MIN_LENGTH = 4
const MAX_LENGTH = 4

const ReadableFieldName = (
  <FormattedMessage id='formKit.last4cardDigitsField.label' />
)

export const last4cardDigitsFieldValidationSchema = yup
  .string()
  .trim()
  .min(MIN_LENGTH, () => (
    <FormattedMessage
      id='formKit.validation.minLength'
      values={{ min: MIN_LENGTH, fieldName: ReadableFieldName }}
    />
  ))
  .max(MAX_LENGTH, () => (
    <FormattedMessage
      id='formKit.validation.maxLength'
      values={{ max: MAX_LENGTH, fieldName: ReadableFieldName }}
    />
  ))
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))

export const Last4CardDigitsField = () => {
  const t = useTranslations()

  return (
    <Field
      name='last4Digits'
      type='card-number'
      label={t('formKit.last4cardDigitsField.label')}
      isRequired
      maxlength={4}
      component={AdapterInput}
    />
  )
}
