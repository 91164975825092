import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import styled from 'styled-components'
import { typographyStyles } from 'ui'

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    background-color: ${({ theme }) => theme.color.primary};
    box-shadow: none;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  ${typographyStyles.caption};
  padding: 0.5rem 0 0 2.5rem;
`

const SidebarListItemButton = styled.button<{
  isNavOpen: boolean
  isActive?: boolean
}>`
  display: flex;
  width: ${({ isNavOpen }) => (isNavOpen ? '100%' : 'auto')};
  justify-content: ${({ isNavOpen }) =>
    isNavOpen ? 'space-between' : 'center'};
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.green[500] : theme.color.primary};
  color: ${({ theme }) => theme.color.background};
  ${typographyStyles.body1};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.green[400]};
  }
`

const ExpandIconWrapper = styled.span<{
  isExpanded: boolean
  isNavOpen: boolean
}>`
  svg {
    transform: ${({ isExpanded }) =>
      !isExpanded ? 'rotate(-90deg)' : 'undefined'};
    display: ${({ isNavOpen }) => (isNavOpen ? 'block' : 'none')};
  }
`

export {
  StyledAccordion,
  StyledAccordionDetails,
  ExpandIconWrapper,
  SidebarListItemButton,
}
