import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslations } from 'translations'

type ForgotPasswordFormData = {
  email: string
}

function useForgotPasswordForm() {
  const t = useTranslations()
  const schema: yup.SchemaOf<ForgotPasswordFormData> = useMemo(
    () =>
      yup
        .object({
          email: yup
            .string()
            // Validate email
            .email(t('ui.form.validation.email'))
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newForgotPassword.form.email'),
              })
            ),
        })
        .required(),
    [t]
  )

  return useForm<ForgotPasswordFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })
}

export type { ForgotPasswordFormData }
export { useForgotPasswordForm }
