import { ErrorMessage as FormikErrorMessage } from 'formik'
import { ErrorMessage } from 'ui-kit'

interface AdapterErrorMessageProps {
  name: string
}

export const AdapterErrorMessage = (props: AdapterErrorMessageProps) => {
  const { name } = props

  return (
    <ErrorMessage>
      <FormikErrorMessage name={name} />
    </ErrorMessage>
  )
}
