import { forwardRef, ReactNode } from 'react'
import { useTheme } from 'styled-components'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { LinkVariant, StyledLink } from './Link.styles'

type BaseLinkProps = {
  variant: LinkVariant
  color?: string
  id?: string // currently being used by CardLendflowWidget's react.cloneElement override, remove afterwards
  children: ReactNode
  className?: string
}

type DefaultLinkProps = {
  href: string
  openInNewPage?: boolean
  onClick?: never
} & BaseLinkProps

type ButtonLinkProps = {
  href?: never
  openInNewPage?: never
  onClick: () => void
} & BaseLinkProps

type LinkProps = DefaultLinkProps | ButtonLinkProps

const RouterLinkComponent = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...rest } = props
  // Map href (MUI) -> to (react-router)
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RouterLink ref={ref} to={href} {...rest} />
})

function Link({
  href,
  variant,
  openInNewPage,
  color: overrideColor,
  id,
  children,
  className,
  onClick,
}: LinkProps) {
  const { color } = useTheme()

  // href is a relative link if it starts with a / -- handle it with the RouterLink
  const isExternalLink = href && !/^\/(?!\/)/.test(href)

  return (
    <StyledLink
      href={href}
      onClick={onClick}
      target={openInNewPage || isExternalLink ? '_blank' : undefined}
      rel={openInNewPage || isExternalLink ? 'noopener noreferrer' : undefined}
      color={overrideColor || color.primary}
      $variant={variant}
      component={isExternalLink || onClick ? 'a' : RouterLinkComponent}
      id={id}
      className={className}
    >
      {children}
    </StyledLink>
  )
}

export { Link }
