/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import { rest } from 'msw'
import { getEnvVariable } from 'utils'
import { CoreCardCardStatus } from '../CoreCardApi.types'

const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

export const getCardsHandler = ({
  status,
  previousCard = null,
}: {
  status: CoreCardCardStatus
  previousCard?: { status: CoreCardCardStatus } | null
}) => [
  rest.get(`${baseUrl}/corecard/getCards`, async (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: {
          actualCard: {
            number: '**** **** **** 1234',
            expiryDate: '**/**',
            cvv: '****',
            status,
          },
          previousCard: previousCard
            ? {
                status: previousCard.status,
                number: '**** **** **** 9874',
                expiryDate: '**/**',
                cvv: '****',
              }
            : null,
        },
      })
    )
  }),
]

export const getCardsOnceHandler = ({
  status,
}: {
  status: CoreCardCardStatus
}) => [
  rest.get(`${baseUrl}/corecard/getCards`, async (req, res, ctx) => {
    return res.once(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: {
          actualCard: {
            number: '**** **** **** 1234',
            expiryDate: '**/**',
            cvv: '****',
            status,
          },
          previousCard: null,
        },
      })
    )
  }),
]

export const getActualCardUnscrambledHandler = () => [
  rest.get(
    `${baseUrl}/corecard/getActualCardUnscrambled`,
    async (req, res, ctx) => {
      return res(
        ctx.delay(),
        ctx.status(200),
        ctx.json({
          data: {
            imageUrl:
              'https://www.hometownbanks.com/Images/webpages/credit_card_mock_up_for_ads_platinum.png',
          },
        })
      )
    }
  ),
]

export const freezeCardHandler = () => [
  rest.post(`${baseUrl}/corecard/freezeCard`, async (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({ statusCode: 200, message: 'Status OK.' })
    )
  }),
]

export const unfreezeCardHandler = () => [
  rest.post(`${baseUrl}/corecard/unfreezeCard`, async (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({ statusCode: 200, message: 'Status OK.' })
    )
  }),
]

export const orderNewCardHandler = () => [
  rest.post(`${baseUrl}/corecard/orderNewCard`, async (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.status(201),
      ctx.json({
        statusCode: 201,
        message: 'Connected OK.',
      })
    )
  }),
]
const DICTIONARIES: string[] = [
  'Stolen',
  'Lost',
  'Damaged',
  'Re-Issue',
  'Breach',
  'Other',
]
export const deactivateCardHandlers = [
  rest.get(
    `${baseUrl}/corecard/deactivateCardReasons`,
    async (req, res, ctx) => {
      return res(
        ctx.delay(1),
        ctx.status(200),
        ctx.json({
          data: {
            deactivateCardReasons: DICTIONARIES,
          },
        })
      )
    }
  ),

  rest.get(`${baseUrl}/corecard/getCards`, async (req, res, ctx) => {
    return res(
      ctx.delay(1),
      ctx.status(200),
      ctx.json({
        data: {},
      })
    )
  }),

  rest.post(`${baseUrl}/corecard/deactivateCard`, async (req, res, ctx) => {
    return res(
      ctx.delay(1),
      ctx.status(200),
      ctx.json({ statusCode: 201, message: 'Deactivated OK.' })
    )
  }),

  rest.post(`${baseUrl}/corecard/orderNewCard`, async (req, res, ctx) => {
    return res(
      ctx.delay(1),
      ctx.status(200),
      ctx.json({ statusCode: 201, message: 'Created OK.' })
    )
  }),
]

export const paymentData = {
  currency: 'USD',
  statementBalance: 272.72,
  currentBalance: 2727.19,
}

export const paymentHandlers = [
  rest.get(`${baseUrl}/corecard/makePayment`, async (req, res, ctx) => {
    return res(
      ctx.delay(1),
      ctx.status(200),
      ctx.json({
        data: paymentData,
      })
    )
  }),

  rest.post(`${baseUrl}/corecard/makePayment`, async (req, res, ctx) => {
    return res(
      ctx.delay(1),
      ctx.status(200),
      ctx.json({ statusCode: 200, message: 'Status OK.' })
    )
  }),
]
