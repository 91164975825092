import MUITypography from '@mui/material/Typography'
import { ElementType } from 'react'
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'body1' | 'caption'

const typographyStyles: Record<
  TypographyVariant,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  h1: css`
    font-family: ${({ theme }) => theme.fontFamily.headline};
    font-size: 2rem;
    line-height: 1.25;
  `,
  h2: css`
    font-family: ${({ theme }) => theme.fontFamily.headline};
    font-size: 1.5rem;
    line-height: 1.25;
  `,
  h3: css`
    font-family: ${({ theme }) => theme.fontFamily.headline};
    font-size: 1.25rem;
    line-height: 1.25;
  `,
  body1: css`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: 1rem;
    line-height: 1.25;
  `,
  caption: css`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: 0.85rem;
    line-height: 1.25;
  `,
}

type StyledTypographyProps = {
  variant: TypographyVariant
  component?: ElementType
  $bold?: boolean
}

const StyledTypography = styled(MUITypography)<StyledTypographyProps>`
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 400)};

  ${({ variant }) => {
    return typographyStyles[variant]
  }}
`

export type { TypographyVariant }
export { StyledTypography, typographyStyles }
