import { ChangeEvent, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { Icon, IconType } from 'ui-kit/Icons/Icon.component'
import { SemanticRequired } from '../SemanticRequired/SemanticRequired.component'
import { StyledInputText } from '../InputText/InputText.styles'

export interface InputPasswordProps {
  label: string
  name: string
  value: string
  hasError: boolean
  isRequired?: boolean
  disabled?: boolean
  ariaLabel: string
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const InputPassword = (props: InputPasswordProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const {
    onChange,
    name,
    value,
    label,
    hasError,
    isRequired,
    disabled,
    ariaLabel,
  } = props

  const labelRequired = (
    <>
      {label}
      <SemanticRequired />
    </>
  )

  const toggleVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <StyledInputText
      id={name}
      error={hasError}
      label={isRequired ? labelRequired : label}
      value={value}
      onChange={onChange}
      size='small'
      variant='outlined'
      type={passwordVisible ? 'text' : 'password'}
      fullWidth
      inputProps={{
        'data-testid': 'InputPassword',
        'aria-required': isRequired,
        'aria-disabled': disabled,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label={ariaLabel}
              onClick={toggleVisibility}
              edge='end'
              role='button'
            >
              {passwordVisible ? (
                <Icon type={IconType.eye} />
              ) : (
                <Icon type={IconType.eye_off} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
