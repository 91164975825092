import { getEnvVariable } from 'utils'
import {
  MfaDeleteOneRequest,
  MfaEmergencyRequest,
  MfaEnableRequest,
  MfaEnableResponse,
  MfaListResponse,
  MfaResendRequest,
  MfaVerifyRequest,
} from './MfaApi.types'
import { httpService } from '../../http-service/Http.service'

export const MfaApi = () => {
  const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

  const { post, get } = httpService(baseUrl)

  const verify = (payload: MfaVerifyRequest) => {
    return post<unknown, MfaVerifyRequest>({
      endpoint: 'mfa/verify',
      requestBody: payload,
    })
  }

  const remember = () => {
    return post({
      endpoint: 'mfa/remember',
    })
  }

  const emergency = (payload: MfaEmergencyRequest) => {
    return post<unknown, MfaEmergencyRequest>({
      endpoint: 'mfa/emergency',
      requestBody: payload,
    })
  }

  const enable = (payload: MfaEnableRequest) => {
    return post<MfaEnableResponse, MfaEnableRequest>({
      endpoint: 'mfa/enable',
      requestBody: payload,
    })
  }

  const resend = (payload: MfaResendRequest) => {
    return post({
      endpoint: 'mfa/resend',
      requestBody: payload,
    })
  }

  const list = () => {
    return get<MfaListResponse>({
      endpoint: 'mfa/list',
    })
  }

  const deleteAll = () => {
    return post({
      endpoint: 'mfa/deleteAll',
    })
  }

  const deleteOne = (payload: MfaDeleteOneRequest) => {
    return post<unknown, MfaDeleteOneRequest>({
      endpoint: 'mfa/delete',
      requestBody: payload,
    })
  }

  return {
    verify,
    remember,
    emergency,
    enable,
    resend,
    list,
    deleteAll,
    deleteOne,
  }
}
