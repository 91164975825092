/* eslint-disable consistent-return */
import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { generatArrayOfLength } from './utils/generateArrayOfLength.utils'

const StyledProgressItem = styled.div<{
  isDone: boolean
  widthInPercent: number
}>`
  height: 0.25rem;
  border-radius: 1.5rem;

  ${({ widthInPercent }) => {
    return css`
      width: ${widthInPercent}%;
    `
  }}

  ${({ theme, isDone }) => {
    if (isDone) {
      return css`
        background-color: ${theme.color.apple[300]};
      `
    }
  }};
`

const StyledProgressBarTrack = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 0;
  background-color: ${({ theme }) => theme.color.primary};
  & > * + * {
    margin-left: 2px;
  }
`

const MIN_VALUE = 1

interface ProgressBarProps {
  totalElementsCount: number
  currentElementIndex: number
  title: string
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { totalElementsCount, currentElementIndex, title } = props

  const elements = useMemo(
    () => generatArrayOfLength(totalElementsCount),
    [totalElementsCount]
  )

  return (
    <StyledProgressBarTrack
      title={title}
      aria-label={title}
      role='progressbar'
      aria-valuenow={currentElementIndex + 1}
      aria-valuemin={MIN_VALUE}
      aria-valuemax={totalElementsCount}
    >
      {elements.map((element, index) => (
        <StyledProgressItem
          key={element}
          isDone={index <= currentElementIndex}
          widthInPercent={100 / totalElementsCount}
        />
      ))}
    </StyledProgressBarTrack>
  )
}
