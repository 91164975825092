import { Name } from './shared'

enum AuthEndpoints {
  ActivateUser = '/auth/activate',
  ForgotPassword = '/auth/forgot-password',
  Login = '/auth/login',
  Logout = '/auth/logout',
  RefreshToken = '/auth/refresh-token',
  ResendActivationCode = '/auth/resend-activation-code',
  ResetPassword = '/auth/reset-password',
  SignUp = '/auth/signup',
}

type ActivateUserReqBody = {
  email: string
  code: string
}

type ForgotPasswordReqBody = {
  email: string
}

type LoginReqBody = {
  email: string
  password: string
}

type LoginRespData = {
  accessToken: string
  refreshToken: string
  userID: number
}

type LogoutReqBody = {
  refreshToken: string
}

type RefreshTokenReqBody = {
  refreshToken: string
}

type RefreshTokenRespData = {
  accessToken: string
}

type ResendActivationCodeReqBody = {
  email: string
}

type ResetPasswordReqBody = {
  email: string
  newPassword: string
  code: string
}

type SignUpReqBody = {
  email: string
  password: string
  name: Name
}

export type {
  ActivateUserReqBody,
  ForgotPasswordReqBody,
  LoginReqBody,
  LoginRespData,
  LogoutReqBody,
  RefreshTokenReqBody,
  RefreshTokenRespData,
  ResendActivationCodeReqBody,
  ResetPasswordReqBody,
  SignUpReqBody,
}
export { AuthEndpoints }
