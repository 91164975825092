import { Checkbox } from '@mui/material'
import styled from 'styled-components'
import * as Typography from '../Typography/Typography.styles'

export const StyledCard = styled.div<{
  checked: boolean
  isValid: boolean
}>`
  width: 100%;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  border: 2px solid
    ${({ theme, isValid, checked }) => {
      if (isValid && checked) {
        return `${theme.color.primary}`
      }
      if (!isValid) {
        return `${theme.color.red.error}`
      }
      return `${theme.color.transparent}`
    }};

  ${({ theme }) => theme.media.minTablet} {
    margin-right: 2rem;
  }

  cursor: pointer;
`

export const StyledCheckbox = styled(Checkbox)<{ $isValid: boolean }>`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  color: ${({ theme }) => theme.color.primary};

  &&&.Mui-checked {
    color: ${({ theme }) => theme.color.primary};
  }
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Typography.headingH6};

  span {
    margin-top: 1.25rem;
  }
`
