/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { getEnvVariable } from 'utils'
import {
  wasLoggedIn,
  clearLoggedInFlag,
} from 'authentication/components/AuthenticationProvider/auth.utils'
import { SessionStore, SessionStoreKey } from 'lib/sessionStore'

const sessionStore = new SessionStore()

const clearSession = () => {
  if (wasLoggedIn()) {
    enqueueSnackbar({
      key: 'TOKEN_EXPIRED',
      variant: 'error',
      preventDuplicate: true,
      translation: { id: 'errorMessages.TOKEN_EXPIRED' },
    })
  }
  sessionStorage.clear()
  sessionStore.clearAll()
  clearLoggedInFlag()
}

const refreshToken = async () => {
  const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')
  return axios.post(`${baseUrl}/auth/refreshToken`, undefined, {
    withCredentials: true,
  })
}

const axiosInstance = axios.create({
  withCredentials: true,
})

axiosInstance.interceptors.response.use(undefined, async error => {
  const originalRequest = error.config

  if (error.response.status === 405 && !originalRequest._retry) {
    originalRequest._retry = true
    try {
      const resp = await refreshToken()
      const { accessToken, userId } = resp.data.data
      sessionStore.set(SessionStoreKey.AccessToken, accessToken)
      sessionStore.set(SessionStoreKey.UserId, String(userId))
      return axiosInstance({
        ...originalRequest,
        headers: accessToken
          ? {
              ...originalRequest.headers,
              authorization: `Bearer ${accessToken}`,
            }
          : originalRequest.headers,
      })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  return Promise.reject(error)
})

export { axiosInstance, refreshToken, sessionStore, clearSession }
