import Grid from '@mui/material/Grid'
import { useTranslations } from 'translations'
import { BaseCreditSummaryCard } from './BaseCreditSummaryCard.component'

type CreditSummaryCardType =
  | 'currentBalance'
  | 'statementBalance'
  | 'availableCredit'

type CreditSummaryCardProps = {
  type: CreditSummaryCardType
  value: number
}

type CreditSummaryCardsProps = {
  cards: CreditSummaryCardProps[]
}

function CreditSummaryCards({ cards }: CreditSummaryCardsProps) {
  const t = useTranslations()

  return (
    <Grid container spacing={2}>
      {cards.map(({ type, value }) => (
        <Grid item key={type}>
          <BaseCreditSummaryCard label={t(`domain.${type}`)} value={value} />
        </Grid>
      ))}
    </Grid>
  )
}

export type { CreditSummaryCardProps }
export { CreditSummaryCards }
