/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components'
import { Card, Typography, TypographyProps } from 'ui'

const SHADOW_COLOR = 'rgba(0, 0, 0, 0.05)'

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const FundidSVGContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  margin-bottom: 3rem;
  background-color: ${({ theme }) => theme.color.primary};
`

const CardContainer = styled.div`
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
`

const StyledCard = styled(Card)`
  width: 100%;
  padding: 1rem 2rem;
  box-shadow: 2px 2px 4px ${SHADOW_COLOR}, -2px 2px 4px ${SHADOW_COLOR},
    2px -2px 4px ${SHADOW_COLOR}, -2px -2px 4px ${SHADOW_COLOR};
`

const Title = styled((props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant='h2' />
))`
  text-align: center;
  margin-bottom: 1.5rem;
`

const Subtitle = styled((props: Omit<TypographyProps, 'variant'>) => (
  <Typography {...props} variant='caption' />
))`
  text-align: center;
  margin-bottom: 1.5rem;
`

export {
  Container,
  FundidSVGContainer,
  CardContainer,
  StyledCard,
  Title,
  Subtitle,
}
