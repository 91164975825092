import MUIIconButton, {
  IconButtonProps as MUIIconButtonProps,
} from '@mui/material/IconButton'
import { Icon, IconProps } from 'ui/Icon/Icon.component'

type IconButtonProps = {
  isDisabled?: boolean
} & Pick<MUIIconButtonProps, 'onClick'> &
  IconProps

function IconButton({
  type,
  color,
  size,
  isDisabled,
  onClick,
}: IconButtonProps) {
  return (
    <MUIIconButton onClick={onClick} disabled={isDisabled}>
      <Icon type={type} color={color} size={size} />
    </MUIIconButton>
  )
}

export { IconButton }
