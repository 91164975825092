import MUICheckbox, {
  CheckboxProps as MUICheckboxProps,
} from '@mui/material/Checkbox'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { StyledFormControlLabel } from './Checkbox.styles'

type BaseCheckboxProps = {
  label: string
  isChecked: boolean
} & Pick<MUICheckboxProps, 'onChange'>

type CheckboxProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
}

function BaseCheckbox({ label, isChecked, onChange }: BaseCheckboxProps) {
  const { color } = useTheme()

  return (
    <StyledFormControlLabel
      label={label}
      control={
        <MUICheckbox
          checked={isChecked}
          onChange={onChange}
          sx={{
            color: color.primary,
            '&.Mui-checked': {
              color: color.primary,
            },
          }}
        />
      }
    />
  )
}

function Checkbox<TFieldValues extends FieldValues>({
  name,
  control,
  label,
}: CheckboxProps<TFieldValues>) {
  const {
    field: { value, onChange },
  } = useController({ name, control })

  return <BaseCheckbox label={label} isChecked={value} onChange={onChange} />
}

export { Checkbox, BaseCheckbox }
