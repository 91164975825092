import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'
import { StyledTextField } from 'ui/form/TextField/TextField.styles'

const StyledStack = styled(Stack)`
  max-width: 20rem;
`

const StyledSearchBar = styled(StyledTextField)<{ $isExpanded: boolean }>`
  margin-bottom: 0;

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiInputBase-input {
    ${typographyStyles.caption}
    padding-right: 0;

    // onFocus transitions
    transition-duration: 750ms;
    max-width: ${({ $isExpanded }) => ($isExpanded ? '28rem' : '0rem')};

    :focus {
      max-width: 28rem;
    }
  }

  // ofFocus border transitions
  .MuiOutlinedInput-notchedOutline {
    border-width: ${({ $isExpanded }) => ($isExpanded ? '0.5px' : 0)};
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
    }
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: 0;
`

export { StyledStack, StyledSearchBar, StyledInputAdornment }
