import MUIFormControlLabel from '@mui/material/FormControlLabel'
import MUIRadio from '@mui/material/Radio'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const StyledRadio = styled(MUIRadio)`
  &.Mui-checked {
    color: ${({ theme }) => theme.color.green['500']};
  }
`

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  .MuiTypography-root {
    ${typographyStyles.body1};
  }
`

export { StyledRadio, StyledFormControlLabel }
