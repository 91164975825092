import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { RequireAuthNavigationState, RoutesPaths } from 'routing'
import { LoginSeo } from './LoginSeo.component'
import { LoginPageContent } from './LoginPageContent.component'
import {
  IdentityVerification,
  MfaData,
} from './IdentityVerification/IdentityVerification.component'

export const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [mfa] = useState<MfaData | null>(null)

  const redirectAfterLogin = async () => {
    // If we were redirected to the LoginPage by RequireAuth, check Location state
    // for the route the user was trying to navigate to.
    const from = (location.state as RequireAuthNavigationState | undefined)
      ?.from
    const path = from ? `${from.pathname}${from.search}` : RoutesPaths.Dashboard

    navigate(path, { replace: true })
  }

  // const handleLogin = async (values: LoginFormValues) => {
  //   setStatus('LOADING')

  //   try {
  //     if (login) {
  //       const response = await login(values)
  //       if (response.statusCode === HttpStatus.ACCEPTED) {
  //         setMfa({ email: values.businessEmail, isSetup: true })
  //         return
  //       }
  //       if (response.statusCode === HttpStatus.NON_AUTHORITATIVE_INFORMATION) {
  //         setMfa({ email: values.businessEmail, isSetup: false })
  //         return
  //       }
  //       await redirectAfterLogin()
  //     }
  //   } catch (err) {
  //     let uiMessage = ''
  //     if (isAxiosError(err)) {
  //       const statusCode = err.response?.status
  //       if (isServerError(err)) {
  //         uiMessage = t('errorMessages.SYSTEM_FAILURE')
  //         logger.error(uiMessage, { error: err.response?.data })
  //       } else if (statusCode && statusCode === 400) {
  //         uiMessage = t('pages.loginPage.loginForm.error')
  //       } else {
  //         uiMessage = t('errorMessages.UNEXPECTED_ERROR')
  //       }
  //     } else {
  //       uiMessage = t('errorMessages.UNEXPECTED_ERROR')
  //       logger.error(uiMessage, { error: err })
  //     }

  //     showErrorSnackbar(uiMessage)
  //     setStatus('IDLE')
  //   }
  // }

  return (
    <>
      <LoginSeo />
      {mfa === null ? (
        <LoginPageContent />
      ) : (
        <IdentityVerification
          onVerified={redirectAfterLogin}
          email={mfa.email}
          isSetup={mfa.isSetup}
        />
      )}
    </>
  )
}
