/* eslint-disable react/no-array-index-key */
import { Stack } from '@mui/material'
import MUIAlert, { AlertProps as MUIAlertProps } from '@mui/material/Alert'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { Typography } from 'ui/Typography/Typography.component'
import { typographyStyles } from 'ui/Typography/Typography.styles'

type AlertProps = {
  message: string | string[] | ReactElement
  severity: MUIAlertProps['severity']
}

const StyledAlert = styled(MUIAlert)`
  &.MuiAlert-root {
    ${typographyStyles.caption};
    align-items: center;
  }

  max-width: 40rem;
  margin-bottom: 1rem;
`

function Alert({ message, severity }: AlertProps) {
  return (
    <StyledAlert severity={severity}>
      {Array.isArray(message) ? (
        <Stack spacing={2}>
          {message.map((m, i) => (
            <Typography key={i} variant='caption' component='div'>
              {m}
            </Typography>
          ))}
        </Stack>
      ) : (
        message
      )}
    </StyledAlert>
  )
}

export { Alert }
