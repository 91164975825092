/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react'
import { ReactComponent as VisaSVG } from 'assets/svgs/visa-cartoon.svg'
import { RoutesPaths, useBrowserUrl } from 'routing'
import { useTranslations } from 'translations'
import {
  List,
  ListItem,
  Button,
  ButtonVariant,
  Card,
  CardActions,
  CardContent,
} from 'ui'
import { CardTitle } from './ActivateCardEntrypoint.styles'

type ActivateCardEntryPointProps = {
  cardId: number
}

function ActivateCardEntrypoint({ cardId }: ActivateCardEntryPointProps) {
  const t = useTranslations()
  const { navigateClientRouting } = useBrowserUrl()

  const instructions = useMemo(() => {
    return [
      t('shared.activateCardEntrypoint.instructions.one'),
      t('shared.activateCardEntrypoint.instructions.two'),
      t('shared.activateCardEntrypoint.instructions.three'),
    ]
  }, [t])

  const navigateToCardActivationFlow = () => {
    navigateClientRouting(`${RoutesPaths.ActivateCard}/${cardId}`)
  }

  return (
    <div>
      <Card>
        <CardContent>
          <VisaSVG />
          <CardTitle>{t('shared.activateCardEntrypoint.title')}</CardTitle>
          <List ordered>
            {instructions.map((instruction, i) => (
              <ListItem key={i}>{instruction}</ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions>
          <Button
            label={t('shared.activateCardEntrypoint.button')}
            variant={ButtonVariant.Primary}
            onClick={navigateToCardActivationFlow}
          />
        </CardActions>
      </Card>
    </div>
  )
}

export { ActivateCardEntrypoint }
