import { useCallback } from 'react'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import { Icon, IconType } from 'ui'
import { StyledLabel, StyledLi } from './SidebarListItem.styles'
import { SidebarListItemLink } from './SidebarListItemLink.component'
import { useNav } from '../NavProvider/NavProvider.component'

type SidebarListItemProps = {
  path: string
  label: string
  iconType: IconType
}

type SidebarListItemContentProps = {
  label: string
  iconType: IconType
  isNavOpen: boolean
}

function SidebarListItemContent({
  label,
  iconType,
  isNavOpen,
}: SidebarListItemContentProps) {
  const { color } = useTheme()

  return (
    <Stack direction='row' alignItems='center'>
      <Icon type={iconType} color={color.background} />
      <StyledLabel isNavOpen={isNavOpen}>{label}</StyledLabel>
    </Stack>
  )
}

function SidebarListItem({ label, path, iconType }: SidebarListItemProps) {
  const { isNavOpen, isMobile, toggleNav } = useNav()

  const onClick = useCallback(() => {
    if (isMobile) {
      toggleNav()
    }
  }, [isMobile, toggleNav])

  return (
    <StyledLi onClick={onClick}>
      <SidebarListItemLink path={path}>
        <SidebarListItemContent
          label={label}
          iconType={iconType}
          isNavOpen={isNavOpen}
        />
      </SidebarListItemLink>
    </StyledLi>
  )
}

export type { SidebarListItemProps }
export { SidebarListItem, SidebarListItemContent }
