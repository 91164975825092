import Stack from '@mui/material/Stack'
import styled from 'styled-components'

const StyledStack = styled(Stack)`
  ${({ theme }) => theme.media.minTablet} {
    padding: 0 2rem;
  }
`

export { StyledStack }
