import { RoutesPaths } from 'routing'
import { PageContainer } from 'shared'
import { useTranslations } from 'translations'
import { ReadOnlyTextField, Paper, Typography, Loader } from 'ui'
import { Link } from 'ui/Link/Link.component'
import {
  FieldsContainer,
  InstructionsContainer,
  SectionHeader,
} from './ProfilePage.styles'
import { usePersonalFields } from './hooks/usePersonalFields'
import { useCompanyFields } from './hooks/useCompanyFields'

function ProfilePage() {
  const t = useTranslations()
  const { data: personalFields } = usePersonalFields()
  const { isLoading: isLoadingCompanyFields, companyFields } =
    useCompanyFields()

  const isLoading = !personalFields || isLoadingCompanyFields
  if (isLoading) {
    return <Loader />
  }

  return (
    <PageContainer helmetTitle='Profile' title='Profile'>
      <Paper>
        <InstructionsContainer>
          <Typography variant='caption'>
            {t('pages.newProfile.instructions')}
          </Typography>
          <Link
            href={RoutesPaths.ContactSupport}
            variant='caption'
            openInNewPage
          >
            {t('pages.newProfile.contactSupport')}
          </Link>
          <Typography variant='caption'>.</Typography>
        </InstructionsContainer>

        <FieldsContainer>
          <SectionHeader>{t('pages.newProfile.personal.header')}</SectionHeader>
          {personalFields.map(({ label, value }) => (
            <ReadOnlyTextField key={label} label={label} value={value} />
          ))}

          {companyFields && (
            <>
              <SectionHeader>
                {t('pages.newProfile.company.header')}
              </SectionHeader>
              {companyFields.map(({ label, value }) => (
                <ReadOnlyTextField key={label} label={label} value={value} />
              ))}
            </>
          )}

          <SectionHeader>
            {t('pages.newProfile.deactivateAccount.header')}
          </SectionHeader>
          <Typography variant='caption'>
            {t('pages.newProfile.deactivateAccount.description')}
          </Typography>
          <Link
            href={RoutesPaths.ContactSupport}
            variant='caption'
            openInNewPage
          >
            {t('pages.newProfile.contactSupport')}
          </Link>
          <Typography variant='caption'>.</Typography>
        </FieldsContainer>
      </Paper>
    </PageContainer>
  )
}

export { ProfilePage }
