import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import {
  emergencyCodeNumberInitialValues,
  emergencyCodeVerificationFieldSetValidationSchema,
  EmergencyCodeVerificationFieldSet,
  EmergencyCodeNumberFormValues,
} from 'form-kit'
import { LayoutOneColumnAndHeader, Typography } from 'ui-kit'
import { useMfaEmergency } from 'models'
import { Button, ButtonVariant } from 'ui'
import { useTranslations } from 'translations'
import {
  ParagraphWrapper,
  StyledButtonsWrapper,
} from './IdentityVerification.styles'

interface EmergencyIdentityVerificationProps {
  onBack?: () => void
  onVerified?: () => Promise<void> | void
}

export const EmergencyIdentityVerification = ({
  onBack,
  onVerified,
}: EmergencyIdentityVerificationProps) => {
  const t = useTranslations()
  const { mutateAsync: emergencyAsync } = useMfaEmergency()
  const handleSubmit = async (data: EmergencyCodeNumberFormValues) => {
    await emergencyAsync({ emergencyCode: data.codeNumber || '' })
    await onVerified?.()
  }

  return (
    <LayoutOneColumnAndHeader>
      <Typography.H1MainLayout>
        <FormattedMessage id='pages.loginPage.mfa.emergency.header' />
      </Typography.H1MainLayout>
      <ParagraphWrapper>
        <FormattedMessage id='pages.loginPage.mfa.emergency.intro' />
      </ParagraphWrapper>

      <Formik
        initialValues={emergencyCodeNumberInitialValues}
        onSubmit={handleSubmit}
        validationSchema={emergencyCodeVerificationFieldSetValidationSchema}
        validateOnChange={false}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <EmergencyCodeVerificationFieldSet />
              <StyledButtonsWrapper>
                <Button
                  label={t('pages.loginPage.mfa.confirmButton')}
                  variant={ButtonVariant.Primary}
                  sizeVariant='large'
                  type='submit'
                  isLoading={isSubmitting}
                />
                <Button
                  label={t('pages.loginPage.mfa.emergency.back')}
                  variant={ButtonVariant.Tertiary}
                  onClick={onBack}
                />
              </StyledButtonsWrapper>
            </Form>
          )
        }}
      </Formik>
    </LayoutOneColumnAndHeader>
  )
}
