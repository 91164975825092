import { ReactNode } from 'react'
import { QueryErrorResetBoundary } from 'react-query'
import { useTheme } from '@mui/material'
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary'
import { Button } from 'ui/Button/Button.component'
import { ButtonVariant } from 'ui/Button/Button.styles'
import { Icon, IconSize, IconType } from 'ui/Icon/Icon.component'
import { useTranslations } from 'translations'
import { Container, ErrorMessage, StyledPaper } from './ErrorBoundary.styles'

type ErrorBoundaryProps = {
  children: ReactNode
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  const t = useTranslations()
  const { color } = useTheme()

  return (
    <Container>
      <StyledPaper>
        <Icon
          type={IconType.Exclamation}
          color={color.red.error}
          size={IconSize.XLarge}
        />
        <ErrorMessage>{t('ui.errorBoundary.message')}</ErrorMessage>
        <Button
          label={t('ui.errorBoundary.button')}
          variant={ButtonVariant.Tertiary}
          sizeVariant='large'
          onClick={resetErrorBoundary}
        />
      </StyledPaper>
    </Container>
  )
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset: resetQueryError }) => (
        <ReactErrorBoundary
          onReset={resetQueryError}
          FallbackComponent={ErrorFallback}
        >
          {children}
        </ReactErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

export { ErrorBoundary }
