export interface CoreCardGetCreditCardAgreementResponse {
  data: {
    creditLimit: number
    creditLimitCurrency: string
    /**
     * html
     */
    agreementContent: string
  }
}

export interface CoreCardSignCreditCardAgreementRequest {
  decision: 'accept' | 'decline'
}

export interface CoreCardDeactivateCardReasonsResponse {
  data: {
    deactivateCardReasons: string[]
  }
}

export interface CoreCardDeactivateCardRequest {
  orderNewOne: boolean
  deactivationReason: string
}

export enum CoreCardCardStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  FROZEN = 'frozen',
}

export interface CoreCardCard {
  number: string
  expiryDate: string
  cvv: string
  status: CoreCardCardStatus
}

export interface CoreCardGetCardsResponse {
  data: {
    actualCard: CoreCardCard | null
    previousCard: CoreCardCard | null
  }
}

export interface CoreCardMakePaymentResponse {
  data: {
    currency: string
    currentBalance: number
    statementBalance: number
  }
}

export interface CorecardMakePaymentPayload {
  paymentAmount: number
}

export interface CoreCardGetActualCardUnscrambledResponse {
  data: {
    imageUrl: string
  }
}

export interface CoreCardActivateCardRequest {
  last4Digits: string
  dateOfBirth: string
}
