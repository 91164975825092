import styled from 'styled-components'
import { TableCell } from '../../../Table/Table.styles'

export const TransactionDetails = styled.div`
  background-color: ${({ theme }) => theme.color.grey[400]};
  color: ${({ theme }) => theme.color.green[700]};
  border-bottom: black;
  caption {
    display: none;
  }
`

const Cell = styled(TableCell)`
  border: unset;
`
export const HeaderTableCell = styled(Cell)`
  font-weight: bold;
  font-size: 0.8125rem;
`

export const DescriptionCell = styled(Cell)`
  width: 100%;
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.color.green[700]};
`

export const DataCell = styled(Cell)`
  white-space: nowrap;
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.color.green[700]};
`
export const ReportLink = styled.a`
  font-weight: 600;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.color.primary};
`

export const TableFooterCell = styled(Cell)`
  padding: 0.375rem 1rem;
  font-size: 0.8125rem;
`
