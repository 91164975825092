/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AppProviders } from 'app-providers'
import { Routes } from 'routing'
import { GoogleTagManager, HubspotTracking } from 'shared'
import { ErrorBoundary } from 'error-handling'
import { getEnvVariable } from 'utils'
import reportWebVitals from './reportWebVitals'

if (getEnvVariable('REACT_APP_ENVIRONMENT') !== 'development') {
  Sentry.init({
    dsn: 'https://1a0fdb5cd7564ce484baa7f59e4ca641@o4504539701444608.ingest.sentry.io/4504735698911232',
    environment: getEnvVariable('REACT_APP_ENVIRONMENT'),
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          // TODO: pull from env config
          'backend.fundid.staging.devguru.co',
          'backend.app.getfundid.com',
          'backend-v2.fundid-staging.com',
          'backend-v2.app.getfundid.com',
        ],
      }),
    ],
    tracesSampleRate: 0.1, // TODO: Sentry recommends playing around with this value in production https://docs.sentry.io/platforms/javascript/configuration/sampling/#configuring-the-transaction-sample-rate
  })
}

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <GoogleTagManager />
      <HubspotTracking />
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
