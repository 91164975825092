import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, Card, CardType, GetCardsRespData } from 'api'
import { isCardNotClosed } from 'lib/models'
import { isNil } from 'lib/utils'
import { cardKeys } from './queryKeys'
import { useAccountId } from '../accounts/useAccountId'
import { errQueryMissingDependencies } from '../queryError'

type UseCardsType = {
  physical: Card | null
  virtual: Card | null
}

function useCards<T = UseCardsType>(select?: (resp: GetCardsRespData) => T) {
  const apiClient = useApiClient()
  const { data: accountId } = useAccountId()

  const queryKey = cardKeys.byAccount(accountId)

  return useQuery({
    queryKey,
    enabled: !isNil(accountId),
    queryFn: () => {
      if (isNil(accountId)) {
        return errQueryMissingDependencies(queryKey, {
          accountId,
        })
      }

      return apiClient.getCards({
        accountID: accountId,
      })
    },
    select: resp => {
      if (select) {
        return select(resp)
      }

      const cards = resp.cards.filter(isCardNotClosed)

      return {
        physical: cards.find(c => c.type === CardType.Physical) || null,
        virtual: cards.find(c => c.type === CardType.Virtual) || null,
      }
    },
  }) as UseQueryResult<typeof select extends undefined ? Card[] : T, ApiError>
}

export { useCards }
