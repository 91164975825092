import * as yup from 'yup'
import { useTranslations } from 'translations'
import { FormGrid, VisuallyHiddenFormLegend } from 'ui-kit'
import {
  Last4CardDigitsField,
  last4cardDigitsFieldValidationSchema,
} from '../../fields/Last4CardDigitsField/Last4CardDigitsField.component'
import {
  BirthDateField,
  birthDateFieldValidationSchema,
} from '../../fields/BirthDateField/BirthDateField.component'

export interface ActivateCardFormValues {
  last4Digits: string
  dateOfBirth: Date | null
}

export const activateCardFormValidationSchema: yup.ObjectSchema<
  Record<keyof ActivateCardFormValues, yup.AnySchema>
> = yup.object().shape({
  last4Digits: last4cardDigitsFieldValidationSchema,
  dateOfBirth: birthDateFieldValidationSchema,
})

export const activateCardFormInitialValues: ActivateCardFormValues = {
  last4Digits: '',
  dateOfBirth: null,
}

export const ActivateCardFieldSet = () => {
  const t = useTranslations()
  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.activateCardFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <FormGrid.Row>
          <Last4CardDigitsField />
        </FormGrid.Row>
        <FormGrid.Row>
          <BirthDateField />
        </FormGrid.Row>
      </FormGrid.Grid>
    </fieldset>
  )
}
