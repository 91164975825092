import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { useTranslations } from 'translations'
import { Button, ButtonVariant } from 'ui'
import {
  FeedbackPage,
  FormGrid,
  IconType,
  LayoutOneColumnAndHeader,
  Typography,
  Wrappers,
} from 'ui-kit'
import {
  SetNewPasswordFormFieldSet,
  setNewPasswordFormInitialValues,
  setNewPasswordFormValidationSchema,
  SetNewPasswordFormValues,
} from 'form-kit'
import { useBrowserUrl } from 'routing'
import { AuthenticationApi } from 'api-connectors'
import { isAxiosError, isServerError } from 'error-handling'
import { useLogger } from 'logger'
import { SetNewPasswordSeo } from './SetNewPasswordSeo.component'

const { setNewPassword } = AuthenticationApi()

export const SetNewPasswordPage = () => {
  const [status, setStatus] = useState<'IDLE' | 'LOADING' | 'FAIL' | 'SUCCESS'>(
    'IDLE'
  )
  const [errorParagraph, setErrorParagraph] = useState('')
  const t = useTranslations()
  const [searchParams] = useSearchParams()
  const { goToLoginPage } = useBrowserUrl()
  const code = searchParams.get('code')
  const { logger } = useLogger()

  useEffect(() => {
    if (!code) {
      goToLoginPage()
    }
  }, [code, goToLoginPage])

  const handleSetNewPasswordFormSubmit = async (
    values: SetNewPasswordFormValues
  ) => {
    if (!code) return

    setStatus('LOADING')

    try {
      await setNewPassword({
        newPassword: values.password,
        passwordResetCode: code,
      })
      setStatus('SUCCESS')
    } catch (err) {
      if (isAxiosError(err)) {
        if (isServerError(err)) {
          const message = t('errorMessages.SYSTEM_FAILURE')
          setErrorParagraph(message)

          logger.error(message, {
            error: err.response?.data,
          })
        } else {
          const statusCode = err.response?.status

          if (statusCode && statusCode === 404) {
            const message = t('pages.setNewPasswordPage.fail.content')
            setErrorParagraph(message)
          } else {
            const message = t('errorMessages.UNEXPECTED_ERROR')
            setErrorParagraph(message)
          }
        }
      } else {
        const message = t('errorMessages.UNEXPECTED_ERROR')
        setErrorParagraph(message)
        logger.error(message, {
          error: err,
        })
      }

      setStatus('FAIL')
    }
  }

  const buttonLabel = t('pages.setNewPasswordPage.feedback.button')

  if (status === 'SUCCESS') {
    return (
      <>
        <SetNewPasswordSeo />
        <FeedbackPage
          icon={IconType.check}
          header={t('pages.setNewPasswordPage.success.header')}
          paragraph={t('pages.setNewPasswordPage.success.content')}
          renderButton={() => {
            return (
              <Button
                label={buttonLabel}
                variant={ButtonVariant.Primary}
                onClick={goToLoginPage}
              />
            )
          }}
        />
      </>
    )
  }

  if (status === 'FAIL') {
    return (
      <>
        <SetNewPasswordSeo />
        <FeedbackPage
          type='error'
          icon={IconType.error}
          header={t('pages.setNewPasswordPage.fail.header')}
          paragraph={errorParagraph}
          renderButton={() => {
            return (
              <Button
                label={buttonLabel}
                variant={ButtonVariant.Primary}
                onClick={goToLoginPage}
              />
            )
          }}
        />
      </>
    )
  }

  return (
    <>
      <SetNewPasswordSeo />
      <LayoutOneColumnAndHeader>
        <Typography.H1>{t('pages.setNewPasswordPage.header')}</Typography.H1>
        <Typography.P>{t('pages.setNewPasswordPage.content')}</Typography.P>
        <Wrappers.FormWrapperAuth>
          <Formik<SetNewPasswordFormValues>
            validateOnChange={false}
            validationSchema={setNewPasswordFormValidationSchema}
            initialValues={setNewPasswordFormInitialValues}
            onSubmit={values => {
              if (status === 'IDLE') {
                handleSetNewPasswordFormSubmit(values)
              }
            }}
          >
            {() => (
              <Form>
                <SetNewPasswordFormFieldSet />
                <FormGrid.CTAWrapper>
                  <Button
                    label={t('pages.setNewPasswordPage.button')}
                    variant={ButtonVariant.Primary}
                    sizeVariant='large'
                    isLoading={status === 'LOADING'}
                    type='submit'
                  />
                </FormGrid.CTAWrapper>
              </Form>
            )}
          </Formik>
        </Wrappers.FormWrapperAuth>
      </LayoutOneColumnAndHeader>
    </>
  )
}
