import { useTheme } from '@mui/material'
import { ReactNode } from 'react'
import { Helmet } from 'seo'
import { ReactComponent as FundidSVG } from 'assets/svgs/fundid.svg'
import { CardContent } from 'ui'
import {
  CardContainer,
  Container,
  FundidSVGContainer,
  StyledCard,
  Subtitle,
  Title,
} from './AuthPageContainer.styles'

type AuthPageContainerProps = {
  title: string
  subtitle?: string
  helmetTitle: string
  children: ReactNode
}

function AuthPageContainer({
  title,
  subtitle,
  helmetTitle,
  children,
}: AuthPageContainerProps) {
  const { color } = useTheme()

  return (
    <>
      <Helmet title={`${helmetTitle} - Fundid`} />
      <Container>
        <FundidSVGContainer>
          <FundidSVG fill={color.white} />
        </FundidSVGContainer>

        <CardContainer>
          <StyledCard>
            <CardContent>
              <Title>{title}</Title>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {children}
            </CardContent>
          </StyledCard>
        </CardContainer>
      </Container>
    </>
  )
}

export { AuthPageContainer }
