import { ReactNode } from 'react'
import { Helmet as HelmetComponent } from 'react-helmet-async'

interface HelmetProps {
  children?: ReactNode
  title: string
}

export const Helmet = (props: HelmetProps) => {
  const { children, title } = props
  return (
    <HelmetComponent>
      <title>{title}</title>
      {children}
    </HelmetComponent>
  )
}
