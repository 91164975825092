import styled from 'styled-components'
import { Typography } from 'ui-kit'
import { theme } from 'ui-kit/theme/ThemeProvider.consts'

export const displayHtmlStyles = `
  * {
    color: ${theme.color.primary};
    font-family: ${theme.fontFamily.body};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${Typography.headingH5};
    margin-bottom: 0.5rem;
    font-family: ${theme.fontFamily.headline};
  }

  p,
  span {
    ${Typography.paragraphSmall};
  }

  strong,
  b {
    font-weight: 700;
  }
`

const StyledWrapper = styled.div`
  ${displayHtmlStyles}
`

interface DisplayHtmlProps {
  html: string
}

export const DisplayHtml = (props: DisplayHtmlProps) => {
  const { html } = props

  return <StyledWrapper dangerouslySetInnerHTML={{ __html: html }} />
}
