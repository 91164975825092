import { useCallback } from 'react'
import { Row } from '@tanstack/react-table'
import { PageContainer } from 'shared'
import { useTranslations } from 'translations'
import { Loader, Table } from 'ui'
import {
  LoanRow,
  useCapitalMarketplaceTable,
} from './hooks/useCapitalMarketplaceTable'
import { LoanDetailsModal } from './components/LoanDetailsModal.component'

function CapitalMarketplacePage() {
  const t = useTranslations()
  const {
    columns,
    data,
    selectedLoan,
    setSelectedLoan,
    clearSelectedLoan,
    isLoading,
  } = useCapitalMarketplaceTable()

  const onClickRow = useCallback(
    (row: Row<LoanRow>) => {
      setSelectedLoan(row.original.id)
    },
    [setSelectedLoan]
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <PageContainer
      title={t('pages.capitalMarketplace.title')}
      subtitle={t('pages.capitalMarketplace.subtitle')}
      helmetTitle={t('pages.capitalMarketplace.seoTitle')}
    >
      <Table
        columns={columns}
        data={data}
        emptyMessage={t('pages.capitalMarketplace.table.emptyMessage')}
        defaultPageSize={25}
        defaultSorting={{ id: 'amount', desc: false }}
        searchBarProps={{
          placeholder: t('pages.capitalMarketplace.table.searchBarPlaceholder'),
        }}
        onClickRow={onClickRow}
      />
      <LoanDetailsModal loan={selectedLoan} onClose={clearSelectedLoan} />
    </PageContainer>
  )
}

export { CapitalMarketplacePage }
