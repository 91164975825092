import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { ReactElement } from 'react'
import * as yup from 'yup'
import { useTranslations } from 'translations'
import { AdapterSelect } from 'form-kit'

const ReadableFieldName = (
  <FormattedMessage id='formKit.deactivateCardField.name' />
)

export interface SelectItem {
  id: string
  value: string
  displayValue: ReactElement
}

interface DeactivateCardFieldProps {
  data?: string[]
}

export const deativateCardReasonFieldValidationSchema = yup
  .string()
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))
  .nullable()

export const deactivateCardPageValidationSchema = yup.object().shape({
  reason: deativateCardReasonFieldValidationSchema,
})

export const DeactivateCardReasonField = (props: DeactivateCardFieldProps) => {
  const t = useTranslations()
  const { data } = props

  const dropdownItems =
    data && data.map(item => ({ displayValue: item, value: item, id: item }))

  return (
    <Field
      items={dropdownItems}
      component={AdapterSelect}
      label={t('pages.deactivateCardPage.selectReasonStep.selectLabel')}
      name='reason'
      isRequired
      labelId='deactivate-card-field'
      selectId='deactivate-card-field'
    />
  )
}
