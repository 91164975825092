/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import { rest } from 'msw'
import { getEnvVariable } from 'utils'

const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

export const getPlaidLinkHandler = () => [
  rest.get(`${baseUrl}/lendflow/getPlaidLink`, async (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: {
          applicationId: `${Date.now()}`,
        },
      })
    )
  }),
]
