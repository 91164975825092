import { Partner } from './shared'

enum LoansEndpoints {
  GetLoans = '/loans',
}

// LoanCategory describes the type of loan.
enum LoanCategory {
  AssetPurchase = 'ASSET_PURCHASE',
  EquipmentFinancing = 'EQUIPMENT_FINANCING',
  Crowdfunding = 'CROWDFUNDING',
  InvoiceFactoring = 'INVOICE_FACTORING',
  LineOfCredit = 'LINE_OF_CREDIT',
  RevenueBased = 'REVENUE_BASED',
  ReceivablesPurchase = 'RECEIVABLES_PURCHASE',
  SBAExpress = 'SBA_EXPRESS',
  SBA7a = 'SBA_7A',
  Term = 'TERM',
  WorkingCapital = 'WORKING_CAPITAL',
  RevenueShare = 'REVENUE_SHARE',
  MerchantCashAdvance = 'MERCHANT_CASH_ADVANCE',
  Other = 'OTHER',
}

// Loan is a form of funding for businesses. Loans are provided by Partners.
type Loan = {
  id: number
  partner: Partner
  name: string
  description: string
  qualifications: string[]
  url: string
  amountMin: number
  amountMax: number
  aprMin: number
  aprMax: number
  creditScoreMin: number
  category: LoanCategory
  states: string[]
  tagWomen: boolean
  tagPersonOfColor: boolean
  tagSpanishSpeaking: boolean
  tagNoCreditCheck: boolean
  tagFeatured: boolean
}

type GetLoansRespData = {
  loans: Loan[]
}

export type { GetLoansRespData, Loan }
export { LoansEndpoints, LoanCategory }
