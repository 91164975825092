import {
  ColumnDef as ReactTableColumnDef,
  Column as ReactTableColumn,
} from '@tanstack/react-table'
import { TableCellProps } from '@mui/material/TableCell'

interface BaseColumnMeta {
  align?: TableCellProps['align']
  isHidden?: boolean
}

type TableFilterOption = {
  label: string
  value: string
}

interface FilteredColumnMeta extends BaseColumnMeta {
  filterConfig: {
    label: string
    options: TableFilterOption[]
    type: 'checkbox' | 'select'
  }
}

type BaseColumnDef<TData, TValue = unknown> = ReactTableColumnDef<
  TData,
  TValue
> & {
  meta?: BaseColumnMeta
}

type FilteredColumnDef<TData, TValue = unknown> = ReactTableColumnDef<
  TData,
  TValue
> & {
  meta: FilteredColumnMeta
}

type ColumnDef<TData, TValue = unknown> =
  | BaseColumnDef<TData, TValue>
  | FilteredColumnDef<TData, TValue>

interface BaseColumn<TData, TValue = unknown>
  extends ReactTableColumn<TData, TValue> {
  columnDef: BaseColumnDef<TData, TValue>
}

interface FilteredColumn<TData, TValue = unknown>
  extends BaseColumn<TData, TValue> {
  columnDef: FilteredColumnDef<TData, TValue>
}

type Column<TData, TValue = unknown> =
  | BaseColumn<TData, TValue>
  | FilteredColumn<TData, TValue>

function isFilteredColumn<TData>(
  column: Column<TData>
): column is FilteredColumn<TData> {
  return (
    column.getCanFilter() &&
    !!(column.columnDef.meta as FilteredColumnMeta)?.filterConfig
  )
}

export type {
  Column,
  ColumnDef,
  FilteredColumn,
  FilteredColumnMeta,
  TableFilterOption,
  BaseColumnMeta,
}
export { isFilteredColumn }
