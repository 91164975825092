import { UseQueryResult } from 'react-query'
import { ApiError } from 'api'
import { displayBankAccount, isBankAccountActive } from 'lib/models'
import { useBankAccounts } from './useBankAccounts'

type BankAccountOption = {
  label: string
  value: string
  isDisabled?: boolean
}

function useBankAccountOptions(): UseQueryResult<
  BankAccountOption[],
  ApiError
> {
  return useBankAccounts(resp =>
    resp.bankAccounts.map(bankAccount => ({
      label: displayBankAccount(bankAccount),
      value: String(bankAccount.id),
      isDisabled: !isBankAccountActive(bankAccount),
    }))
  )
}

export { useBankAccountOptions }
