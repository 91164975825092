import MUIFormGroup from '@mui/material/FormGroup'
import styled from 'styled-components'

const StyledFormGroup = styled(MUIFormGroup)`
  &.MuiFormGroup-root {
    margin-top: 0.5rem;
  }
`

export { StyledFormGroup }
