import styled from 'styled-components'
import { Card, CardActions, CardContent } from 'ui'

const Container = styled.div`
  max-width: 24rem;
  width: 100%;
`

const StyledCard = styled(Card)`
  width: 100%;
`

const StyledCardContent = styled(CardContent)`
  align-items: flex-start;
  padding-top: 2rem;
`

const StyledCardActions = styled(CardActions)`
  justify-content: space-between;
`

const CreditCardContainer = styled.div`
  position: relative;
  width: 100%;
`

const MaskedVirtualCardContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const BillingAddressContainer = styled.div`
  padding: 2rem 0 0.5rem 0;
`

export {
  Container,
  StyledCard,
  StyledCardContent,
  StyledCardActions,
  CreditCardContainer,
  MaskedVirtualCardContainer,
  BillingAddressContainer,
}
