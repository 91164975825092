import { Helmet } from 'react-helmet-async'
import { getEnvVariable } from 'utils'

export const GoogleTagManager = () => {
  if (getEnvVariable('REACT_APP_ENVIRONMENT') !== 'production') {
    return null
  }
  return (
    <Helmet>
      <script>
        {`
              (function (w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : ''
            j.async = true
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
            f.parentNode.insertBefore(j, f)
          })(window, document, 'script', 'dataLayer', 'GTM-5KKZBT9')
        `}
      </script>
    </Helmet>
  )
}
