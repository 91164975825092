import { ReactElement } from 'react'
import { Navigate, RouteProps, useLocation } from 'react-router-dom'
import { clearSession } from 'api-connectors'
import { FullScreenLoader } from 'ui-kit'
import { useUserStatusV2 } from 'models'
import { RoutesPaths } from 'routing'

interface RequireAuthProps extends RouteProps {
  children: ReactElement
}

type RequireAuthNavigationState = {
  from?: Location
}

export const RequireAuth = (props: RequireAuthProps) => {
  const { children } = props
  const { isError, isLoading } = useUserStatusV2()
  const location = useLocation()

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (isError) {
    clearSession()

    return (
      <Navigate to={RoutesPaths.Login} state={{ from: location }} replace />
    )
  }

  return children
}

export type { RequireAuthNavigationState }
