import MUIFormLabel from '@mui/material/FormLabel'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const StyledFormLabel = styled(MUIFormLabel)`
  &.MuiFormLabel-root {
    ${typographyStyles.body1};
    color: ${({ theme }) => theme.color.black};
    font-weight: bold;
  }
`

export { StyledFormLabel }
