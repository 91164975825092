import { useTheme } from '@mui/material'
import MUIIconButton from '@mui/material/IconButton'
import { MouseEvent, useCallback } from 'react'
import { Icon, IconSize, IconType } from 'ui'
import { StyledTableCell } from './TableRowExpanderCell.styles'

type TableRowExpanderCellProps = {
  isRowExpanded: boolean
  toggleIsRowExpanded: () => void
}

function TableRowExpanderCell({
  isRowExpanded,
  toggleIsRowExpanded,
}: TableRowExpanderCellProps) {
  const { color } = useTheme()

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation()
      toggleIsRowExpanded()
    },
    [toggleIsRowExpanded]
  )

  return (
    <StyledTableCell>
      <MUIIconButton onClick={onClick}>
        <Icon
          type={isRowExpanded ? IconType.ArrowUp : IconType.ArrowDown}
          color={color.black}
          size={IconSize.Small}
        />
      </MUIIconButton>
    </StyledTableCell>
  )
}

export { TableRowExpanderCell }
