import { StyledTextField } from '../TextField/TextField.styles'

type ReadOnlyTextFieldProps = {
  label: string
  value: string
}

export function ReadOnlyTextField({ label, value }: ReadOnlyTextFieldProps) {
  return <StyledTextField label={label} value={value} aria-disabled disabled />
}
