import { useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Icon, IconType, Typography } from 'ui'

type PartnerDetailsModalLabelProps = {
  label: string
  iconType: IconType
}

function PartnerDetailsModalLabel({
  label,
  iconType,
}: PartnerDetailsModalLabelProps) {
  const { color } = useTheme()

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Icon type={iconType} color={color.backgroundGreen} />
      <Typography variant='caption'>{label}</Typography>
    </Stack>
  )
}

export type { PartnerDetailsModalLabelProps }
export { PartnerDetailsModalLabel }
