import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isActiveAutopaySchedule } from 'lib/models'
import {
  useAccountId,
  useBankAccounts,
  usePaymentSchedules,
  useUserHasCompany,
} from 'lib/queries'

enum ActivateCardPageStep {
  CompanyIntake = 0,
  ConnectBank = 1,
  SetupAutopay = 2,
  ActivateCard = 3,
}

type UseActivateCardPageStepType = {
  setActiveStep: Dispatch<SetStateAction<ActivateCardPageStep | undefined>>
} & (
  | {
      isLoading: false
      activeStep: ActivateCardPageStep
    }
  | {
      isLoading: true
      activeStep: undefined
    }
)

function useActivateCardPageStep(): UseActivateCardPageStepType {
  const [activeStep, setActiveStep] = useState<ActivateCardPageStep>()
  const { data: accountId } = useAccountId()
  const { data: userHasCompany } = useUserHasCompany()
  const { data: bankAccounts } = useBankAccounts()
  const { data: paymentSchedules } = usePaymentSchedules()

  useEffect(() => {
    if (
      activeStep !== undefined ||
      // loading userHasCompany
      userHasCompany === undefined
    ) {
      return
    }

    if (!userHasCompany) {
      setActiveStep(ActivateCardPageStep.CompanyIntake)
      return
    }

    // Loading data dependencies
    if (!accountId || !bankAccounts || !paymentSchedules) {
      return
    }

    if (bankAccounts.length === 0) {
      setActiveStep(ActivateCardPageStep.ConnectBank)
      return
    }

    if (
      !paymentSchedules.some(
        ps => ps.accountID === accountId && isActiveAutopaySchedule(ps)
      )
    ) {
      setActiveStep(ActivateCardPageStep.SetupAutopay)
      return
    }

    setActiveStep(ActivateCardPageStep.ActivateCard)
  }, [
    accountId,
    bankAccounts,
    paymentSchedules,
    activeStep,
    setActiveStep,
    userHasCompany,
  ])

  return activeStep === undefined
    ? {
        isLoading: true,
        activeStep: undefined,
        setActiveStep,
      }
    : {
        isLoading: false,
        activeStep,
        setActiveStep,
      }
}

export { useActivateCardPageStep }
