/* eslint-disable react/no-array-index-key */
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import { ReactComponent as FundidSVG } from 'assets/svgs/fundid.svg'
import { ReactComponent as VisaSVG } from 'assets/svgs/visa.svg'
import { DateFormat, stringFormatter, useFormatDate } from 'lib/formatter'
import {
  CardType,
  FundidDate,
  GetSensitiveCardInfoRespBody as SensitiveCardInfo,
  Name,
} from 'api'
import { useTranslations } from 'translations'
import { Tooltip, Typography } from 'ui'
import { cardNumberLast4ToChunks, cardNumberToChunks } from 'lib/models'
import { Container, StyledChip } from './CreditCard.styles'

type CreditCardProps = {
  cardNumberLast4: string
  expDate: FundidDate
  sensitiveCardInfo?: SensitiveCardInfo
  name: Name
  type: CardType
}

const TRANSLATION_KEY_BY_TYPE: Record<CardType, string> = {
  [CardType.Physical]: 'physical',
  [CardType.Virtual]: 'virtual',
}

function CreditCard({
  cardNumberLast4,
  expDate,
  sensitiveCardInfo,
  name,
  type,
}: CreditCardProps) {
  const t = useTranslations()
  const formatDate = useFormatDate()
  const { color } = useTheme()

  const cardNumberDisplay = sensitiveCardInfo
    ? cardNumberToChunks(sensitiveCardInfo.cardNumber)
    : cardNumberLast4ToChunks(cardNumberLast4)

  return (
    <Container>
      <Stack justifyContent='space-between' sx={{ height: '100%' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FundidSVG fill={color.white} width={84} />
          <Tooltip
            title={t(`pages.cards.${TRANSLATION_KEY_BY_TYPE[type]}.tooltip`)}
            isDisabled={type === CardType.Physical}
          >
            <StyledChip
              label={t(`pages.cards.${TRANSLATION_KEY_BY_TYPE[type]}.label`)}
            />
          </Tooltip>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          {cardNumberDisplay.map((s, i) => (
            <Typography key={i} variant='body1'>
              {s}
            </Typography>
          ))}
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Typography variant='body1'>
            {formatDate(expDate, DateFormat.MonthYear)}
          </Typography>
          <Typography variant='body1'>
            {sensitiveCardInfo?.cvv || '•••'}
          </Typography>
        </Stack>

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-end'
        >
          <Typography variant='body1'>
            {stringFormatter.displayName(name)}
          </Typography>
          <VisaSVG fill={color.white} width={64} />
        </Stack>
      </Stack>
    </Container>
  )
}

export { CreditCard }
