import TextField from '@mui/material/TextField'
import styled, { css } from 'styled-components'
import { paragraphBody } from 'ui-kit/Typography/Typography.styles'

const valueStyles = css`
  ${paragraphBody};
  color: ${({ theme }) => theme.color.green[700]};
`

const valueHover = valueStyles

const valueFocus = valueStyles

const valueError = valueFocus

const labelDefault = valueFocus

const labelHover = labelDefault

const labelFocus = css`
  ${paragraphBody};
  color: ${({ theme }) => theme.color.primary};
`

const labelDisabled = css`
  color: ${({ theme }) => theme.color.primary};

  abbr {
    color: ${({ theme }) => theme.color.primary};
  }
`

const labelError = css`
  color: ${({ theme }) => theme.color.primary};

  abbr {
    color: ${({ theme }) => theme.color.red.error};
  }
`
const labelErrorFocus = css`
  color: ${({ theme }) => theme.color.red.error};
`

const borderDefault = css`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.color.backgroundGreen};
`

const borderFocus = css`
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.apple[700]};
`

const borderError = css`
  border-color: ${({ theme }) => theme.color.red.error};
`

const borderErrorFocused = borderError

const borderDisabled = css`
  border-color: ${({ theme }) => theme.color.primary};
`

const borderHover = css`
  border-color: ${({ theme }) => theme.color.primary};
`

export const StyledInputText = styled(TextField)`
  // remove type="number" input arrows coming from the browser styles
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
  // -- DEFAULT --
  // border
  .MuiOutlinedInput-notchedOutline {
    ${borderDefault};

    transition: ${({ theme }) => theme.transition};
    transition-property: border-color border-width transform;
  }
  // label
  .MuiInputLabel-outlined {
    ${labelDefault};
    transition: ${({ theme }) => theme.transition};
    transition-property: color transform;
  }
  // value
  .MuiOutlinedInput-input {
    ${valueStyles};
    transition: ${({ theme }) => theme.transition};
    transition-property: color transform;
  }

  // -- HOVER --
  &:hover {
    // border
    .MuiOutlinedInput-notchedOutline {
      ${borderHover};
    }
    // label
    .MuiInputLabel-outlined {
      ${labelHover};
    }
    // value
    .MuiOutlinedInput-input {
      ${valueHover}
    }
  }

  // -- DISABLED --
  .Mui-disabled {
    // border
    .MuiOutlinedInput-notchedOutline {
      ${borderDisabled}
    }
    // label
    &.MuiInputLabel-outlined {
      ${labelDisabled};
    }

    &.MuiInputBase-input {
      color: ${({ theme }) => theme.color.green[700]};
      -webkit-text-fill-color: ${({ theme }) => theme.color.green[700]};
    }
  }

  // -- ERROR --
  .Mui-error {
    // border
    .MuiOutlinedInput-notchedOutline {
      ${borderError};
    }
    // label
    &.MuiInputLabel-outlined {
      ${labelError};
      // -- ERROR and FOCUSED --
      // label
      &.Mui-focused {
        ${labelErrorFocus};
      }
    }
    // input value
    .MuiOutlinedInput-input {
      ${valueError}
    }
    // -- ERROR and FOCUSED --
    &.Mui-focused {
      // border
      .MuiOutlinedInput-notchedOutline {
        ${borderErrorFocused}
      }
    }
  }

  // -- FOCUSED --
  .Mui-focused {
    // label
    &.MuiInputLabel-outlined {
      ${labelFocus};
    }
    // border
    .MuiOutlinedInput-notchedOutline {
      ${borderFocus};
    }
    // input value
    .MuiOutlinedInput-input {
      ${valueFocus}
    }
  }
`
