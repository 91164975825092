import { createGlobalStyle } from 'styled-components'
import GellerHeadlineMedium from '../fonts/geller-headlinemedium-webfont.woff2'
import Inter400 from '../fonts/inter-v8-latin-regular.woff2'
import Inter600 from '../fonts/inter-v8-latin-600.woff2'

export const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'Geller';
        font-weight: 500;  
        font-style: normal;      
        src:url(${GellerHeadlineMedium}); 
    }
    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        src:url(${Inter400});
    }
    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        src:url(${Inter600});
    }
`
