/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext } from 'react'
import { Logger } from '../../types/Logger.types'

type LoggerContextValue = {
  logger: Logger
}

const initialValue: LoggerContextValue = {
  logger: {
    send: () => {},
    debug: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  },
}

export const LoggerContext = createContext(initialValue)

interface LoggerProviderProps {
  logger: Logger
  children: ReactNode
}

export const LoggerProvider = (props: LoggerProviderProps) => {
  const { logger, children } = props

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LoggerContext.Provider value={{ logger }}>
      {children}
    </LoggerContext.Provider>
  )
}

export const useLogger = () => useContext(LoggerContext)
