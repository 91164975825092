import { Components, Theme } from '@mui/material'

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme: baseTheme }) => ({
      fontFamily: baseTheme.fontFamily.body,
      fontSize: '0.875rem',
      color: baseTheme.color.primary,
      '&.Mui-selected': {
        backgroundColor: baseTheme.color.backgroundBlue,
        fontWeight: 600,
      },
    }),
  },
}
