enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

type FundidDate = {
  year: number
  month: Month
  day: number
}

type Name = {
  first: string
  middle?: string
  last: string
}

type Address = {
  line1: string
  line2: string // For e.g. apartment number, PO box, etc.
  city: string
  stateISO2Code: string
  countryISO2Code: string
  postalCode: string // 5-digit zip code
}

// Partner is an organization that offers grants.
type Partner = {
  id: number
  name: string
  logoURL: string
  about: string
}

const DEFAULT_COUNTRY_ISO_CODE = 'US'

export type { FundidDate, Name, Address, Partner }
export { DEFAULT_COUNTRY_ISO_CODE }
