import styled from 'styled-components'
import { Typography, TypographyProps } from 'ui'

const InstructionsContainer = styled.div`
  margin: 0.5rem 0 1rem 0;
`

const FieldsContainer = styled.div`
  max-width: 28rem;
`

const SectionHeader = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h2' />
))`
  margin: 0.5rem 0 1rem 0;
`

export { InstructionsContainer, FieldsContainer, SectionHeader }
