import * as yup from 'yup'
import { useTranslations } from 'translations'
import { FormGrid, VisuallyHiddenFormLegend } from 'ui-kit'
import {
  BusinessEmailField,
  businessEmailFieldValidationSchema,
} from '../../fields/BusinessEmailField/BusinessEmailField.component'
import {
  PasswordField,
  passwordFieldValidationSchema,
} from '../../fields/PasswordField/PasswordField.component'

export interface LoginFormValues {
  businessEmail: string
  password: string
}

export const loginFormValidationSchema: yup.ObjectSchema<
  Record<keyof LoginFormValues, yup.AnySchema>
> = yup.object().shape({
  businessEmail: businessEmailFieldValidationSchema,
  password: passwordFieldValidationSchema,
})

export const loginFormInitialValues: LoginFormValues = {
  businessEmail: '',
  password: '',
}

export const LoginFormFieldSet = () => {
  const t = useTranslations()
  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.loginFormFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <FormGrid.Row>
          <BusinessEmailField />
        </FormGrid.Row>
        <FormGrid.Row>
          <PasswordField hideHint />
        </FormGrid.Row>
      </FormGrid.Grid>
    </fieldset>
  )
}
