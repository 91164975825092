/* eslint-disable react/no-unstable-nested-components */
import { ReactElement, ReactNode } from 'react'
import { Field } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { useTranslations } from 'translations'
import { getEnvVariable } from 'utils'
import { AdapterInputCheckbox } from '../../formik/AdapterInputCheckbox/AdapterInputCheckbox.component'

export const privacyPolicyFieldValidationSchema = yup
  .boolean()
  .isTrue(() => <FormattedMessage id='formKit.privacyPolicyField.validation' />)

export const PrivacyPolicyField = () => {
  const t = useTranslations<ReactElement>()
  const baseUrl = getEnvVariable('REACT_APP_FUNDID_HUBSPOT_BASE_URL')

  const label = t('formKit.privacyPolicyField.label')

  const description = t('formKit.privacyPolicyField.description', {
    link: (linkText: ReactNode[]) => (
      <a href={`${baseUrl}/legal`} target='_blank' rel='noreferrer'>
        {linkText}
      </a>
    ),
  })

  return (
    <Field
      label={label}
      name='privacyPolicyConsent'
      description={description}
      component={AdapterInputCheckbox}
    />
  )
}
