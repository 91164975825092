import MUIPopover, {
  PopoverProps as MUIPopoverProps,
} from '@mui/material/Popover'

type PopoverProps = {
  isOpen: boolean
  className?: string
} & Pick<
  MUIPopoverProps,
  'anchorEl' | 'anchorOrigin' | 'children' | 'onClose' | 'transformOrigin'
>

function Popover({
  anchorEl,
  anchorOrigin,
  children,
  isOpen,
  onClose,
  transformOrigin,
  className,
}: PopoverProps) {
  return (
    <MUIPopover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={className}
    >
      {children}
    </MUIPopover>
  )
}

export { Popover }
