import Grid from '@mui/material/Grid'
import {
  List,
  ListItem,
  Button,
  CardActions,
  CardContent,
  SVGComponent,
  ButtonProps,
} from 'ui'
import { StyledCard, StyledCardTitle } from './CardGrid.styles'

type CardGridItemProps = {
  title: string
  SVG: SVGComponent
  lis: string[]
  button: ButtonProps
}

type CardGridProps = {
  items: CardGridItemProps[]
}

function CardGrid({ items }: CardGridProps) {
  return (
    <Grid container spacing={2}>
      {items.map(({ SVG, title, lis, button }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item xs={12} sm={12} md={6} lg={4} key={`${title}-${i}`}>
          <StyledCard>
            <CardContent>
              <SVG />
              <StyledCardTitle>{title}</StyledCardTitle>
              <List>
                {lis.map(item => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </List>
            </CardContent>
            <CardActions>
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...button}
              />
            </CardActions>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  )
}

export { CardGrid }
