import { CreateAccountRequest } from 'api-connectors'
import { RegisterFormValues } from 'form-kit'

export const mapRegistrationFormValuesToRequest = (
  values: RegisterFormValues
): CreateAccountRequest => {
  const { businessEmail, lastName, name, password, privacyPolicyConsent } =
    values

  return {
    businessEmail,
    lastName,
    name,
    password,
    privacyPolicyConsent,
  }
}
