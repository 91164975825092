/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, createColumnHelper } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Offer, OfferCategory } from 'api'
import { useOffers } from 'lib/queries'
import { useTranslations } from 'translations'
import {
  Button,
  ButtonVariant,
  ColumnDef,
  TABLE_FILTER_SELECT_FIELD_ANY_OPTION,
  TableImageCell,
} from 'ui'
import { OFFER_CATEGORY_OPTIONS } from 'lib/models/offers'
import { isFeaturedComparator } from 'lib/models'

type OfferRow = Offer

const columnHelper = createColumnHelper<OfferRow>()

function filterFnCategory(
  row: Row<OfferRow>,
  _: string,
  filterValue: OfferCategory | typeof TABLE_FILTER_SELECT_FIELD_ANY_OPTION
): boolean {
  if (filterValue === TABLE_FILTER_SELECT_FIELD_ANY_OPTION) {
    return true
  }

  return row.original.category === filterValue
}

function sortingFnPartner(rowA: Row<OfferRow>, rowB: Row<OfferRow>): number {
  const {
    original: {
      partner: { name: nameA },
      tagFeatured: isFeaturedA,
    },
  } = rowA
  const {
    original: {
      partner: { name: nameB },
      tagFeatured: isFeaturedB,
    },
  } = rowB

  return isFeaturedComparator(isFeaturedA, isFeaturedB, () => {
    return nameA < nameB ? -1 : 1
  })
}

const SEARCH_PARAM_KEY_OFFER_ID = 'id'

function useSelectedOffer(offersById: Map<number, Offer>) {
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedOffer = useMemo(() => {
    const offerId = searchParams.get(SEARCH_PARAM_KEY_OFFER_ID)
    return offersById.get(Number(offerId))
  }, [searchParams, offersById])

  const setSelectedOffer = useCallback(
    (id: number) => {
      setSearchParams({
        [SEARCH_PARAM_KEY_OFFER_ID]: String(id),
      })
    },
    [setSearchParams]
  )

  const clearSelectedOffer = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  return {
    selectedOffer,
    setSelectedOffer,
    clearSelectedOffer,
  }
}

function useGrowthPartnersTable() {
  const t = useTranslations()

  const { data: offersData } = useOffers(resp => {
    const offersById = new Map<number, Offer>()

    resp.offers.forEach(offer => {
      offersById.set(offer.id, offer)
    })

    return {
      offers: resp.offers,
      offersById,
    }
  })

  const { selectedOffer, setSelectedOffer, clearSelectedOffer } =
    useSelectedOffer(offersData?.offersById || new Map())

  const columns: ColumnDef<OfferRow, any>[] = useMemo(() => {
    return [
      {
        id: 'partner',
        header: t('pages.growthPartners.table.partner'),
        accessorFn: row => row.partner.name,
        sortingFn: sortingFnPartner,
        cell: info => (
          <TableImageCell
            label={info.getValue()}
            url={info.row.original.partner.logoURL}
            chip={
              info.row.original.tagFeatured
                ? { label: t('ui.table.featured') }
                : undefined
            }
          />
        ),
      },
      {
        accessorKey: 'name',
        header: t('pages.growthPartners.table.offer'),
        enableSorting: false,
      },
      columnHelper.display({
        id: 'learnMore',
        cell: info => (
          <Button
            label={t('pages.growthPartners.table.learnMore')}
            variant={ButtonVariant.Tertiary}
            onClick={() => {
              setSelectedOffer(info.row.original.id)
            }}
          />
        ),
        meta: {
          align: 'right',
        },
      }),
      {
        accessorKey: 'category',
        filterFn: filterFnCategory,
        meta: {
          isHidden: true,
          filterConfig: {
            label: t('ui.filter.category.label'),
            options: OFFER_CATEGORY_OPTIONS,
            type: 'select',
          },
        },
      },
    ]
  }, [t, setSelectedOffer])

  return {
    columns,
    data: offersData?.offers || [],
    selectedOffer,
    setSelectedOffer,
    clearSelectedOffer,
    isLoading: !offersData,
  }
}

export type { OfferRow }
export { useGrowthPartnersTable }
