import styled from 'styled-components'
import * as Typography from '../Typography/Typography.styles'

export const StyledP = styled(Typography.P)`
  max-width: 40rem;
`

export const FirstParagraph = styled(StyledP)`
  margin-bottom: 2rem;
`
