import styled from 'styled-components'
import { Container as CreditCardContainer } from './CreditCard.styles'

const Container = styled.div`
  position: relative;
`

const Mask = styled(CreditCardContainer)`
  background-color: #000;
  opacity: 0.5;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export { Container, Mask, ButtonContainer }
