/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios'

export const isAxiosError = (e: any): e is AxiosError<unknown> => {
  return Boolean(e.isAxiosError) && typeof e.config === 'object'
}

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number'
}

export const isString = (value: unknown): value is string => {
  return typeof value === 'string'
}

export const isServerError = (e: AxiosError) => {
  if (!e.response) {
    return false
  }
  const { status } = e.response
  return status >= 500
}

export const isObject = (value: unknown): value is object => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export const hasProperty = <T extends string>(
  value: unknown,
  property: T
): value is Record<T, unknown> => {
  return isObject(value) && property in value
}

export const hasServerErrorDetails = (
  error: AxiosError
): error is AxiosError<{ data: object }> => {
  return (
    error.response !== undefined &&
    hasProperty(error.response.data, 'data') &&
    isObject(error.response.data.data)
  )
}
