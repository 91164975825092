import { useCallback } from 'react'
import { FormatDateOptions, useIntl } from 'react-intl'
import { FundidDate } from 'api'

const dateFormatter = {
  /**
   * @param {Date | FundidDate} date The raw date to be converted to a JS Date.
   * @returns JS Date that represents the input.
   */
  toDate: (date: Date | FundidDate): Date => {
    return date instanceof Date
      ? date
      : new Date(date.year, date.month - 1, date.day)
  },

  /**
   * @param date JS date to be converted to FundidDate.
   * @returns FundidDate that represents the input.
   */
  toFundidDate: (date: Date): FundidDate => {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    }
  },

  /**
   * @param timestamp Unix timestamp in seconds.
   * @returns Date equivalent of timestamp.
   */
  fromUnixSeconds: (timestamp: number): Date => new Date(timestamp * 1000),
}

enum DateFormat {
  MonthDayYear = 'MonthDayYear',
  MonthYear = 'MonthYear',
  Full = 'Full',
}

function dateFormatToIntlFormat(format: DateFormat): FormatDateOptions {
  switch (format) {
    case DateFormat.MonthDayYear:
      return {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
    case DateFormat.MonthYear:
      return {
        year: '2-digit',
        month: '2-digit',
      }
    case DateFormat.Full:
      return {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }
    default:
      return {}
  }
}

function useFormatDate() {
  const intl = useIntl()

  const formatDate = useCallback(
    (date: Date | FundidDate, format: DateFormat) => {
      return intl.formatDate(
        dateFormatter.toDate(date),
        dateFormatToIntlFormat(format)
      )
    },
    [intl]
  )

  return formatDate
}

export { useFormatDate, dateFormatter, DateFormat }
