import { useCompany, useUserHasCompany } from 'lib/queries'
import { useTranslations } from 'translations'

function useCompanyFields() {
  const t = useTranslations()
  const userHasCompanyQuery = useUserHasCompany()
  const companyFieldsQuery = useCompany(resp => {
    const { address, name, dba } = resp.company

    const fields = [
      {
        label: t('pages.newProfile.company.fields.company'),
        value: dba || name,
      },
      {
        label: t('pages.newProfile.company.fields.state'),
        value: address.stateISO2Code,
      },
      {
        label: t('pages.newProfile.company.fields.city'),
        value: address.city,
      },
      {
        label: t('pages.newProfile.company.fields.address'),
        value: address.line1,
      },
    ]

    if (address.line2) {
      fields.push({
        label: t('pages.newProfile.company.fields.address2'),
        value: address.line2,
      })
    }

    fields.push({
      label: t('pages.newProfile.company.fields.zip'),
      value: address.postalCode,
    })

    return fields
  })

  return {
    isLoading:
      userHasCompanyQuery.data === undefined ||
      (userHasCompanyQuery.data && !companyFieldsQuery.data),
    companyFields: companyFieldsQuery.data,
  }
}

export { useCompanyFields }
