/* eslint-disable @typescript-eslint/no-explicit-any */
import { getEnvVariable } from 'utils'
import {
  DictionariesResponse,
  GetGrantMatchingProgramDataResponse,
  SaveGrantMatchingProgramRequest,
} from './GrantsApi.types'
import { httpService } from '../../http-service/Http.service'

export const GrantsApi = () => {
  const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

  const { get, post } = httpService(baseUrl)

  const getDictionaries = () => {
    return get<DictionariesResponse>({
      endpoint: 'grants/dictionaries',
    })
  }

  const saveGrantMatchingProgram = (
    payload: SaveGrantMatchingProgramRequest
  ) => {
    return post<unknown, SaveGrantMatchingProgramRequest>({
      endpoint: 'grants/save',
      requestBody: payload,
    })
  }

  const getGrantMatchingProgramData = () => {
    return get<GetGrantMatchingProgramDataResponse>({
      endpoint: 'grants/status',
    })
  }

  return {
    getDictionaries,
    saveGrantMatchingProgram,
    getGrantMatchingProgramData,
  }
}
