import styled from 'styled-components'
import {
  paragraphCaptions,
  paragraphLarge,
} from '../Typography/Typography.styles'

export const StyledCard = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledHeading = styled.span`
  ${paragraphLarge};
  width: 100%;
  display: block;
`

export const StyledContent = styled.span`
  ${paragraphCaptions};
  color: ${({ theme }) => theme.color.primary};
  width: 100%;
  display: block;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`
