import { Field } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { useTranslations } from 'translations'
import { AdapterInput } from 'form-kit/formik/AdapterInput/AdapterInput.component'

const ReadableFieldName = (
  <FormattedMessage id='formKit.businessEmailField.label' />
)

const MIN = 1
const MAX = 128

export const businessEmailFieldValidationSchema = yup
  .string()
  .trim()
  .min(MIN, () => (
    <FormattedMessage
      id='formKit.validation.minLength'
      values={{ min: MIN, fieldName: ReadableFieldName }}
    />
  ))
  .max(MAX, () => (
    <FormattedMessage
      id='formKit.validation.maxLength'
      values={{ max: MAX, fieldName: ReadableFieldName }}
    />
  ))
  .email(() => <FormattedMessage id='formKit.businessEmailField.validation' />)
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))

export const BusinessEmailField = () => {
  const t = useTranslations()
  return (
    <Field
      name='businessEmail'
      label={t('formKit.businessEmailField.label')}
      isRequired
      type='email'
      component={AdapterInput}
      testId='businessEmailField'
    />
  )
}
