import styled from 'styled-components'

const ForgotPasswordLinkContainer = styled.div`
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: 2rem;
`

const SignupLinkContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`

export { ForgotPasswordLinkContainer, SignupLinkContainer }
