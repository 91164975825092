import { ReactNode } from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { AppHeader, GridOneColumn } from 'ui-kit'

export interface LayoutOneColumnAndHeaderProps {
  children: ReactNode
  onBackButtonClick?: () => void
}

const StyledSpacingWrapper = styled(Box)`
  margin-top: 3.5rem;
  padding-bottom: 2rem;
  ${({ theme }) => theme.media.minTablet} {
    margin-top: 4rem;
  }
`

export const LayoutOneColumnAndHeader = (
  props: LayoutOneColumnAndHeaderProps
) => {
  const { children, onBackButtonClick } = props

  return (
    <div>
      <AppHeader onBackButtonClick={onBackButtonClick} />
      <StyledSpacingWrapper>
        <GridOneColumn>{children}</GridOneColumn>
      </StyledSpacingWrapper>
    </div>
  )
}
