// FIXME prop-types does not work with forwarded elements
/* eslint-disable react/prop-types */
import { forwardRef, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { FieldProps } from 'formik'
import { InputText } from '../InputText/InputText.component'
import { Icon, IconType } from '../../Icons/Icon.component'

interface InputDateProps extends FieldProps {
  name?: string
  max?: Date
  isRequired?: boolean
  hasError?: boolean
  testId?: string
  label: string
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  (props, ref) => {
    const [isOpen, setOpen] = useState(false)

    const { label, max, form, field, isRequired, hasError, testId } = props

    const { name, value, onChange } = field

    const handleOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <DatePicker
        ref={ref}
        label={label}
        value={value}
        open={isOpen}
        onClose={handleClose}
        maxDate={max}
        onChange={date => form.setFieldValue(name, date)}
        renderInput={({ inputRef, inputProps }) => {
          return (
            <InputText
              ref={inputRef}
              name={name}
              label={label}
              onChange={onChange}
              isRequired={isRequired}
              hasError={hasError || false}
              testId={testId}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleOpen} edge='end' role='button'>
                      <Icon type={IconType.calendar} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...inputProps}
              value={inputProps?.value}
            />
          )
        }}
      />
    )
  }
)
