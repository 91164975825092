import Loader from '@mui/material/CircularProgress'
import styled from 'styled-components'

export const StyledWrapper = styled.section`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  background-color: ${({ theme }) => theme.color.background};
  justify-content: center;
  pointer-events: none;
  align-items: center;
`

export const StyledLoader = styled(Loader)`
  color: ${({ theme }) => theme.color.primary};
`

export const FullScreenLoader = () => {
  return (
    <StyledWrapper>
      <StyledLoader role='progressbar' title='Loading...' />
    </StyledWrapper>
  )
}
