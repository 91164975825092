import { Loan as ApiLoan, LoanCategory } from 'api'
import { USState } from './states'
import { Tag, getTagSet } from './tags'
import { AmountFilter, getAmountFilters } from './amounts'

enum APRFilter {
  Between0And10 = 'Between0And10',
  Between10And20 = 'Between10And20',
  GreaterThan20 = 'GreaterThan20',
}

type Loan = Omit<ApiLoan, 'states'> & {
  amountFilters: Set<AmountFilter>
  aprFilters: Set<APRFilter>
  states: Set<USState>
  isFeatured: boolean
  tags: Set<Tag>
}

const APR_FILTER_TO_RANGE: Record<APRFilter, [number, number]> = {
  [APRFilter.Between0And10]: [0, 10],
  [APRFilter.Between10And20]: [10, 20],
  [APRFilter.GreaterThan20]: [20, Number.MAX_SAFE_INTEGER],
}

const APR_FILTER_TO_READABLE: Record<APRFilter, string> = {
  [APRFilter.Between0And10]: '0-10%',
  [APRFilter.Between10And20]: '10-20%',
  [APRFilter.GreaterThan20]: '20%+',
}

const APR_FILTER_OPTIONS = Object.values(APRFilter).map(value => ({
  label: APR_FILTER_TO_READABLE[value],
  value,
}))

const LOAN_CATEGORY_TO_READABLE: Record<LoanCategory, string> = {
  [LoanCategory.AssetPurchase]: 'Asset Purchase',
  [LoanCategory.Crowdfunding]: 'Crowdfunding',
  [LoanCategory.EquipmentFinancing]: 'Equipment Financing',
  [LoanCategory.InvoiceFactoring]: 'Invoice Factoring',
  [LoanCategory.LineOfCredit]: 'Line of Credit',
  [LoanCategory.MerchantCashAdvance]: 'Merchant Cash Advance',
  [LoanCategory.Other]: 'Other',
  [LoanCategory.ReceivablesPurchase]: 'Receivables Purchase',
  [LoanCategory.RevenueBased]: 'Revenue Based',
  [LoanCategory.RevenueShare]: 'Revenue Share',
  [LoanCategory.SBA7a]: 'SBA 7(a)',
  [LoanCategory.SBAExpress]: 'SBA Express',
  [LoanCategory.Term]: 'Term',
  [LoanCategory.WorkingCapital]: 'Working Capital',
}

const LOAN_CATEGORY_OPTIONS = Object.values(LoanCategory)
  .sort((a, b) => (a < b ? -1 : 1))
  .map(value => ({
    label: LOAN_CATEGORY_TO_READABLE[value],
    value,
  }))

function getAPRFilters(aprMin: number, aprMax: number): Set<APRFilter> {
  const filters = new Set<APRFilter>()

  Object.keys(APRFilter).forEach(key => {
    const filter = key as APRFilter
    const range = APR_FILTER_TO_RANGE[filter]

    if (
      (aprMin >= range[0] && aprMin < range[1]) || // aprMin within range [min, max)
      (aprMax >= range[0] && aprMax < range[1]) || // aprMax within range [min, max)
      (aprMin <= range[0] && aprMax >= range[1]) // range [aprMin, aprMax] contains range [min, max]
    ) {
      filters.add(filter)
    }
  })

  return filters
}

function formatAPR(aprMin: number, aprMax: number) {
  if (aprMin === aprMax) {
    return `${aprMax}%`
  }

  if (!aprMin) {
    return `<${aprMax}%`
  }

  if (!aprMax) {
    return `${aprMin}%+`
  }

  return `${aprMin}-${aprMax}%`
}

function apiLoanToLoan(loan: ApiLoan): Loan {
  const {
    amountMin,
    amountMax,
    aprMin,
    aprMax,
    states,
    tagNoCreditCheck,
    tagPersonOfColor,
    tagSpanishSpeaking,
    tagWomen,
    tagFeatured,
  } = loan

  return {
    ...loan,
    amountFilters: getAmountFilters(amountMin, amountMax),
    aprFilters: getAPRFilters(aprMin, aprMax),
    states: new Set(states) as Set<USState>,
    isFeatured: tagFeatured,
    tags: getTagSet({
      tagNoCreditCheck,
      tagPersonOfColor,
      tagSpanishSpeaking,
      tagWomen,
    }),
  }
}

export type { Loan }
export {
  apiLoanToLoan,
  formatAPR,
  LOAN_CATEGORY_OPTIONS,
  getAPRFilters,
  APRFilter,
  APR_FILTER_OPTIONS,
}
