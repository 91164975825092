import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthenticationApi } from 'api-connectors'
import { useLogger } from 'logger'
import { Button, ButtonVariant } from 'ui'
import { useBrowserUrl } from 'routing'
import { FeedbackPage, IconType, useSnackbars } from 'ui-kit'
import { useTranslations } from 'translations'
import { hasPropertyBusinessEmail } from 'utils'
import { isAxiosError, isServerError } from 'error-handling'
import { ResetPasswordCheckEmailSeo } from './ResetPasswordCheckEmailSeo.component'

export const ResetPasswordCheckEmailPage = () => {
  const [status, setStatus] = useState<'IDLE' | 'LOADING'>('IDLE')
  const { goToLoginPage } = useBrowserUrl()
  const { forgotPassword } = AuthenticationApi()
  const { logger } = useLogger()
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbars()
  const t = useTranslations()
  const { state } = useLocation()

  useEffect(() => {
    if (!hasPropertyBusinessEmail(state)) {
      goToLoginPage()
    }
  }, [state, goToLoginPage])

  const handleResendButtonClick = async () => {
    if (!hasPropertyBusinessEmail(state)) return

    setStatus('LOADING')
    try {
      await forgotPassword({ businessEmail: state.businessEmail })
      showSuccessSnackbar(t('pages.resetPasswordCheckEmailPage.success'))
    } catch (err) {
      let uiMessage = ''

      if (isAxiosError(err)) {
        if (isServerError(err)) {
          uiMessage = t('errorMessages.SYSTEM_FAILURE')
          logger.error(uiMessage, { error: err.response?.data })
        } else {
          uiMessage = t('errorMessages.UNEXPECTED_ERROR')
        }
        showErrorSnackbar(uiMessage)
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
        showErrorSnackbar(uiMessage)
        logger.error(uiMessage, { error: err })
      }
    } finally {
      setStatus('IDLE')
    }
  }

  return (
    <>
      <ResetPasswordCheckEmailSeo />
      <FeedbackPage
        icon={IconType.mail}
        header={t('pages.resetPasswordCheckEmailPage.header')}
        paragraph={t('pages.resetPasswordCheckEmailPage.paragraph')}
        renderButton={() => {
          return (
            <Button
              label={t('pages.resetPasswordCheckEmailPage.buttonLabel')}
              variant={ButtonVariant.Tertiary}
              isLoading={status === 'LOADING'}
              onClick={() => {
                if (status === 'IDLE') {
                  handleResendButtonClick()
                }
              }}
            />
          )
        }}
      />
    </>
  )
}
