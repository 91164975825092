import { Field } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { AdapterInput } from 'form-kit'
import { useTranslations } from 'translations'

const MIN_LENGTH = 1

const ReadableFieldName = (
  <FormattedMessage id='formKit.companyNameField.label' />
)

export const companyNameFieldValidationSchema = yup
  .string()
  .trim()
  .min(MIN_LENGTH, () => (
    <FormattedMessage
      id='formKit.validation.minLength'
      values={{ min: MIN_LENGTH, fieldName: ReadableFieldName }}
    />
  ))
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))

export const companyNameScreenValidationSchema = yup.object().shape({
  companyName: companyNameFieldValidationSchema,
})

export const CompanyNameField = () => {
  const t = useTranslations()

  return (
    <Field
      name='companyName'
      label={t('formKit.companyNameField.label')}
      isRequired
      component={AdapterInput}
    />
  )
}
