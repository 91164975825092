/**
 * For up-to-date explanation of these statuses head over to our [wiki](https://netguru.atlassian.net/wiki/spaces/FM/pages/3153068033/16.03.2022+-+Charge+card+application+status)
 */
export enum StatusValues {
  LOANS_STARTED = 'LOANS_STARTED',

  GRANT_MATCH_PROGRAM_STARTED = 'GRANT_MATCH_PROGRAM_STARTED',
  GRANT_MATCH_PROGRAM_FINISHED = 'GRANT_MATCH_PROGRAM_FINISHED',

  /**
   * User has started the preliminary flow
   */
  PRELIMINARY_STARTED = 'PRELIMINARY_STARTED',

  /**
   * User has finished all the questions in the preliminary flow
   */
  PRELIMINARY_FINISHED = 'PRELIMINARY_FINISHED',

  /**
   * User has failed one of the questions in the preliminary flow
   */
  PRELIMINARY_FAILED = 'PRELIMINARY_FAILED',

  /**
   * User has failed, but decided to continue
   */
  PRELIMINARY_FAILED_BUT_CONTINUED = 'PRELIMINARY_FAILED_BUT_CONTINUED',

  /**
   * Just after we receive application data when user finishes all processing with Lendflow widget.
   */
  WIDGET_FINISHED = 'WIDGET_FINISHED',

  /**
   * When Lendflow rejected application.
   */
  WIDGET_FINISHED_AND_REJECTED = 'WIDGET_FINISHED_AND_REJECTED',

  /**
   * When Lendflow accepted application and prepared the offer for users.
   */
  WIDGET_FINISHED_AND_ACCEPTED = 'WIDGET_FINISHED_AND_ACCEPTED',

  // When two widgets were finished and user is waiting for the manual approval
  WIDGET_IN_PROGRESS = 'WIDGET_IN_PROGRESS',

  /**
   * When user accepted the offer and signed it.
   */
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',

  /**
   * When user has CoreCard account and linking is in progress.
   */
  ACH_IN_PROGRESS = 'ACH_IN_PROGRESS',

  /**
   * When linking process has failed.
   */
  ACH_FAILED = 'ACH_FAILED',

  /**
   * When user has CoreCard account and product set up and has bank account for repayments connected. The product is fully ready to use.
   */
  ACH_CONNECTED = 'ACH_CONNECTED',

  /**
   * When user has an enabled card connected to account
   */
  CARD_ENABLED = 'CARD_ENABLED',

  /**
   * When user has a disabled card
   */
  CARD_DISABLED = 'CARD_DISABLED',

  // Whan the card was disabled by admin
  ADMIN_CARD_DISABLED = 'ADMIN_CARD_DISABLED',

  /**
   * When user has a frozen card
   */
  CARD_FROZEN = 'CARD_FROZEN',

  /**
   * When admin froze user's card
   */
  ADMIN_CARD_FROZEN = 'ADMIN_CARD_FROZEN',

  /**
   * This flag is set as soon as we detect that new physical card has been printed and now it is shipping to its owner, as soon as you activate the card this flag will disappear
   * TODO: Change property name once all related PRs are merged
   */
  CARD_PHYSICAL_SHIPPING = 'CARD_SHIPPING',

  /**
   * When new physical card is created and is still not activated, of course in this state you cannot freeze or disable the card, the only allowed action is to activate it
   */
  CARD_NOT_ACTIVE = 'CARD_NOT_ACTIVE',

  /**
   * When user is able to see financial data i.e. transactions list or graphs on dashboard
   */
  SHOW_FINANCIAL_DATA = 'SHOW_FINANCIAL_DATA',

  /**
   * When user used all available card activation attempts
   */
  CARD_ACTIVATION_DEPLETED = 'CARD_ACTIVATION_DEPLETED',
}

export enum FeatureFlags {
  MFA = 'MFA',
}
