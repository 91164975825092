import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslations } from 'translations'

type LoginFormData = {
  email: string
  password: string
}

function useLoginForm() {
  const t = useTranslations()
  const schema: yup.SchemaOf<LoginFormData> = useMemo(
    () =>
      yup
        .object({
          email: yup
            .string()
            // Validate email
            .email(t('ui.form.validation.email'))
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newLogin.form.email'),
              })
            ),
          password: yup
            .string()
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newLogin.form.password'),
              })
            ),
        })
        .required(),
    [t]
  )

  return useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })
}

export type { LoginFormData }
export { useLoginForm }
