import { Components, Theme } from '@mui/material'
import { MuiTooltip } from './MuiTooltip.styles'
import { MuiMenuItem } from './MuiMenuItem.styles'
import { MuiPickersPopper } from './MuiPickersPopper.styles'
import { MuiPickersDay } from './MuiPickersDay.styles'
import { MuiDayPicker } from './MuiDayPicker.styles'
import { MuiPickersCalendarHeader } from './MuiPickersCalendarHeader.styles'
import { PrivatePickersYear } from './PrivatePickersYear.styles'

/*
 This constant holds styles for components where our standard approach (with using `styled(MuiComponent)` ) does not work properly.
 For example, when using that approach with MuiTooltip, styles are passed to the child instead of tooltip itself.

 Disclaimer: We wanted to move all our components to this constant and style them here, but due to time constraints
 we won't be able to do it quickly. So for now, use this constant to style components where our standard approach does not work.
 */
export const components: Components<Theme> = {
  MuiTooltip,
  MuiMenuItem,
  MuiPickersPopper,
  MuiPickersDay,
  MuiDayPicker,
  MuiPickersCalendarHeader,
  PrivatePickersYear,
}
