import * as yup from 'yup'
import {
  EmergencyCodeNumberField,
  EmergencyCodeNumberFieldValidationSchema,
} from 'form-kit/fields/EmergencyCodeNumberField/EmergencyCodeNumberField.component'
import { FormGrid } from 'ui-kit'

export const emergencyCodeNumberInitialValues = {
  codeNumber: '',
}

export interface EmergencyCodeNumberFormValues {
  codeNumber: string
}

export const emergencyCodeVerificationFieldSetValidationSchema: yup.ObjectSchema<
  Record<keyof EmergencyCodeNumberFormValues, yup.AnySchema>
> = yup.object().shape({
  codeNumber: EmergencyCodeNumberFieldValidationSchema,
})

export const EmergencyCodeVerificationFieldSet = () => {
  return (
    <FormGrid.Row>
      <EmergencyCodeNumberField />
    </FormGrid.Row>
  )
}
