import { QueryDependency, QueryKey } from '../types'

const cardKeys = {
  all: ['cards'] as const,
  byAccount: (accountId: QueryDependency): QueryKey => [
    ...cardKeys.all,
    'account',
    accountId,
  ],
} as const

const sensitiveCardInfoKeys = {
  all: ['sensitiveCardInfo'] as const,
  byAccount: (accountId: QueryDependency): QueryKey => [
    ...sensitiveCardInfoKeys.all,
    'account',
    accountId,
  ],
  byCard: (accountId: QueryDependency, cardId: QueryDependency): QueryKey => [
    ...sensitiveCardInfoKeys.byAccount(accountId),
    'card',
    cardId,
  ],
}

export { cardKeys, sensitiveCardInfoKeys }
