import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledIconTile = styled(Link)`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  padding-left: 1.5rem;
  align-items: center;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fontFamily.headline};
  height: 4.375rem;
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
`

export const StyledIconWrapper = styled.span`
  margin-right: 1rem;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`
