import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslations } from 'translations'

type ResetPasswordFormData = {
  newPassword: string
}

function useResetPasswordForm() {
  const t = useTranslations()
  const schema: yup.SchemaOf<ResetPasswordFormData> = useMemo(
    () =>
      yup
        .object({
          newPassword: yup
            .string()
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newResetPassword.form.newPassword'),
              })
            ),
        })
        .required(),
    [t]
  )

  return useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: '',
    },
  })
}

export type { ResetPasswordFormData }
export { useResetPasswordForm }
