import { useMemo } from 'react'
import Stack from '@mui/material/Stack'
import { FundidDate } from 'api'
import { DateFormat, useFormatCurrency, useFormatDate } from 'lib/formatter'
import { OneTimePaymentButton } from 'shared'
import { useTranslations } from 'translations'
import { CardActions, CardContent, Typography } from 'ui'
import { StyledCard } from './PaymentsCard.styles'

type PaymentsCardProps = {
  autopayDate: FundidDate
  autopayAmount: number
}

function PaymentsCard({ autopayAmount, autopayDate }: PaymentsCardProps) {
  const t = useTranslations()
  const formatDate = useFormatDate()
  const formatCurrency = useFormatCurrency()

  const rows = useMemo(() => {
    return [
      {
        label: t(
          'pages.dashboard.creditAccountHolder.cards.payments.nextPaymentDate'
        ),
        value: formatDate(autopayDate, DateFormat.MonthDayYear),
      },
      {
        label: t(
          'pages.dashboard.creditAccountHolder.cards.payments.paymentAmount'
        ),
        value: formatCurrency(autopayAmount),
      },
    ]
  }, [autopayAmount, autopayDate, formatCurrency, formatDate, t])

  return (
    <StyledCard>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant='caption' bold>
            {t('pages.dashboard.creditAccountHolder.cards.payments.title')}
          </Typography>

          {rows.map(({ label, value }) => (
            <Stack
              direction='row'
              justifyContent='space-between'
              spacing={4}
              key={`${label}-${value}`}
            >
              <Typography variant='caption'>{label}</Typography>
              <Typography variant='caption'>{value}</Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
      <CardActions>
        <OneTimePaymentButton />
      </CardActions>
    </StyledCard>
  )
}

export { PaymentsCard }
