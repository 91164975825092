import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, GetPaymentSchedulesRespData, PaymentSchedule } from 'api'
import { paymentSchedulesKeys } from './queryKeys'
import { errQueryMissingDependencies } from '../queryError'
import { useUser } from '../users/useUser'

function usePaymentSchedules<T = PaymentSchedule[]>(
  select?: (resp: GetPaymentSchedulesRespData) => T
) {
  const apiClient = useApiClient()
  const { data: user } = useUser()

  const queryKey = paymentSchedulesKeys.byAccount(user?.accountID)

  return useQuery({
    queryKey,
    enabled: !!user?.accountID && !!user.companyID,
    queryFn: () => {
      if (!user?.companyID || !user?.accountID) {
        return errQueryMissingDependencies(queryKey, {
          companyId: user?.companyID,
          accountId: user?.accountID,
        })
      }

      const { accountID, companyID } = user
      return apiClient.getPaymentSchedules({
        accountID,
        companyID,
      })
    },
    select: resp => (select ? select(resp) : resp.paymentSchedules),
  }) as UseQueryResult<
    typeof select extends undefined ? PaymentSchedule[] : T,
    ApiError
  >
}

export { usePaymentSchedules }
