import styled, { css } from 'styled-components'
import { typographyStyles } from 'ui'

const StyledLi = styled.li`
  ${typographyStyles.body1};
  margin-bottom: 1rem;
  list-style-type: none;

  :last-child {
    margin-bottom: 0;
  }
`

const StyledLabel = styled.span<{ isNavOpen: boolean }>`
  ${({ isNavOpen }) => {
    return !isNavOpen
      ? css`
          display: none;
        `
      : css`
          display: block;
          margin-left: 0.75rem;
        `
  }}

  overflow: hidden;
  white-space: nowrap;
`

export { StyledLi, StyledLabel }
