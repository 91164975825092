import { useEffect } from 'react'
import { createAndAppendScript, getEnvVariable } from 'utils'
import './HubspotTracking.types'
import { useHubspotTracking } from './useHubspotTracking.hook'

export const HubspotTracking = () => {
  useHubspotTracking()

  useEffect(() => {
    const isProductionEnv =
      getEnvVariable('REACT_APP_ENVIRONMENT') === 'production'
    const isStagingEnv =
      getEnvVariable('REACT_APP_ENVIRONMENT') === 'staging' &&
      !['development', 'test'].includes(getEnvVariable('NODE_ENV'))

    /**
     * Append only on production and staging
     * Local env has `REACT_APP_ENVIRONMENT` set to `staging` as well,
     * hence the additional check
     */
    if (!isProductionEnv && !isStagingEnv) {
      return
    }

    const script = createAndAppendScript({
      src: '//js.hs-scripts.com/6737579.js',
      id: 'hs-script-loader',
      async: true,
      defer: true,
    })

    // eslint-disable-next-line consistent-return
    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [])

  return null
}
