/* eslint-disable react/no-array-index-key */
import { Grid, Stack } from '@mui/material'
import { useBrowserUrl } from 'routing'
import {
  Button,
  ButtonVariant,
  IconType,
  Modal,
  ModalTitle,
  Typography,
} from 'ui'
import {
  PartnerDetailsModalLabel,
  PartnerDetailsModalLabelProps,
} from './components/PartnerDetailsModalLabel.component'
import {
  PartnerDetailsModalSection,
  PartnerDetailsModalSectionProps,
} from './components/PartnerDetailsModalSection.component'
import { Logo } from './PartnerDetailsModal.styles'

type PartnerDetailsModalProps = {
  isOpen: boolean
  onClose: () => void
  title: string
  logoUrl: string
  labels: PartnerDetailsModalLabelProps[]
  sections: PartnerDetailsModalSectionProps[]
  button: {
    label: string
    externalUrl: string
  }
}

function PartnerDetailsModal({
  title,
  logoUrl,
  labels,
  sections,
  button,
  isOpen,
  onClose,
}: PartnerDetailsModalProps) {
  const { navigateOutsideApp } = useBrowserUrl()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle onClose={onClose}>
        <Stack
          direction='row'
          spacing={1}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
        >
          {!!logoUrl && <Logo src={logoUrl} alt={`${title} logo`} />}
          <Typography variant='h2'>{title}</Typography>
        </Stack>
      </ModalTitle>

      <Grid container columnSpacing={2} rowSpacing={1}>
        {labels.map(({ label, iconType }, i) => (
          <Grid item key={i}>
            <PartnerDetailsModalLabel label={label} iconType={iconType} />
          </Grid>
        ))}
      </Grid>

      {sections.map(({ header, iconType, description }, i) => (
        <PartnerDetailsModalSection
          key={i}
          header={header}
          iconType={iconType}
          description={description}
        />
      ))}

      <Stack direction='row' justifyContent='flex-end' mt={2}>
        <Button
          label={button.label}
          variant={ButtonVariant.Primary}
          iconType={IconType.ExternalLink}
          onClick={() => navigateOutsideApp(button.externalUrl)}
        />
      </Stack>
    </Modal>
  )
}

export { PartnerDetailsModal }
