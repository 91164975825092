import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslations } from 'translations'
import { useBrowserUrl } from 'routing'
import { Button, ButtonVariant } from 'ui'
import { FeedbackPage, IconType, useSnackbars } from 'ui-kit'
import { isAxiosError, isServerError } from 'error-handling'
import { useLogger } from 'logger'
import { hasPropertyBusinessEmail } from 'utils'
import { RegistrationApi } from 'api-connectors'
import { ConfirmEmailSeo } from './ConfirmEmailSeo.component'

export const ConfirmEmailPage = () => {
  const { state } = useLocation()
  const { goToLoginPage } = useBrowserUrl()
  const [loading, setIsLoading] = useState(false)
  const { logger } = useLogger()
  const t = useTranslations()
  const { requestActivationEmailResend } = RegistrationApi()
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbars()

  useEffect(() => {
    if (!hasPropertyBusinessEmail(state)) {
      goToLoginPage()
    }
  }, [goToLoginPage, state])

  const handleResendConfirmationEmail = async () => {
    if (!hasPropertyBusinessEmail(state)) return
    setIsLoading(true)
    try {
      await requestActivationEmailResend({
        businessEmail: state.businessEmail,
      })

      showSuccessSnackbar(t('pages.confirmEmailPage.success'))
    } catch (err) {
      let uiMessage = ''

      if (isAxiosError(err)) {
        if (isServerError(err)) {
          uiMessage = t('errorMessages.SYSTEM_FAILURE')
          logger.error(uiMessage, { error: err.response?.data })
        } else {
          uiMessage = t('pages.confirmEmailPage.error')
        }
        showErrorSnackbar(uiMessage)
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
        showErrorSnackbar(uiMessage)
        logger.error(uiMessage, { error: err })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ConfirmEmailSeo />
      <FeedbackPage
        icon={IconType.mail}
        header={t('pages.confirmEmailPage.header')}
        paragraph={t('pages.confirmEmailPage.content')}
        renderButton={() => {
          return (
            <Button
              label={t('pages.confirmEmailPage.button')}
              variant={ButtonVariant.Tertiary}
              isLoading={loading}
              onClick={() => {
                if (!loading) {
                  handleResendConfirmationEmail()
                }
              }}
            />
          )
        }}
      />
    </>
  )
}
