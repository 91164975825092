import styled from 'styled-components'
import { Paper } from 'ui/Paper/Paper.component'
import { Typography, TypographyProps } from 'ui/Typography/Typography.component'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledPaper = styled(Paper)`
  max-width: 48rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ErrorMessage = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='body1' />
))`
  margin: 2.5rem 0;
`

export { Container, StyledPaper, ErrorMessage }
