import { useCallback } from 'react'
import styled from 'styled-components'
import { useAuthentication } from 'authentication'
import { isAxiosError, isServerError } from 'error-handling'
import { useSnackbars } from 'ui-kit'
import { useBrowserUrl } from 'routing'
import { IconType, Icon } from 'ui-kit/Icons/Icon.component'
import { useLogger } from 'logger'
import { useTranslations } from 'translations'

const StyledLogoutButton = styled.button`
  background: none;
  border: none;
  right: 2rem;
  cursor: pointer;
`

function useLogout() {
  const { logout } = useAuthentication()
  const { showErrorSnackbar } = useSnackbars()
  const { logger } = useLogger()
  const { goToLoginPage } = useBrowserUrl()
  const t = useTranslations()

  return useCallback(async () => {
    try {
      await logout()
      goToLoginPage()
    } catch (err) {
      if (isAxiosError(err)) {
        if (isServerError(err)) {
          const message = t('shared.navbar.errors.serverError')
          showErrorSnackbar(message)
          logger.error(message, { error: err.response?.data })
        } else {
          const message = t('shared.navbar.errors.unexpectedError')
          showErrorSnackbar(message)
        }
      } else {
        const message = t('shared.navbar.errors.unexpectedError')
        showErrorSnackbar(message)
        logger.error(message, { error: err })
      }
    }
  }, [goToLoginPage, logger, logout, showErrorSnackbar, t])
}

function LogoutButton() {
  const handleLogout = useLogout()

  return (
    <StyledLogoutButton type='button' onClick={handleLogout}>
      <Icon type={IconType.logout} />
    </StyledLogoutButton>
  )
}

export { useLogout, LogoutButton }
