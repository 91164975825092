import * as yup from 'yup'
import {
  CodeNumberField,
  CodeNumberFieldValidationSchema,
} from 'form-kit/fields/CodeNumberField/CodeNumberField.component'
import { FormGrid } from 'ui-kit'
import {
  SaveDeviceField,
  saveDeviceFieldValidationSchema,
} from '../../fields/SaveDeviceField/SaveDeviceField.component'

export const codeNumberInitialValues = {
  codeNumber: null,
  saveDevice: false,
}

export interface CodeNumberFormValues {
  codeNumber: string | null
  saveDevice: boolean
}

export const identityCodeVerificationFieldSetValidationSchema: yup.ObjectSchema<
  Record<keyof CodeNumberFormValues, yup.AnySchema>
> = yup.object().shape({
  codeNumber: CodeNumberFieldValidationSchema,
  saveDevice: saveDeviceFieldValidationSchema,
})

export const IdentityCodeVerificationFieldSet = () => {
  return (
    <FormGrid.Grid>
      <FormGrid.Row>
        <CodeNumberField />
      </FormGrid.Row>
      <FormGrid.Row>
        <SaveDeviceField />
      </FormGrid.Row>
    </FormGrid.Grid>
  )
}
