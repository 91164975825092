import styled from 'styled-components'
import { Typography, TypographyProps } from 'ui'

const CardTitle = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h3' />
))`
  margin: 1rem 0;
`

export { CardTitle }
