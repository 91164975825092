import { flexRender, Row } from '@tanstack/react-table'
import MUICollapse from '@mui/material/Collapse'
import { ReactNode, useCallback } from 'react'
import {
  ExpandableTableCell,
  ExpandableTableRow,
  StyledTableCell,
  StyledTableRow,
} from './TableRow.styles'
import { TableRowExpanderCell } from './TableRowExpanderCell.component'
import { BaseColumnMeta } from '../types'

type TableRowProps<TData> = {
  row: Row<TData>
  renderExpandedRowContent?: (row: Row<TData>) => ReactNode
  onClickRow?: (row: Row<TData>) => void
}

function TableRow<TData>({
  row,
  renderExpandedRowContent,
  onClickRow,
}: TableRowProps<TData>) {
  const onClick = useCallback(() => {
    if (renderExpandedRowContent) {
      row.getToggleExpandedHandler()()
      return
    }

    if (onClickRow) {
      onClickRow(row)
    }
  }, [row, onClickRow, renderExpandedRowContent])

  return (
    <>
      <StyledTableRow
        onClick={onClick}
        $isClickable={!!renderExpandedRowContent || !!onClickRow}
      >
        {row.getVisibleCells().map(cell => (
          <StyledTableCell
            key={cell.id}
            align={(cell.column.columnDef.meta as BaseColumnMeta)?.align}
            sx={{
              maxWidth: '18rem',
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </StyledTableCell>
        ))}
        {row.getCanExpand() && (
          <TableRowExpanderCell
            isRowExpanded={row.getIsExpanded()}
            toggleIsRowExpanded={row.getToggleExpandedHandler()}
          />
        )}
      </StyledTableRow>

      {renderExpandedRowContent && (
        <ExpandableTableRow aria-hidden={!row.getIsExpanded()}>
          <ExpandableTableCell
            colSpan={
              row.getVisibleCells().length +
              1 /* add one colSpan to account for the collapse icon */
            }
          >
            <MUICollapse in={row.getIsExpanded()} timeout='auto' unmountOnExit>
              {renderExpandedRowContent(row)}
            </MUICollapse>
          </ExpandableTableCell>
        </ExpandableTableRow>
      )}
    </>
  )
}

export { TableRow }
