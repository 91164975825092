import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'
import { AdapterInput } from 'form-kit/formik/AdapterInput/AdapterInput.component'
import { useTranslations } from 'translations'

const VALID_INPUT_LENGTH = 10

const ReadableFieldName = (
  <FormattedMessage id='formKit.emergencyCodeNumberField.label' />
)

export const EmergencyCodeNumberFieldValidationSchema = yup
  .string()
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))
  .nullable()
  .min(VALID_INPUT_LENGTH, () => (
    <FormattedMessage
      id='formKit.validation.minLength'
      values={{ min: VALID_INPUT_LENGTH, fieldName: ReadableFieldName }}
    />
  ))
  .max(VALID_INPUT_LENGTH, () => (
    <FormattedMessage
      id='formKit.validation.maxLength'
      values={{ max: VALID_INPUT_LENGTH, fieldName: ReadableFieldName }}
    />
  ))

export const EmergencyCodeNumberField = () => {
  const t = useTranslations()

  return (
    <Field
      label={t('formKit.emergencyCodeNumberField.label')}
      name='codeNumber'
      isRequired
      type='text'
      component={AdapterInput}
    />
  )
}
