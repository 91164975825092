import { useCards, useUser } from 'lib/queries'
import { PageContainer } from 'shared'
import { useTranslations } from 'translations'
import { Loader } from 'ui'
import { CreditAccountHolderDashboard } from './components/CreditAccountHolderDashboard.component'
import { NonCreditAccountHolderDashboard } from './components/NonCreditAccountHolderDashboard.component'

function DashboardPage() {
  const t = useTranslations()
  const { data: user } = useUser()
  const { data: cards } = useCards()

  const isLoading = !user || (user.accountID && !cards)
  if (isLoading) {
    return <Loader />
  }

  // Only show credit account holder content if user has an account and
  // at least one card
  const showCreditAccountHolderDashboard =
    user.accountID && (!!cards?.physical || !!cards?.virtual)

  return (
    <PageContainer helmetTitle={t('pages.dashboard.seoTitle')}>
      {showCreditAccountHolderDashboard ? (
        <CreditAccountHolderDashboard />
      ) : (
        <NonCreditAccountHolderDashboard />
      )}
    </PageContainer>
  )
}

export { DashboardPage }
