import { FundidDate } from './shared'

enum PaymentSchedulesEndpoints {
  GetPaymentSchedules = '/credit/payment-schedules',
  CreatePaymentSchedule = '/credit/payment-schedules',
}

enum PaymentAmountType {
  MinDue = 'MIN_DUE',
  RemainingStatementBalance = 'REMAINING_STATEMENT_BALANCE',
  CurrentBalance = 'CURRENT_BALANCE',
  Fixed = 'FIXED_AMOUNT',
}

enum PaymentFrequency {
  OneTime = 'ONE_TIME',
  Monthly = 'MONTHLY',
}

enum PaymentScheduleStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Completed = 'COMPLETED',
}

type PaymentSchedule = {
  id: number
  accountID: number
  amountType: PaymentAmountType
  frequency: PaymentFrequency
  status: PaymentScheduleStatus
  fixedAmount: number
  date: FundidDate
}

type GetPaymentSchedulesQueryParams = {
  accountID: number
  companyID: number
}

type GetPaymentSchedulesRespData = {
  paymentSchedules: PaymentSchedule[]
}

type CreatePaymentScheduleReqBody = {
  accountID: number
  bankAccountID: number
  frequency: PaymentFrequency
  amountType: PaymentAmountType
  amount?: number // Required if AmountType = PaymentAmountTypeFixedAmount
  date?: FundidDate // Required if Frequency = PaymentFrequencyOneTime
}

type CreatePaymentScheduleRespData = {
  paymentScheduleID: number
}

export type {
  GetPaymentSchedulesQueryParams,
  GetPaymentSchedulesRespData,
  CreatePaymentScheduleReqBody,
  CreatePaymentScheduleRespData,
  PaymentSchedule,
}
export {
  PaymentFrequency,
  PaymentScheduleStatus,
  PaymentSchedulesEndpoints,
  PaymentAmountType,
}
