import { FieldValues } from 'react-hook-form'
import { StyledMenuItem } from './SelectField.styles'
import { TextField, TextFieldProps } from '../TextField/TextField.component'

type SelectOption = {
  label: string
  value: string // use strings only, since numbers can cause issues with default values
}

type SelectFieldProps<TFieldValues extends FieldValues> = Omit<
  TextFieldProps<TFieldValues>,
  'children' | 'select'
> & {
  options: SelectOption[]
}

function SelectField<TFieldValues extends FieldValues>({
  options,
  ...rest
}: SelectFieldProps<TFieldValues>) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...rest} select>
      {options.map(({ label, value }) => (
        <StyledMenuItem key={value} value={value}>
          {label}
        </StyledMenuItem>
      ))}
    </TextField>
  )
}

export { SelectField }
export type { SelectOption }
