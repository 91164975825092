import { Components, Theme } from '@mui/material'

export const PrivatePickersYear: Components<Theme>['PrivatePickersYear'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '.PrivatePickersYear-yearButton': {
        fontFamily: theme.fontFamily.body,
        '&.Mui-selected': {
          backgroundColor: theme.color.primary,
          '&:hover,&:focus': {
            backgroundColor: theme.color.primary,
          },
        },
      },
    }),
  },
}
