import { Components, Theme } from '@mui/material'

export const MuiPickersDay: Components<Theme>['MuiPickersDay'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: theme.fontFamily.body,
      '&.Mui-selected': {
        backgroundColor: theme.color.primary,
        '&:hover,&:focus': {
          backgroundColor: theme.color.primary,
        },
      },
    }),
  },
}
