import { Stack, useTheme } from '@mui/material'
import { RoutesPaths } from 'routing'
import { useTranslations } from 'translations'
import { Alert, Link, Typography } from 'ui'

type ActivateCardAlertProps = {
  cardId: number
}

function ActivateCardAlert({ cardId }: ActivateCardAlertProps) {
  const t = useTranslations()
  const { color } = useTheme()

  return (
    <Alert
      severity='warning'
      message={
        <Stack spacing={2}>
          <Typography variant='caption'>
            {t('shared.activateCardAlert.textOne')}
            <Link
              variant='caption'
              color={color.warning.text}
              href={`${RoutesPaths.ActivateCard}/${cardId}`}
            >
              {t('shared.activateCardAlert.cta')}
            </Link>
            {t('shared.activateCardAlert.textTwo')}
          </Typography>

          <Typography variant='caption' component='div'>
            {t('shared.activateCardAlert.textThree')}
          </Typography>
        </Stack>
      }
    />
  )
}

export { ActivateCardAlert }
