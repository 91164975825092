const SESSION_KEY = 'flags'

export const setSession = (flags: string) => {
  sessionStorage.setItem(SESSION_KEY, flags)
}

export const clearSession = () => {
  sessionStorage.clear()
}

export const getSession = () => {
  return Boolean(sessionStorage.getItem(SESSION_KEY))
}

const LOGGED_IN_KEY = 'loggedIn'

export const flagAsLoggedIn = () => {
  return localStorage.setItem(LOGGED_IN_KEY, 'true')
}

/**
 * Is used to define if a user was previously logged in
 * it's used primarily to display an error indicating session timeout.
 * Because of security reasons, we do not say if the token is invalid or expired.
 */
export const wasLoggedIn = () => {
  return localStorage.getItem(LOGGED_IN_KEY)
}

export const clearLoggedInFlag = () => {
  return localStorage.removeItem(LOGGED_IN_KEY)
}
