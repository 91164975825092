import { FormControlLabel } from '@mui/material'
import { FieldProps } from 'formik'
import styled from 'styled-components'
import { CustomRadioButton, CustomRadioButtonValue } from 'ui-kit'

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin: 0 auto;
`

interface AdapterRadioButtonProps extends FieldProps {
  label: string
  value: CustomRadioButtonValue
  name: string
  id: string
}

export const AdapterRadioButton = (props: AdapterRadioButtonProps) => {
  const { label, value, field, form, id } = props

  const selectedValue = form.values[field.name]

  const onChange = () => {
    form.setFieldValue(field.name, value)
  }

  return (
    <StyledFormControlLabel
      name={field.name}
      value={value}
      control={
        <CustomRadioButton
          onClick={onChange}
          id={id}
          selectedValue={selectedValue}
          value={value}
          content={label}
          isValid={form.isValid}
        />
      }
      label={label}
    />
  )
}
