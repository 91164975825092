import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import { AdapterRadioButton } from '../../formik/AdapterRadioButton/AdapterRadioButton.component'

export interface RevenueValue {
  slug: 'UNDER_50_K' | '50_TO_100_K' | '100_TO_250_K' | 'OVER_250_K'
  value: boolean
}

interface RevenueRadioFieldProps {
  label: string
  name: string
  value: RevenueValue
  id: string
}

export const revenueRadioValidationSchema = yup.object().shape({
  revenue: yup
    .object()
    .required(() => <FormattedMessage id='formKit.revenueFieldSet.required' />),
})

export const RevenueRadioField = (props: RevenueRadioFieldProps) => {
  const { label, value, name, id } = props

  return (
    <Field
      name={name}
      type='radio'
      label={label}
      id={id}
      value={value}
      component={AdapterRadioButton}
    />
  )
}
