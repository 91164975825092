import { QueryDependency, QueryKey } from '../types'

const companyKeys = {
  all: ['companies'] as const,
  byUserId: (id: QueryDependency): QueryKey => [
    ...companyKeys.all,
    'userId',
    id,
  ],
} as const

export { companyKeys }
