import { FormattedMessage } from 'react-intl'
import { LayoutOneColumnAndHeader, Typography } from 'ui-kit'

export const LoginPageContent = () => {
  return (
    <LayoutOneColumnAndHeader>
      <Typography.H1>
        <FormattedMessage id='pages.loginPage.header' />
      </Typography.H1>
      <Typography.P>
        <FormattedMessage id='pages.loginPage.content' />
      </Typography.P>
      {/* <Wrappers.FormWrapperAuth>
        <Formik<LoginFormValues>
          validateOnChange={false}
          validationSchema={loginFormValidationSchema}
          initialValues={loginFormInitialValues}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <LoginFormFieldSet />
              <Box mt='1.5rem' textAlign='center'>
                <Link to={RoutesPaths.ForgotPassword}>
                  <FormattedMessage id='pages.loginPage.forgotPasswordLink' />
                </Link>
              </Box>
              <FormGrid.CTAWrapper>
                <Button
                  label={t('pages.loginPage.button')}
                  variant={ButtonVariant.Primary}
                  sizeVariant='large'
                  type='submit'
                  isLoading={isLoading}
                />
              </FormGrid.CTAWrapper>
            </Form>
          )}
        </Formik>
      </Wrappers.FormWrapperAuth> */}
      {/* <StyledBox>
        <Link to={RoutesPaths.Register}>
          <FormattedMessage id='pages.loginPage.signUpLink' />
        </Link>
      </StyledBox> */}
    </LayoutOneColumnAndHeader>
  )
}
