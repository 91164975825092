import { StyledChip } from './Chip.styles'

type ChipProps = {
  label: string
  variant?: 'info' | 'error'
  className?: string
}

function Chip({ label, variant = 'info', className }: ChipProps) {
  return <StyledChip label={label} $variant={variant} className={className} />
}

export type { ChipProps }
export { Chip }
