/* eslint-disable no-unused-vars */
import { ReactComponent as ArrowLeft } from './assets/icon-arrow-left.svg'
import { ReactComponent as Eye } from './assets/icon-eye.svg'
import { ReactComponent as EyeOff } from './assets/icon-eye-off.svg'
import { ReactComponent as Check } from './assets/icon-check.svg'
import { ReactComponent as Error } from './assets/icon-error.svg'
import { ReactComponent as Mail } from './assets/icon-mail.svg'
import { ReactComponent as Card } from './assets/icon-businessCard.svg'
import { ReactComponent as RadioCheck } from './assets/icon-radio-check.svg'
import { ReactComponent as GrantsAndCard } from './assets/icon-grants-card.svg'
import { ReactComponent as MoneyPlant } from './assets/money-plant.svg'
import { ReactComponent as DoubleArrow } from './assets/icon-double-arrow.svg'
import { ReactComponent as Award } from './assets/icon-award.svg'
import { ReactComponent as Expand } from './assets/icon-expand.svg'
import { ReactComponent as SubmenuItem } from './assets/icon-submenuItem.svg'
import { ReactComponent as Close } from './assets/icon-close.svg'
import { ReactComponent as Open } from './assets/icon-hamburger.svg'
import { ReactComponent as Logout } from './assets/icon-logout.svg'
import { ReactComponent as Dollar } from './assets/icon-dollar.svg'
import { ReactComponent as Home } from './assets/icon-home.svg'
import { ReactComponent as Squares } from './assets/icon-squares.svg'
import { ReactComponent as Sign } from './assets/sign.svg'
import { ReactComponent as CardSmall } from './assets/icon-card.svg'
import { ReactComponent as QuestionMark } from './assets/icon-question-mark.svg'
import { ReactComponent as Money } from './assets/icon-money.svg'
import { ReactComponent as MoneyBag } from './assets/icon-money-bag.svg'
import { ReactComponent as CardApplication } from './assets/icon-card-application.svg'
import { ReactComponent as Application } from './assets/icon-application.svg'
import { ReactComponent as Laptop } from './assets/icon-laptop-lock.svg'
import { ReactComponent as Help } from './assets/icon-help.svg'
import { ReactComponent as CardCheck } from './assets/icon-card-check.svg'
import { ReactComponent as PlantBig } from './assets/icon-plant-big.svg'
import { ReactComponent as CardBig } from './assets/icon-card-big.svg'
import { ReactComponent as FolderCheck } from './assets/icon-folder-check.svg'
import { ReactComponent as DownloadFile } from './assets/icon-download-file.svg'
import { ReactComponent as CardAdd } from './assets/icon-card-add.svg'
import { ReactComponent as Hourglass } from './assets/icon-hourglass.svg'
import { ReactComponent as Lock } from './assets/icon-lock.svg'
import { ReactComponent as ExpandLess } from './assets/icon-expand-less.svg'
import { ReactComponent as ExpandMore } from './assets/icon-expand-more.svg'
import { ReactComponent as CardUpload } from './assets/icon-card-upload.svg'
import { ReactComponent as CardLocked } from './assets/icon-card-locked.svg'
import { ReactComponent as Calendar } from './assets/icon-calendar.svg'
import { ReactComponent as TrendingDown } from './assets/icon-trending-down.svg'
import { ReactComponent as ViewList } from './assets/icon-view-list.svg'
import { ReactComponent as Computer } from './assets/icon-computer.svg'
import { ReactComponent as VisaCard } from './assets/icon-visa-card.svg'
import { ReactComponent as Idea } from './assets/icon-idea.svg'
import { ReactComponent as Capital } from './assets/icon-capital.svg'
import { ReactComponent as Banking } from './assets/icon-banking.svg'
import { ReactComponent as Grow } from './assets/icon-grow.svg'
import { ReactComponent as Phone } from './assets/icon-phone.svg'
import { ReactComponent as Desktop } from './assets/icon-desktop.svg'
import { ReactComponent as Tablet } from './assets/icon-tablet.svg'

// eslint-disable-next-line no-shadow
export enum IconType {
  plant_big = 'plant_big',
  arrow_left = 'arrow_left',
  double_arrow = 'double_arrow',
  award = 'award',
  eye = 'eye',
  eye_off = 'eye_off',
  card_big = 'card_big',
  check = 'check',
  error = 'error',
  mail = 'mail',
  card = 'businessCard',
  radio_check = 'radio_check',
  card_application = 'card_application',
  grants_card = 'grants_card',
  money_plant = 'money_plant',
  expand = 'expand',
  submenuItem = 'submenuItem',
  close = 'close',
  application = 'application',
  sign = 'sign',
  laptop_lock = 'laptop_lock',
  open = 'open',
  logout = 'logout',
  dollar = 'dollar',
  home = 'home',
  squares = 'squares',
  cardSmall = 'cardSmall',
  questionMark = 'questionMark',
  money = 'money',
  moneyBag = 'moneyBag',
  help = 'help',
  cardCheck = 'cardCheck',
  folderCheck = 'folderCheck',
  downloadFile = 'downloadFile',
  cardAdd = 'cardAdd',
  hourglass = 'hourglass',
  lock = 'lock',
  expandMore = 'expandMore',
  expandLess = 'expandLess',
  cardUpload = 'cardUpload',
  cardLocked = 'cardLocked',
  calendar = 'calendar',
  trendingDown = 'trendingDown',
  viewList = 'viewList',
  computer = 'computer',
  visaCard = 'visaCard',
  idea = 'idea',
  capital = 'capital',
  banking = 'banking',
  grow = 'grow',
  tablet = 'tablet',
  phone = 'phone',
  desktop = 'desktop',
}

interface IconProps {
  type: IconType
}

const iconMap = {
  plant_big: PlantBig,
  arrow_left: ArrowLeft,
  double_arrow: DoubleArrow,
  award: Award,
  eye: Eye,
  eye_off: EyeOff,
  card_big: CardBig,
  check: Check,
  error: Error,
  mail: Mail,
  businessCard: Card,
  radio_check: RadioCheck,
  sign: Sign,
  grants_card: GrantsAndCard,
  money_plant: MoneyPlant,
  laptop_lock: Laptop,
  expand: Expand,
  submenuItem: SubmenuItem,
  card_application: CardApplication,
  application: Application,
  close: Close,
  open: Open,
  logout: Logout,
  dollar: Dollar,
  home: Home,
  squares: Squares,
  cardSmall: CardSmall,
  questionMark: QuestionMark,
  money: Money,
  moneyBag: MoneyBag,
  help: Help,
  cardCheck: CardCheck,
  folderCheck: FolderCheck,
  downloadFile: DownloadFile,
  cardAdd: CardAdd,
  hourglass: Hourglass,
  lock: Lock,
  expandLess: ExpandLess,
  expandMore: ExpandMore,
  cardUpload: CardUpload,
  cardLocked: CardLocked,
  calendar: Calendar,
  trendingDown: TrendingDown,
  viewList: ViewList,
  computer: Computer,
  visaCard: VisaCard,
  idea: Idea,
  capital: Capital,
  banking: Banking,
  grow: Grow,
  desktop: Desktop,
  phone: Phone,
  tablet: Tablet,
}

export const Icon = ({ type }: IconProps) => {
  const IconComp = iconMap[type]

  if (!IconComp) {
    return null
  }

  return <IconComp title={`${type} icon`} />
}
