enum Tag {
  Women = 'Women',
  PeopleOfColor = 'PeopleOfColor',
  Government = 'Government',
  SpanishSpeaking = 'SpanishSpeaking',
  NoCreditCheck = 'NoCreditCheck',
}

type Tags = {
  tagGovernment?: boolean
  tagPersonOfColor?: boolean
  tagWomen?: boolean
  tagSpanishSpeaking?: boolean
  tagNoCreditCheck?: boolean
}

function getTagSet(tags: Tags): Set<Tag> {
  const {
    tagGovernment,
    tagPersonOfColor,
    tagWomen,
    tagNoCreditCheck,
    tagSpanishSpeaking,
  } = tags
  const filterTags = new Set<Tag>()

  if (tagGovernment) {
    filterTags.add(Tag.Government)
  }

  if (tagWomen) {
    filterTags.add(Tag.Women)
  }

  if (tagPersonOfColor) {
    filterTags.add(Tag.PeopleOfColor)
  }

  if (tagNoCreditCheck) {
    filterTags.add(Tag.NoCreditCheck)
  }

  if (tagSpanishSpeaking) {
    filterTags.add(Tag.SpanishSpeaking)
  }

  return filterTags
}

function isFeaturedComparator(
  isFeaturedA: boolean,
  isFeaturedB: boolean,
  multiComparator: () => number
): number {
  if (isFeaturedA && !isFeaturedB) {
    return -1
  }

  if (isFeaturedB && !isFeaturedA) {
    return 1
  }

  return multiComparator()
}

export { Tag, getTagSet, isFeaturedComparator }
