import { useMemo } from 'react'
import { DateFormat, useFormatDate } from 'lib/formatter'
import { Grant, useFormatAmount } from 'lib/models'
import {
  PartnerDetailsModal,
  PartnerDetailsModalLabelProps,
  PartnerDetailsModalSectionProps,
} from 'shared'
import { useTranslations } from 'translations'
import { IconType } from 'ui'

type GrantDetailsModalProps = {
  grant: Grant | undefined
  onClose: () => void
}

function GrantDetailsModal({ grant, onClose }: GrantDetailsModalProps) {
  const t = useTranslations()
  const formatAmount = useFormatAmount()
  const formatDate = useFormatDate()

  const labels: PartnerDetailsModalLabelProps[] = useMemo(() => {
    if (!grant) {
      return []
    }

    const { amountMin, amountMax, closeDate, partner } = grant

    return [
      {
        label: `${t('pages.grantMatchProgram.modal.amount')}: ${formatAmount(
          amountMin,
          amountMax
        )}`,
        iconType: IconType.DollarSign,
      },
      {
        label: `${t('pages.grantMatchProgram.modal.deadline')}: ${formatDate(
          closeDate,
          DateFormat.Full
        )}`,
        iconType: IconType.Calendar,
      },
      {
        label: `${t('pages.grantMatchProgram.modal.grantor')}: ${partner.name}`,
        iconType: IconType.House,
      },
    ]
  }, [grant, formatAmount, formatDate, t])

  const sections: PartnerDetailsModalSectionProps[] = useMemo(() => {
    if (!grant) {
      return []
    }

    const { description, qualifications, partner } = grant

    const secs: PartnerDetailsModalSectionProps[] = [
      {
        header: t('pages.grantMatchProgram.modal.aboutTheGrant'),
        iconType: IconType.Disclosure,
        description,
      },
      {
        header: t('pages.grantMatchProgram.modal.aboutTheGrantor'),
        iconType: IconType.House,
        description: partner.about,
      },
    ]

    if (qualifications.length) {
      secs.splice(1, 0, {
        header: t('pages.grantMatchProgram.modal.qualifications'),
        iconType: IconType.Checkmark,
        description: qualifications,
      })
    }

    return secs
  }, [grant, t])

  return (
    <PartnerDetailsModal
      isOpen={!!grant}
      onClose={onClose}
      title={grant?.name || ''}
      logoUrl={grant?.partner.logoURL || ''}
      labels={labels}
      sections={sections}
      button={{
        label: t('pages.grantMatchProgram.modal.applyNow'),
        externalUrl: grant?.url || '',
      }}
    />
  )
}

export { GrantDetailsModal }
