import { Field } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { useTranslations } from 'translations'
import { AdapterInput } from 'form-kit/formik/AdapterInput/AdapterInput.component'

const ReadableFieldName = <FormattedMessage id='formKit.passwordField.label' />

const MIN = 12
const MAX = 128

export const passwordFieldValidationSchemaComplexity = yup
  .string()
  .test(
    '',
    (
      <FormattedMessage id='formKit.passwordField.validation' />
    ) as unknown as string,
    value =>
      Boolean(value?.match(/([\p{L}]+)/gmu) && value?.match(/([\p{N}]+)/gmu))
  )
  .min(MIN, () => (
    <FormattedMessage
      id='formKit.validation.minLength'
      values={{ min: MIN, fieldName: ReadableFieldName }}
    />
  ))

export const passwordFieldValidationSchema = yup
  .string()
  .max(MAX, () => (
    <FormattedMessage
      id='formKit.validation.maxLength'
      values={{ max: MAX, fieldName: ReadableFieldName }}
    />
  ))
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))

export const PasswordField = (props: { hideHint?: boolean }) => {
  const { hideHint = false } = props
  const t = useTranslations()

  const hint = hideHint ? null : t('formKit.passwordField.hint')

  return (
    <Field
      name='password'
      label={t('formKit.passwordField.label')}
      ariaLabel={t('formKit.passwordField.toggleButton.ariaLabel')}
      isRequired
      type='password'
      hint={hint}
      component={AdapterInput}
    />
  )
}
