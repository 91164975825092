import Stack from '@mui/material/Stack'
import { LogoPanelDesktop } from './components/LogoPanelDesktop.component'
import { StyledSidebar } from './NavDesktop.styles'
import { NavLinks, NavLinksProps } from '../NavLinks/NavLinks.component'
import { useNav } from '../NavProvider/NavProvider.component'
import { ProfileSidebarListItem } from '../SidebarListItem/ProfileSidebarListItem.component'

type NavDesktopProps = NavLinksProps

function NavDesktop({ sidebarListItems }: NavDesktopProps) {
  const { isNavOpen } = useNav()

  return (
    <StyledSidebar isNavOpen={isNavOpen}>
      <Stack justifyContent='space-between' height='100%'>
        <div>
          <LogoPanelDesktop />
          <NavLinks sidebarListItems={sidebarListItems} />
        </div>
        <ProfileSidebarListItem />
      </Stack>
    </StyledSidebar>
  )
}

export { NavDesktop }
