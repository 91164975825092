import styled from 'styled-components'

const StyledTopBar = styled.nav`
  display: flex;
  background: ${({ theme }) => theme.color.primary};
  padding: 0.75rem 0 0.75rem 0.5rem;
  margin-right: -0.5rem;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  ${({ theme }) => theme.media.minTablet} {
    display: none;
  }
`

export { StyledTopBar }
