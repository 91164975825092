import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { setUser as setSentryUser } from '@sentry/react'
import { UseMutateFunction, useMutation } from 'react-query'
import { ApiError, LoginReqBody, LoginRespData } from 'api'
import { SessionStoreKey } from 'lib/sessionStore'
import { useSessionStore } from './sessionStoreContext'
import { useApiClient } from './apiClientContext'

type AuthProviderProps = {
  children: ReactNode
}

type AuthContextType = {
  userId: number | null
  login: UseMutateFunction<LoginRespData, ApiError, LoginReqBody>
  logout: UseMutateFunction<void, ApiError, void>
  isLoggingIn: boolean
  isLoggingOut: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

function AuthProvider({ children }: AuthProviderProps) {
  const apiClient = useApiClient()
  const sessionStore = useSessionStore()

  // userId !== null -> user is authenticated
  const [userId, setUserId] = useState<number | null>(() => {
    const id = sessionStore.get(SessionStoreKey.UserId)
    return id ? Number(id) : null
  })

  const clearAuthState = useCallback(() => {
    setUserId(null)
    setSentryUser(null)
  }, [setUserId])

  useEffect(() => {
    // on refreshToken failure, wipe auth state
    apiClient.setRefreshTokenMiddleware({ onRefreshTokenError: clearAuthState })
  }, [apiClient, clearAuthState])

  const { mutate: login, isLoading: isLoggingIn } = useMutation<
    LoginRespData,
    ApiError,
    LoginReqBody
  >(
    args => {
      return apiClient.login(args)
    },
    {
      onSuccess: ({ userID }, { email }) => {
        setUserId(userID)
        setSentryUser({
          id: String(userID),
          email,
        })
      },
    }
  )

  const { mutate: logout, isLoading: isLoggingOut } = useMutation<
    void,
    ApiError,
    void
  >(
    () => {
      return apiClient.logout()
    },
    {
      onSuccess: () => {
        clearAuthState()
      },
    }
  )

  const value = useMemo(
    () => ({
      userId,
      login,
      logout,
      isLoggingIn,
      isLoggingOut,
    }),
    [userId, isLoggingIn, isLoggingOut, login, logout]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

/**
 * `useAuth` is a hook that allows callers to consume from the `AuthContext`.
 */
function useAuth(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be called by a child of an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
