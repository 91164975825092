import styled from 'styled-components'

const Container = styled.div`
  padding: 1rem 0;
`

const ListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

export { Container, ListItemContainer }
