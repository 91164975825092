import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { ApiError } from 'api'
import {
  CreatePaymentScheduleReqBody,
  CreatePaymentScheduleRespData,
  PaymentFrequency,
} from 'api/types/paymentSchedules'
import { useApiClient } from 'lib/context'
import { paymentSchedulesKeys } from './queryKeys'

function useMakeOneTimePayment(): UseMutationResult<
  CreatePaymentScheduleRespData,
  ApiError,
  Omit<CreatePaymentScheduleReqBody, 'frequency' | 'amount'>
> {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    args => {
      return apiClient.createPaymentSchedule({
        ...args,
        frequency: PaymentFrequency.OneTime,
      })
    },
    {
      onSuccess: (_, { accountID }) => {
        const queryKey = paymentSchedulesKeys.byAccount(accountID)
        queryClient.cancelQueries(queryKey)
        queryClient.invalidateQueries(queryKey)
      },
    }
  )
}

export { useMakeOneTimePayment }
