import { UseMutationResult, useMutation } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, ActivateUserReqBody } from 'api'

function useActivateUser(): UseMutationResult<
  void,
  ApiError,
  ActivateUserReqBody
> {
  const apiClient = useApiClient()

  return useMutation(args => {
    return apiClient.activateUser(args)
  })
}

export { useActivateUser }
