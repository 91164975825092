import { LoaderContainer, StyledLoader } from './Loader.styles'

type LoaderProps = {
  size?: number
}

const DEFAULT_SIZE = 40

function Loader({ size }: LoaderProps) {
  return (
    <LoaderContainer>
      <StyledLoader
        style={{
          width: `${size || DEFAULT_SIZE}px`,
          height: `${size || DEFAULT_SIZE}px`,
        }}
      />
    </LoaderContainer>
  )
}

export { Loader }
