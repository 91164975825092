import { ChangeEvent, forwardRef, HTMLInputTypeAttribute } from 'react'
import { TextFieldProps } from '@mui/material'
import { StyledInputText } from './InputText.styles'
import { SemanticRequired } from '../SemanticRequired/SemanticRequired.component'

export interface InputTextProps {
  label: string
  name: string
  value: string
  hasError: boolean
  isRequired?: boolean
  disabled?: boolean
  testId?: string
  type?: HTMLInputTypeAttribute
  InputProps?: TextFieldProps['InputProps']
  inputProps?: TextFieldProps['inputProps']
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const InputText = forwardRef<HTMLDivElement, InputTextProps>(
  (props, ref) => {
    const {
      onChange,
      name,
      value,
      label,
      hasError,
      isRequired,
      disabled,
      type = 'text',
      testId,
      InputProps,
      inputProps,
    } = props

    const labelRequired = (
      <>
        {label}
        <SemanticRequired />
      </>
    )

    return (
      <StyledInputText
        ref={ref}
        disabled={disabled}
        id={name}
        error={hasError}
        name={name}
        label={isRequired ? labelRequired : label}
        value={value}
        onChange={onChange}
        size='small'
        variant='outlined'
        type={type}
        fullWidth
        aria-disabled={disabled}
        aria-required={isRequired}
        data-testid={testId}
        InputProps={InputProps}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={inputProps}
      />
    )
  }
)
