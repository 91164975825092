import MUIMenu from '@mui/material/Menu'
import styled from 'styled-components'

const StyledMenu = styled(MUIMenu)`
  .MuiMenu-list {
    min-width: 12rem;
  }
`

export { StyledMenu }
