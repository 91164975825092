import { IconType, List, ListItem, Typography } from 'ui'
import { SectionHeader } from './PartnerDetailsModalSection.styles'
import { PartnerDetailsModalLabel } from './PartnerDetailsModalLabel.component'

type PartnerDetailsModalSectionProps = {
  header: string
  iconType: IconType
  description: string | string[]
}

function PartnerDetailsModalSection({
  header,
  iconType,
  description,
}: PartnerDetailsModalSectionProps) {
  return (
    <>
      <SectionHeader>
        <PartnerDetailsModalLabel label={header} iconType={iconType} />
      </SectionHeader>
      {typeof description === 'string' ? (
        <Typography variant='caption'>{description}</Typography>
      ) : (
        <List>
          {description.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={i}>{d}</ListItem>
          ))}
        </List>
      )}
    </>
  )
}

export type { PartnerDetailsModalSectionProps }
export { PartnerDetailsModalSection }
