import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { Suspense } from 'react'
import { useImage } from 'react-image'
import { Fallback } from './Image.styles'

type ImageProps = {
  src: string
  width: string
  height: string
  alt: string
}

function ImageRenderer({ src, width, height, alt }: ImageProps) {
  const { src: imgSrc } = useImage({
    srcList: src,
  })

  return (
    <img
      src={imgSrc}
      alt={alt}
      loading='lazy'
      style={{
        width,
        height,
      }}
    />
  )
}

function Image({ src, width, height, alt }: ImageProps) {
  return (
    <ReactErrorBoundary fallback={<Fallback width={width} height={height} />}>
      <Suspense fallback={<Fallback width={width} height={height} />}>
        <ImageRenderer src={src} width={width} height={height} alt={alt} />
      </Suspense>
    </ReactErrorBoundary>
  )
}

export { Image }
