import styled from 'styled-components'
import { Typography } from 'ui-kit'

export const Title = styled.h3`
  text-align: center;
  margin-top: 2rem;
  ${Typography.headingH3}
`

export const Lead = styled.p`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${Typography.headingH3}
`

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 4rem;
    height: 4rem;
    path {
      fill: ${({ theme }) => theme.color.backgroundGreen};
    }
  }
`
