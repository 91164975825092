import styled, { css } from 'styled-components'

export const StyledIframeContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`

export const StyledIframe = styled.iframe<{ isLoading: boolean }>`
  width: 100%;
  flex: 1;

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        flex: 0;
        height: 0;
      `
    }
    return ''
  }}
`
