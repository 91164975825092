import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import { AdapterRadioButton } from '../../formik/AdapterRadioButton/AdapterRadioButton.component'

export interface CompanyOwnerValue {
  slug: 'YES' | 'NO'
  value: boolean
}

interface CompanyOwnerRadioFieldProps {
  label: string
  name: string
  value: CompanyOwnerValue
  id: string
}

export const companyOwnerRadioValidationSchema = yup.object().shape({
  companyOwner: yup
    .object()
    .required(() => (
      <FormattedMessage id='formKit.companyOwnerFieldSet.required' />
    )),
})

export const CompanyOwnerRadioField = (props: CompanyOwnerRadioFieldProps) => {
  const { label, value, name, id } = props

  return (
    <Field
      name={name}
      type='radio'
      label={label}
      id={id}
      value={value}
      component={AdapterRadioButton}
    />
  )
}
