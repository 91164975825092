import {
  ResponseType,
  HttpParams,
  HttpUrl,
  HttpHeaders,
} from '../axios/SendRequest.types'

export interface GetRequestArguments {
  endpoint: HttpUrl
  params?: HttpParams
  responseType?: ResponseType
}

export interface PostRequestArguments<TRequest> {
  endpoint: HttpUrl
  requestBody?: TRequest
  headers?: HttpHeaders
}

export interface PatchRequestArguments<TRequest> {
  endpoint: HttpUrl
  requestBody?: TRequest
  headers?: HttpHeaders
}

export interface PutRequestArguments<TRequest> {
  endpoint: HttpUrl
  requestBody?: TRequest
  headers?: HttpHeaders
}

export interface DeleteRequestArguments<TRequest> {
  endpoint: HttpUrl
  requestBody?: TRequest
  headers?: HttpHeaders
}

export enum HttpStatus {
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
}
