import styled from 'styled-components'
import { useTranslations } from 'translations'
import { hiddenOnMobile } from 'ui-kit/globalStyles/hiddenOnMobile.styles'
import { Icon, IconType } from 'ui-kit/Icons/Icon.component'
import { paragraphSmall } from 'ui-kit/Typography/Typography.styles'

const StyledButtonText = styled.span`
  ${paragraphSmall}
  ${hiddenOnMobile};
  color: ${({ theme }) => theme.color.background};
  margin-left: 1rem;
`

const StyledButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`

interface ButtonBackProps {
  onClick: () => void
}
export const ButtonBack = (props: ButtonBackProps) => {
  const { onClick } = props
  const t = useTranslations()
  return (
    <StyledButton type='button' role='link' onClick={onClick}>
      <Icon type={IconType.arrow_left} />
      <StyledButtonText>{t('uiKit.buttonBack.text')}</StyledButtonText>
    </StyledButton>
  )
}
