enum SessionStoreKey {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  UserId = 'userId',
}

class SessionStore {
  get(key: SessionStoreKey) {
    return localStorage.getItem(key)
  }

  set(key: SessionStoreKey, val: string) {
    localStorage.setItem(key, val)
  }

  clearAll() {
    localStorage.clear()
  }
}

export { SessionStore, SessionStoreKey }
