import { Theme } from '@mui/material'
import MUIChip from '@mui/material/Chip'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

type ChipVariant = 'info' | 'error'

function getColor(theme: Theme, variant: ChipVariant) {
  switch (variant) {
    case 'error':
      return theme.color.red.error
    default:
      return theme.color.grey[900]
  }
}

function getBackgroundColor(theme: Theme, variant: ChipVariant) {
  switch (variant) {
    case 'error':
      return theme.color.red[200]
    default:
      return undefined
  }
}

const StyledChip = styled(MUIChip)<{ $variant: ChipVariant }>`
  ${typographyStyles.caption};
  font-size: 0.7rem;
  height: 1.5rem;
  color: ${({ theme, $variant }) => getColor(theme, $variant)};
  background-color: ${({ theme, $variant }) =>
    getBackgroundColor(theme, $variant)};
`

export { StyledChip }
