import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, BankAccount, GetBankAccountsRespData } from 'api'
import { bankAccountsKeys } from './queryKeys'
import { errQueryMissingDependencies } from '../queryError'
import { useUser } from '../users/useUser'

function useBankAccounts<T = BankAccount[]>(
  select?: (resp: GetBankAccountsRespData) => T
) {
  const apiClient = useApiClient()
  const { data: user } = useUser()

  const queryKey = bankAccountsKeys.byCompanyId(user?.companyID)

  return useQuery({
    queryKey,
    enabled: !!user?.companyID,
    queryFn: () => {
      if (!user?.companyID) {
        return errQueryMissingDependencies(queryKey, {
          companyId: user?.companyID,
        })
      }

      return apiClient.getBankAccounts({ companyID: user.companyID })
    },
    select: resp => (select ? select(resp) : resp.bankAccounts),
  }) as UseQueryResult<
    typeof select extends undefined ? BankAccount[] : T,
    ApiError
  >
}

export { useBankAccounts }
