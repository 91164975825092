import styled from 'styled-components'
import { useTranslations } from 'translations'

const StyledAbbr = styled.abbr`
  margin-left: 0.25rem;
  text-decoration: none;
  color: ${({ theme }) => theme.color.red.error};
`

export const SemanticRequired = () => {
  const t = useTranslations()
  const title = t('uiKit.semanticRequired.title')

  return (
    <StyledAbbr title={title}>{t('uiKit.semanticRequired.mark')}</StyledAbbr>
  )
}
