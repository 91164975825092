import { Field } from 'formik'
import * as yup from 'yup'
import { useTranslations } from 'translations'
import { AdapterInputCheckbox } from '../../formik/AdapterInputCheckbox/AdapterInputCheckbox.component'

export const saveDeviceFieldValidationSchema = yup.boolean()

export const SaveDeviceField = () => {
  const t = useTranslations()

  const label = t('formKit.saveDeviceField.label')
  const description = t('formKit.saveDeviceField.description')

  return (
    <Field
      name='saveDevice'
      label={label}
      description={description}
      component={AdapterInputCheckbox}
    />
  )
}
