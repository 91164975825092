import MUIDialog from '@mui/material/Dialog'
import { ReactNode } from 'react'
import styled from 'styled-components'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

const StyledDialog = styled(MUIDialog)`
  .MuiDialog-paper {
    margin: 1rem;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
  }
`

function Modal({ isOpen, onClose, children }: ModalProps) {
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      {children}
    </StyledDialog>
  )
}

export { Modal }
