import styled, { css } from 'styled-components'
import { Typography } from 'ui-kit'

export const Container = styled.div<{
  $variant: 'default' | 'primary' | 'danger'
  $isClickable?: boolean
}>`
  ${Typography.paragraphBody};
  display: flex;
  padding: 0.75rem;
  gap: 0.25rem;
  align-items: center;
  appearance: none;
  border: none;
  background-color: ${({ theme }) => theme.color.white};

  svg {
    display: block;
    width: 1rem;
    height: auto;
  }

  ${({ theme, $variant }) => {
    if ($variant === 'primary') {
      return css`
        background-color: ${theme.color.primary};
        color: ${theme.color.white};

        svg {
          width: 1.25rem;
        }
      `
    }
    if ($variant === 'danger') {
      return css`
        background-color: ${theme.color.pumpkin[50]};
      `
    }
    return ''
  }}

  ${({ theme, $isClickable, $variant }) =>
    $variant === 'primary' &&
    $isClickable &&
    css`
      cursor: pointer;
      &:focus {
        outline-width: 1px;
        outline-style: solid;
        outline-offset: 0.25rem;
        outline-color: ${theme.color.primary};
      }
      &:hover {
        background-color: ${theme.color.green[600]};
        color: ${theme.color.white};
      }

      &:active {
        background-color: ${theme.color.green[400]};
        color: ${theme.color.white};
      }
    `}

  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }
  &:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  ${({ theme }) => theme.media.minTablet} {
    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
    }
    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }
  }
`
