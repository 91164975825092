import styled from 'styled-components'
import { TablePagination } from '@mui/material'
import { Typography } from 'ui-kit'

export const Pagination = styled(TablePagination)<{ component: string }>`
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    ${Typography.paragraphSmall}
    line-height: 1;
  }
  .MuiTablePagination-actions {
    color: ${({ theme }) => theme.color.backgroundGreen};

    .MuiIconButton-root:hover {
      outline: ${({ theme }) => theme.color.primary} solid 1px;
    }
    .MuiIconButton-root.Mui-focusVisible {
      outline: ${({ theme }) => theme.color.primary} solid 2px;
    }
  }
  .MuiTablePagination-select {
    ${Typography.paragraphSmall}
    border: 2px solid transparent;
    border-radius: 4px;
    line-height: 2;
  }
  .Mui-focused .MuiTablePagination-select,
  .MuiTablePagination-select:hover {
    border: 2px solid ${({ theme }) => theme.color.primary};
  }

  .MuiToolbar-root {
    min-height: 0;
    flex-wrap: wrap;
    padding: 1rem;
    justify-content: center;
    .MuiTablePagination-actions {
      width: 100%;
      margin: 0;
      text-align: center;
    }

    ${({ theme }) => theme.media.minTablet} {
      justify-content: flex-end;
      min-height: 3.25rem;
      display: flex;
      flex-wrap: nowrap;
      padding: 0 0.125rem 0 0;
      .MuiTablePagination-actions {
        width: auto;
        margin-left: 1.25rem;
      }
    }
  }
`
