import { ReactNode } from 'react'
import styled from 'styled-components'
import MUICardActions from '@mui/material/CardActions'

type CardActionsProps = {
  children: ReactNode
  className?: string
}

const StyledCardActions = styled(MUICardActions)`
  padding: 0 1rem 1rem;
  justify-content: space-around;
`

export function CardActions({ children, className }: CardActionsProps) {
  return <StyledCardActions className={className}>{children}</StyledCardActions>
}
