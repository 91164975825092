import { HubspotPaths, RoutesPaths, getHubspotURL } from 'routing'
import { useTranslations } from 'translations'
import { IconType } from 'ui'
import { useIsCardFunctionalityEnabled, useUser } from 'lib/queries'
import {
  SidebarExpandableListItemProps,
  SubLink,
} from '../SidebarListItem/SidebarExpandableListItem.component'
import { SidebarListItemProps } from '../SidebarListItem/SidebarListItem.component'

function useResourceSubLinks(): SubLink[] {
  const t = useTranslations()

  return [
    {
      path: getHubspotURL(HubspotPaths.Learn),
      label: t('shared.navbar.labels.resources.learn'),
    },
    {
      path: getHubspotURL(HubspotPaths.BusinessFunding),
      label: t('shared.navbar.labels.resources.funding'),
    },
    {
      path: getHubspotURL(HubspotPaths.GrowingYourBusiness),
      label: t('shared.navbar.labels.resources.grow'),
    },
    {
      path: getHubspotURL(HubspotPaths.GrantResources),
      label: t('shared.navbar.labels.resources.grants'),
    },
    {
      path: getHubspotURL(HubspotPaths.Webinars),
      label: t('shared.navbar.labels.resources.webinars'),
    },
  ]
}

function useCardSubLinks(): SubLink[] {
  const t = useTranslations()

  return [
    {
      path: RoutesPaths.Transactions,
      label: t('shared.navbar.labels.card.transactions'),
    },
    {
      path: RoutesPaths.Payments,
      label: t('shared.navbar.labels.card.payments'),
    },
    {
      path: RoutesPaths.Statements,
      label: t('shared.navbar.labels.card.statements'),
    },
    {
      path: RoutesPaths.Cards,
      label: t('shared.navbar.labels.card.cards'),
    },
  ]
}

type UseSidebarListItemsType =
  | {
      isLoading: true
      sidebarListItems: undefined
    }
  | {
      isLoading: false
      sidebarListItems: (
        | SidebarListItemProps
        | SidebarExpandableListItemProps
      )[]
    }

function useSidebarListItems(): UseSidebarListItemsType {
  const t = useTranslations()
  const { data: user } = useUser()
  const {
    isCardFunctionalityEnabled,
    isLoading: isLoadingIsCardFunctionalityEnabled,
  } = useIsCardFunctionalityEnabled()

  const cardSubLinks = useCardSubLinks()
  const resourceSubLinks = useResourceSubLinks()

  if (!user || isLoadingIsCardFunctionalityEnabled) {
    return {
      isLoading: true,
      sidebarListItems: undefined,
    }
  }

  const sidebarListItems = [
    {
      path: RoutesPaths.Dashboard,
      label: t('shared.navbar.labels.dashboard'),
      iconType: IconType.Grid,
    },
    {
      path: RoutesPaths.Grants,
      label: t('shared.navbar.labels.grants'),
      iconType: IconType.Trophy,
    },
    {
      path: RoutesPaths.CapitalMarketplace,
      label: t('shared.navbar.labels.capitalMarketplace'),
      iconType: IconType.DollarSign,
    },
    {
      path: RoutesPaths.GrowthPartners,
      label: t('shared.navbar.labels.partners'),
      iconType: IconType.Connection,
    },
    {
      label: t('shared.navbar.labels.resources.main'),
      iconType: IconType.QuestionMark,
      subLinks: resourceSubLinks,
    },
  ]

  const hasCreditAccount = !!user.accountID
  if (hasCreditAccount) {
    const cardItem = isCardFunctionalityEnabled
      ? {
          label: t('shared.navbar.labels.card.main'),
          iconType: IconType.Card,
          subLinks: cardSubLinks,
        }
      : {
          path: RoutesPaths.Cards,
          // If user has a credit account, but hasn't added a bank account and
          // autopay schedule, we only display the CardsPage
          label: t('shared.navbar.labels.card.main'),
          iconType: IconType.Card,
        }
    sidebarListItems.splice(1, 0, cardItem)
  }

  return {
    isLoading: false,
    sidebarListItems,
  }
}

export { useSidebarListItems }
