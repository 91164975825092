import { useMemo } from 'react'

export const useCensoredEmail = (email: string, redactedSymbol = '*') => {
  return useMemo(() => {
    const [before, after] = email.split('@')

    const censoredBefore = before
      .split('')
      .map((value, index, array) => {
        if ([0, 1, array.length - 1].includes(index)) {
          return value
        }
        return redactedSymbol
      })
      .join('')

    const [emailProvider, domain] = after.split('.')
    const censoredEmailProvider = emailProvider.replaceAll(/./g, redactedSymbol)

    return [censoredBefore, [censoredEmailProvider, domain].join('.')].join('@')
  }, [email, redactedSymbol])
}
