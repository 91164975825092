/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { Theme, useTheme } from '@mui/material'
import { Transaction, TransactionStatus, TransactionType } from 'api'
import {
  DateFormat,
  dateFormatter,
  stringFormatter,
  useFormatCurrency,
  useFormatDate,
} from 'lib/formatter'
import { useTranslations } from 'translations'
import { TableIconCell, IconType, ColumnDef, ChipProps } from 'ui'
import { StyledTypography } from '../TransactionTable.styles'

type TransactionRow = {
  cardNumberLast4: string | undefined // cardNumber will not be present if transaction is non-purchase (e.g. payment)
  date: Date
  description: string
  postedDate: Date | undefined
} & Pick<Transaction, 'amount' | 'id' | 'status' | 'type'>

const ICON_TYPE_BY_TRANSACTION_TYPE: Record<TransactionType, IconType> = {
  [TransactionType.Fee]: IconType.DollarSign,
  [TransactionType.Other]: IconType.Card,
  [TransactionType.Payment]: IconType.Bank,
  [TransactionType.Purchase]: IconType.Card,
  [TransactionType.Return]: IconType.CardUpload,
}

function getIconColor(theme: Theme, status: TransactionStatus) {
  return status === TransactionStatus.Pending
    ? theme.color.green[300]
    : theme.color.backgroundGreen
}

function getLabelColor(
  theme: Theme,
  type: TransactionType,
  status: TransactionStatus
) {
  if (status === TransactionStatus.Declined) {
    return theme.color.red.error
  }

  switch (type) {
    case TransactionType.Payment:
    case TransactionType.Return:
      return theme.color.apple[700]
    default:
      return undefined
  }
}

function useGetTransactionStatusChip() {
  const t = useTranslations()

  return useCallback(
    (status: TransactionStatus): ChipProps | undefined => {
      if (status === TransactionStatus.Settled) {
        return undefined
      }

      return {
        label: t(
          `shared.transactionsTable.statusChip.${
            status === TransactionStatus.Pending ? 'pending' : 'declined'
          }`
        ),
        variant: status === TransactionStatus.Pending ? 'info' : 'error',
      }
    },
    [t]
  )
}

function useTransactionsTable(transactions: Transaction[]) {
  const t = useTranslations()
  const theme = useTheme()
  const formatDate = useFormatDate()
  const formatCurrency = useFormatCurrency()
  const getTransactionStatusChip = useGetTransactionStatusChip()

  const columns: ColumnDef<TransactionRow, any>[] = useMemo(() => {
    return [
      {
        accessorKey: 'description',
        header: t('shared.transactionsTable.columns.description'),
        sortingFn: 'text',
        cell: info => {
          const { status, type } = info.row.original
          return (
            <TableIconCell
              label={info.getValue()}
              icon={{
                type:
                  status === TransactionStatus.Pending
                    ? IconType.CardLocked
                    : ICON_TYPE_BY_TRANSACTION_TYPE[type],
                color: getIconColor(theme, status),
              }}
              chip={getTransactionStatusChip(status)}
            />
          )
        },
      },
      {
        accessorKey: 'date',
        header: t('shared.transactionsTable.columns.date'),
        sortingFn: 'datetime',
        sortDescFirst: true,
        cell: info => formatDate(info.getValue(), DateFormat.MonthDayYear),
      },
      {
        accessorKey: 'amount',
        header: t('shared.transactionsTable.columns.amount'),
        sortingFn: 'basic',
        cell: info => {
          const { status, type } = info.row.original

          return (
            <StyledTypography
              variant='caption'
              color={getLabelColor(theme, type, status)}
            >
              {formatCurrency(info.getValue())}
            </StyledTypography>
          )
        },
        meta: {
          align: 'right',
        },
      },
    ]
  }, [t, theme, formatCurrency, formatDate, getTransactionStatusChip])

  const data: TransactionRow[] = useMemo(() => {
    return transactions.map(
      ({
        amount,
        card,
        currentStatusTimestamp,
        id,
        initialStatusTimestamp,
        merchant,
        status,
        type,
      }) => ({
        amount,
        cardNumberLast4: card?.cardNumberLast4,
        date: dateFormatter.fromUnixSeconds(currentStatusTimestamp),
        description: merchant
          ? merchant.name
          : stringFormatter.capitalizeFirstLetter(type),
        id,
        postedDate:
          currentStatusTimestamp !== initialStatusTimestamp
            ? dateFormatter.fromUnixSeconds(initialStatusTimestamp)
            : undefined,
        status,
        type,
      })
    )
  }, [transactions])

  return {
    columns,
    data,
  }
}

export { useTransactionsTable }
