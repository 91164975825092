import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import { FormGrid, IconType, VisuallyHiddenFormLegend } from 'ui-kit'
import { useTranslations } from 'translations'
import { StyledCardWrapper } from './ProductSelectionFieldSet.styles'
import {
  ProductSelectionCheckboxField,
  ProductSelectionFormValuesTypes,
} from '../../fields/ProductSelectionCheckboxField/ProductSelectionCheckboxField.component'

export interface ProductSelectionFormValues {
  selectedProducts: ProductSelectionFormValuesTypes[]
}

export const productSelectionFormInitialValues = {
  selectedProducts: [],
}

export const productSelectionValidationSchema = yup.object().shape({
  selectedProducts: yup
    .array()
    .min(1, () => (
      <FormattedMessage id='formKit.productSelectionFieldSet.required' />
    ))
    .required(() => (
      <FormattedMessage id='formKit.productSelectionFieldSet.required' />
    )),
})

export const ProductSelectionFieldSet = () => {
  const t = useTranslations()

  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.productSelectionFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <StyledCardWrapper>
          <ProductSelectionCheckboxField
            label={ProductSelectionFormValuesTypes.CARD}
            id={ProductSelectionFormValuesTypes.CARD}
            name='selectedProducts'
            value={ProductSelectionFormValuesTypes.CARD}
            icon={IconType.card}
            content={t('pages.productSelectionPage.labels.card')}
          />

          <ProductSelectionCheckboxField
            label={ProductSelectionFormValuesTypes.LOANS}
            id={ProductSelectionFormValuesTypes.LOANS}
            name='selectedProducts'
            value={ProductSelectionFormValuesTypes.LOANS}
            icon={IconType.money}
            content={t('pages.productSelectionPage.labels.loans')}
          />
          <ProductSelectionCheckboxField
            label={ProductSelectionFormValuesTypes.GRANTS}
            id={ProductSelectionFormValuesTypes.GRANTS}
            name='selectedProducts'
            value={ProductSelectionFormValuesTypes.GRANTS}
            icon={IconType.moneyBag}
            content={t('pages.productSelectionPage.labels.grants')}
          />
        </StyledCardWrapper>
      </FormGrid.Grid>
    </fieldset>
  )
}
