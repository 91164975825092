/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Row, createColumnHelper } from '@tanstack/react-table'
import {
  AmountFilter,
  Loan,
  Tag,
  USState,
  US_STATE_OPTIONS,
  amountComparator,
  apiLoanToLoan,
  useFormatAmount,
  formatAPR,
  APRFilter,
  LOAN_CATEGORY_OPTIONS,
  APR_FILTER_OPTIONS,
  isFeaturedComparator,
} from 'lib/models'
import { useLoans } from 'lib/queries'
import {
  Button,
  ButtonVariant,
  ColumnDef,
  TABLE_FILTER_SELECT_FIELD_ANY_OPTION,
  TableFilterOption,
  TableImageCell,
} from 'ui'
import { useTranslations } from 'translations'
import { LoanCategory } from 'api'
import { AMOUNT_FILTER_OPTIONS } from 'lib/models/amounts'

type LoanRow = Pick<
  Loan,
  | 'amountFilters'
  | 'amountMin'
  | 'amountMax'
  | 'aprFilters'
  | 'aprMin'
  | 'aprMax'
  | 'category'
  | 'id'
  | 'states'
  | 'tags'
  | 'isFeatured'
> & {
  lender: string
  logoUrl: string
  type: string
}

const columnHelper = createColumnHelper<LoanRow>()

function filterFnAmount(
  row: Row<LoanRow>,
  _: string,
  filterValue: Set<AmountFilter>
): boolean {
  // Pass if no filters applied
  if (filterValue.size === 0) {
    return true
  }

  // Pass if amountFilters includes any applied filters
  let pass = false
  filterValue.forEach(val => {
    if (row.original.amountFilters.has(val)) {
      pass = true
    }
  })

  return pass
}

function filterFnAPR(
  row: Row<LoanRow>,
  _: string,
  filterValue: APRFilter | typeof TABLE_FILTER_SELECT_FIELD_ANY_OPTION
): boolean {
  if (filterValue === TABLE_FILTER_SELECT_FIELD_ANY_OPTION) {
    return true
  }

  return row.original.aprFilters.has(filterValue)
}

function filterFnCategory(
  row: Row<LoanRow>,
  _: string,
  filterValue: LoanCategory | typeof TABLE_FILTER_SELECT_FIELD_ANY_OPTION
): boolean {
  if (filterValue === TABLE_FILTER_SELECT_FIELD_ANY_OPTION) {
    return true
  }

  return row.original.category === filterValue
}

function filterFnStates(
  row: Row<LoanRow>,
  _: string,
  filterValue: USState | typeof TABLE_FILTER_SELECT_FIELD_ANY_OPTION
): boolean {
  if (filterValue === TABLE_FILTER_SELECT_FIELD_ANY_OPTION) {
    return true
  }

  return row.original.states.has(filterValue)
}

function filterFnTags(
  row: Row<LoanRow>,
  _: string,
  filterValue: Set<Tag>
): boolean {
  // Pass if no filters applied
  if (filterValue.size === 0) {
    return true
  }

  // Pass if tagFilters includes any applied filters
  let pass = false
  filterValue.forEach(val => {
    if (row.original.tags.has(val)) {
      pass = true
    }
  })

  return pass
}

function sortingFnAPR(rowA: Row<LoanRow>, rowB: Row<LoanRow>): number {
  const {
    original: { aprMin: aprMinA, aprMax: aprMaxA, isFeatured: isFeaturedA },
  } = rowA
  const {
    original: { aprMin: aprMinB, aprMax: aprMaxB, isFeatured: isFeaturedB },
  } = rowB

  return isFeaturedComparator(isFeaturedA, isFeaturedB, () => {
    return amountComparator(
      { min: aprMinA, max: aprMaxA },
      { min: aprMinB, max: aprMaxB }
    )
  })
}

function sortingFnAmount(rowA: Row<LoanRow>, rowB: Row<LoanRow>): number {
  const {
    original: {
      amountMin: amountMinA,
      amountMax: amountMaxA,
      isFeatured: isFeaturedA,
    },
  } = rowA
  const {
    original: {
      amountMin: amountMinB,
      amountMax: amountMaxB,
      isFeatured: isFeaturedB,
    },
  } = rowB

  return isFeaturedComparator(isFeaturedA, isFeaturedB, () => {
    return amountComparator(
      { min: amountMinA, max: amountMaxA },
      { min: amountMinB, max: amountMaxB }
    )
  })
}

function useTagFilterOptions(): TableFilterOption[] {
  const t = useTranslations()

  return [
    {
      label: t('ui.filter.tags.women'),
      value: Tag.Women,
    },
    {
      label: t('ui.filter.tags.poc'),
      value: Tag.PeopleOfColor,
    },
    {
      label: t('ui.filter.tags.spanishSpeaking'),
      value: Tag.SpanishSpeaking,
    },
    {
      label: t('ui.filter.tags.noCreditCheck'),
      value: Tag.NoCreditCheck,
    },
  ]
}

const SEARCH_PARAM_KEY_LOAN_ID = 'id'

function useSelectedLoan(loansById: Map<number, Loan>) {
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedLoan = useMemo(() => {
    const loanId = searchParams.get(SEARCH_PARAM_KEY_LOAN_ID)
    return loansById.get(Number(loanId))
  }, [searchParams, loansById])

  const setSelectedLoan = useCallback(
    (id: number) => {
      setSearchParams({
        [SEARCH_PARAM_KEY_LOAN_ID]: String(id),
      })
    },
    [setSearchParams]
  )

  const clearSelectedLoan = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  return {
    selectedLoan,
    setSelectedLoan,
    clearSelectedLoan,
  }
}

function useCapitalMarketplaceTable() {
  const t = useTranslations()
  const tagFilterOptions = useTagFilterOptions()
  const formatAmount = useFormatAmount()

  const { data: loansData } = useLoans(resp => {
    const loansById = new Map<number, Loan>()

    const loans = resp.loans.map(apiLoan => {
      const loan = apiLoanToLoan(apiLoan)
      loansById.set(loan.id, loan)
      return loan
    })

    return {
      loans,
      loansById,
    }
  })

  const { selectedLoan, setSelectedLoan, clearSelectedLoan } = useSelectedLoan(
    loansData?.loansById || new Map()
  )

  const columns: ColumnDef<LoanRow, any>[] = useMemo(() => {
    return [
      {
        accessorKey: 'lender',
        header: t('pages.capitalMarketplace.table.lender'),
        sortingFn: 'text',
        cell: info => (
          <TableImageCell
            label={info.getValue()}
            url={info.row.original.logoUrl}
            chip={
              info.row.original.isFeatured
                ? { label: t('ui.table.featured') }
                : undefined
            }
          />
        ),
      },
      {
        accessorKey: 'type',
        header: t('pages.capitalMarketplace.table.type'),
        enableSorting: false,
      },
      {
        accessorKey: 'tags',
        filterFn: filterFnTags,
        meta: {
          isHidden: true,
          filterConfig: {
            label: t('ui.filter.tags.label'),
            options: tagFilterOptions,
            type: 'checkbox',
          },
        },
      },
      {
        id: 'amount',
        header: t('pages.capitalMarketplace.table.amount'),
        accessorFn: row => formatAmount(row.amountMin, row.amountMax),
        filterFn: filterFnAmount,
        sortingFn: sortingFnAmount,
        cell: info =>
          formatAmount(
            info.row.original.amountMin,
            info.row.original.amountMax
          ),
        meta: {
          align: 'right',
          filterConfig: {
            label: t('ui.filter.amount.label'),
            options: AMOUNT_FILTER_OPTIONS,
            type: 'checkbox',
          },
        },
      },
      {
        id: 'apr',
        header: t('pages.capitalMarketplace.table.apr'),
        accessorFn: row => formatAPR(row.aprMin, row.aprMax),
        filterFn: filterFnAPR,
        sortingFn: sortingFnAPR,
        cell: info =>
          formatAPR(info.row.original.aprMin, info.row.original.aprMax),
        meta: {
          align: 'right',
          filterConfig: {
            label: t('pages.capitalMarketplace.table.filters.apr'),
            options: APR_FILTER_OPTIONS,
            type: 'select',
          },
        },
      },
      columnHelper.display({
        id: 'learnMore',
        cell: info => (
          <Button
            label={t('pages.capitalMarketplace.table.learnMore')}
            variant={ButtonVariant.Tertiary}
            onClick={() => {
              setSelectedLoan(info.row.original.id)
            }}
          />
        ),
        meta: {
          align: 'right',
        },
      }),
      {
        accessorKey: 'states',
        filterFn: filterFnStates,
        meta: {
          isHidden: true,
          filterConfig: {
            label: t('ui.filter.state.label'),
            options: US_STATE_OPTIONS,
            type: 'select',
          },
        },
      },
      {
        accessorKey: 'category',
        filterFn: filterFnCategory,
        meta: {
          isHidden: true,
          filterConfig: {
            label: t('ui.filter.category.label'),
            options: LOAN_CATEGORY_OPTIONS,
            type: 'select',
          },
        },
      },
    ]
  }, [formatAmount, setSelectedLoan, tagFilterOptions, t])

  const data: LoanRow[] = useMemo(() => {
    return (loansData?.loans || []).map(
      ({
        amountFilters,
        amountMin,
        amountMax,
        aprFilters,
        aprMin,
        aprMax,
        category,
        id,
        isFeatured,
        name,
        partner,
        states,
        tags,
      }) => ({
        amountFilters,
        amountMin,
        amountMax,
        aprFilters,
        aprMin,
        aprMax,
        category,
        id,
        isFeatured,
        lender: partner.name,
        logoUrl: partner.logoURL,
        states,
        tags,
        type: name,
      })
    )
  }, [loansData])

  return {
    columns,
    data,
    selectedLoan,
    setSelectedLoan,
    clearSelectedLoan,
    isLoading: !loansData,
  }
}

export type { LoanRow }
export { useCapitalMarketplaceTable }
