import { useCallback } from 'react'
import { FormatNumberOptions, useIntl } from 'react-intl'

function useFormatCurrency() {
  const intl = useIntl()

  const formatCurrency = useCallback(
    (val: number, opts?: FormatNumberOptions) =>
      intl.formatNumber(val, {
        currency: 'USD',
        style: 'currency',
        ...opts,
      }),
    [intl]
  )

  return formatCurrency
}

export { useFormatCurrency }
