import { useNavigate, useSearchParams } from 'react-router-dom'
import { RoutesPaths } from 'routing'
import { AuthPageContainer } from 'shared'
import { useTranslations } from 'translations'
import { Button, ButtonVariant, Link, TextField } from 'ui'
import { useResetPassword } from 'lib/queries'
import { useSnackbars } from 'ui-kit'
import {
  ResetPasswordFormData,
  useResetPasswordForm,
} from './useResetPasswordForm'
import { ButtonContainer } from './ResetPasswordPage.styles'

enum ResetPasswordSearchParam {
  Code = 'code',
  Email = 'email',
}

function ResetPasswordPage() {
  const t = useTranslations()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbars()
  const { control, handleSubmit } = useResetPasswordForm()
  const { mutate: resetPassword, isLoading: isResettingPassword } =
    useResetPassword()

  const email = searchParams.get(ResetPasswordSearchParam.Email)
  const code = searchParams.get(ResetPasswordSearchParam.Code)

  const onSubmit = ({ newPassword }: ResetPasswordFormData) => {
    resetPassword(
      {
        email: String(email),
        code: String(code),
        newPassword,
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(t('pages.newResetPassword.success'))
          navigate(RoutesPaths.NewLogin, { replace: true })
        },
        onError: () => showErrorSnackbar(t('pages.newResetPassword.error')),
      }
    )
  }

  return (
    <AuthPageContainer
      title={t('pages.newResetPassword.title')}
      subtitle={t('pages.newResetPassword.subtitle', { email: email || '' })}
      helmetTitle={t('pages.newResetPassword.seoTitle')}
    >
      <TextField
        name='newPassword'
        label={t('pages.newResetPassword.form.newPassword')}
        control={control}
        required
        type='password'
      />

      <ButtonContainer>
        <Button
          label={t('pages.newResetPassword.actions.submit')}
          variant={ButtonVariant.Primary}
          sizeVariant='large'
          type='submit'
          isLoading={isResettingPassword}
          onClick={handleSubmit(onSubmit)}
        />
      </ButtonContainer>
      <Link variant='caption' href={RoutesPaths.NewLogin}>
        {t('pages.newResetPassword.actions.returnToLogin')}
      </Link>
    </AuthPageContainer>
  )
}

export { ResetPasswordPage }
