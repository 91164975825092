import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  max-width: 43rem;
  grid-template-columns: repeat(2, 1fr);
  ${({ theme }) => theme.media.minTablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Link = styled.a`
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  aspect-ratio: 1/1;
  font-weight: bold;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  text-align: center;
  border: 1px solid ${({ theme }) => theme.color.alabaster[600]};
  outline-color: ${({ theme }) => theme.color.primary};
  border-radius: 0.25rem;

  svg {
    height: 4rem;
    width: auto;
  }

  &:hover,
  &:focus {
    box-shadow: ${({ theme }) => theme.shadowVariants.selectedTile};
  }
`
