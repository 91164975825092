import { QueryDependency, QueryKey } from 'lib/queries/types'

/**
 * Note: this function should never actually be called in the normal course of execution.
 *
 * `errQueryMissingDependencies` is intended to be used with `react-query` `useQuery`'s `enabled` option.
 * Since we often use the `enabled` option to disable queries until certain dependencies
 * are present, we need a way to safely use those dependencies in the query function (Typescript
 * complains if we don't perform a check).
 * See this blog post for more details: https://tkdodo.eu/blog/react-query-and-type-script#type-safety-with-the-enabled-option
 * @param {QueryDependency[]} queryKey Query key for the query that triggered the error (useful for debugging).
 * @param {QueryDependency[]} deps List of dependencies that may be undefined | null.
 * @returns
 */
function errQueryMissingDependencies(
  queryKey: QueryKey,
  deps: Record<string, QueryDependency>
): Promise<never> {
  return Promise.reject(
    new Error(
      `errQueryMissingDependencies invariant violation: queryKey = ${JSON.stringify(
        queryKey
      )}, missingDependencies = ${JSON.stringify(deps)}`
    )
  )
}

export { errQueryMissingDependencies }
