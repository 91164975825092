import { useCallback } from 'react'
import { Row } from '@tanstack/react-table'
import { PageContainer } from 'shared'
import { useTranslations } from 'translations'
import { Loader, Table } from 'ui'
import { GrantRow, useGrantsTable } from './hooks/useGrantsTable'
import { GrantDetailsModal } from './components/GrantDetailsModal.component'

function GrantsPage() {
  const t = useTranslations()
  const {
    columns,
    data,
    selectedGrant,
    setSelectedGrant,
    clearSelectedGrant,
    isLoading,
  } = useGrantsTable()

  const onClickRow = useCallback(
    (row: Row<GrantRow>) => {
      setSelectedGrant(row.original.id)
    },
    [setSelectedGrant]
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <PageContainer
      title={t('pages.grantMatchProgram.title')}
      subtitle={t('pages.grantMatchProgram.subtitle')}
      helmetTitle={t('pages.grantMatchProgram.seoTitle')}
    >
      <Table
        columns={columns}
        data={data}
        emptyMessage={t('pages.grantMatchProgram.table.emptyMessage')}
        defaultPageSize={25}
        defaultSorting={{ id: 'deadline', desc: false }}
        searchBarProps={{
          placeholder: t('pages.grantMatchProgram.table.searchBarPlaceholder'),
        }}
        onClickRow={onClickRow}
      />

      <GrantDetailsModal grant={selectedGrant} onClose={clearSelectedGrant} />
    </PageContainer>
  )
}

export { GrantsPage }
