import { useMemo } from 'react'
import { ReactComponent as MoneyPlantSVG } from 'assets/svgs/money-plant.svg'
import { ReactComponent as SignSVG } from 'assets/svgs/sign.svg'
import { RoutesPaths, useBrowserUrl } from 'routing'
import { CardGrid } from 'shared'
import { useTranslations } from 'translations'
import { ButtonVariant } from 'ui'

function NonCreditAccountHolderDashboard() {
  const t = useTranslations()
  const { navigateClientRouting } = useBrowserUrl()

  const cardGridItems = useMemo(() => {
    return [
      {
        title: t('pages.dashboard.nonCreditAccountHolder.grantsCard.title'),
        SVG: SignSVG,
        lis: [t('pages.dashboard.nonCreditAccountHolder.grantsCard.lis.one')],
        button: {
          label: t(
            'pages.dashboard.nonCreditAccountHolder.grantsCard.buttonLabel'
          ),
          variant: ButtonVariant.Tertiary,
          onClick: () => navigateClientRouting(RoutesPaths.Grants),
        },
      },
      {
        title: t('pages.dashboard.nonCreditAccountHolder.capitalCard.title'),
        SVG: MoneyPlantSVG,
        lis: [t('pages.dashboard.nonCreditAccountHolder.capitalCard.lis.one')],
        button: {
          label: t(
            'pages.dashboard.nonCreditAccountHolder.capitalCard.buttonLabel'
          ),
          variant: ButtonVariant.Tertiary,
          onClick: () => navigateClientRouting(RoutesPaths.CapitalMarketplace),
        },
      },
    ]
  }, [navigateClientRouting, t])

  return <CardGrid items={cardGridItems} />
}

export { NonCreditAccountHolderDashboard }
