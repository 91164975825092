import MUIMenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const StyledMenuItem = styled(MUIMenuItem)`
  ${typographyStyles.body1};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.color.backgroundBlue};
  }
`

export { StyledMenuItem }
