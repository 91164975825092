import styled from 'styled-components'

const StyledList = styled.ul<{ isNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isNavOpen }) => (isNavOpen ? 'undefined' : 'center')};

  overflow: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

export { StyledList }
