import { useMemo } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { Transaction } from 'api'
import {
  DateFormat,
  dateFormatter,
  useFormatCurrency,
  useFormatDate,
} from 'lib/formatter'
import { useTranslations } from 'translations'
import { PaymentsTableDescriptionCell } from '../components/PaymentsTableDescriptionCell'

type PastPaymentRow = {
  amount: number
  date: Date
  description: string
}

const columnHelper = createColumnHelper<PastPaymentRow>()

function usePastPaymentsTable(pastPayments: Transaction[]) {
  const t = useTranslations()
  const formatDate = useFormatDate()
  const formatCurrency = useFormatCurrency()

  const columns = useMemo(
    () => [
      columnHelper.accessor('description', {
        header: t('pages.payments.pastPaymentsTable.pastPayments'),
        cell: info => <PaymentsTableDescriptionCell label={info.getValue()} />,
        sortingFn: 'text',
      }),
      columnHelper.accessor('date', {
        header: t('pages.payments.pastPaymentsTable.date'),
        cell: info => formatDate(info.getValue(), DateFormat.MonthDayYear),
        sortingFn: 'datetime',
        sortDescFirst: true,
      }),
      columnHelper.accessor('amount', {
        header: t('pages.payments.pastPaymentsTable.amount'),
        cell: info => formatCurrency(info.getValue()),
        sortingFn: 'basic',
        meta: {
          align: 'right',
        },
      }),
    ],
    [t, formatCurrency, formatDate]
  )

  const data: PastPaymentRow[] = useMemo(
    () =>
      pastPayments.map(({ amount, description, initialStatusTimestamp }) => ({
        amount: Math.abs(amount),
        date: dateFormatter.fromUnixSeconds(initialStatusTimestamp),
        description,
      })),
    [pastPayments]
  )

  return {
    columns,
    data,
  }
}

export { usePastPaymentsTable }
