import { FunctionComponent, SVGProps } from 'react'
import { ReactComponent as ArrowDownSVG } from 'assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpSVG } from 'assets/icons/arrow-up.svg'
import { ReactComponent as AvatarSVG } from 'assets/icons/avatar.svg'
import { ReactComponent as BankSVG } from 'assets/icons/bank.svg'
import { ReactComponent as CalendarSVG } from 'assets/icons/calendar.svg'
import { ReactComponent as CardSVG } from 'assets/icons/card.svg'
import { ReactComponent as CardLockedSVG } from 'assets/icons/card-locked.svg'
import { ReactComponent as CardUploadSVG } from 'assets/icons/card-upload.svg'
import { ReactComponent as CheckmarkSVG } from 'assets/icons/checkmark.svg'
import { ReactComponent as CloseSVG } from 'assets/icons/close.svg'
import { ReactComponent as ConnectionSVG } from 'assets/icons/connection.svg'
import { ReactComponent as DisclosureSVG } from 'assets/icons/disclosure.svg'
import { ReactComponent as DollarSignSVG } from 'assets/icons/dollar-sign.svg'
import { ReactComponent as DoubleArrowSVG } from 'assets/icons/double-arrow.svg'
import { ReactComponent as DownloadSVG } from 'assets/icons/download.svg'
import { ReactComponent as ExclamationSVG } from 'assets/icons/exclamation.svg'
import { ReactComponent as ExpanderSVG } from 'assets/icons/expander.svg'
import { ReactComponent as ExternalLinkSVG } from 'assets/icons/external-link.svg'
import { ReactComponent as FilterSVG } from 'assets/icons/filter.svg'
import { ReactComponent as GridSVG } from 'assets/icons/grid.svg'
import { ReactComponent as HamburgerSVG } from 'assets/icons/hamburger.svg'
import { ReactComponent as HeartSVG } from 'assets/icons/heart.svg'
import { ReactComponent as HouseSVG } from 'assets/icons/house.svg'
import { ReactComponent as LockSVG } from 'assets/icons/lock.svg'
import { ReactComponent as QuestionMarkSVG } from 'assets/icons/question-mark.svg'
import { ReactComponent as SearchSVG } from 'assets/icons/search.svg'
import { ReactComponent as TrophySVG } from 'assets/icons/trophy.svg'
import { ReactComponent as UnlockSVG } from 'assets/icons/unlock.svg'

enum IconType {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Avatar = 'Avatar',
  Bank = 'Bank',
  Calendar = 'Calendar',
  Card = 'Card',
  CardLocked = 'CardLocked',
  CardUpload = 'CardUpload',
  Checkmark = 'Checkmark',
  Close = 'Close',
  Connection = 'Connection',
  Disclosure = 'Disclosure',
  DollarSign = 'DollarSign',
  DoubleArrow = 'DoubleArrow',
  Download = 'Download',
  Exclamation = 'Exclamation',
  Expander = 'Expander',
  ExternalLink = 'ExternalLink',
  Filter = 'Filter',
  Grid = 'Grid',
  Hamburger = 'Hamburger',
  Heart = 'Heart',
  House = 'House',
  Lock = 'Lock',
  QuestionMark = 'QuestionMark',
  Search = 'Search',
  Trophy = 'Trophy',
  Unlock = 'Unlock',
}

type SVGComponent = FunctionComponent<SVGProps<SVGSVGElement>>

type IconProps = {
  type: IconType
  color: string
  size?: number
  className?: string
}

enum IconSize {
  XSmall = 12,
  Small = 16,
  Default = 24,
  Large = 48,
  XLarge = 64,
}

const ICON_TYPE_TO_SVG: Record<IconType, SVGComponent> = {
  [IconType.ArrowDown]: ArrowDownSVG,
  [IconType.ArrowUp]: ArrowUpSVG,
  [IconType.Avatar]: AvatarSVG,
  [IconType.Bank]: BankSVG,
  [IconType.Calendar]: CalendarSVG,
  [IconType.Card]: CardSVG,
  [IconType.CardLocked]: CardLockedSVG,
  [IconType.CardUpload]: CardUploadSVG,
  [IconType.Checkmark]: CheckmarkSVG,
  [IconType.Close]: CloseSVG,
  [IconType.Connection]: ConnectionSVG,
  [IconType.Disclosure]: DisclosureSVG,
  [IconType.DollarSign]: DollarSignSVG,
  [IconType.DoubleArrow]: DoubleArrowSVG,
  [IconType.Download]: DownloadSVG,
  [IconType.Exclamation]: ExclamationSVG,
  [IconType.Expander]: ExpanderSVG,
  [IconType.ExternalLink]: ExternalLinkSVG,
  [IconType.Filter]: FilterSVG,
  [IconType.Grid]: GridSVG,
  [IconType.Hamburger]: HamburgerSVG,
  [IconType.Heart]: HeartSVG,
  [IconType.House]: HouseSVG,
  [IconType.Lock]: LockSVG,
  [IconType.QuestionMark]: QuestionMarkSVG,
  [IconType.Search]: SearchSVG,
  [IconType.Trophy]: TrophySVG,
  [IconType.Unlock]: UnlockSVG,
}

function Icon({ type, color, size = IconSize.Default, className }: IconProps) {
  const IconSVG = ICON_TYPE_TO_SVG[type]

  return (
    // wrap icon in a span; otherwise it gets resized by flex parents
    <span>
      <IconSVG
        fill={color}
        height={size}
        width={size}
        display='block'
        className={className}
      />
    </span>
  )
}

export type { SVGComponent, IconProps }
export { Icon, IconSize, IconType }
