type CreateAndAppendScriptOptions = {
  src: string
  async?: boolean
  defer?: boolean
  id?: string
}

export const createAndAppendScript = ({
  src,
  async = false,
  defer = false,
  id,
}: CreateAndAppendScriptOptions) => {
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.src = src
  if (id) script.id = id
  // there might be some quirky behavior in tests / old browsers when assigning to these properties directly
  if (async) script.setAttribute('async', '')
  if (defer) script.setAttribute('defer', '')

  document.body.appendChild(script)

  return script
}
