import MUITableHead from '@mui/material/TableHead'
import MUITableRow from '@mui/material/TableRow'
import MUITableSortLabel from '@mui/material/TableSortLabel'
import { Header, SortingState, flexRender } from '@tanstack/react-table'
import { TableHeaderCell } from './TableHead.styles'
import { BaseColumnMeta } from '../types'

type TableHeadProps<TData> = {
  headers: Header<TData, unknown>[]
  sorting: SortingState
  hasExpandableRows: boolean
}

function TableHead<TData>({
  headers,
  sorting,
  hasExpandableRows,
}: TableHeadProps<TData>) {
  return (
    <MUITableHead>
      <MUITableRow>
        {headers.map(header => {
          const cellValue = flexRender(
            header.column.columnDef.header,
            header.getContext()
          )
          const sortingMatch = sorting.find(sort => sort.id === header.id)

          return (
            <TableHeaderCell
              key={header.id}
              align={(header.column.columnDef.meta as BaseColumnMeta)?.align}
            >
              {header.column.getCanSort() ? (
                <MUITableSortLabel
                  active={!!sortingMatch}
                  direction={sortingMatch?.desc ? 'desc' : 'asc'}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {cellValue}
                </MUITableSortLabel>
              ) : (
                cellValue
              )}
            </TableHeaderCell>
          )
        })}
        {hasExpandableRows && <TableHeaderCell />}
      </MUITableRow>
    </MUITableHead>
  )
}

export { TableHead }
