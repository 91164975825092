import { BankAccount, BankAccountStatus } from 'api'
import { stringFormatter } from 'lib/formatter'

const BANK_ACCOUNT_ACTIVE_STATUSES = new Set([
  BankAccountStatus.Active,
  BankAccountStatus.Verified,
])

function isBankAccountActive(bankAccount: BankAccount): boolean {
  return BANK_ACCOUNT_ACTIVE_STATUSES.has(bankAccount.status)
}

function displayBankAccount(bankAccount: BankAccount): string {
  const { name, accountNumberDisplay } = bankAccount
  return `${name} ${stringFormatter.displayLastFourDigits(
    accountNumberDisplay
  )}`
}

export { isBankAccountActive, displayBankAccount }
