import { ReactNode } from 'react'
import styled from 'styled-components'
import MUICardContent from '@mui/material/CardContent'

type CardContentProps = {
  children: ReactNode
  className?: string
}

const StyledCardContent = styled(MUICardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;

  :last-child {
    padding-bottom: 1rem;
  }

  &.MuiCardContent-root {
    padding: 1rem 0;

    ${({ theme }) => theme.media.minTablet} {
      padding: 1rem;
    }
  }
`

export function CardContent({ children, className }: CardContentProps) {
  return <StyledCardContent className={className}>{children}</StyledCardContent>
}
