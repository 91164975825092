import { useCallback } from 'react'
import { useFormatCurrency } from 'lib/formatter'

type Amount = {
  min: number
  max: number
}

enum AmountFilter {
  LessThan5K = 'LessThan5K',
  Between5KAnd10K = 'Between5KAnd10K',
  Between10KAnd25K = 'Between10KAnd25K',
  GreaterThan25K = 'GreaterThan25K',
}

const AMOUNT_FILTER_TO_RANGE: Record<AmountFilter, [number, number]> = {
  [AmountFilter.LessThan5K]: [0, 5000],
  [AmountFilter.Between5KAnd10K]: [5000, 10000],
  [AmountFilter.Between10KAnd25K]: [10000, 25000],
  [AmountFilter.GreaterThan25K]: [25000, Number.MAX_SAFE_INTEGER],
}

const AMOUNT_FILTER_TO_READABLE: Record<AmountFilter, string> = {
  [AmountFilter.LessThan5K]: '<$5,000',
  [AmountFilter.Between5KAnd10K]: '$5,000-$10,000',
  [AmountFilter.Between10KAnd25K]: '$10,000-$25,000',
  [AmountFilter.GreaterThan25K]: '>$25,000',
}

const AMOUNT_FILTER_OPTIONS = Object.values(AmountFilter).map(value => ({
  label: AMOUNT_FILTER_TO_READABLE[value],
  value,
}))

function useFormatAmount() {
  const formatCurrency = useFormatCurrency()

  return useCallback(
    (amountMin: number, amountMax: number) => {
      const opts = {
        maximumFractionDigits: 0,
      }

      if (amountMin === amountMax) {
        return amountMin ? formatCurrency(amountMax, opts) : 'Varies'
      }

      if (!amountMin) {
        return `<${formatCurrency(amountMax, opts)}`
      }

      if (!amountMax) {
        return `${formatCurrency(amountMin, opts)}+`
      }

      return `${formatCurrency(amountMin, opts)}-${formatCurrency(
        amountMax,
        opts
      )}`
    },
    [formatCurrency]
  )
}

function getAmountFilters(
  amountMin: number,
  amountMax: number
): Set<AmountFilter> {
  const filters = new Set<AmountFilter>()

  Object.keys(AmountFilter).forEach(key => {
    const filter = key as AmountFilter
    const range = AMOUNT_FILTER_TO_RANGE[filter]

    if (
      (amountMin >= range[0] && amountMin < range[1]) || // amountMin within range [min, max)
      (amountMax >= range[0] && amountMax < range[1]) || // amountMax within range [min, max)
      (amountMin <= range[0] && amountMax >= range[1]) // range [amountMin, amountMax] contains range [min, max]
    ) {
      filters.add(filter)
    }
  })

  return filters
}

function amountComparator(amountA: Amount, amountB: Amount): number {
  const { min: minA, max: maxA } = amountA
  const { min: minB, max: maxB } = amountB

  if (minA !== minB) {
    return minA < minB ? -1 : 1
  }

  return maxA < maxB ? -1 : 1
}

export type { Amount }
export {
  useFormatAmount,
  AmountFilter,
  getAmountFilters,
  amountComparator,
  AMOUNT_FILTER_OPTIONS,
}
