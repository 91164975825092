import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledMain = styled.main`
  margin: 0 auto;
  padding: 0 1rem;
  width: 36rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface GridOneColumnProps {
  className?: string
  children: ReactNode
}

export const GridOneColumn = (props: GridOneColumnProps) => {
  const { children, className } = props
  return <StyledMain className={className}>{children}</StyledMain>
}
