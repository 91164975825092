import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient, useSessionStore } from 'lib/context'
import { ApiError, GetUserRespData, User } from 'api'
import { SessionStoreKey } from 'lib/sessionStore'
import { isNil } from 'lib/utils'
import { userKeys } from './queryKeys'

function useUser<T = User>(select?: (resp: GetUserRespData) => T) {
  const apiClient = useApiClient()
  const sessionStore = useSessionStore()

  const userId = sessionStore.get(SessionStoreKey.UserId)
  const queryKey = userKeys.byId(userId)

  return useQuery({
    queryKey,
    queryFn: () => {
      if (isNil(userId)) {
        return Promise.reject(
          new Error(
            'useUser must be used within a session context, userId not found'
          )
        )
      }

      return apiClient.getUser({
        id: Number(userId),
      })
    },
    select: resp => (select ? select(resp) : resp.user),
  }) as UseQueryResult<typeof select extends undefined ? User : T, ApiError>
}

export { useUser }
