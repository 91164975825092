import Stack, { StackProps } from '@mui/material/Stack'
import { Children } from 'react'
import styled from 'styled-components'
import { Paper, Typography, TypographyProps } from 'ui'

const StyledPaper = styled(Paper)`
  max-width: 48rem;
`

const StyledTitle = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h2' />
))`
  margin-bottom: 1.5rem;
`

const StyledSectionHeader = styled(
  (props: Omit<TypographyProps, 'variant'>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography {...props} variant='body1' bold />
  )
)`
  margin-bottom: 1rem;
`

const TextFieldContainer = styled.div`
  width: 100%;
`

const FieldStack = ({ children, ...rest }: StackProps) => (
  <Stack
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    direction={{ xs: 'column', md: 'row' }}
    spacing={{ md: 2 }}
  >
    {Children.map(children, child => (
      <TextFieldContainer>{child}</TextFieldContainer>
    ))}
  </Stack>
)

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export {
  StyledPaper,
  StyledTitle,
  StyledSectionHeader,
  FieldStack,
  ButtonContainer,
}
