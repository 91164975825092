import { getEnvVariable } from 'utils'
import {
  ActivateAccountRequest,
  CreateAccountRequest,
  CreateAccountResponse,
  ResendEmailRequest,
} from './RegistrationApi.types'
import { httpService } from '../../http-service/Http.service'

export const RegistrationApi = () => {
  const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

  const { post } = httpService(baseUrl)

  const createAccount = (payload: CreateAccountRequest) => {
    return post<CreateAccountResponse, CreateAccountRequest>({
      endpoint: 'auth/register',
      requestBody: payload,
    })
  }

  const requestActivationEmailResend = (payload: ResendEmailRequest) => {
    return post<unknown, ResendEmailRequest>({
      endpoint: 'auth/resendActivationLink',
      requestBody: payload,
    })
  }

  const activateAccount = (payload: ActivateAccountRequest) => {
    return post<unknown, ActivateAccountRequest>({
      endpoint: 'auth/activate',
      requestBody: payload,
    })
  }

  return {
    createAccount,
    requestActivationEmailResend,
    activateAccount,
  }
}
