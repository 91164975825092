import { ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

interface ReactQueryProviderProps {
  children: ReactNode
  queryClient: QueryClient
}

export const ReactQueryProvider = (props: ReactQueryProviderProps) => {
  const { children, queryClient } = props

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  )
}
