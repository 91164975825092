import { FormattedMessage } from 'react-intl'
import { LayoutOneColumnAndHeader, Typography } from 'ui-kit'
import { displayHtmlStyles } from 'shared'
import { Button, ButtonVariant } from 'ui'
import { Translate, useTranslations } from 'translations'
import { ParagraphWrapper } from './IdentityVerification.styles'

const printCodes = (codes: string[], t: Translate) => {
  const printWindow = window.open()
  if (!printWindow) return

  printWindow.document.write(
    `<html>
        <head>
          <title>
            ${t('pages.loginPage.mfa.emergencyCodes.header')}
          </title>
          <style>
            ${displayHtmlStyles}
          </style>
        </head>
        <body>
          <h1>${t('pages.loginPage.mfa.emergencyCodes.header')}</h1>
          <ul>${codes.map(code => `<li><p>${code}</p></li>`).join('')}</ul>
        </body>
      </html>`
  )
  printWindow.document.close()
  printWindow.print()
}

interface EmergencyCodesProps {
  codes: string[]
  onVerified?: () => void | Promise<void>
}

export const EmergencyCodes = ({ codes, onVerified }: EmergencyCodesProps) => {
  const t = useTranslations()
  const handleButtonClick = () => {
    printCodes(codes, t)
    onVerified?.()
  }

  return (
    <LayoutOneColumnAndHeader>
      <Typography.H1MainLayout>
        <FormattedMessage id='pages.loginPage.mfa.emergencyCodes.header' />
      </Typography.H1MainLayout>
      <ParagraphWrapper>
        <FormattedMessage id='pages.loginPage.mfa.emergencyCodes.intro' />
      </ParagraphWrapper>

      <Button
        label={t('pages.loginPage.mfa.emergencyCodes.button')}
        variant={ButtonVariant.Tertiary}
        onClick={handleButtonClick}
      />
    </LayoutOneColumnAndHeader>
  )
}
