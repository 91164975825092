import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useBrowserUrl } from 'routing/hooks/useBrowserUrl'
import {
  FormGrid,
  LayoutOneColumnAndHeader,
  useSnackbars,
  Typography,
  Wrappers,
} from 'ui-kit'
import { Button, ButtonVariant } from 'ui'
import { useTranslations } from 'translations'
import {
  RegisterFormFieldSet,
  registerFormValidationSchema,
  registerFormInitialValues,
  RegisterFormValues,
} from 'form-kit'
import { useLogger } from 'logger'
import { RoutesPaths } from 'routing'
import { RegistrationApi } from 'api-connectors'
import { isAxiosError, isServerError } from 'error-handling'
import { mapRegistrationFormValuesToRequest } from 'models'
import { RegisterSeo } from './RegisterSeo.component'

export const RegisterPage = () => {
  const [status, setStatus] = useState<'IDLE' | 'LOADING'>('IDLE')
  const { goToLoginPage, navigateClientRouting } = useBrowserUrl()
  const { logger } = useLogger()
  const t = useTranslations()
  const { showErrorSnackbar } = useSnackbars()
  const { createAccount } = RegistrationApi()

  const handleCreateAccount = async (values: RegisterFormValues) => {
    try {
      await createAccount(mapRegistrationFormValuesToRequest(values))
      navigateClientRouting(`${RoutesPaths.ConfirmEmail}`, {
        businessEmail: values.businessEmail,
      })
    } catch (err) {
      let uiMessage = ''
      if (isAxiosError(err)) {
        const statusCode = err.response?.status
        if (isServerError(err)) {
          uiMessage = t('errorMessages.SYSTEM_FAILURE')
          logger.error(uiMessage, { error: err.response?.data })
        } else if (statusCode && statusCode === 400) {
          uiMessage = t('pages.registerPage.registerForm.error')
        } else {
          uiMessage = t('errorMessages.UNEXPECTED_ERROR')
        }
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
        logger.error(uiMessage, { error: err })
      }

      setStatus('IDLE')
      showErrorSnackbar(uiMessage)
    }
  }

  const handleRegisterSubmit = async (values: RegisterFormValues) => {
    setStatus('LOADING')
    await handleCreateAccount(values)
  }

  return (
    <>
      <RegisterSeo />
      <LayoutOneColumnAndHeader onBackButtonClick={goToLoginPage}>
        <Typography.H1>{t('pages.registerPage.header')}</Typography.H1>
        <Wrappers.FormWrapperAuth>
          <Formik<RegisterFormValues>
            validateOnChange={false}
            validationSchema={registerFormValidationSchema}
            initialValues={registerFormInitialValues}
            onSubmit={values => {
              if (status === 'IDLE') {
                handleRegisterSubmit(values)
              }
            }}
          >
            {() => (
              <Form>
                <RegisterFormFieldSet />
                <FormGrid.CTAWrapper>
                  <Button
                    label={t('pages.registerPage.submitFormButton')}
                    variant={ButtonVariant.Primary}
                    sizeVariant='large'
                    type='submit'
                    isLoading={status === 'LOADING'}
                  />
                </FormGrid.CTAWrapper>
              </Form>
            )}
          </Formik>
        </Wrappers.FormWrapperAuth>
      </LayoutOneColumnAndHeader>
    </>
  )
}
