import MUIStep from '@mui/material/Step'
import MUIStepLabel from '@mui/material/StepLabel'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const StyledStepLabel = styled(MUIStepLabel)`
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    ${typographyStyles.caption};
    margin-top: 0.5rem;
  }

  .MuiSvgIcon-root.Mui-active,
  .MuiSvgIcon-root.Mui-completed {
    color: ${({ theme }) => theme.color.primary};
  }
`

const StyledStep = styled(MUIStep)`
  .MuiStepConnector-root.Mui-active {
    .MuiStepConnector-line {
      border-color: ${({ theme }) => theme.color.primary};
    }
  }
`

const ContentContainer = styled.div`
  margin-top: 2rem;
`

export { StyledStep, StyledStepLabel, ContentContainer }
