import Stack from '@mui/material/Stack'
import { ReactComponent as MoneySVG } from 'assets/svgs/money.svg'
import { useTranslations } from 'translations'
import { Button, ButtonVariant } from 'ui'
import { SVGContainer } from './OneTimePaymentSuccess.styles'
import { ButtonContainer } from '../OneTimePaymentButton.styles'

type OneTimePaymentSuccessProps = {
  onClose: () => void
}

function OneTimePaymentSuccess({ onClose }: OneTimePaymentSuccessProps) {
  const t = useTranslations()

  return (
    <Stack alignItems='center' spacing={4}>
      <SVGContainer>
        <MoneySVG />
      </SVGContainer>

      <ButtonContainer>
        <Button
          label={t('shared.oneTimePaymentButton.modal.success.button')}
          variant={ButtonVariant.Primary}
          onClick={onClose}
        />
      </ButtonContainer>
    </Stack>
  )
}

export { OneTimePaymentSuccess }
