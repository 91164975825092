import { useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Icon, IconSize, IconType } from 'ui'
import { SidebarListItemLink } from './SidebarListItemLink.component'
import { SidebarListItemContent } from './SidebarListItem.component'
import {
  ExpandIconWrapper,
  SidebarListItemButton,
  StyledAccordion,
  StyledAccordionDetails,
} from './SidebarExpandableListItem.styles'
import { StyledLabel, StyledLi } from './SidebarListItem.styles'
import { useNav } from '../NavProvider/NavProvider.component'

type SubLink = {
  path: string
  label: string
}

type SidebarExpandableListItemProps = {
  label: string
  iconType: IconType
  subLinks: SubLink[]
}

// Handles expanded state when linking to a subLink from elsewhere in the app.
function useHandleLinkToExpandableListItem(label: string, subLinks: SubLink[]) {
  const { pathname } = useLocation()
  const prevPathname = useRef(pathname)
  const { setExpandedNavItem } = useNav()

  useEffect(() => {
    const hasPathnameChanged = pathname !== prevPathname.current

    if (
      hasPathnameChanged &&
      subLinks.some(subLink => subLink.path === pathname)
    ) {
      setExpandedNavItem(label)
    }

    if (hasPathnameChanged) {
      prevPathname.current = pathname
    }
  }, [pathname, prevPathname, label, subLinks, setExpandedNavItem])
}

function SidebarExpandableListItem({
  iconType,
  label,
  subLinks,
}: SidebarExpandableListItemProps) {
  const { pathname } = useLocation()
  const { color } = useTheme()
  const {
    isNavOpen,
    isMobile,
    toggleNav,
    expandedNavItem,
    setExpandedNavItem,
  } = useNav()

  useHandleLinkToExpandableListItem(label, subLinks)

  const handleExpandListItemClick = useCallback(() => {
    if (!isNavOpen) {
      toggleNav()
      setExpandedNavItem(label)
      return
    }

    setExpandedNavItem(prev => {
      return prev === label ? undefined : label
    })
  }, [label, toggleNav, setExpandedNavItem, isNavOpen])

  const onClickSubLink = useCallback(() => {
    if (isMobile) {
      toggleNav()
    }
  }, [isMobile, toggleNav])

  const isExpanded = expandedNavItem === label

  const isParentActive = useMemo(() => {
    return !isExpanded && subLinks.some(subLink => subLink.path === pathname)
  }, [subLinks, isExpanded, pathname])

  return (
    <StyledLi>
      <StyledAccordion expanded={isExpanded && isNavOpen}>
        <Stack alignItems='center'>
          <SidebarListItemButton
            isNavOpen={isNavOpen}
            isActive={isParentActive}
            onClick={handleExpandListItemClick}
          >
            <SidebarListItemContent
              label={label}
              iconType={iconType}
              isNavOpen={isNavOpen}
            />
            <ExpandIconWrapper isExpanded={isExpanded} isNavOpen={isNavOpen}>
              <Icon
                type={IconType.Expander}
                color={color.background}
                size={IconSize.XSmall}
              />
            </ExpandIconWrapper>
          </SidebarListItemButton>
        </Stack>

        <StyledAccordionDetails>
          <Stack spacing={1}>
            {subLinks.map(({ path, label: subLinkLabel }) => (
              <SidebarListItemLink
                key={path}
                path={path}
                onClick={onClickSubLink}
              >
                <StyledLabel isNavOpen={isNavOpen}>{subLinkLabel}</StyledLabel>
              </SidebarListItemLink>
            ))}
          </Stack>
        </StyledAccordionDetails>
      </StyledAccordion>
    </StyledLi>
  )
}

export type { SubLink, SidebarExpandableListItemProps }
export { SidebarExpandableListItem }
