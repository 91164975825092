import styled from 'styled-components'
import { Typography } from 'ui-kit'
import { paragraphCaptions } from 'ui-kit/Typography/Typography.styles'

export const StyledImageWidget = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  transition: ${({ theme }) => theme.transition};
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.minDesktop} {
    display: grid;
    align-items: center;
    grid-template-columns: 6.25rem auto auto;
    gap: 2rem;
    > svg {
      margin: 0.5rem auto 0 auto;
      align-self: flex-start;
    }
  }
`

export const StyledH2 = styled.h2`
  ${Typography.headingH6};
  color: ${({ theme }) => theme.color.primary};
  text-align: left;
  margin: 0.5rem auto 1rem;
`

export const StyledContent = styled.div`
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.minDesktop} {
    margin-bottom: unset;
  }

  ul,
  ol {
    ${Typography.paragraphSmall};
    list-style-type: disc;
    margin-left: 1rem;
    line-height: 1.5rem;
  }

  a {
    font-weight: bold;
  }

  ol {
    list-style-type: decimal;
  }

  p {
    ${Typography.paragraphSmall};
    line-height: 1.5rem;
    margin-bottom: 2rem;

    ${({ theme }) => theme.media.minDesktop} {
      margin-bottom: unset;
    }
  }

  .subheader {
    ${paragraphCaptions}
  }
`

export const StyledLi = styled.li`
  line-height: 2rem;
`
