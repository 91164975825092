import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import { IconType } from 'ui/Icon/Icon.component'
import { IconButton } from 'ui/IconButton/IconButton.component'
import {
  StyledInputAdornment,
  StyledSearchBar,
  StyledStack,
} from './TableSearchBar.styles'

type TableSearchBarProps = {
  value: string
  placeholder: string
  onChange: (value: string) => void
}

const DEBOUNCE_TIMEOUT = 200

function TableSearchBar({
  value: initialValue,
  placeholder,
  onChange,
}: TableSearchBarProps) {
  const { color } = useTheme()
  const [value, setValue] = useState(initialValue)
  const ref = useRef<HTMLInputElement>()
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // debounce input
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, DEBOUNCE_TIMEOUT)

    return () => clearTimeout(timeout)
  }, [value, onChange])

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  return (
    <StyledStack direction='row' alignItems='center'>
      <StyledSearchBar
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        inputRef={ref}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        $isExpanded={!!value}
        InputProps={{
          startAdornment: (
            <StyledInputAdornment position='start'>
              <IconButton
                type={IconType.Search}
                color={color.primary}
                isDisabled={isFocused}
                onClick={() => {
                  ref.current?.focus()
                }}
              />
            </StyledInputAdornment>
          ),
        }}
      />
    </StyledStack>
  )
}

export { TableSearchBar }
