/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from 'ui'
import { Chip, ChipProps } from 'ui/Chip/Chip.component'
import { IconProps } from 'ui/Icon/Icon.component'
import { TableCell } from './TableCell.component'

type TableIconCellProps = {
  label: string
  icon: IconProps
  chip?: ChipProps
}

function TableIconCell({ label, icon, chip }: TableIconCellProps) {
  return (
    <TableCell
      label={label}
      leadingElement={<Icon {...icon} />}
      trailingElement={chip && <Chip {...chip} />}
    />
  )
}

export { TableIconCell }
