import { IconType } from '../Icons/Icon.component'

export const LINKS = [
  {
    icon: IconType.grow,
    translationKey: 'uiKit.knowledgeBase.about',
    href: 'https://www.help.getfundid.com/knowledge/about-fundid',
  },
  {
    icon: IconType.sign,
    translationKey: 'uiKit.knowledgeBase.general',
    href: 'https://www.help.getfundid.com/knowledge/general',
  },
  {
    icon: IconType.card,
    translationKey: 'uiKit.knowledgeBase.card',
    href: 'https://www.help.getfundid.com/knowledge/business-building-card',
  },
  {
    icon: IconType.banking,
    translationKey: 'uiKit.knowledgeBase.grants',
    href: 'https://www.help.getfundid.com/knowledge/grant-match-program',
  },
  {
    icon: IconType.capital,
    translationKey: 'uiKit.knowledgeBase.capital',
    href: 'https://www.help.getfundid.com/knowledge/capital-marketplace',
  },
  {
    icon: IconType.idea,
    translationKey: 'uiKit.knowledgeBase.other',
    href: 'https://www.help.getfundid.com/knowledge/other',
  },
]
