import { UseMutationResult, useMutation } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, ResendActivationCodeReqBody } from 'api'

function useResendActivationCode(): UseMutationResult<
  void,
  ApiError,
  ResendActivationCodeReqBody
> {
  const apiClient = useApiClient()

  return useMutation(args => {
    return apiClient.resendActivationCode(args)
  })
}

export { useResendActivationCode }
