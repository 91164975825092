import { isActiveAutopaySchedule } from 'lib/models'
import { usePaymentSchedules } from './usePaymentSchedules'

function useAutopaySchedule() {
  return usePaymentSchedules(
    resp => resp.paymentSchedules.find(isActiveAutopaySchedule) || null
  )
}

export { useAutopaySchedule }
