import styled, { css } from 'styled-components'

export const paragraphLarge = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.color.primary};
`

export const paragraphBody = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.color.primary};
`

export const paragraphSmall = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.color.primary};
`

export const paragraphCaptions = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-weight: normal;
  font-size: 0.815rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.color.primary};
`

export const headingH2 = css`
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 1.25;
  color: ${({ theme }) => theme.color.green[700]};
`

export const headingH3 = css`
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-weight: normal;
  font-size: 2rem;
  line-height: 1;
  color: ${({ theme }) => theme.color.green[700]};
`

export const headingH4 = css`
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 1.875;
  color: ${({ theme }) => theme.color.green[700]};
`

export const headingH5 = css`
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.color.primary};
`

export const headingH6 = css`
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fontFamily.headline};
`

export const H1 = styled.h1`
  ${headingH4};
  line-height: 1;
  text-align: center;
  text-decoration: none;

  margin-bottom: 1rem;
  ${({ theme }) => theme.media.minTablet} {
    ${headingH3};
    margin-bottom: 1.5rem;
  }
`
export const H1WrappedCentered = styled.h1`
  ${headingH4};
  margin-bottom: 1rem;
  max-width: 10em;
  text-align: center;
  line-height: 1.875rem;
  ${({ theme }) => theme.media.minTablet} {
    ${headingH3};
    margin-bottom: 1.5rem;
    line-height: 2rem;
  }
`

export const P = styled.p`
  text-align: center;
  ${paragraphSmall};
  margin-bottom: 1.75rem;
  ${({ theme }) => theme.media.minTablet} {
    ${paragraphBody};
    margin-bottom: 4rem;
  }
`

export const Caption = styled.p`
  text-align: center;
  ${paragraphCaptions};
  color: ${({ theme }) => theme.color.grey[900]};
`

export const PSmallMargin = styled(P)`
  margin-bottom: 1rem;
`

export const H1MainLayout = styled(H1)`
  text-align: left;
  margin-bottom: 1.5rem;
`
export const H2LoanPage = styled.h2`
  text-align: left;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-weight: normal;
  font-size: 2rem;
  line-height: 1;
`
export const H1Loans = styled.h1`
  text-align: left;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.headline};
  font-weight: normal;
  text-align: center;
  font-size: 3rem;
  line-height: 3.75rem;
`
export const ParagraphLoans = styled.p`
  ${paragraphBody}
  color: ${({ theme }) => theme.color.background};
`

export const PMainLayout = styled(P)`
  text-align: left;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.minTablet} {
    margin-bottom: 2.5rem;
  }
`
