import { getEnvVariable } from 'utils'
import {
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  SetNewPasswordRequest,
  UserStatusResponse,
} from './AuthenticationApi.types'
import { httpService } from '../../http-service/Http.service'

export const AuthenticationApi = () => {
  const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

  const { post, get } = httpService(baseUrl)

  const login = (payload: LoginRequest) => {
    return post<LoginResponse, LoginRequest>({
      endpoint: 'auth/login',
      requestBody: payload,
    })
  }

  const logout = () => {
    return post<unknown, undefined>({
      endpoint: 'auth/logout',
    })
  }

  const forgotPassword = (payload: ForgotPasswordRequest) => {
    return post<unknown, ForgotPasswordRequest>({
      endpoint: 'auth/forgotPassword',
      requestBody: payload,
    })
  }

  const setNewPassword = (payload: SetNewPasswordRequest) => {
    return post<unknown, SetNewPasswordRequest>({
      endpoint: 'auth/setNewPassword',
      requestBody: payload,
    })
  }

  const userStatus = () => {
    return get<UserStatusResponse>({ endpoint: 'auth/status' })
  }

  return {
    login,
    logout,
    forgotPassword,
    setNewPassword,
    userStatus,
  }
}
