import styled from 'styled-components'
import { Loader, Paper, Typography, TypographyProps } from 'ui'

const StyledHeader = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h2' />
))`
  margin-bottom: 1.5rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SVGContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

function ActivateCardStepLoader() {
  return (
    <Paper>
      <Loader />
    </Paper>
  )
}

export { ButtonContainer, StyledHeader, SVGContainer, ActivateCardStepLoader }
