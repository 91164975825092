import { FieldProps } from 'formik'
import { ReactElement } from 'react'
import { Checkbox } from 'ui-kit'
import { AdapterErrorMessage } from '../AdapterErrorMessage/AdapterErrorMessage.component'

export interface AdapterInputTextProps extends FieldProps {
  label: string
  description: string | ReactElement
  disabled?: boolean
}

export const AdapterInputCheckbox = (props: AdapterInputTextProps) => {
  const { field, label, form, description, disabled } = props
  const { name, value } = field

  const hasError = Boolean(form.errors[name])

  const toggle = () => {
    form.setFieldValue(name, !value)
  }

  return (
    <>
      <Checkbox
        description={description}
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        toggle={toggle}
        hasError={hasError}
      />
      <AdapterErrorMessage name={name} />
    </>
  )
}
