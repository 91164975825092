import { ReactNode } from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'

import { GridOneWiderColumn } from 'ui-kit/GridOneWiderColumn/GridOneWiderColumn.component'
import { AppHeader } from 'ui-kit'

export interface LayoutOneWiderColumnAndHeaderProps {
  children: ReactNode
  onBackButtonClick?: () => void
}

const StyledSpacingWrapper = styled(Box)`
  margin-top: 3.5rem;
  padding-bottom: 1rem;

  ${({ theme }) => theme.media.minTablet} {
    margin-top: 4rem;
  }
`

export const LayoutOneWiderColumnAndHeader = (
  props: LayoutOneWiderColumnAndHeaderProps
) => {
  const { children, onBackButtonClick } = props

  return (
    <>
      <AppHeader onBackButtonClick={onBackButtonClick} />
      <StyledSpacingWrapper>
        <GridOneWiderColumn>{children}</GridOneWiderColumn>
      </StyledSpacingWrapper>
    </>
  )
}
