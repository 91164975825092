export const getEnvVariable = (variableName: string) => {
  // quick MVP solution to avoid throwing an error in storybook
  if (process.env.STORYBOOK_ENV) return variableName
  const variable = process.env[variableName]
  if (!variable) {
    throw Error(
      `There is no environment variable with name: ${variableName}. Check .evn file.`
    )
  }

  return variable
}
