import { useTheme } from '@mui/material'
import { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import { Icon, IconSize, IconType } from 'ui/Icon/Icon.component'
import { Typography } from 'ui/Typography/Typography.component'
import {
  ButtonSizeVariant,
  ButtonVariant,
  getLabelColor,
  getLoadingIndicatorSize,
  StyledButton,
  StyledButtonLoader,
  StyledStack,
} from './Button.styles'

type ButtonProps = {
  label: string
  variant: ButtonVariant
  sizeVariant?: ButtonSizeVariant
  type?: MUIButtonProps['type']
  iconType?: IconType
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}

function Button({
  label,
  variant,
  sizeVariant = 'default',
  type,
  iconType,
  isDisabled,
  isLoading,
  onClick,
  ...rest
}: ButtonProps) {
  const theme = useTheme()

  return (
    <StyledButton
      onClick={onClick}
      type={type}
      disabled={isDisabled || isLoading}
      disableTouchRipple
      aria-label={label}
      $variant={variant}
      $sizeVariant={sizeVariant}
      $isLoading={!!isLoading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest} // Currently only used to pass through props from CardLendflowWidget's React.cloneElement usage
    >
      <StyledStack
        direction='row'
        alignItems='center'
        spacing={1}
        $isLoading={!!isLoading}
      >
        {iconType && (
          <Icon
            type={iconType}
            color={getLabelColor(theme, variant)}
            size={sizeVariant === 'default' ? IconSize.Small : 20}
          />
        )}
        <Typography variant={sizeVariant === 'default' ? 'caption' : 'body1'}>
          {label}
        </Typography>
      </StyledStack>
      {isLoading && (
        <StyledButtonLoader
          $buttonVariant={variant}
          $sizeVariant={sizeVariant}
          size={`${getLoadingIndicatorSize(sizeVariant)}px`}
        />
      )}
    </StyledButton>
  )
}

export type { ButtonProps }
export { Button }
