import { useCallback } from 'react'
import { FormatNumberOptions, useIntl } from 'react-intl'

const numberFormatter = {
  getWholePart: (n: number) => {
    return Math.floor(n)
  },

  getDecimalPart: (n: number) => {
    const split = n.toString().split('.')

    // e.g. 100
    if (split.length === 1) {
      return '00'
    }

    // e.g. 100.1
    if (split[1].length === 1) {
      return `${split[1]}0`
    }

    // e.g. 100.11
    return split[1].substring(0, 2)
  },
}

function useFormatPercent() {
  const intl = useIntl()

  const formatPercent = useCallback(
    (val: number, opts?: FormatNumberOptions) =>
      intl.formatNumber(val, {
        style: 'percent',
        ...opts,
      }),
    [intl]
  )

  return formatPercent
}

export { numberFormatter, useFormatPercent }
