import { Partner } from './shared'

enum OffersEndpoints {
  GetOffers = '/offers',
}

// OfferCategory describes the type of offer.
enum OfferCategory {
  Banking = 'BANKING',
  Accounting = 'ACCOUNTING',
  Insurance = 'INSURANCE',
  BusinessFormation = 'BUSINESS_FORMATION',
  CustomerManagement = 'CUSTOMER_MANAGEMENT',
  Design = 'DESIGN',
  Retirement = 'RETIREMENT',
  Marketing = 'MARKETING',
  Payments = 'PAYMENTS',
  Payroll = 'PAYROLL',
  Website = 'WEBSITE',
  Other = 'OTHER',
}

// Offer is an offer by a Partner for e.g. a discount on their product.
type Offer = {
  id: number
  partner: Partner
  name: string
  category: OfferCategory
  url: string
  tagFeatured: boolean
}

type GetOffersRespData = {
  offers: Offer[]
}

export type { GetOffersRespData, Offer }
export { OffersEndpoints, OfferCategory }
