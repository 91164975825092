import { Address, Name } from './shared'

enum UsersEndpoints {
  GetUser = '/users',
}

type User = {
  id: number
  accountID?: number
  companyID?: number
  name: Name
  email: string
  phone?: string
  address: Address
}

type GetUserQueryParams = {
  id: number
}

type GetUserRespData = {
  user: User
}

export type { GetUserQueryParams, GetUserRespData, User, Name }
export { UsersEndpoints }
