import Stack from '@mui/material/Stack'
import { ReactElement } from 'react'
import { Typography } from 'ui'

type TableCellProps = {
  label: string
  leadingElement?: ReactElement
  trailingElement?: ReactElement
}

function TableCell({ label, leadingElement, trailingElement }: TableCellProps) {
  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {leadingElement}
      <Typography variant='caption'>{label}</Typography>
      {trailingElement}
    </Stack>
  )
}

export { TableCell }
