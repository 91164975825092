/* eslint-disable no-nested-ternary */
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthPageContainer } from 'shared'
import { useTranslations } from 'translations'
import { useActivateUser } from 'lib/queries'
import { Icon, IconSize, IconType, Loader, Typography } from 'ui'
import { useSnackbars } from 'ui-kit'
import { RoutesPaths } from 'routing'
import { IconContainer } from './EmailVerificationPage.styles'

enum EmailVerificationSearchParam {
  Code = 'code',
  Email = 'email',
}

function EmailVerificationPage() {
  const t = useTranslations()
  const { color } = useTheme()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbars()
  const {
    mutate: activateUser,
    isLoading: isActivatingUser,
    isError: isErrorActivatingUser,
  } = useActivateUser()

  const code = searchParams.get(EmailVerificationSearchParam.Code)
  const email = searchParams.get(EmailVerificationSearchParam.Email)

  useEffect(() => {
    activateUser(
      {
        code: String(code),
        email: String(email),
      },
      {
        onSuccess: () => {
          showSuccessSnackbar(
            t('pages.newEmailVerification.success', { email: email || '' })
          )
          navigate(RoutesPaths.NewLogin, { replace: true })
        },
        onError: () =>
          showErrorSnackbar(t('pages.newEmailVerification.error.snackbar')),
      }
    )
  }, [
    code,
    email,
    activateUser,
    navigate,
    showSuccessSnackbar,
    showErrorSnackbar,
    t,
  ])

  return (
    <AuthPageContainer
      title=''
      helmetTitle={t('pages.newEmailVerification.seoTitle')}
    >
      {isActivatingUser ? (
        <Loader />
      ) : isErrorActivatingUser ? (
        <Stack direction='column' alignItems='center'>
          <IconContainer>
            <Icon
              type={IconType.Exclamation}
              color={color.red.error}
              size={IconSize.Large}
            />
          </IconContainer>
          <Typography variant='caption'>
            {t('pages.newEmailVerification.error.body', {
              email: String(email),
            })}
          </Typography>
        </Stack>
      ) : null}
    </AuthPageContainer>
  )
}

export { EmailVerificationPage }
