import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient } from 'lib/context'
import { isNil } from 'lib/utils'
import { ApiError, GetTransactionsRespData, Transaction } from 'api'
import { transactionsKeys } from './queryKeys'
import { useAccountId } from '../accounts/useAccountId'
import { errQueryMissingDependencies } from '../queryError'

function useTransactions<T = Transaction[]>(
  select?: (resp: GetTransactionsRespData) => T
) {
  const apiClient = useApiClient()
  const { data: accountId } = useAccountId()

  const queryKey = transactionsKeys.byAccount(accountId)

  return useQuery({
    queryKey,
    enabled: !isNil(accountId),
    queryFn: () => {
      if (isNil(accountId)) {
        return errQueryMissingDependencies(queryKey, { accountId })
      }

      return apiClient.getTransactions({
        accountID: accountId,
      })
    },
    select: resp => (select ? select(resp) : resp.transactions),
  }) as UseQueryResult<
    typeof select extends undefined ? Transaction[] : T,
    ApiError
  >
}

export { useTransactions }
