import { useMediaQuery } from '@mui/material'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type NavContextValue = {
  isNavOpen: boolean
  isMobile: boolean
  toggleNav: () => void
  expandedNavItem: string | undefined
  setExpandedNavItem: Dispatch<SetStateAction<string | undefined>>
}

const NavContext = createContext<NavContextValue | null>(null)

type NavProviderProps = {
  children: ReactNode
}

function NavProvider({ children }: NavProviderProps) {
  const [isOpenDesktop, setIsOpenDesktop] = useState(true)
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const [expandedNavItem, setExpandedNavItem] = useState<string>()
  const isMobile = !useMediaQuery('(min-width: 767px)')

  const toggleNav = useCallback(() => {
    if (isMobile) {
      setIsOpenMobile(prev => !prev)
    } else {
      setIsOpenDesktop(prev => !prev)
    }
  }, [isMobile])

  const isNavOpen = useMemo(() => {
    return (isMobile && isOpenMobile) || (!isMobile && isOpenDesktop)
  }, [isMobile, isOpenMobile, isOpenDesktop])

  return (
    <NavContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isNavOpen,
        isMobile,
        toggleNav,
        expandedNavItem,
        setExpandedNavItem,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

function useNav() {
  const ctx = useContext(NavContext)
  if (!ctx) {
    throw new Error('Error: Nav Context not available')
  }

  return ctx
}

export { useNav, NavProvider }
