function isNil(val: unknown): val is null | undefined {
  return val === null || val === undefined
}

function timedPromise<T = void>(args: {
  resp?: T
  ms: number
  shouldReject?: boolean
}): Promise<T | undefined> {
  const { resp, ms, shouldReject } = args

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (shouldReject) {
        reject()
      } else {
        resolve(resp)
      }
    }, ms)
  })
}

export { isNil, timedPromise }
