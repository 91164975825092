import Stack from '@mui/material/Stack'
import { useBrowserUrl } from 'routing'
import { useTranslations } from 'translations'
import { Button, ButtonVariant, Typography } from 'ui'
import { CardFundid } from 'ui-kit'
import {
  StyledCardFundidWrapper,
  StyledPageContainer,
} from './NotFoundPage.styles'

function NotFoundPage() {
  const t = useTranslations()
  const { goToDashboardPage } = useBrowserUrl()

  return (
    <StyledPageContainer helmetTitle={t('pages.notFoundPage.seo.title')}>
      <StyledCardFundidWrapper>
        <CardFundid />
      </StyledCardFundidWrapper>
      <Stack alignItems='center' spacing={2}>
        <Typography variant='h1'>{t('pages.notFoundPage.header')}</Typography>
        <Button
          label={t('pages.notFoundPage.button')}
          variant={ButtonVariant.Primary}
          onClick={goToDashboardPage}
        />
      </Stack>
    </StyledPageContainer>
  )
}

export { NotFoundPage }
