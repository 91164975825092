import { QueryDependency, QueryKey } from '../types'

const bankAccountsKeys = {
  all: ['bankAccounts'] as const,
  byCompanyId: (companyId: QueryDependency): QueryKey => [
    ...bankAccountsKeys.all,
    'companyId',
    companyId,
  ],
} as const

export { bankAccountsKeys }
