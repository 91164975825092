import { useQuery, UseQueryResult } from 'react-query'
import { ApiError, GetGrantsRespData, Grant } from 'api'
import { useApiClient } from 'lib/context'
import { grantKeys } from './queryKeys'

function useGrants<T = Grant[]>(select?: (resp: GetGrantsRespData) => T) {
  const apiClient = useApiClient()

  return useQuery({
    queryKey: grantKeys.all,
    queryFn: () => apiClient.getGrants(),
    select: resp => (select ? select(resp) : resp.grants),
  }) as UseQueryResult<typeof select extends undefined ? Grant[] : T, ApiError>
}

export { useGrants }
