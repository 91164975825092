import { Control } from 'react-hook-form'
import { useTranslations } from 'translations'
import {
  Button,
  ButtonVariant,
  DatePicker,
  SelectField,
  SelectOption,
} from 'ui'
import { StyledStack } from './OneTimePaymentForm.styles'
import { ButtonContainer } from '../OneTimePaymentButton.styles'
import { OneTimePaymentFormData } from '../useOneTimePaymentForm'

type OneTimePaymentFormProps = {
  control: Control<OneTimePaymentFormData>
  oneTimePaymentOptions: SelectOption[]
  bankAccountOptions: SelectOption[]
  isSubmitting: boolean
  onSubmit: () => void
}

function OneTimePaymentForm({
  control,
  oneTimePaymentOptions,
  bankAccountOptions,
  isSubmitting,
  onSubmit,
}: OneTimePaymentFormProps) {
  const t = useTranslations()

  return (
    <form onSubmit={onSubmit}>
      <StyledStack>
        <SelectField
          label={t('shared.oneTimePaymentButton.modal.form.amount')}
          name='amountType'
          control={control}
          options={oneTimePaymentOptions}
          required
        />

        <SelectField
          label={t('shared.oneTimePaymentButton.modal.form.payFrom')}
          name='bankAccountId'
          control={control}
          options={bankAccountOptions}
          required
        />

        <DatePicker
          label={t('shared.oneTimePaymentButton.modal.form.paymentDate')}
          name='paymentDate'
          control={control}
          required
          disablePast
        />

        <ButtonContainer>
          <Button
            label={t('shared.oneTimePaymentButton.modal.button')}
            variant={ButtonVariant.Primary}
            isLoading={isSubmitting}
            type='submit'
          />
        </ButtonContainer>
      </StyledStack>
    </form>
  )
}

export { OneTimePaymentForm }
