import styled from 'styled-components'
import { FormControl } from '@mui/material'

export const StyledCardWrapper = styled(FormControl)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;

  ${({ theme }) => theme.media.minTablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.5rem;
  }
`
