import { Field } from 'formik'
import { IconType } from 'ui-kit'
import { AdapterCheckboxButton } from '../../formik/AdapterCheckboxButton/AdapterCheckboxButton.component'

export enum ProductSelectionFormValuesTypes {
  LOANS = 'LOANS',
  GRANTS = 'GRANTS',
  CARD = 'CARD',
}

interface ProductSelectionRadioFieldProps {
  label: string
  name: string
  value: ProductSelectionFormValuesTypes
  id: string
  icon: IconType
  content: string
}

export const ProductSelectionCheckboxField = (
  props: ProductSelectionRadioFieldProps
) => {
  const { label, value, name, icon, content, id } = props

  return (
    <Field
      name={name}
      type='checkbox'
      label={label}
      id={id}
      value={value}
      icon={icon}
      content={content}
      component={AdapterCheckboxButton}
    />
  )
}
