import styled, { css } from 'styled-components'

const Fallback = styled.div<{ width: string; height: string }>`
  border-radius: 0.2rem;

  ${({ width, height, theme }) => {
    return css`
      width: ${width};
      height: ${height};
      background-color: ${theme.color.grey[400]};
    `
  }}
`

export { Fallback }
