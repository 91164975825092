import styled from 'styled-components'
import { Typography } from 'ui-kit'
import { TableCell } from '../Table/Table.styles'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.green[700]};
  caption {
    display: none;
  }
`

export const HeaderCell = styled(TableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.color.green[700]};
`

export const HeaderAmountCell = styled(HeaderCell)`
  padding-right: 3.5rem;
`

export const HeaderDateCell = styled(HeaderCell)`
  display: none;
  ${({ theme }) => theme.media.minTablet} {
    display: table-cell;
  }
`

export const NoTransactions = styled.p`
  ${Typography.headingH5};
  padding: 7.25rem 0;
  color: ${({ theme }) => theme.color.green[700]};
  text-align: center;
`
