import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, Company, GetCompanyRespData } from 'api'
import { isNil } from 'lib/utils'
import { companyKeys } from './queryKeys'
import { useUser } from '../users/useUser'
import { errQueryMissingDependencies } from '../queryError'

function useCompany<T = Company>(select?: (resp: GetCompanyRespData) => T) {
  const apiClient = useApiClient()
  const { data: user } = useUser()

  const queryKey = companyKeys.byUserId(user?.id)

  return useQuery({
    queryKey,
    enabled: !isNil(user?.id) && !isNil(user?.companyID), // only run query if we expect to find a company
    queryFn: () => {
      if (user?.id === undefined) {
        return errQueryMissingDependencies(queryKey, {
          userId: user?.id,
        })
      }

      return apiClient.getCompany({
        userID: user.id,
      })
    },
    select: resp => (select ? select(resp) : resp.company),
  }) as UseQueryResult<typeof select extends undefined ? Company : T, ApiError>
}

export { useCompany }
