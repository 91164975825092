import { createContext, ReactNode, useContext, useMemo } from 'react'
import { ApiClient } from 'api'

type ApiClientProviderProps = {
  apiClient: ApiClient
  children: ReactNode
}

type ApiClientContextType = {
  apiClient: ApiClient
}

const ApiClientContext = createContext<ApiClientContextType | undefined>(
  undefined
)

function ApiClientProvider({ apiClient, children }: ApiClientProviderProps) {
  const value = useMemo(
    () => ({
      apiClient,
    }),
    [apiClient]
  )

  return (
    <ApiClientContext.Provider value={value}>
      {children}
    </ApiClientContext.Provider>
  )
}

/**
 * `useApiClient` is a hook that allows callers to consume from the `ApiClientContext`.
 */
function useApiClient(): ApiClient {
  const context = useContext(ApiClientContext)
  if (context === undefined) {
    throw new Error(
      'useApiClient must be called by a child of an ApiClientProvider'
    )
  }

  return context.apiClient
}

export { ApiClientProvider, useApiClient }
