import { UseMutationResult, useMutation } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, SignUpReqBody } from 'api'

function useSignUp(): UseMutationResult<void, ApiError, SignUpReqBody> {
  const apiClient = useApiClient()

  return useMutation(args => {
    return apiClient.signUp(args)
  })
}

export { useSignUp }
