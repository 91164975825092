import { Card, CardStatus } from 'api'

function isCardActivated(card: Card | null) {
  return (
    card?.status === CardStatus.Active || card?.status === CardStatus.Frozen
  )
}

function isCardNotClosed(card: Card) {
  return card.status !== CardStatus.Closed
}

function cardNumberLast4ToChunks(cardNumberLast4: string): string[] {
  return ['••••', '••••', '••••', cardNumberLast4]
}

function cardNumberToChunks(cardNumber: string): string[] {
  const n = 4

  // Split the string into an array of characters
  const chars = cardNumber.split('')

  // Reduce chars into chunks
  return chars.reduce((acc, _, i) => {
    if (i % n === 0) {
      const chunk = chars.slice(i, i + n).join('')
      return [...acc, chunk]
    }

    return acc
  }, [] as string[])
}

export {
  isCardActivated,
  isCardNotClosed,
  cardNumberLast4ToChunks,
  cardNumberToChunks,
}
