import { Field } from 'formik'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { isPast } from 'date-fns'
import { AdapterInput } from 'form-kit'
import { useTranslations } from 'translations'

const ReadableFieldName = <FormattedMessage id='formKit.birthDateField.label' />

export const birthDateFieldValidationSchema = yup
  .date()
  .typeError(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))
  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))
  .test('is-in-the-past', '', (value, { createError, path }) => {
    if (value && isPast(value)) {
      return true
    }
    return createError({
      path,
      message: () => (
        <FormattedMessage
          id='formKit.validation.dateInPast'
          values={{
            fieldName: ReadableFieldName,
          }}
        />
      ),
    })
  })

export const BirthDateField = () => {
  const t = useTranslations()

  return (
    <Field
      name='dateOfBirth'
      label={t('formKit.birthDateField.label')}
      type='date'
      isRequired
      max={new Date()}
      component={AdapterInput}
    />
  )
}
