import { ReactNode } from 'react'
import styled from 'styled-components'
import { paragraphCaptions } from 'ui-kit/Typography/Typography.styles'

const StyledHintMessage = styled.p`
  ${paragraphCaptions};
  padding-top: 0.25rem;
  padding-left: 1rem;
`

interface HintMessageProps {
  children: ReactNode
}
export const HintMessage = (props: HintMessageProps) => {
  const { children } = props

  return <StyledHintMessage>{children}</StyledHintMessage>
}
