import { useUser } from 'lib/queries'
import { useTranslations } from 'translations'

function usePersonalFields() {
  const t = useTranslations()

  return useUser(resp => {
    const { name, email, phone } = resp.user

    return [
      {
        label: t('pages.newProfile.personal.fields.firstName'),
        value: name.first,
      },
      {
        label: t('pages.newProfile.personal.fields.lastName'),
        value: name.last,
      },
      {
        label: t('pages.newProfile.personal.fields.email'),
        value: email,
      },
      {
        label: t('pages.newProfile.personal.fields.phone'),
        value: phone || '',
      },
    ]
  })
}

export { usePersonalFields }
