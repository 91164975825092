import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { RoutesPaths } from 'routing'
import { Icon, IconType } from '../Icons/Icon.component'
import { Alert } from '../Alert/Alert.component'

const StyledLink = styled.a`
  font-weight: bold;
`

export const AdminCardDisabledAlert = () => {
  return (
    <Alert variant='error' icon={<Icon type={IconType.lock} />}>
      <p>
        <span>
          <FormattedMessage id='uiKit.accountAlert.adminCardDisabled.message' />
        </span>
        <StyledLink href={RoutesPaths.ContactSupport}>
          <FormattedMessage id='uiKit.accountAlert.adminCardDisabled.linkLabel' />
        </StyledLink>
      </p>
    </Alert>
  )
}
