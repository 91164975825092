import MUIPaper from '@mui/material/Paper'
import { ReactNode } from 'react'
import styled from 'styled-components'

type PaperProps = {
  children: ReactNode
  className?: string
}

const StyledPaper = styled(MUIPaper)`
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
`

export function Paper({ children, className }: PaperProps) {
  return <StyledPaper className={className}>{children}</StyledPaper>
}
