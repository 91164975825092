import { ReactElement, ReactNode, useCallback } from 'react'
import { useIntl } from 'react-intl'

type X = string | ReactElement

export interface TranslationsValues {
  [key: string]: string | number | Date | ((args: ReactNode[]) => ReactElement)
}

export type Translate<T extends X = string> = (
  path: string,
  values?: TranslationsValues
) => T

export const useTranslations = <T extends X = string>() => {
  const { formatMessage } = useIntl()

  const translate: Translate<T> = useCallback(
    (path: string, values?: TranslationsValues) =>
      formatMessage({ id: path }, values) as T,
    [formatMessage]
  )

  return translate
}
