import {
  GetRequestArguments,
  PatchRequestArguments,
  PostRequestArguments,
  PutRequestArguments,
  DeleteRequestArguments,
} from './Http.types'
import { jointUrlsWithSlash } from '../utils/joinUrlsWithSlash.utils'
import { sendRequest } from '../axios/SendRequest.service'

export const httpService = (baseUrl: string) => {
  const get = async <TResponse>({
    endpoint,
    params,
    responseType,
  }: GetRequestArguments) => {
    const requestUrl = jointUrlsWithSlash(baseUrl, endpoint)

    return sendRequest<TResponse>({
      method: 'GET',
      url: requestUrl,
      params,
      responseType,
    })
  }

  const post = async <TResponse, TRequest = unknown>({
    endpoint,
    requestBody,
    headers,
  }: PostRequestArguments<TRequest>) => {
    const requestUrl = jointUrlsWithSlash(baseUrl, endpoint)

    return sendRequest<TResponse>({
      method: 'POST',
      url: requestUrl,
      body: requestBody,
      headers,
    })
  }

  const patch = async <TResponse, TRequest>({
    requestBody,
    endpoint,
    headers,
  }: PatchRequestArguments<TRequest>) => {
    const requestUrl = jointUrlsWithSlash(baseUrl, endpoint)

    return sendRequest<TResponse>({
      method: 'PATCH',
      url: requestUrl,
      body: requestBody,
      headers,
    })
  }

  const put = async <TResponse, TRequest>({
    endpoint,
    headers,
    requestBody,
  }: PutRequestArguments<TRequest>) => {
    const requestUrl = jointUrlsWithSlash(baseUrl, endpoint)

    return sendRequest<TResponse>({
      method: 'PUT',
      url: requestUrl,
      body: requestBody,
      headers,
    })
  }

  //   delete is restricted keyword, so method is called remove
  const remove = async <TResponse, TRequest>({
    endpoint,
    requestBody,
    headers,
  }: DeleteRequestArguments<TRequest>) => {
    const requestUrl = jointUrlsWithSlash(baseUrl, endpoint)

    return sendRequest<TResponse>({
      method: 'DELETE',
      url: requestUrl,
      body: requestBody,
      headers,
    })
  }

  return {
    get,
    post,
    patch,
    put,
    remove,
  }
}
