import { ChangeEventHandler, forwardRef } from 'react'
import NumberFormat from 'react-number-format'

interface InputCurrencyProps {
  onChange?: ChangeEventHandler<HTMLInputElement>
  name?: string
}

export const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(
  (props, ref) => {
    const { onChange, name, ...other } = props

    return (
      <NumberFormat
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...other}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.source !== 'event') {
            return
          }
          // Overwriting the `value` attribute, as it includes additional `$` which is added by NumberFormat component
          // For more, checkout MUI docs - https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
          onChange?.({
            ...sourceInfo.event,
            target: {
              ...sourceInfo.event.target,
              name: sourceInfo.event.target.name || name || '',
              value: values.value,
            },
          })
        }}
        allowNegative={false}
        decimalScale={2}
        getInputRef={ref}
        isNumericString
        // TODO: Change to depend on user's currency
        prefix='$'
      />
    )
  }
)
