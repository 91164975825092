import { KeyboardEvent } from 'react'

const enterCode = 'Enter'

export const handleEnterKeyPress = (e: KeyboardEvent, callback: () => void) => {
  e.preventDefault()

  if (e.code === enterCode) {
    callback()
  }
}
