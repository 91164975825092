import { useState } from 'react'
import { dateFormatter } from 'lib/formatter'
import { useAccountId, useMakeOneTimePayment } from 'lib/queries'
import { useTranslations } from 'translations'
import {
  Button,
  Modal,
  ModalTitle,
  ButtonVariant,
  Tooltip,
  Typography,
} from 'ui'
import { useSnackbars } from 'ui-kit'
import {
  OneTimePaymentFormData,
  useOneTimePaymentForm,
} from './useOneTimePaymentForm'
import { OneTimePaymentSuccess } from './components/OneTimePaymentSuccess.component'
import { OneTimePaymentForm } from './components/OneTimePaymentForm.component'

function OneTimePaymentButton() {
  const t = useTranslations()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { showErrorSnackbar } = useSnackbars()
  const { data: accountId } = useAccountId()
  const {
    form: { control, handleSubmit, reset: resetForm },
    oneTimePaymentOptions,
    bankAccountOptions,
    isLoading: isLoadingOneTimePaymentForm,
  } = useOneTimePaymentForm()
  const {
    mutate: makeOneTimePayment,
    isLoading: isMakingOneTimePayment,
    isSuccess: isSuccessMakeOneTimePayment,
    reset: resetMakeOneTimePayment,
  } = useMakeOneTimePayment()

  if (isLoadingOneTimePaymentForm || !accountId) {
    return null
  }

  const onSubmit = ({
    amountType,
    bankAccountId,
    paymentDate,
  }: OneTimePaymentFormData) => {
    makeOneTimePayment(
      {
        accountID: accountId,
        amountType,
        bankAccountID: Number(bankAccountId),
        date: dateFormatter.toFundidDate(paymentDate),
      },
      {
        onError: () =>
          showErrorSnackbar(t('shared.oneTimePaymentButton.error')),
      }
    )
  }

  const onClose = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      resetForm()
      resetMakeOneTimePayment()
    }, 100)
  }

  const isMakePaymentButtonDisabled = oneTimePaymentOptions.length === 0

  return (
    <>
      <Tooltip
        title={t('shared.oneTimePaymentButton.button.tooltip')}
        isDisabled={!isMakePaymentButtonDisabled}
      >
        <Button
          variant={ButtonVariant.Primary}
          label={t('shared.oneTimePaymentButton.button.label')}
          isDisabled={isMakePaymentButtonDisabled}
          onClick={() => setIsModalOpen(prev => !prev)}
        />
      </Tooltip>

      <Modal isOpen={isModalOpen} onClose={onClose}>
        <ModalTitle>
          <Typography variant='h3'>
            {isSuccessMakeOneTimePayment
              ? t('shared.oneTimePaymentButton.modal.success.title')
              : t('shared.oneTimePaymentButton.modal.title')}
          </Typography>
        </ModalTitle>

        {isSuccessMakeOneTimePayment ? (
          <OneTimePaymentSuccess onClose={onClose} />
        ) : (
          <OneTimePaymentForm
            control={control}
            oneTimePaymentOptions={oneTimePaymentOptions}
            bankAccountOptions={bankAccountOptions}
            isSubmitting={isMakingOneTimePayment}
            onSubmit={handleSubmit(onSubmit)}
          />
        )}
      </Modal>
    </>
  )
}

export { OneTimePaymentButton }
