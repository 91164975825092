/* eslint-disable react/prop-types */
import { ReactNode, forwardRef, CSSProperties, FunctionComponent } from 'react'
import { closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack'
import { FormattedMessage } from 'react-intl'
import { RoutesPaths } from '../../routing/components/Routes/Routes.consts'
import { Alert, CloseButton } from '../Alert/Alert.component'
import { ActionLink } from '../Link/Link.styles'

type SnackbarAction = 'CONTACT_SUPPORT'

interface SnackbarActionProps {
  onClick: () => void
}

const SnackbarActionMap: Record<
  SnackbarAction,
  FunctionComponent<SnackbarActionProps>
> = {
  CONTACT_SUPPORT: ({ onClick }) => (
    <>
      <ActionLink onClick={onClick} to={RoutesPaths.ContactSupport}>
        <FormattedMessage id='uiKit.snackbar.action.contactSupport' />
      </ActionLink>
      <CloseButton onClick={onClick} />
    </>
  ),
}

type SnackbarProperties = {
  message?: string
  translation?: { id: string; values?: Record<string, ReactNode> }
  testId?: string
  action?: SnackbarAction
}

declare module 'notistack' {
  interface VariantOverrides {
    default: false
    error: SnackbarProperties
    warning: SnackbarProperties
    success: SnackbarProperties
    info: SnackbarProperties
  }
}

export interface CustomSnackbarProps
  extends Omit<
      CustomContentProps,
      'action' | 'iconVariant' | 'hideIconVariant' | 'style'
    >,
    SnackbarProperties {
  style?: CSSProperties
}

export const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => {
    const {
      id,
      message,
      variant,
      translation,
      action,
      testId,
      className,
      style,
    } = props

    const handleClose = () => closeSnackbar(id)

    const Action = action && SnackbarActionMap[action]
    const actionComponent = Action ? (
      <Action onClick={handleClose} />
    ) : undefined

    return (
      <SnackbarContent
        ref={ref}
        role='alert'
        className={className}
        style={style}
      >
        <Alert
          data-testid={testId}
          variant={variant}
          action={actionComponent}
          onClose={handleClose}
        >
          {translation ? (
            <FormattedMessage id={translation.id} values={translation.values} />
          ) : (
            message
          )}
        </Alert>
      </SnackbarContent>
    )
  }
)
