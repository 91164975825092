import { FormattedMessage } from 'react-intl'
import { Container, Link } from './KnowledgeBase.styles'
import { LINKS } from './KnowledgeBase.consts'
import { Icon } from '../Icons/Icon.component'

export const KnowledgeBase = () => {
  return (
    <Container>
      {LINKS.map(({ translationKey, href, icon }) => (
        <Link
          key={translationKey}
          href={href}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon type={icon} />
          <FormattedMessage id={translationKey} />
        </Link>
      ))}
    </Container>
  )
}
