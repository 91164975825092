import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'

export const StyledIconButton = styled(IconButton)`
  &.Mui-focusVisible {
    outline-offset: 0;
    outline: ${({ theme }) => theme.color.primary} solid 2px;
  }
  svg,
  path {
    fill: currentColor;
  }
`
