import { ReactNode, useContext, createContext, useCallback } from 'react'
import {
  SnackbarProvider as NotistackSnackProvider,
  enqueueSnackbar,
  OptionsObject,
} from 'notistack'
import { useMediaQuery } from '@mui/material'
import { useTheme } from 'styled-components'
import { CustomSnackbar, CustomSnackbarProps } from './Snackbar.component'

interface SnackbarOptions extends Pick<OptionsObject, 'persist'> {
  action?: CustomSnackbarProps['action']
}

interface SnackbarContextType {
  showSuccessSnackbar: (message: string) => void
  showErrorSnackbar: (message: string, options?: SnackbarOptions) => void
}

interface SnackbarProviderProps {
  children: ReactNode
}

const SnackbarContext = createContext<SnackbarContextType | null>(null)

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const showSuccessSnackbar = useCallback((message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
      testId: 'successSnackbar',
      preventDuplicate: true,
    })
  }, [])

  const showErrorSnackbar = useCallback(
    (message: string, options: SnackbarOptions = {}) => {
      enqueueSnackbar(message, {
        variant: 'error',
        testId: 'errorSnackbar',
        preventDuplicate: true,
        ...options,
      })
    },
    []
  )

  const theme = useTheme()
  const matches = useMediaQuery(
    `${theme.media.minTablet.replace('@media', '')}` // remove @media to match the MUI pattern
  )

  return (
    <NotistackSnackProvider
      preventDuplicate
      maxSnack={1}
      autoHideDuration={6000}
      anchorOrigin={
        !matches
          ? { vertical: 'bottom', horizontal: 'center' }
          : { vertical: 'top', horizontal: 'right' }
      }
      Components={{
        success: CustomSnackbar,
        error: CustomSnackbar,
        warning: CustomSnackbar,
        info: CustomSnackbar,
      }}
    >
      <SnackbarContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{ showErrorSnackbar, showSuccessSnackbar }}
      >
        {children}
      </SnackbarContext.Provider>
    </NotistackSnackProvider>
  )
}

export const useSnackbars = () => {
  const ctx = useContext(SnackbarContext)
  if (!ctx) {
    throw new Error('Error: SnackbarContext not available')
  }
  return ctx
}
