import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import {
  MfaApi,
  MfaDeleteOneRequest,
  MfaEmergencyRequest,
  MfaResendRequest,
  MfaVerifyRequest,
} from 'api-connectors'
import { useLogger } from 'logger'
import { useSnackbars } from 'ui-kit'
import { isAxiosError, isServerError } from 'error-handling'
import { useTranslations } from 'translations'

const verifyCode = async (payload: MfaVerifyRequest) => {
  const { verify: mfaVerify } = MfaApi()
  const response = await mfaVerify(payload)
  return response.data
}

const MUTATION_VERIFY_KEY = 'mfa-verify'
export const useMfaVerify = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_VERIFY_KEY, verifyCode, {
    retry: 0,
    onError: handleError,
  })
}

const saveDevice = async () => {
  const { remember: mfaRemember } = MfaApi()
  const response = await mfaRemember()
  return response.data
}

const MUTATION_REMEMBER_KEY = 'mfa-remember'
export const useMfaRemember = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_REMEMBER_KEY, saveDevice, {
    retry: 0,
    onError: handleError,
  })
}

const enable = async (payload: MfaVerifyRequest) => {
  const { enable: mfaEnable } = MfaApi()
  const response = await mfaEnable(payload)
  return response.data
}

const MUTATION_ENABLE_KEY = 'mfa-enable'
export const useMfaEnable = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_ENABLE_KEY, enable, {
    retry: 0,
    onError: handleError,
  })
}
const resend = async (payload: MfaResendRequest) => {
  const { resend: mfaResend } = MfaApi()
  const response = await mfaResend(payload)
  return response.data
}

const MUTATION_RESEND_KEY = 'mfa-resend'
export const useMfaResend = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_RESEND_KEY, resend, {
    retry: 0,
    onError: handleError,
  })
}

const list = async () => {
  const { list: mfaList } = MfaApi()
  const response = await mfaList()
  return response.data
}

const QUERY_LIST_KEY = 'mfa-list'
export const useMfaList = (options: Pick<UseQueryOptions, 'enabled'> = {}) => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useQuery(QUERY_LIST_KEY, list, {
    onError: handleError,
    ...options,
  })
}

const deleteAll = async () => {
  const { deleteAll: mfaDeleteAll } = MfaApi()
  const response = await mfaDeleteAll()
  return response.data
}

const MUTATION_DELETE_ALL_KEY = 'mfa-deleteAll'
export const useMfaDeleteAll = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()
  const client = useQueryClient()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_DELETE_ALL_KEY, deleteAll, {
    retry: 0,
    onError: handleError,
    onSuccess: () => {
      return client.refetchQueries(QUERY_LIST_KEY)
    },
  })
}
const deleteOne = async (payload: MfaDeleteOneRequest) => {
  const { deleteOne: mfaDeleteOne } = MfaApi()
  const response = await mfaDeleteOne(payload)
  return response.data
}

const MUTATION_DELETE_ONE_KEY = 'mfa-deleteOne'
export const useMfaDeleteOne = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()
  const client = useQueryClient()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_DELETE_ONE_KEY, deleteOne, {
    retry: 0,
    onError: handleError,
    onSuccess: () => {
      return client.refetchQueries(QUERY_LIST_KEY)
    },
  })
}

const emergency = async (payload: MfaEmergencyRequest) => {
  const { emergency: mfaEmergency } = MfaApi()
  const response = await mfaEmergency(payload)
  return response.data
}

const MUTATION_EMERGENCY_KEY = 'mfa-emergency'
export const useMfaEmergency = () => {
  const { logger } = useLogger()
  const { showErrorSnackbar } = useSnackbars()
  const t = useTranslations()

  const handleError = (err: unknown) => {
    let uiMessage = ''
    if (isAxiosError(err)) {
      if (isServerError(err)) {
        uiMessage = t('errorMessages.SYSTEM_FAILURE')
      } else {
        uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      }
      logger.error(uiMessage, { error: err.response?.data })
    } else {
      uiMessage = t('errorMessages.UNEXPECTED_ERROR')
      logger.error(uiMessage, { error: err })
    }

    showErrorSnackbar(uiMessage)
  }

  return useMutation(MUTATION_EMERGENCY_KEY, emergency, {
    retry: 0,
    onError: handleError,
  })
}
