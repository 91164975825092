import { PaymentAmountType } from 'api'
import { Translate, useTranslations } from 'translations'
import { useFormatCurrency } from 'lib/formatter'
import { useAccount } from '../accounts/useAccount'

type OneTimePaymentOption = {
  label: string
  value: PaymentAmountType
}

type OneTimePaymentValues = {
  currentBalance: number
  remainingStatementBalance: number
  minimumPaymentDue: number
}

function getOneTimePaymentAmountsByTypeMap(
  opts: OneTimePaymentOption[],
  {
    currentBalance,
    remainingStatementBalance,
    minimumPaymentDue,
  }: OneTimePaymentValues
) {
  const m: Map<PaymentAmountType, number> = new Map()
  opts.forEach(({ value: paymentType }) => {
    let amount = 0
    switch (paymentType) {
      case PaymentAmountType.CurrentBalance:
        amount = currentBalance
        break
      case PaymentAmountType.MinDue:
        amount = minimumPaymentDue
        break
      case PaymentAmountType.RemainingStatementBalance:
        amount = remainingStatementBalance
        break
      default:
        break
    }

    m.set(paymentType, amount)
  })

  return m
}

function getOneTimePaymentOptions(
  t: Translate<string>,
  formatCurrency: (val: number) => string,
  {
    currentBalance,
    remainingStatementBalance,
    minimumPaymentDue,
  }: OneTimePaymentValues
) {
  const opts: OneTimePaymentOption[] = []
  if (currentBalance > 0) {
    opts.push({
      label: `${t('domain.currentBalance')} (${formatCurrency(
        currentBalance
      )})`,
      value: PaymentAmountType.CurrentBalance,
    })
  }

  if (remainingStatementBalance > 0) {
    opts.push({
      label: `${t('domain.statementBalance')} (${formatCurrency(
        remainingStatementBalance
      )})`,
      value: PaymentAmountType.RemainingStatementBalance,
    })
  }

  if (minimumPaymentDue > 0) {
    opts.push({
      label: `${t('domain.minimumPaymentDue')} (${formatCurrency(
        minimumPaymentDue
      )})`,
      value: PaymentAmountType.MinDue,
    })
  }

  return opts
}

function useOneTimePaymentOptions() {
  const t = useTranslations()
  const formatCurrency = useFormatCurrency()

  return useAccount(resp => {
    const { account } = resp
    const currentBalance = Number(account.currentBalance)
    const remainingStatementBalance = Number(
      account.lastStatement?.remainingBalance
    )
    const minimumPaymentDue = Number(account.paymentDue?.minimum)

    const oneTimePaymentOptions = getOneTimePaymentOptions(t, formatCurrency, {
      currentBalance,
      remainingStatementBalance,
      minimumPaymentDue,
    })

    const oneTimePaymentAmountsByType = getOneTimePaymentAmountsByTypeMap(
      oneTimePaymentOptions,
      { currentBalance, remainingStatementBalance, minimumPaymentDue }
    )

    return {
      oneTimePaymentOptions,
      oneTimePaymentAmountsByType,
    }
  })
}

export type { OneTimePaymentOption }
export { useOneTimePaymentOptions }
