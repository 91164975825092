import { FundidDate, Partner } from './shared'

enum GrantsEndpoints {
  GetGrants = '/grants',
}

// Grant is a form of funding for businesses. Grants are provided by Grantors.
type Grant = {
  id: number
  partner: Partner
  name: string
  states: string[]
  openDate: FundidDate
  closeDate: FundidDate
  amountMin: number
  amountMax: number
  url: string
  description: string
  qualifications: string[]
  tagWomen: boolean
  tagPersonOfColor: boolean
  tagGovernment: boolean
  tagFeatured: boolean
}

type GetGrantsQueryParams = {
  active: boolean
}

type GetGrantsRespData = {
  grants: Grant[]
}

export type { GetGrantsRespData, Grant, GetGrantsQueryParams }
export { GrantsEndpoints }
