// import { Route, Routes as ReactRouterRoutes, Outlet } from 'react-router-dom'
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom'
import {
  ActivateAccountPage,
  ConfirmEmailPage,
  ContactSupportPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
  RegisterPage,
  ResetPasswordCheckEmailPage,
  SetNewPasswordPage,
  ProfilePage,
  // StatementsPage,
  // CardsPage,
  DashboardPage,
  // BusinessBuildingCardPage,
  // PaymentsPage,
  // ActivateCardPage,
  // TransactionsPage,
  GrantsPage,
  CapitalMarketplacePage,
  GrowthPartnersPage,
  NewLoginPage,
  NewSignUpPage,
  NewForgotPasswordPage,
  NewResetPasswordPage,
  NewEmailVerificationPage,
} from 'pages'
import { LayoutMain } from 'shared'
import { ErrorBoundary } from 'ui'
import { RoutesPaths } from './Routes.consts'
import { RequireAuth } from '../RequireAuth/RequireAuth.component'
import { BlockAuthenticated } from '../BlockAuthenticated/BlockAuthenticated.component'
// import { CreditAccountGuard } from '../CreditAccountGuard'

// const creditAccountHoldersOnlyRoutes = (
//   <Route
//     element={
//       <CreditAccountGuard>
//         <Outlet />
//       </CreditAccountGuard>
//     }
//   >
//     <Route
//       path={`${RoutesPaths.ActivateCard}/:cardId`}
//       element={<ActivateCardPage />}
//     />
//     <Route path={RoutesPaths.Cards} element={<CardsPage />} />
//     <Route path={RoutesPaths.Payments} element={<PaymentsPage />} />
//     <Route path={RoutesPaths.Statements} element={<StatementsPage />} />
//     <Route path={RoutesPaths.Transactions} element={<TransactionsPage />} />
//   </Route>
// )

// const nonCreditAccountHoldersOnlyRoutes = (
//   <Route
//     element={
//       <CreditAccountGuard blockHasCreditAccount>
//         <Outlet />
//       </CreditAccountGuard>
//     }
//   >
//     <Route
//       path={RoutesPaths.BusinessBuildingCard}
//       element={<BusinessBuildingCardPage />}
//     />
//   </Route>
// )

const creditAccountNotRequiredRoutes = (
  <>
    <Route path={RoutesPaths.ContactSupport} element={<ContactSupportPage />} />
    <Route path={RoutesPaths.Dashboard} element={<DashboardPage />} />
    <Route
      path={RoutesPaths.CapitalMarketplace}
      element={<CapitalMarketplacePage />}
    />
    <Route path={RoutesPaths.Grants} element={<GrantsPage />} />
    <Route path={RoutesPaths.GrowthPartners} element={<GrowthPartnersPage />} />
    <Route path={RoutesPaths.Profile} element={<ProfilePage />} />
  </>
)

const authRoutes = (
  <>
    <Route
      path={RoutesPaths.ActivateAccount}
      element={<ActivateAccountPage />}
    />
    <Route path={RoutesPaths.ConfirmEmail} element={<ConfirmEmailPage />} />
    <Route path={RoutesPaths.ForgotPassword} element={<ForgotPasswordPage />} />
    <Route
      path={RoutesPaths.ForgotPasswordCheckEmail}
      element={<ResetPasswordCheckEmailPage />}
    />
    <Route
      path={RoutesPaths.Login}
      element={
        <BlockAuthenticated>
          <LoginPage />
        </BlockAuthenticated>
      }
    />
    <Route path={RoutesPaths.Register} element={<RegisterPage />} />
    <Route path={RoutesPaths.SetNewPassword} element={<SetNewPasswordPage />} />

    <Route path={RoutesPaths.NewLogin} element={<NewLoginPage />} />
    <Route path={RoutesPaths.NewSignUp} element={<NewSignUpPage />} />
    <Route
      path={RoutesPaths.NewForgotPassword}
      element={<NewForgotPasswordPage />}
    />
    <Route
      path={RoutesPaths.NewResetPassword}
      element={<NewResetPasswordPage />}
    />
    <Route
      path={RoutesPaths.NewEmailVerification}
      element={<NewEmailVerificationPage />}
    />
  </>
)

function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path={RoutesPaths.NotFound} element={<NotFoundPage />} />
      {authRoutes}
      <Route
        element={
          <ErrorBoundary>
            <RequireAuth>
              <LayoutMain />
            </RequireAuth>
          </ErrorBoundary>
        }
      >
        {/* {creditAccountHoldersOnlyRoutes} */}
        {/* {nonCreditAccountHoldersOnlyRoutes} */}
        {creditAccountNotRequiredRoutes}
      </Route>
    </ReactRouterRoutes>
  )
}

export { Routes }
