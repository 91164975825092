import { Grant as ApiGrant } from 'api'
import { USState } from './states'
import { AmountFilter, getAmountFilters } from './amounts'
import { Tag, getTagSet } from './tags'

type Grant = Omit<ApiGrant, 'states'> & {
  amountFilters: Set<AmountFilter>
  states: Set<USState>
  tags: Set<Tag>
  isFeatured: boolean
}

function apiGrantToGrant(grant: ApiGrant): Grant {
  const {
    amountMin,
    amountMax,
    states,
    tagGovernment,
    tagPersonOfColor,
    tagWomen,
    tagFeatured,
  } = grant

  return {
    ...grant,
    amountFilters: getAmountFilters(amountMin, amountMax),
    states: new Set(states) as Set<USState>,
    tags: getTagSet({
      tagGovernment,
      tagPersonOfColor,
      tagWomen,
    }),
    isFeatured: tagFeatured,
  }
}

export type { Grant }
export { apiGrantToGrant }
