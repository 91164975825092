import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link'
import React from 'react'
import styled from 'styled-components'
import { TypographyVariant, typographyStyles } from 'ui'

type LinkVariant = Extract<TypographyVariant, 'body1' | 'caption'>

const StyledLink = styled(MUILink)<
  MUILinkProps & { $variant: LinkVariant; component?: React.ElementType }
>`
  ${({ $variant }) => {
    return typographyStyles[$variant]
  }};

  :hover {
    cursor: pointer;
  }
`

export type { LinkVariant }
export { StyledLink }
