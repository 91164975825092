import { Components, Theme } from '@mui/material'

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme: baseTheme }) => ({
      backgroundColor: baseTheme.color.background,
      color: baseTheme.color.green[600],
      boxShadow: baseTheme.shadows[2],
      fontSize: '0.8125rem',
      padding: '0.5rem 1rem',
      fontFamily: baseTheme.fontFamily.body,
      borderRadius: '0.5rem',
    }),
    popper: {
      '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom':
        {
          marginTop: 0,
        },
    },
  },
}
