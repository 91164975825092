import MUIFormControlLabel from '@mui/material/FormControlLabel'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  .MuiTypography-root {
    ${typographyStyles.body1};
  }
`

export { StyledFormControlLabel }
