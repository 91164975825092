import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from './axiosInstance'
import { SendRequestArguments } from './SendRequest.types'

export const sendRequest = async <Response>({
  method,
  url,
  headers,
  body,
  params,
  responseType,
}: SendRequestArguments) => {
  const request: AxiosRequestConfig = {
    method,
    url,
    data: body,
    headers,
    params,
    responseType,
  }

  const response = await axiosInstance.request<Response>(request)

  return response
}
