import { Components, createTheme } from '@mui/material'
import { DefaultTheme } from 'styled-components'
import { components } from './components'

export const theme: DefaultTheme = createTheme({
  color: {
    white: '#FFFFFF',
    black: '#000000',
    background: '#F0EDE6',
    backgroundBlue: '#ECF2F6',
    backgroundGreen: '#5A7269',
    primary: '#42574F',
    secondary: '#96BF17',
    accent: '#FA7617',
    transparent: 'transparent',
    red: {
      error: '#D11B0D',
      '200': '#f6d1cf',
      '300': '#f5b5b2',
      '500': '#e88c86',
    },
    alabaster: {
      '600': '#E1DECF',
      '700': '#D4CEB9',
    },
    aliceBlue: {
      '600': '#CDDDE7',
      '700': '#AEC7D8',
      '800': '#8FB2CA',
    },
    apple: {
      '50': '#DDF39C',
      '100': '#D7F08A',
      '200': '#CBEC66',
      '300': '#BEE741',
      '400': '#B2E31D',
      '700': '#769613',
    },
    green: {
      '50': '#E1E7E5',
      '100': '#D1DBD7',
      '200': '#B2C2BC',
      '300': '#93AAA1',
      '400': '#739186',
      '500': '#5a726a',
      '600': '#2E3D37',
      '700': '#1A2320',
    },
    grey: {
      '300': '#FAFAFA',
      '400': '#F4F4F4',
      '500': '#E0E0E0',
      '600': '#C9C9C9',
      '700': '#B2B2B2',
      '800': '#9B9B9B',
      '900': '#848484',
    },
    pumpkin: {
      '50': '#FEE0CB',
      '100': '#FDD4B7',
      '200': '#FDBC8F',
      '300': '#FCA567',
      '400': '#FB8D3F',
      '600': '#DE5F05',
    },
    warning: {
      text: '#663C00',
    },
    info: {
      text: '#014361',
    },
  },
  fontFamily: {
    headline: 'Geller',
    body: 'Inter',
  },
  shadowVariants: {
    selectableTile: `0px 2px 4px -2px rgba(66, 87, 79, 0.12),
    0px 4px 4px -2px rgba(66, 87, 79, 0.08)`,
    selectedTile: '0 4px 6px 0 rgb(0 0 0 / 12%)',
  },
  media: {
    minTablet: '@media(min-width: 767px)',
    minDesktop: '@media(min-width: 1200px)',
  },
  transition: '0.3s cubic-bezier(0.17, 0.67, 0.31, 1.04)',
  // Unfortunately, this needs to be overridden as there's no way to override Theme in Components which is in ThemeOptions.
  components: components as Components,
})
