import { useAccount } from 'lib/queries'
import { CreditSummaryCardProps } from 'shared'

function useDashboardCreditSummaryCards() {
  return useAccount(resp => {
    const { currentBalance, availableCredit } = resp.account

    return [
      {
        type: 'currentBalance',
        value: currentBalance,
      },
      {
        type: 'availableCredit',
        value: availableCredit,
      },
    ] as CreditSummaryCardProps[]
  })
}

export { useDashboardCreditSummaryCards }
