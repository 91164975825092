import styled from 'styled-components'
import { Chip } from 'ui'

const Container = styled.div`
  aspect-ratio: 20/12.25;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
`

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.color.grey[500]};
  background-color: rgb(0, 0, 0, 0.3);
`

export { Container, StyledChip }
