import Stack from '@mui/material/Stack'
import { numberFormatter, useFormatCurrency } from 'lib/formatter'
import { Card, Typography } from 'ui'
import {
  H1Currency,
  H2Currency,
  StyledCardContent,
} from './BaseCreditSummaryCard.styles'

type BaseCreditSummaryCardProps = {
  label: string
  value: number
}

function BaseCreditSummaryCard({ label, value }: BaseCreditSummaryCardProps) {
  const formatCurrency = useFormatCurrency()

  return (
    <Card>
      <StyledCardContent>
        <Stack spacing={1}>
          <Typography variant='caption'>{label}</Typography>
          <div>
            <H1Currency>
              {formatCurrency(value, { maximumFractionDigits: 0 })}.
            </H1Currency>
            <H2Currency>{numberFormatter.getDecimalPart(value)}</H2Currency>
          </div>
        </Stack>
      </StyledCardContent>
    </Card>
  )
}

export { BaseCreditSummaryCard }
