import { createContext, ReactNode, useContext, useMemo } from 'react'
import { SessionStore } from 'lib/sessionStore'

type SessionStoreProviderProps = {
  sessionStore: SessionStore
  children: ReactNode
}

type SessionStoreContextType = {
  sessionStore: SessionStore
}

const SessionStoreContext = createContext<SessionStoreContextType | undefined>(
  undefined
)

function SessionStoreProvider({
  sessionStore,
  children,
}: SessionStoreProviderProps) {
  const value = useMemo(
    () => ({
      sessionStore,
    }),
    [sessionStore]
  )

  return (
    <SessionStoreContext.Provider value={value}>
      {children}
    </SessionStoreContext.Provider>
  )
}

/**
 * `useSessionStore` is a hook that allows callers to consume from the `SessionStoreContext`.
 */
function useSessionStore(): SessionStore {
  const context = useContext(SessionStoreContext)
  if (context === undefined) {
    throw new Error(
      'useSessionStore must be called by a child of a SessionStoreProvider'
    )
  }

  return context.sessionStore
}

export { SessionStoreProvider, useSessionStore }
