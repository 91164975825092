/* eslint-disable consistent-return */
import CheckboxMui from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled, { css } from 'styled-components'
import { paragraphCaptions } from '../../Typography/Typography.styles'

export const StyledCheckbox = styled(CheckboxMui)<{ $hasError: boolean }>`
  color: ${({ theme }) => theme.color.backgroundGreen};
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.75rem;
  margin-top: 0;
  margin-bottom: auto;
  &.Mui-checked {
    ${({ $hasError, theme }) => {
      if ($hasError)
        return css`
          color: ${theme.color.red.error};
        `

      return css`
        color: ${theme.color.primary};
      `
    }}
  }

  &.Mui-focusVisible {
    color: ${({ theme }) => theme.color.green[700]};
  }

  .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
  }

  ${({ $hasError, theme }) => {
    if ($hasError)
      return css`
        color: ${theme.color.red.error};
      `
  }}
`

export const StyledLabel = styled(FormControlLabel)<{ $hasError: boolean }>`
  & .MuiTypography-root {
    & a {
      color: inherit;
      text-decoration: underline;
    }
    text-align: justify;
    ${paragraphCaptions};

    ${({ $hasError, theme }) => {
      if ($hasError) {
        return css`
          color: ${theme.color.red.error};
        `
      }
    }}

    ${({ theme }) => theme.media.minTablet} {
      ${({ theme, $hasError }) => {
        if ($hasError) {
          return css`
            color: ${theme.color.red.error};
          `
        }
      }}
    }
  }
`
