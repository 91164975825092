import { ReactNode } from 'react'
import styled from 'styled-components'
import { typographyStyles } from 'ui/Typography/Typography.styles'

type ListItemProps = {
  children: ReactNode
}

const StyledLi = styled.li`
  ${typographyStyles.caption};
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

function ListItem({ children }: ListItemProps) {
  return <StyledLi>{children}</StyledLi>
}

export { ListItem }
