import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(NavLink)`
  display: flex;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.color.background};

  &:hover {
    background-color: ${({ theme }) => theme.color.green[400]};
  }

  &.active {
    background-color: ${({ theme }) => theme.color.green[500]};
  }
`

export { StyledLink }
