/* eslint-disable no-underscore-dangle */
import { AxiosError, AxiosInstance } from 'axios'
import { SessionStore, SessionStoreKey } from 'lib/sessionStore'
import {
  ActivateCardReqBody,
  CardsEndpoints,
  GetCardsQueryParams,
  GetCardsRespData,
  GetSensitiveCardInfoQueryParams,
  GetSensitiveCardInfoRespBody,
  UpdateCardStatusReqBody,
} from './types/cards'
import {
  GetTransactionsQueryParams,
  GetTransactionsRespData,
  TransactionsEndpoints,
} from './types/transactions'
import {
  AccountsEndpoints,
  GetAccountQueryParams,
  GetAccountRespData,
} from './types/accounts'
import {
  GetStatementsQueryParams,
  GetStatementsRespData,
  StatementsEndpoints,
} from './types/statements'
import {
  GetUserQueryParams,
  GetUserRespData,
  UsersEndpoints,
} from './types/users'
import {
  CreatePaymentScheduleReqBody,
  CreatePaymentScheduleRespData,
  GetPaymentSchedulesQueryParams,
  GetPaymentSchedulesRespData,
  PaymentSchedulesEndpoints,
} from './types/paymentSchedules'
import {
  BankAccountsEndpoints,
  GenerateFinicityConnectURLReqBody,
  GenerateFinicityConnectURLRespData,
  GenerateFinicityConnectVerifyURLReqBody,
  GenerateFinicityConnectVerifyURLRespData,
  GetBankAccountsQueryParams,
  GetBankAccountsRespData,
} from './types/bankAccounts'
import {
  CompaniesEndpoints,
  CreateCompanyReqBody,
  CreateCompanyRespData,
  GetCompanyQueryParams,
  GetCompanyRespData,
} from './types/companies'
import {
  GetGrantsQueryParams,
  GetGrantsRespData,
  GrantsEndpoints,
} from './types/grants'
import { GetLoansRespData, LoansEndpoints } from './types/loans'
import { GetOffersRespData, OffersEndpoints } from './types/offers'
import {
  ActivateUserReqBody,
  AuthEndpoints,
  ForgotPasswordReqBody,
  LoginReqBody,
  LoginRespData,
  LogoutReqBody,
  RefreshTokenReqBody,
  RefreshTokenRespData,
  ResendActivationCodeReqBody,
  ResetPasswordReqBody,
  SignUpReqBody,
} from './types/auth'

type ApiClientArgs = {
  baseUrl: string
  client: AxiosInstance
  sessionStore: SessionStore
}

type ApiResponse<T> = {
  data: T
}

type ApiError = AxiosError<{
  status: number
  message: string
}>

class ApiClient {
  private baseUrl: string

  private client: AxiosInstance

  private sessionStore: SessionStore

  constructor({ baseUrl, client, sessionStore }: ApiClientArgs) {
    this.baseUrl = baseUrl
    this.client = client
    this.sessionStore = sessionStore

    // inject bearer token in requests
    this.client.interceptors.request.use(config => {
      const accessToken = this.sessionStore.get(SessionStoreKey.AccessToken)
      const headers = accessToken
        ? { ...config.headers, authorization: `Bearer ${accessToken}` }
        : config.headers

      return {
        ...config,
        headers,
      }
    })

    // TODO: after migrating to auth via ApiClient, add response interceptor that:
    // - response => response.data
    // - handles refresh token logic
  }

  // TODO: remove and replace with a response interceptor once we're not connected to NodeJS for auth
  private async get<TQueryParams, TResponse>(
    url: string,
    params: TQueryParams
  ): Promise<TResponse> {
    const resp = await this.client.get<ApiResponse<TResponse>>(url, {
      params,
      baseURL: this.baseUrl,
    })

    return resp.data.data
  }

  // TODO: remove and replace with a response interceptor once we're not connected to NodeJS for auth
  private async post<TData, TResponse>(
    url: string,
    data: TData
  ): Promise<TResponse> {
    const resp = await this.client.post<ApiResponse<TResponse>>(url, data, {
      baseURL: this.baseUrl,
    })

    return resp.data.data
  }

  // TODO: remove and replace with a response interceptor once we're not connected to NodeJS for auth
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async patch<TData, TResponse>(
    url: string,
    data: TData
  ): Promise<TResponse> {
    const resp = await this.client.patch<ApiResponse<TResponse>>(url, data, {
      baseURL: this.baseUrl,
    })

    return resp.data.data
  }

  // handle refresh token
  setRefreshTokenMiddleware({
    onRefreshTokenError,
  }: {
    onRefreshTokenError: () => void
  }): void {
    this.client.interceptors.response.use(undefined, async error => {
      const originalRequest = error.config

      if (error.response.status === 405 && !originalRequest._retry) {
        originalRequest._retry = true
        try {
          const resp = await this.refreshToken()
          const { accessToken } = resp
          this.sessionStore.set(SessionStoreKey.AccessToken, accessToken)

          return this.client({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              authorization: `Bearer ${accessToken}`,
            },
          })
        } catch (err) {
          onRefreshTokenError()
          this.sessionStore.clearAll()
          return Promise.reject(err)
        }
      }

      return Promise.reject(error)
    })
  }

  // ================================= AUTH ENDPOINTS ================================= //

  async activateUser(data: ActivateUserReqBody) {
    return this.post<ActivateUserReqBody, undefined>(
      AuthEndpoints.ActivateUser,
      data
    )
  }

  async forgotPassword(data: ForgotPasswordReqBody) {
    return this.post<ForgotPasswordReqBody, undefined>(
      AuthEndpoints.ForgotPassword,
      data
    )
  }

  async login(data: LoginReqBody) {
    const resp = await this.post<LoginReqBody, LoginRespData>(
      AuthEndpoints.Login,
      data
    )

    const { accessToken, refreshToken, userID } = resp
    this.sessionStore.set(SessionStoreKey.AccessToken, accessToken)
    this.sessionStore.set(SessionStoreKey.RefreshToken, refreshToken)
    this.sessionStore.set(SessionStoreKey.UserId, String(userID))

    return resp
  }

  async logout() {
    await this.post<LogoutReqBody, void>(AuthEndpoints.Logout, {
      refreshToken: this.sessionStore.get(
        SessionStoreKey.RefreshToken
      ) as string,
    })
    this.sessionStore.clearAll()
  }

  async refreshToken() {
    const resp = await this.post<RefreshTokenReqBody, RefreshTokenRespData>(
      AuthEndpoints.RefreshToken,
      {
        refreshToken: this.sessionStore.get(
          SessionStoreKey.RefreshToken
        ) as string,
      }
    )

    const { accessToken } = resp
    this.sessionStore.set(SessionStoreKey.AccessToken, accessToken)

    return resp
  }

  async resendActivationCode(data: ResendActivationCodeReqBody) {
    return this.post<ResendActivationCodeReqBody, undefined>(
      AuthEndpoints.ResendActivationCode,
      data
    )
  }

  async resetPassword(data: ResetPasswordReqBody) {
    return this.post<ResetPasswordReqBody, undefined>(
      AuthEndpoints.ResetPassword,
      data
    )
  }

  async signUp(data: SignUpReqBody) {
    return this.post<SignUpReqBody, undefined>(AuthEndpoints.SignUp, data)
  }

  // ================================= ACCOUNTS ENDPOINTS ================================= //

  getAccount(params: GetAccountQueryParams) {
    return this.get<GetAccountQueryParams, GetAccountRespData>(
      AccountsEndpoints.GetAccount,
      params
    )
  }

  // ================================= BANK ACCOUNTS ENDPOINTS ================================= //

  getBankAccounts(params: GetBankAccountsQueryParams) {
    return this.get<GetBankAccountsQueryParams, GetBankAccountsRespData>(
      BankAccountsEndpoints.GetBankAccounts,
      params
    )
  }

  generateFinicityConnectURL(data: GenerateFinicityConnectURLReqBody) {
    return this.post<
      GenerateFinicityConnectURLReqBody,
      GenerateFinicityConnectURLRespData
    >(BankAccountsEndpoints.GenerateFinicityConnectURL, data)
  }

  generateFinicityConnectVerifyURL(
    data: GenerateFinicityConnectVerifyURLReqBody
  ) {
    return this.post<
      GenerateFinicityConnectVerifyURLReqBody,
      GenerateFinicityConnectVerifyURLRespData
    >(BankAccountsEndpoints.GenerateFinicityConnectVerifyURL, data)
  }

  // ================================= CARDS ENDPOINTS ================================= //

  activateCard(data: ActivateCardReqBody) {
    return this.patch<ActivateCardReqBody, void>(
      CardsEndpoints.ActivateCard,
      data
    )
  }

  getCards(params: GetCardsQueryParams) {
    return this.get<GetCardsQueryParams, GetCardsRespData>(
      CardsEndpoints.GetCards,
      params
    )
  }

  getSensitiveCardInfo(params: GetSensitiveCardInfoQueryParams) {
    return this.get<
      GetSensitiveCardInfoQueryParams,
      GetSensitiveCardInfoRespBody
    >(CardsEndpoints.GetSensitiveCardInfo, params)
  }

  updateCardStatus(data: UpdateCardStatusReqBody) {
    return this.patch<UpdateCardStatusReqBody, void>(
      CardsEndpoints.UpdateCardStatus,
      data
    )
  }

  // ================================= COMPANIES ENDPOINTS ================================= //

  getCompany(params: GetCompanyQueryParams) {
    return this.get<GetCompanyQueryParams, GetCompanyRespData>(
      CompaniesEndpoints.GetCompany,
      params
    )
  }

  createCompany(data: CreateCompanyReqBody) {
    return this.post<CreateCompanyReqBody, CreateCompanyRespData>(
      CompaniesEndpoints.CreateCompany,
      data
    )
  }

  // ================================= GRANTS ENDPOINTS ================================= //

  getGrants() {
    return this.get<GetGrantsQueryParams, GetGrantsRespData>(
      GrantsEndpoints.GetGrants,
      {
        active: true,
      }
    )
  }

  // ================================= LOANS ENDPOINTS ================================= //

  getLoans() {
    return this.get<void, GetLoansRespData>(LoansEndpoints.GetLoans, undefined)
  }

  // ================================= OFFERS ENDPOINTS ================================= //

  getOffers() {
    return this.get<void, GetOffersRespData>(
      OffersEndpoints.GetOffers,
      undefined
    )
  }

  // ================================= PAYMENT SCHEDULES ENDPOINTS ================================= //

  getPaymentSchedules(params: GetPaymentSchedulesQueryParams) {
    return this.get<
      GetPaymentSchedulesQueryParams,
      GetPaymentSchedulesRespData
    >(PaymentSchedulesEndpoints.GetPaymentSchedules, params)
  }

  createPaymentSchedule(data: CreatePaymentScheduleReqBody) {
    return this.post<
      CreatePaymentScheduleReqBody,
      CreatePaymentScheduleRespData
    >(PaymentSchedulesEndpoints.CreatePaymentSchedule, data)
  }

  // ================================= STATEMENTS ENDPOINTS ================================= //

  getStatements(params: GetStatementsQueryParams) {
    return this.get<GetStatementsQueryParams, GetStatementsRespData>(
      StatementsEndpoints.GetStatements,
      params
    )
  }

  // ================================= TRANSACTIONS ENDPOINTS ================================= //

  getTransactions(params: GetTransactionsQueryParams) {
    return this.get<GetTransactionsQueryParams, GetTransactionsRespData>(
      TransactionsEndpoints.GetTransactions,
      params
    )
  }

  // ================================= USERS ENDPOINTS ================================= //

  getUser(params: GetUserQueryParams) {
    return this.get<GetUserQueryParams, GetUserRespData>(
      UsersEndpoints.GetUser,
      params
    )
  }
}

export { ApiClient }
export type { ApiError, ApiResponse }
