import { IconButtonProps } from '@mui/material'
import { forwardRef } from 'react'
import { StyledIconButton } from './IconButton.styles'

export type { IconButtonProps }

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    return (
      <StyledIconButton
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        disableFocusRipple
        disableRipple
      />
    )
  }
)
