import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslations } from 'translations'
import { usePasswordValidation } from 'lib/formatter/stringFormatter'

type SignUpFormData = {
  firstName: string
  lastName: string
  email: string
  password: string
}

function useSignUpForm() {
  const t = useTranslations()
  const passwordValidator = usePasswordValidation()
  const schema: yup.SchemaOf<SignUpFormData> = useMemo(
    () =>
      yup
        .object({
          firstName: yup
            .string()
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newSignUp.form.firstName'),
              })
            ),
          lastName: yup
            .string()
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newSignUp.form.lastName'),
              })
            ),
          email: yup
            .string()
            // Validate email
            .email(t('ui.form.validation.email'))
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('pages.newSignUp.form.email'),
              })
            ),
          password: passwordValidator,
        })
        .required(),
    [t, passwordValidator]
  )

  return useForm<SignUpFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  })
}

export type { SignUpFormData }
export { useSignUpForm }
