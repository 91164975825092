import { BaseCheckbox, StyledFormLabel } from 'ui'
import { StyledFormGroup } from './TableFilterCheckboxGroup.styles'
import { FilteredColumn, TableFilterOption } from '../types'

type TableFilterCheckboxGroupProps = {
  label: string
  filterOptions: TableFilterOption[]
} & Pick<FilteredColumn<unknown>, 'getFilterValue' | 'setFilterValue'>

function TableFilterCheckboxGroup({
  label,
  filterOptions,
  getFilterValue,
  setFilterValue,
}: TableFilterCheckboxGroupProps) {
  const filterValue = getFilterValue() as Set<string> | undefined

  return (
    <>
      <StyledFormLabel>{label}</StyledFormLabel>
      <StyledFormGroup>
        {filterOptions.map(opt => (
          <BaseCheckbox
            key={opt.value}
            label={opt.label}
            isChecked={filterValue?.has(opt.value) || false}
            onChange={(_, checked) => {
              setFilterValue((prev: Set<string> | undefined) => {
                const s = new Set(prev?.values())
                if (checked) {
                  s.add(opt.value)
                } else {
                  s.delete(opt.value)
                }

                return s
              })
            }}
          />
        ))}
      </StyledFormGroup>
    </>
  )
}

export { TableFilterCheckboxGroup }
