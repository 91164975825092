import { getEnvVariable } from 'utils'

enum RoutesPaths {
  // auth
  ActivateAccount = '/register/activate-account',
  ConfirmEmail = '/register/confirm-email',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ForgotPasswordCheckEmail = '/forgot-password/check-email',
  Register = '/register',
  SetNewPassword = '/set-new-password',

  // application
  PreliminaryProcess = '/preliminary-process',

  // other
  NotFound = '*',

  // creditAccountNotRequiredRoutes
  CapitalMarketplace = '/capital-marketplace',
  ContactSupport = '/contact-support',
  Dashboard = '/',
  Grants = '/grants',
  GrowthPartners = '/growth-partners',
  Profile = '/profile',

  // creditAccountHoldersOnlyRoutes
  ActivateCard = '/activate-card',
  Cards = '/cards',
  Payments = '/payments',
  Statements = '/statements',
  Transactions = '/transactions',

  // nonCreditAccountHoldersOnlyRoutes
  BusinessBuildingCard = '/business-building-card',

  // FULL LAYOUT TO REMOVE
  'ACTIVATE_CARD' = '/deprecated/activate-card',
  'FINICITY_CONNECT' = '/deprecated/connect-bank-account',
  'PRODUCT_SELECTION' = '/deprecated/product-selection',

  NewLogin = '/new/login',
  NewSignUp = '/new/signup',
  NewEmailVerification = '/new/email-verification',
  NewForgotPassword = '/new/forgot-password',
  NewResetPassword = '/new/reset-password',
}

enum HubspotPaths {
  BusinessBuildingCard = '/business-building-card',
  BusinessFunding = '/learn/tag/business-funding',
  CapitalMarketPlace = '/capital-marketplace',
  GetFunding = '/learn/how-to-get-funding-for-your-business',
  GrantResources = '/grant-resources',
  Grants = '/platform-grants',
  GrowingYourBusiness = '/learn/tag/growing-your-business',
  GrowthPartnersPlatform = '/growth-partners-platform',
  Learn = '/learn',
  Partners = '/partners',
  Webinars = '/webinars',
}

function getHubspotURL(path: HubspotPaths) {
  switch (path) {
    case HubspotPaths.CapitalMarketPlace:
    case HubspotPaths.GrantResources:
    case HubspotPaths.GrowthPartnersPlatform:
      return `${getEnvVariable('REACT_APP_FUNDID_HUBSPOT_GRANTS_URL')}${path}`
    default:
      return `${getEnvVariable('REACT_APP_FUNDID_HUBSPOT_BASE_URL')}${path}`
  }
}

export { RoutesPaths, HubspotPaths, getHubspotURL }
