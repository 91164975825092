import styled from 'styled-components'
import { Typography } from 'ui-kit'

export const ParagraphWrapper = styled(Typography.PMainLayout)`
  max-width: 36rem;
  text-align: center;
  margin-bottom: 2rem;
`

export const StyledButtonsWrapper = styled.div`
  margin: 2.5rem auto 1rem;
  width: 100%;
  max-width: 22.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`
export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.primary};
  margin: 1rem 0;
  cursor: pointer;
`
