import { FundidDate } from './shared'

enum StatementsEndpoints {
  GetStatements = '/credit/statements',
}

type Statement = {
  id: number
  url: string
  date: FundidDate
}

type GetStatementsQueryParams = {
  accountID: number
}

type GetStatementsRespData = {
  statements: Statement[]
}

export type { Statement, GetStatementsQueryParams, GetStatementsRespData }
export { StatementsEndpoints }
