import MUILoader from '@mui/material/CircularProgress'
import styled from 'styled-components'

const StyledLoader = styled(MUILoader)`
  color: ${({ theme }) => theme.color.primary};
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export { StyledLoader, LoaderContainer }
