import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { LoggerContext } from 'logger'
import { FeedbackPage, IconType } from 'ui-kit'

interface ErrorBoundaryProps {
  children: ReactNode
}

type ErrorBoundaryState =
  | {
      hasError: false
    }
  | {
      hasError: true
      error: Error
      errorInfo: React.ErrorInfo
    }
const initialState: ErrorBoundaryState = {
  hasError: false,
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = initialState
  }

  static contextType = LoggerContext

  context!: React.ContextType<typeof LoggerContext> // enforcing the type of this.context

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  formatErrorInfo(errorInfo: React.ErrorInfo) {
    return {
      componentStack: errorInfo.componentStack,
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { logger } = this.context

    logger.error(`UI Error Boundary reached: ${JSON.stringify(error)}`, {
      message: JSON.stringify(error.message),
      ...this.formatErrorInfo(errorInfo),
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <FeedbackPage
          header={
            (
              <FormattedMessage id='errorMessages.UNEXPECTED_ERROR' />
            ) as unknown as string
          }
          icon={IconType.error}
          type='error'
        />
      )
    }
    return children
  }
}
