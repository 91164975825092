import { Components, Theme } from '@mui/material'

export const MuiPickersCalendarHeader: Components<Theme>['MuiPickersCalendarHeader'] =
  {
    styleOverrides: {
      labelContainer: ({ theme }) => ({
        fontFamily: theme.fontFamily.body,
      }),
    },
  }
