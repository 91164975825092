import styled from 'styled-components'
import { Popover } from 'ui/Popover/Popover.component'

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    min-width: 16rem;
  }
`

export { StyledPopover }
