import styled from 'styled-components'
import { ToggleButton } from '@mui/material'
import * as Typography from '../Typography/Typography.styles'

export const StyledRadio = styled(ToggleButton)<{
  $isValid: boolean
}>`
  ${Typography.paragraphBody};
  color: ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.white};
  width: 100%;
  padding: 1.25rem;
  border-radius: 1rem;
  text-transform: none;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.color.white};
  }

  &.Mui-focusVisible {
    border-width: 2px;
    border-color: ${({ theme }) => theme.color.apple[700]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundBlue};
  }

  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  border: 2px solid
    ${({ theme, $isValid, selected }) => {
      if ($isValid && selected) {
        return `${theme.color.primary}`
      }
      if (!$isValid) {
        return `${theme.color.red.error}`
      }
      return `${theme.color.transparent}`
    }};
`
