import { UseMutationResult, useMutation } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, ResetPasswordReqBody } from 'api'

function useResetPassword(): UseMutationResult<
  void,
  ApiError,
  ResetPasswordReqBody
> {
  const apiClient = useApiClient()

  return useMutation(args => {
    return apiClient.resetPassword(args)
  })
}

export { useResetPassword }
