import { useQuery, UseQueryOptions } from 'react-query'
import {
  AuthenticationApi,
  FeatureFlags,
  StatusValues,
  UserStatusResponse,
} from 'api-connectors'

export const USER_STATUS_QUERY_KEY = 'user-status'

export interface CompanyDetails {
  state: string
  city: string
  street1: string
  street2: string
  zip: string
}

export interface UserStatusDTO {
  flags: StatusValues[]
  businessEmail: string
  name: string
  lastName: string
  id: string
  featureFlags: FeatureFlags[]
  phone: string
  businessName: string
  address: CompanyDetails
  reApplicationAt: string | null
  lastShippedAt: string | null
}

/**
 * Keep in mind that this function needs to return an object which is easy to compare.
 * Search `structuralSharing` in react-query docs for more information.
 * @see https://tanstack.com/query/v4/docs/reference/useQuery
 * @param data
 */
const mapGetUserDataToDTO = (data: UserStatusResponse): UserStatusDTO => {
  const { data: userInfo } = data

  return {
    flags: userInfo.flags,
    businessEmail: userInfo.businessEmail,
    name: userInfo.name,
    lastName: userInfo.lastName,
    id: userInfo.id,
    featureFlags: userInfo.featureFlags,
    phone: userInfo.phone,
    businessName: userInfo.businessName,
    reApplicationAt: userInfo.reApplicationAt,
    lastShippedAt: userInfo.lastShippedAt,
    address: {
      state: userInfo.address.state,
      city: userInfo.address.city,
      street1: userInfo.address.street1,
      street2: userInfo.address.street2,
      zip: userInfo.address.zip,
    },
  }
}

export const getUserData = () => {
  return AuthenticationApi().userStatus()
}

export const useUserStatus = (
  options?: Pick<
    UseQueryOptions<UserStatusDTO>,
    'retry' | 'enabled' | 'refetchIntervalInBackground' | 'onSuccess'
  > & { refetchInterval?: number }
) => {
  const { data, refetch, isError, status } = useQuery(
    [USER_STATUS_QUERY_KEY],
    getUserData,
    {
      retry: false,
      enabled: false,
      select: resp => {
        return mapGetUserDataToDTO(resp.data)
      },
      ...options,
    }
  )

  return {
    userStatus: data,
    checkSession: refetch,
    isError,
    status,
  }
}

// Temp func to avoid potentially having to refactor all the callers of useUserStatus.
//
// The existing useUserStatus is configured with incorrect options, so we can't throw errors
// properly.
export function useUserStatusV2() {
  return useQuery({
    queryKey: 'user-status-v2',
    queryFn: getUserData,
    retry: false,
    useErrorBoundary: false,
    select: resp => mapGetUserDataToDTO(resp.data),
  })
}
