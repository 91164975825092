import { UseMutationResult, useMutation } from 'react-query'
import { useApiClient } from 'lib/context'
import { ApiError, ForgotPasswordReqBody } from 'api'

function useForgotPassword(): UseMutationResult<
  void,
  ApiError,
  ForgotPasswordReqBody
> {
  const apiClient = useApiClient()

  return useMutation(args => {
    return apiClient.forgotPassword(args)
  })
}

export { useForgotPassword }
