import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  StyledTextWrapper,
  StyledToggleExpandButton,
} from './ToggleMoreText.styles'

interface ToggleMoreTextFixedHeightProps {
  children: ReactNode
  expandLabel: string
  collapseLabel: string
  collapseTresholdInPx?: number
  id: string
  isLoading?: boolean
  refProp: React.RefObject<HTMLDivElement>
  onScroll: () => void
  onClickCallback?: () => void
}

const StyledWrapper = styled(StyledTextWrapper)`
  width: 100%;
`

const StyledChildrenWrapper = styled.div<{
  isOpen: boolean
  isCollapsable: boolean
}>`
  ${({ isOpen, isCollapsable }) => {
    if (!isCollapsable) return null
    if (isOpen) {
      return css`
        max-height: 40vh;
      `
    }

    return css`
      max-height: 6.25rem;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2rem;
        bottom: 0;
        left: 0;

        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 0.9) 0,
          rgba(255, 255, 255, 0.6) 40%,
          rgba(255, 255, 255, 0.4) 100%
        );
      }
    `
  }}

  &:focus-within {
    outline-width: 1px;
    outline-style: solid;
    outline-offset: 0.25rem;
    border-radius: 0.5rem;
    outline-color: ${({ theme }) => theme.color.primary};
  }
`

export const ToggleMoreTextFixedHeight = (
  props: ToggleMoreTextFixedHeightProps
) => {
  const {
    children,
    isLoading,
    expandLabel,
    id,
    collapseLabel,
    refProp,
    onScroll,
    collapseTresholdInPx = 420,
    onClickCallback,
  } = props
  const [isCollapsable, setIsCollabsable] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (!refProp || !refProp.current) return
    const shouldCollapse =
      refProp.current.getBoundingClientRect().height > collapseTresholdInPx

    if (shouldCollapse) {
      setIsCollabsable(true)
    }
  }, [collapseTresholdInPx, isLoading, refProp])

  useEffect(() => {
    if (!refProp || !refProp.current) return
    if (!isOpen) {
      refProp.current.scrollTo(0, 0)
      refProp.current.style.overflowY = 'hidden'
    } else {
      refProp.current.style.overflowY = 'scroll'
    }
  }, [setIsOpen, isOpen, refProp])

  const toggle = () => {
    setIsOpen(prev => !prev)
    if (onClickCallback) onClickCallback()
  }

  const handleFocus = () => {
    setIsOpen(true)
  }

  return (
    <StyledWrapper>
      <StyledChildrenWrapper
        onScroll={onScroll}
        onFocus={handleFocus}
        isCollapsable={isCollapsable}
        isOpen={isOpen}
        id={id}
        ref={refProp}
        tabIndex={0}
      >
        {children}
      </StyledChildrenWrapper>
      {isCollapsable && (
        <StyledToggleExpandButton onClick={toggle} type='button'>
          {isOpen ? collapseLabel : expandLabel}
        </StyledToggleExpandButton>
      )}
    </StyledWrapper>
  )
}
