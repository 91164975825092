import { ReactNode } from 'react'
import styled from 'styled-components'

type ListProps = {
  children: ReactNode
  ordered?: boolean
}

const StyledUl = styled.ol`
  list-style: disc;
  padding-left: 1rem;
`

const StyledOl = styled.ol`
  list-style-type: decimal;
  padding-left: 1rem;
`

function List({ children, ordered }: ListProps) {
  return ordered ? (
    <StyledOl>{children}</StyledOl>
  ) : (
    <StyledUl>{children}</StyledUl>
  )
}

export { List }
