import styled, { css } from 'styled-components'

const HEIGHT_TOP_NAV = '64px'

const NavMobileWrapper = styled.nav<{
  isNavOpen: boolean
}>`
  // position in the app
  width: 100vw;
  position: fixed;
  z-index: 1000;
  grid-row: 1;
  padding: 0 1rem 2rem;

  ${({ isNavOpen }) => {
    if (isNavOpen) {
      return css`
        top: 0;
        bottom: 0;
      `
    }
    return css`
      height: ${HEIGHT_TOP_NAV};
    `
  }}

  // grid
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  align-items: stretch;
  grid-row-gap: 1rem;

  // other styles
  background-color: ${({ theme }) => theme.color.primary};

  ${({ theme }) => theme.media.minTablet} {
    display: none;
    grid-row: unset;
  }
`

export { HEIGHT_TOP_NAV, NavMobileWrapper }
