import { CheckboxProps as CheckboxPropsMui } from '@mui/material/Checkbox'
import { ReactElement } from 'react'
import { StyledCheckbox, StyledLabel } from './Checkbox.styles'
import { handleEnterKeyPress } from '../../utils/handleEnterKeyPress.utils'

export interface CheckboxProps extends CheckboxPropsMui {
  label: string
  description: string | ReactElement
  hasError?: boolean
  name: string
  isRequired?: boolean
  disabled?: boolean
  value: boolean
  toggle: () => void
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    label,
    value,
    disabled,
    toggle,
    hasError = false,
    name,
    description,
    isRequired,
  } = props

  return (
    <StyledLabel
      labelPlacement='end'
      $hasError={hasError}
      // passing ReactElement here cause propType warning in the console related to invalid type, but TS declarations says everthing is fine
      label={description}
      control={
        <StyledCheckbox
          aria-required={isRequired}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          disabled={disabled}
          inputProps={{
            'aria-label': label,
            'aria-invalid': hasError,
            type: 'checkbox',
            id: name,
            role: 'checkbox',
          }}
          checked={value}
          onChange={toggle}
          onKeyPress={e => {
            handleEnterKeyPress(e, toggle)
          }}
          $hasError={hasError}
        />
      }
    />
  )
}
