import { useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import { MouseEvent, useState } from 'react'
import { IconButton, IconType, Paper } from 'ui'
import { TableFilterCheckboxGroup } from './TableFilterCheckboxGroup.component'
import { TableFilterSelectField } from './TableFilterSelectField.component'
import { StyledPopover } from './TableFilter.styles'
import { FilteredColumn } from '../types'

type TableFilterProps<TData> = {
  filteredColumns: FilteredColumn<TData>[]
}

function TableFilter<TData>({ filteredColumns }: TableFilterProps<TData>) {
  const { color } = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openFilter = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        type={IconType.Filter}
        color={color.primary}
        onClick={openFilter}
      />
      <StyledPopover
        isOpen={!!anchorEl}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Stack spacing={2}>
            {filteredColumns.map((column, i) => {
              const {
                columnDef: {
                  meta: { filterConfig },
                },
                getFilterValue,
                setFilterValue,
              } = column

              const FilterField =
                filterConfig.type === 'checkbox'
                  ? TableFilterCheckboxGroup
                  : TableFilterSelectField

              return (
                <FilterField
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  label={filterConfig.label}
                  filterOptions={filterConfig.options}
                  getFilterValue={getFilterValue}
                  setFilterValue={setFilterValue}
                />
              )
            })}
          </Stack>
        </Paper>
      </StyledPopover>
    </>
  )
}

export { TableFilter }
