import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  display: inline-flex;
  gap: 2px;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.minTablet} {
    flex-direction: row;
    width: auto;
  }
`
