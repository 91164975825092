import { useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as FundidSVG } from 'assets/svgs/fundid.svg'
import { Icon, IconType } from 'ui'
import { RoutesPaths } from 'routing'
import { useNav } from 'shared/Navbar/NavProvider/NavProvider.component'
import {
  RotatingIconWrapper,
  TopSidebarLogoPanel,
} from './LogoPanelDesktop.styles'

function LogoPanelDesktop() {
  const { isNavOpen, toggleNav } = useNav()
  const { color } = useTheme()

  return (
    <TopSidebarLogoPanel isNavOpen={isNavOpen}>
      {isNavOpen && (
        <Link to={RoutesPaths.Dashboard}>
          <FundidSVG fill={color.background} />
        </Link>
      )}
      <RotatingIconWrapper isNavOpen={isNavOpen} onClick={toggleNav}>
        <Icon type={IconType.DoubleArrow} color={color.background} />
      </RotatingIconWrapper>
    </TopSidebarLogoPanel>
  )
}

export { LogoPanelDesktop }
