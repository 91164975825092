import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import { PaymentAmountField } from 'form-kit/fields/PaymentAmountField/PaymentAmountField.component'
import { FormGrid } from 'ui-kit'

export interface PaymentFormValues {
  paymentAmount: string
}

const MIN_LENGTH = 1

const ReadableFieldName = (
  <FormattedMessage id='formKit.paymentAmountField.label' />
)

export const paymentAmountFieldValidationSchema = yup
  .string()
  .trim()
  .min(MIN_LENGTH, () => (
    <FormattedMessage
      id='formKit.validation.minLength'
      values={{ min: MIN_LENGTH, fieldName: ReadableFieldName }}
    />
  ))

  .required(() => (
    <FormattedMessage
      id='formKit.validation.required'
      values={{
        fieldName: ReadableFieldName,
      }}
    />
  ))

export const paymentAmountPageValidationSchema: yup.ObjectSchema<
  Record<keyof PaymentFormValues, yup.AnySchema>
> = yup.object().shape({
  paymentAmount: paymentAmountFieldValidationSchema,
})
export const PaymentFormFieldSet = () => {
  return (
    <FormGrid.Row>
      <PaymentAmountField />
    </FormGrid.Row>
  )
}
