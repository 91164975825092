import { ReactNode } from 'react'
import { Container, Lead, Title } from './ErrorScreen.styles'

interface ErrorScreenProps {
  button?: ReactNode
  leadContent: string
  title: string
  icon: ReactNode
}

export const ErrorScreen = ({
  leadContent,
  title,
  icon,
  button,
}: ErrorScreenProps) => {
  return (
    <Container>
      {icon}
      <Title>{title}</Title>
      <Lead>{leadContent}</Lead>
      {button}
    </Container>
  )
}
