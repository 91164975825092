import styled from 'styled-components'
import { Typography, TypographyProps } from 'ui'

const Notice = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='caption' />
))`
  margin-top: 3rem;
  font-style: italic;
`

export { Notice }
