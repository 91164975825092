import styled from 'styled-components'
import { Box } from '../Box/Box.styles'

export const FormWrapperAuth = styled(Box)`
  width: 28rem;
  max-width: 100%;
`

export const PreliminaryWrapper = styled(Box)`
  margin: 2.5rem auto;

  ${({ theme }) => theme.media.minTablet} {
    margin: 4.5rem auto;
  }
`

export const PreliminaryCtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  max-width: 22.5rem;
`

export const WhiteBackgroundFormWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
`
