import { useMemo } from 'react'
import { isActiveAutopaySchedule, isCardActivated } from 'lib/models'
import {
  useBankAccounts,
  useCards,
  usePaymentSchedules,
  useUser,
} from 'lib/queries'

type UseIsCardFunctionalityEnabledType =
  | {
      isLoading: true
      isCardFunctionalityEnabled: undefined
    }
  | {
      isLoading: false
      isCardFunctionalityEnabled: boolean
    }

// User must have a company, bank account, and active autopay schedule in order
// to use full card functionality, e.g. view transactions, statements, make payments,
// etc.
function useIsCardFunctionalityEnabled(): UseIsCardFunctionalityEnabledType {
  const { data: user } = useUser()
  const { data: cards } = useCards()
  const { data: bankAccounts } = useBankAccounts()
  const { data: paymentSchedules } = usePaymentSchedules()

  return useMemo(() => {
    // Wait for user data to load
    if (!user) {
      return {
        isLoading: true,
        isCardFunctionalityEnabled: undefined,
      }
    }

    const userHasCreditAccount = !!user?.accountID
    const userHasCompany = !!user?.companyID

    // If user doesn't have a credit account or company attached, they don't have any card functionality
    if (!userHasCreditAccount || !userHasCompany) {
      return {
        isLoading: false,
        isCardFunctionalityEnabled: false,
      }
    }

    // If user has a credit account, we need to wait for result of cards, bankAccounts, and
    // paymentSchedules queries
    if (!cards || !bankAccounts || !paymentSchedules) {
      return {
        isLoading: true,
        isCardFunctionalityEnabled: undefined,
      }
    }

    return {
      isLoading: false,
      // Card functionality is enabled for users who have an attached company, at least one active card,
      // a bank account, and an autopay schedule
      isCardFunctionalityEnabled:
        userHasCompany &&
        (isCardActivated(cards.physical) || isCardActivated(cards.virtual)) &&
        bankAccounts.length > 0 &&
        paymentSchedules.some(isActiveAutopaySchedule),
    }
  }, [user, cards, bankAccounts, paymentSchedules])
}

export { useIsCardFunctionalityEnabled }
