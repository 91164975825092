import ButtonMui from '@mui/material/Button'
import styled, { css } from 'styled-components'
import { ButtonColor, ButtonSize } from './Button.component'
import { paragraphBody } from '../Typography/Typography.styles'

export const StyledButton = styled(ButtonMui)<{
  $sizeVariant: ButtonSize
  $colorVariant: ButtonColor
}>`
  & .MuiCircularProgress-root {
    // have to overwrite inline styles with important
    width: 1rem !important;
    height: 1rem !important;
  }
  display: inline-flex;
  border-radius: 6.25rem;
  text-transform: none;
  ${paragraphBody};

  /* COLOR VARIANTS OF BUTTON */
  ${({ $colorVariant, variant, theme }) => {
    //   SECONDARY
    if ($colorVariant === ButtonColor.SECONDARY) {
      // SECONDARY OUTLINED
      if (variant === 'outlined') {
        return css`
          border: 1px solid ${theme.color.accent};
          background-color: ${theme.color.white};
          color: ${theme.color.accent};

          &:focus {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: 0.25rem;
            outline-color: ${theme.color.accent};
          }

          &:hover {
            border-color: ${theme.color.accent};
            color: ${theme.color.pumpkin[600]};
            background-color: ${theme.color.pumpkin[50]};
          }

          &:active {
            border-color: ${theme.color.accent};
            color: ${theme.color.pumpkin[600]};
            background-color: ${theme.color.pumpkin[100]};
          }

          &:disabled {
            background-color: ${theme.color.white};
            border-color: ${theme.color.green[400]};
            color: ${theme.color.primary};
          }
        `
      }
      //   SECONDARY DEFAULT
      return css`
        background-color: ${theme.color.accent};
        color: ${theme.color.white};

        &:focus {
          outline-width: 1px;
          outline-style: solid;
          outline-offset: 0.25rem;
          outline-color: ${theme.color.accent};
        }

        &:hover {
          background-color: ${theme.color.pumpkin[600]};
          color: ${theme.color.white};
        }
        &:active {
          background-color: ${theme.color.pumpkin[400]};
          color: ${theme.color.white};
        }
        &:disabled {
          color: ${theme.color.white};
          background-color: ${theme.color.green[100]};
        }
      `
    }

    if ($colorVariant === ButtonColor.DANGER) {
      // DANGER OUTLINED
      if (variant === 'outlined') {
        return css`
          border: 1px solid ${theme.color.red.error};
          background-color: ${theme.color.white};
          color: ${theme.color.red.error};

          &:focus {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: 0.25rem;
            outline-color: ${theme.color.red.error};
          }

          &:hover {
            border-color: ${theme.color.red.error};
            color: ${theme.color.red.error};
            background-color: ${theme.color.red['200']};
          }

          &:active {
            border-color: ${theme.color.red.error};
            color: ${theme.color.red.error};
            background-color: ${theme.color.red['200']};
          }

          &:disabled {
            border-color: ${theme.color.red['500']};
            color: ${theme.color.red['500']};
            background-color: ${theme.color.white};
          }
        `
      }
      // DANGER TEXT
      if (variant === 'text') {
        return css`
          border: 1px solid ${theme.color.transparent};
          background-color: ${theme.color.transparent};
          color: ${theme.color.white};
          font-weight: 700;

          &:focus {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: 0.25rem;
            outline-color: ${theme.color.white};
          }

          &:hover {
            border-color: ${theme.color.white};
            color: ${theme.color.red.error};
            background-color: ${theme.color.red['200']};
          }

          &:active {
            border-color: ${theme.color.white};
            color: ${theme.color.red.error};
            background-color: ${theme.color.red['200']};
          }

          &:disabled {
            color: ${theme.color.red['500']};
          }
        `
      }
    }

    if ($colorVariant === ButtonColor.INFO) {
      // INFO TEXT
      if (variant === 'text') {
        return css`
          border: 1px solid ${theme.color.transparent};
          background-color: ${theme.color.transparent};
          color: ${theme.color.green['700']};
          font-weight: 700;

          &:focus {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: 0.25rem;
            outline-color: ${theme.color.white};
          }

          &:hover {
            border-color: ${theme.color.white};
            color: ${theme.color.green['600']};
          }

          &:active {
            border-color: ${theme.color.white};
            color: ${theme.color.white};
          }

          &:disabled {
            color: ${theme.color.white};
          }
        `
      }
    }

    if ($colorVariant === ButtonColor.WARNING) {
      // WARNING TEXT
      if (variant === 'text') {
        return css`
          border: 1px solid ${theme.color.transparent};
          background-color: ${theme.color.transparent};
          color: ${theme.color.white};
          font-weight: 700;

          &:focus {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: 0.25rem;
            outline-color: ${theme.color.white};
          }

          &:hover {
            border-color: ${theme.color.white};
            color: ${theme.color.pumpkin[600]};
            background-color: ${theme.color.pumpkin[100]};
          }

          &:active {
            border-color: ${theme.color.white};
            color: ${theme.color.pumpkin[600]};
            background-color: ${theme.color.pumpkin[200]};
          }

          &:disabled {
            color: ${theme.color.pumpkin[400]};
          }
        `
      }
    }

    // PRIMARY
    return css`
      ${() => {
        // PRIMARY TEXT
        if (variant === 'text') {
          return css`
            text-decoration: underline;
            color: ${theme.color.primary};

            &:focus {
              outline-width: 1px;
              outline-style: solid;
              outline-offset: 0.25rem;
              outline-color: ${theme.color.primary};
            }

            &:hover {
              background-color: transparent;
              color: ${theme.color.primary};
              text-decoration: underline;
            }
          `
        }
        // PRIMARY OUTLINE
        if (variant === 'outlined') {
          return css`
            border: 1px solid ${theme.color.primary};
            background-color: ${theme.color.transparent};
            color: ${theme.color.primary};

            &:focus {
              outline-width: 1px;
              outline-style: solid;
              outline-offset: 0.25rem;
              outline-color: ${theme.color.primary};
            }

            &:hover {
              background-color: ${theme.color.green[50]};
              border-color: ${theme.color.primary};
              color: ${theme.color.primary};
            }

            &:active {
              background-color: ${theme.color.green[100]};
              border-color: ${theme.color.primary};
              color: ${theme.color.primary};
            }

            &:disabled {
              background-color: ${theme.color.transparent};
              border-color: ${theme.color.green[400]};
              color: ${theme.color.green[300]};
            }
          `
        }

        // PRIMARY DEFAULT
        return css`
          background-color: ${theme.color.primary};
          color: ${theme.color.white};

          &:focus {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: 0.25rem;
            outline-color: ${theme.color.primary};
          }

          &:hover {
            background-color: ${theme.color.green[600]};
            color: ${theme.color.white};
          }

          &:active {
            background-color: ${theme.color.green[400]};
            color: ${theme.color.white};
          }

          &:disabled {
            background-color: ${theme.color.green[100]};
            color: ${theme.color.white};
          }
        `
      }}
    `
  }}

  /* size */
    ${({ $sizeVariant }) => {
    if ($sizeVariant === ButtonSize.SMALL)
      return css`
        padding: 0.75rem 2rem;
      `
    if ($sizeVariant === ButtonSize.XSMALL)
      return css`
        padding: 0.625rem 1.125rem;
      `
    if ($sizeVariant === ButtonSize.BIG_WIDE)
      return css`
        min-width: 16rem;
        padding: 0.625rem 1.125rem;
      `
    if ($sizeVariant === ButtonSize.XXSMALL)
      return css`
        padding: 0.25rem;
        font-size: 0.8125rem;
        border-radius: 0.25rem;
      `

    return css`
      padding: 1rem 2rem;
    `
  }}
`
