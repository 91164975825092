export interface MfaVerifyRequest {
  receivedCode: string
}

export interface MfaEnableRequest {
  receivedCode: string
}

export interface MfaEnableResponse {
  data: {
    emergencyCodes: string[]
  }
}

export interface MfaEmergencyRequest {
  emergencyCode: string
}

export interface MfaDeleteOneRequest {
  id: number
}

export enum MfaDeviceType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export interface MfaFingerprint {
  id: number
  name: string
  type: MfaDeviceType
}

export interface MfaListResponse {
  data: {
    fingerPrints: MfaFingerprint[]
  }
}

export interface MfaResendRequest {
  email: string
}
