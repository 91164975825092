import { forwardRef, ReactNode } from 'react'
import { StyledAlert } from './Alert.styles'
import { Icon, IconType } from '../Icons/Icon.component'
import { IconButton } from '../IconButton/IconButton.component'

export interface AlertProps {
  variant?: 'success' | 'error' | 'warning' | 'info'
  action?: ReactNode
  children?: ReactNode
  onClose?: () => void
  icon?: ReactNode
}
interface CloseButtonProps {
  onClick: AlertProps['onClose']
}

export const CloseButton = ({ onClick }: CloseButtonProps) => (
  <IconButton onClick={onClick}>
    <Icon type={IconType.close} />
  </IconButton>
)

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ variant = 'success', action, icon, children, onClose }, ref) => {
    return (
      <StyledAlert
        ref={ref}
        action={action || <CloseButton onClick={onClose} />}
        onClose={onClose}
        severity={variant}
        iconMapping={{
          success: <Icon type={IconType.check} />,
          error: <Icon type={IconType.error} />,
          ...(icon ? { [variant]: icon } : {}),
        }}
      >
        {children}
      </StyledAlert>
    )
  }
)
