import { useTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslations } from 'translations'

const ID = 'fundid-logo-icon'

interface LogoFundidProps {
  url: string
}

export const LogoFundid = (props: LogoFundidProps) => {
  const { url } = props
  const theme = useTheme()
  const t = useTranslations()

  return (
    <Link to={url} tabIndex={0}>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 645 162'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        aria-labelledby={ID}
      >
        <title id={ID}>{t('uiKit.logoFundid.title')}</title>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M498.04 38.4655H482.365V22.4256C482.365 16.6067 484.676 11.0259 488.789 6.90989C492.902 2.79388 498.481 0.479502 504.3 0.475464H519.99V16.5154C519.99 22.3369 517.677 27.92 513.561 32.0365C509.444 36.1529 503.861 38.4655 498.04 38.4655ZM19.5637 157.975V72.4035H0V47.0159H19.5637V40.141C19.5637 12.834 37.5635 3.37967 58.6606 3.37967H82.7434V27.894H66.5867C57.3405 27.894 49.4449 30.91 49.4449 42.111V47.0667H78.0466V72.4543H49.4449V157.975H19.5637ZM90.8877 109.17V47.0315H120.774V109.17C120.774 126.586 128.842 135.614 146.258 135.614C163.674 135.614 171.95 126.155 171.95 108.525V47.0315H201.623V108.525C201.623 145.084 181.085 160.986 146.278 160.986C111.472 160.986 90.8877 145.718 90.8877 109.17ZM218.567 96.2678V157.975H248.454V96.2678C248.454 78.6387 257.482 69.3925 274.897 69.3925C292.313 69.3925 301.56 78.4254 301.56 95.8413V157.975H331.228V95.8413C331.228 59.2882 309.932 44.0252 274.897 44.0252C240.071 44.0252 218.567 59.7096 218.567 96.2678ZM344.383 102.503C344.383 63.3759 372.223 44.0254 397.057 44.0254C413.544 44.0254 425.462 49.4944 433.823 60.632V7.35045H463.496V108.525C463.496 141.057 442.861 161.951 403.937 161.951C377.062 161.941 344.383 141.849 344.383 102.503ZM434.473 102.503C434.473 81.4315 422.216 68.5346 404.587 68.5346C387.384 68.5346 374.914 79.9286 374.914 102.503C374.914 125.078 387.384 136.472 404.587 136.472C422.216 136.472 434.473 123.575 434.473 102.503ZM482.365 49.9203V157.975H512.252V49.9203H482.365ZM525.457 102.503C525.457 63.3759 553.292 44.0254 578.126 44.0254C594.615 44.0254 606.531 49.492 614.888 60.6247V7.35045H644.561V108.525C644.561 141.057 623.925 161.951 585.001 161.951C558.136 161.941 525.457 141.849 525.457 102.503ZM615.542 102.503C615.542 81.4315 603.29 68.5346 585.661 68.5346C568.459 68.5346 555.988 79.9286 555.988 102.503C555.988 125.078 568.459 136.472 585.661 136.472C603.29 136.472 615.542 123.575 615.542 102.503Z'
          fill={theme.color.background}
        />
      </svg>
    </Link>
  )
}
