import MUITooltip, {
  TooltipProps as MUITooltipProps,
} from '@mui/material/Tooltip'

type TooltipProps = { isDisabled?: boolean } & Pick<
  MUITooltipProps,
  'children' | 'title'
>

function Tooltip({ title, isDisabled, children }: TooltipProps) {
  return isDisabled ? (
    children
  ) : (
    <MUITooltip title={title} arrow={false}>
      {/* some components don't work well when wrapped with a MUI Tooltip, e.g. disabled buttons, so we wrap with a span */}
      <span>{children}</span>
    </MUITooltip>
  )
}

export { Tooltip }
