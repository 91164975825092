import styled from 'styled-components'
import * as Typography from '../Typography/Typography.styles'

export const StyledTextWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.white};
  padding: 2rem;
  border-radius: 1rem;
  max-width: 50rem;
  margin-bottom: 3rem;

  ${Typography.paragraphBody}
`
export const StyledToggleExpandButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0.5rem;
  ${Typography.paragraphBody}
  font-weight: bold;
`
