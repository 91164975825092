enum TransactionsEndpoints {
  GetTransactions = '/credit/transactions',
}

enum TransactionStatus {
  Pending = 'PENDING',
  Settled = 'SETTLED',
  Declined = 'DECLINED',
}

enum TransactionType {
  Purchase = 'PURCHASE',
  Return = 'RETURN',
  Payment = 'PAYMENT',
  Fee = 'FEE',
  Other = 'OTHER',
}

enum MerchantCategory {
  Other = 'OTHER',
}

type MerchantInfo = {
  name: string
  address?: string
  category: MerchantCategory
}

type TransactionCardInfo = {
  cardNumberLast4: string
}

type Transaction = {
  id: number
  amount: number
  status: TransactionStatus
  type: TransactionType
  description: string
  initialStatusTimestamp: number
  currentStatusTimestamp: number
  merchant?: MerchantInfo
  card?: TransactionCardInfo
}

type GetTransactionsQueryParams = {
  accountID: number
}

type GetTransactionsRespData = {
  transactions: Transaction[]
}

export {
  TransactionsEndpoints,
  TransactionStatus,
  TransactionType,
  MerchantCategory,
}
export type { GetTransactionsQueryParams, GetTransactionsRespData, Transaction }
