/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-underscore-dangle */
import { setUser as setSentryUser } from '@sentry/react'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { SessionStore, SessionStoreKey } from 'lib/sessionStore'
import { AuthenticationApi, LoginResponse } from 'api-connectors'
import { UserStatusDTO, useUserStatus, USER_STATUS_QUERY_KEY } from 'models'
import {
  clearLoggedInFlag,
  clearSession,
  flagAsLoggedIn,
  getSession,
  setSession,
} from './auth.utils'

interface LoginPayload {
  password: string
  businessEmail: string
}

type AuthStatus = 'AUTHENTICATED' | 'NOT_AUTHENTICATED' | 'LOADING' | 'IDLE'

type AuthenticationContextValue = {
  login: ((paylod: LoginPayload) => Promise<LoginResponse>) | undefined
  logout: (() => Promise<unknown>) | (() => void)
  checkSession: () => void
  status: AuthStatus
  userStatus: UserStatusDTO | undefined
  getSession: () => boolean
}

const initialValue: AuthenticationContextValue = {
  login: undefined,
  logout: () => {},
  checkSession: () => {},
  status: 'IDLE',
  userStatus: undefined,
  getSession: () => false,
}

export const AuthenticationContext = createContext(initialValue)

interface AuthenticationProviderProps {
  children: ReactNode
  sessionStore: SessionStore
}

const { login: loginApi, logout: logoutApi } = AuthenticationApi()

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const [status, setStatus] = useState<AuthStatus>('IDLE')
  const { children, sessionStore } = props

  const { checkSession: getUserStatus, userStatus } = useUserStatus()
  const client = useQueryClient()

  const checkSession = useCallback(async () => {
    setStatus('LOADING')
    try {
      clearLoggedInFlag()
      const response = await getUserStatus()

      // react query doesnt throw an error so it needs to be handled that way
      if (response.data) {
        setStatus('AUTHENTICATED')
        setSession(JSON.stringify(response.data.flags))
        flagAsLoggedIn()
      }
      if (response.isError) {
        client.removeQueries(USER_STATUS_QUERY_KEY)
        clearSession()
        sessionStore.clearAll()
        setStatus('NOT_AUTHENTICATED')
      }
    } catch (err) {
      setStatus('NOT_AUTHENTICATED')
    }
  }, [getUserStatus, client, sessionStore])

  const login = useCallback(
    async (payload: LoginPayload) => {
      const response = await loginApi(payload)
      if (response.data.data?.flags) {
        setSession(JSON.stringify(response.data.data.flags))
      }

      const { businessEmail } = payload
      const { accessToken, userId } = response.data.data
      sessionStore.set(SessionStoreKey.AccessToken, accessToken)
      sessionStore.set(SessionStoreKey.UserId, String(userId))
      setSentryUser({
        id: String(userId),
        email: businessEmail,
      })

      return response.data
    },
    [sessionStore]
  )

  const logout = useCallback(async () => {
    clearLoggedInFlag()
    await logoutApi()

    sessionStore.clearAll()
    setSentryUser(null)
    // remove react query cache after logout
    client.removeQueries()

    clearSession()
  }, [client, sessionStore])

  const memoizedContextValue: AuthenticationContextValue = useMemo(
    () => ({ login, logout, checkSession, status, userStatus, getSession }),
    [checkSession, status, userStatus, logout, login]
  )

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthenticationContext.Provider value={memoizedContextValue}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = () => {
  const ctx = useContext(AuthenticationContext)
  if (!ctx) {
    throw new Error('Component beyond Authentication Context')
  }

  return ctx
}
