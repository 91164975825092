import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackbars } from 'ui-kit'
import { RequireAuthNavigationState, RoutesPaths } from 'routing'
import { useAuth } from 'lib/context'
import { useTranslations } from 'translations'
import { AuthPageContainer } from 'shared'
import { Button, ButtonVariant, Link, TextField, Typography } from 'ui'
import { LoginFormData, useLoginForm } from './useLoginForm'
import {
  ForgotPasswordLinkContainer,
  SignupLinkContainer,
} from './LoginPage.styles'

function LoginPage() {
  const t = useTranslations()
  const navigate = useNavigate()
  const location = useLocation()
  const { showErrorSnackbar } = useSnackbars()
  const { control, handleSubmit } = useLoginForm()
  const { login, isLoggingIn } = useAuth()

  const onSubmit = ({ email, password }: LoginFormData) => {
    login(
      {
        email,
        password,
      },
      {
        onError: () => showErrorSnackbar(t('pages.newLogin.error')),
        onSuccess: () => {
          // If we were redirected to the LoginPage by RequireAuth, check Location state
          // for the route the user was trying to navigate to.
          const from = (
            location.state as RequireAuthNavigationState | undefined
          )?.from
          const path = from
            ? `${from.pathname}${from.search}`
            : RoutesPaths.Dashboard
          navigate(path, { replace: true })
        },
      }
    )
  }

  return (
    <AuthPageContainer
      title={t('pages.newLogin.title')}
      helmetTitle={t('pages.newLogin.seoTitle')}
    >
      <TextField
        name='email'
        label={t('pages.newLogin.form.email')}
        control={control}
        required
        type='email'
      />
      <TextField
        name='password'
        label={t('pages.newLogin.form.password')}
        control={control}
        required
        type='password'
      />

      <ForgotPasswordLinkContainer>
        <Link variant='caption' href={RoutesPaths.NewForgotPassword}>
          {t('pages.newLogin.actions.forgotYourPassword')}
        </Link>
      </ForgotPasswordLinkContainer>

      <Button
        label={t('pages.newLogin.actions.login')}
        variant={ButtonVariant.Primary}
        sizeVariant='large'
        isLoading={isLoggingIn}
        type='submit'
        onClick={handleSubmit(onSubmit)}
      />

      <SignupLinkContainer>
        <Typography variant='caption'>
          {t('pages.newLogin.actions.signUp.text')}
          <Link variant='caption' href={RoutesPaths.NewSignUp}>
            {t('pages.newLogin.actions.signUp.link')}
          </Link>
        </Typography>
      </SignupLinkContainer>
    </AuthPageContainer>
  )
}

export { LoginPage }
