import { QueryDependency, QueryKey } from '../types'

const transactionsKeys = {
  all: ['transactions'] as const,
  byAccount: (accountId: QueryDependency): QueryKey => [
    ...transactionsKeys.all,
    'account',
    accountId,
  ],
} as const

export { transactionsKeys }
