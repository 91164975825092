import styled from 'styled-components'
import { CardContent, Typography, TypographyProps } from 'ui'

const StyledCardContent = styled(CardContent)`
  align-items: flex-start;
`

const H1Currency = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h1' component='span' />
))`
  font-family: ${({ theme }) => theme.fontFamily.body};
`

const H2Currency = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h2' component='span' />
))`
  font-family: ${({ theme }) => theme.fontFamily.body};
`

export { StyledCardContent, H1Currency, H2Currency }
