/* eslint-disable @typescript-eslint/no-explicit-any */
import MUITableContainer from '@mui/material/TableContainer'
import MUITable from '@mui/material/Table'
import { rankItem } from '@tanstack/match-sorter-utils'
import MUITableBody from '@mui/material/TableBody'
import Stack from '@mui/material/Stack'
import {
  useReactTable,
  getCoreRowModel,
  Row,
  getExpandedRowModel,
  getPaginationRowModel,
  getFilteredRowModel as filteredRowModel,
  VisibilityState,
  AccessorKeyColumnDef,
  SortingState,
  getSortedRowModel,
  ColumnSort,
  PaginationState,
} from '@tanstack/react-table'
import React, { ReactNode, useState } from 'react'
import { Typography } from 'ui/Typography/Typography.component'
import {
  EmptyPaper,
  StyledPaper,
  TableHeader,
  TablePagination,
} from './Table.styles'
import { TableRow } from './components/TableRow.component'
import { TableFilter } from './components/TableFilter.component'
import { isFilteredColumn, ColumnDef } from './types'
import { TableHead } from './components/TableHead.component'
import { TableSearchBar } from './components/TableSearchBar.component'

type TableSearchProps = {
  placeholder: string
}

type PageSizeOption = 10 | 25 | 50

const ROWS_PER_PAGE_OPTIONS: PageSizeOption[] = [10, 25, 50]

type TableProps<TData> = {
  header?: string
  columns: ColumnDef<TData, any>[]
  data: TData[]
  emptyMessage: string
  isPaginated?: boolean
  defaultSorting?: ColumnSort
  defaultPageSize?: PageSizeOption
  searchBarProps?: TableSearchProps
  // if renderExpandedRowContent is defined, then onClickRow is set to toggle expanded row state
  renderExpandedRowContent?: (row: Row<TData>) => ReactNode
  onClickRow?: (row: Row<TData>) => void
  Footer?: ReactNode
}

function useColumnVisibility<TData>(columns: ColumnDef<TData, any>[]) {
  const [columnVisibility] = useState<VisibilityState>(() => {
    const visibilityState: VisibilityState = {}
    columns.forEach(column => {
      if (column.meta?.isHidden) {
        const id =
          column.id || (column as AccessorKeyColumnDef<TData>).accessorKey
        visibilityState[String(id)] = false
      }
    })
    return visibilityState
  })

  return [columnVisibility]
}

function fuzzyFilter<TData>(row: Row<TData>, columnId: string, value: string) {
  return rankItem(row.getValue(columnId), value).passed
}

function Table<TData>({
  header: title,
  columns,
  data,
  emptyMessage,
  isPaginated = true,
  defaultSorting,
  defaultPageSize = 10,
  searchBarProps,
  renderExpandedRowContent,
  onClickRow,
  Footer,
}: TableProps<TData>) {
  const [columnVisibility] = useColumnVisibility(columns)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  })
  const [sorting, setSorting] = useState<SortingState>(
    defaultSorting ? [defaultSorting] : []
  )
  const [globalFilter, setGlobalFilter] = useState('')

  const { getFlatHeaders, getRowModel, getAllColumns, getFilteredRowModel } =
    useReactTable({
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getFilteredRowModel: filteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onGlobalFilterChange: setGlobalFilter,
      onSortingChange: setSorting,
      globalFilterFn: fuzzyFilter,
      sortDescFirst: false,
      getRowCanExpand: () => !!renderExpandedRowContent,
      state: {
        columnVisibility,
        globalFilter,
        pagination,
        sorting,
      },
    })

  const filteredColumns = getAllColumns().filter(isFilteredColumn)

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPagination(prev => {
      return {
        ...prev,
        pageIndex: page,
      }
    })
  }

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPagination({
      pageIndex: 0,
      pageSize: Number(e.target.value),
    })
  }

  if (data.length === 0) {
    return (
      <>
        {title && <TableHeader>{title}</TableHeader>}
        <EmptyPaper>
          <Typography variant='h3'>{emptyMessage}</Typography>
        </EmptyPaper>
      </>
    )
  }

  return (
    <>
      {title && <TableHeader>{title}</TableHeader>}
      <MUITableContainer component={StyledPaper}>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={1}
          mr={1}
          mt={searchBarProps ? 1 : 0}
        >
          {!!filteredColumns.length && (
            <TableFilter filteredColumns={filteredColumns} />
          )}
          {!!searchBarProps && (
            <TableSearchBar
              value={globalFilter}
              placeholder={searchBarProps.placeholder}
              onChange={setGlobalFilter}
            />
          )}
        </Stack>

        <MUITable>
          <TableHead
            headers={getFlatHeaders()}
            sorting={sorting}
            hasExpandableRows={!!renderExpandedRowContent}
          />

          <MUITableBody>
            {getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                row={row}
                renderExpandedRowContent={renderExpandedRowContent}
                onClickRow={onClickRow}
              />
            ))}
          </MUITableBody>
        </MUITable>

        {isPaginated && (
          <TablePagination
            component='div'
            count={getFilteredRowModel().rows.length}
            page={pagination.pageIndex}
            onPageChange={onPageChange}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowsPerPage={pagination.pageSize}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        )}

        {Footer}
      </MUITableContainer>
    </>
  )
}

export { Table }
