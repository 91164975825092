/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import { rest, DefaultRequestBody, PathParams } from 'msw'
import { getEnvVariable, oneDay } from 'utils'
import { DashboardSummaryResponse } from '../DashboardApi.types'

const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

const createTransaction = () => ({
  id: `787544-${Date.now()}`,
  date: '2022-07-04',
  time: '06:29:17',
  startDate: '2022-07-04',
  startTime: '06:29:17',
  description: 'Card payment description',
  amount: -1579.99,
  type: 'Card',
  transactionCurrency: 'USD',
  transactionAmount: -1579.99,
  cardNumber: '**** **** **** 2244',
  pending: false,
})

export const simpleDashboardSummary = [
  rest.post<DefaultRequestBody, PathParams, DashboardSummaryResponse>(
    `${baseUrl}/dashboard/summary`,
    async (req, res, ctx) => {
      return res(
        ctx.status(201),
        ctx.json({
          data: {
            currency: 'USD',
            expensesThisMonth: {
              total: 3000.0,
              byDate: Array(31)
                .fill({})
                .map((_, index) => ({
                  value: Math.floor(Math.random() * 100),
                  date: new Date(2022, 1, index + 1, 12, 0, 0).toISOString(),
                  dateEnd: new Date(2022, 1, index + 1, 12, 0, 0).toISOString(),
                })),
            },
            credit: {
              total: 15000.0,
              available: 12000.0,
              expenses: 3000.0,
              percentage: 80.0,
            },
            repayment: {
              date: new Date(Date.now() + oneDay * 5).toISOString(),
              next: 346.0,
              overdue: 0.0,
              debt: 3000.0,
            },
            latestTransactions: [
              createTransaction(),
              createTransaction(),
              createTransaction(),
              createTransaction(),
            ],
          },
        })
      )
    }
  ),
]
