import { ReactNode, useMemo } from 'react'
import { DateFormat, stringFormatter, useFormatDate } from 'lib/formatter'
import { useTranslations } from 'translations'
import { Link, Typography } from 'ui'
import { RoutesPaths } from 'routing'
import {
  Container,
  ListItemContainer,
} from './ExpandedTransactionRowContent.styles'

type ExpandedTransactionRowContentProps = {
  id: number
  cardNumberLast4?: string
  postedDate?: Date
}

export function ExpandedTransactionRowContent({
  id,
  cardNumberLast4,
  postedDate,
}: ExpandedTransactionRowContentProps) {
  const t = useTranslations()
  const formatDate = useFormatDate()

  const listItems = useMemo(() => {
    const items: {
      label: string
      value: ReactNode
    }[] = [
      {
        label: t('shared.transactionsTable.expandedRowContent.id'),
        value: id,
      },
    ]

    if (postedDate) {
      items.push({
        label: t('shared.transactionsTable.expandedRowContent.postedDate'),
        value: formatDate(postedDate, DateFormat.MonthDayYear),
      })
    }

    if (cardNumberLast4) {
      items.push({
        label: t('shared.transactionsTable.expandedRowContent.cardNumber'),
        value: stringFormatter.displayLastFourDigits(cardNumberLast4),
      })
    }

    return items
  }, [id, cardNumberLast4, postedDate, formatDate, t])

  return (
    <Container>
      {listItems.map(({ label, value }) => (
        <ListItemContainer key={`${label}-${value}`}>
          <Typography variant='caption'>{label}</Typography>
          <Typography variant='caption'>{value}</Typography>
        </ListItemContainer>
      ))}

      <Link href={RoutesPaths.ContactSupport} variant='caption' openInNewPage>
        {t('shared.transactionsTable.expandedRowContent.report')}
      </Link>
    </Container>
  )
}
