import { ReactNode } from 'react'
import {
  BrowserRouter,
  unstable_HistoryRouter as TestRouter,
} from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history'

interface RoutesProviderProps {
  children: ReactNode
  history?: History
}

export const RoutesProvider = (props: RoutesProviderProps) => {
  const { children, history } = props

  if (history) {
    return <TestRouter history={history}>{children}</TestRouter>
  }

  return <BrowserRouter>{children}</BrowserRouter>
}
