import { ReactNode } from 'react'
import styled from 'styled-components'
import MUICard from '@mui/material/Card'

type CardProps = {
  children: ReactNode
  className?: string
}

const StyledCard = styled(MUICard)`
  display: inline-block;
  background: ${({ theme }) => theme.color.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.color.transparent};
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};

  &.MuiCard-root {
    padding: 1rem 1.5rem;

    ${({ theme }) => theme.media.minTablet} {
      padding: 1rem 2rem;
    }
  }
`

function Card({ children, className }: CardProps) {
  return (
    <StyledCard className={className} sx={{ padding: 0 }}>
      {children}
    </StyledCard>
  )
}

export { Card }
