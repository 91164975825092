import { QueryDependency, QueryKey } from '../types'

const paymentSchedulesKeys = {
  all: ['paymentSchedules'] as const,
  byAccount: (accountId: QueryDependency): QueryKey => [
    ...paymentSchedulesKeys.all,
    'account',
    accountId,
  ],
} as const

export { paymentSchedulesKeys }
