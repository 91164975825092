import styled, { css } from 'styled-components'
import { TableCell } from '../../Table/Table.styles'
import { IconButton } from '../../IconButton/IconButton.component'

export const TransactionCell = styled(TableCell)`
  padding: 0.375rem;
  color: ${({ theme }) => theme.color.green[700]};

  &:first-of-type {
    padding-left: 1rem;
  }

  ${({ theme }) => theme.media.minTablet} {
    padding: 1rem;
  }
`
export const TransactionDateCell = styled(TableCell)`
  display: none;
  color: ${({ theme }) => theme.color.green[700]};
  ${({ theme }) => theme.media.minTablet} {
    display: table-cell;
  }
`

export const ExpenseDescriptionCell = styled(TransactionCell)`
  width: 100%;
  max-width: 0;
`

export const TransactionAmountCell = styled(TransactionCell)`
  white-space: nowrap;
`

export const TransactionDetailsCell = styled(TableCell)`
  border: unset;
  padding: 0;
`

export const DetailedTransactionDate = styled.p`
  display: block;
  color: ${({ theme }) => theme.color.backgroundGreen};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.media.minTablet} {
    display: none;
  }
`
export const TransactionDescription = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TransactionAmount = styled.span<{
  $isIncome?: boolean
}>`
  ${({ $isIncome, theme }) =>
    $isIncome &&
    css`
      color: ${theme.color.secondary};
    `}
`

export const TransactionInfo = styled.div<{
  $isIncome?: boolean
  $isPending?: boolean
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  svg,
  path {
    width: 16px;
    height: auto;
    fill: ${({ $isIncome, $isPending, theme }) => {
      if ($isPending) {
        return theme.color.green[300]
      }
      if ($isIncome) {
        return theme.color.secondary
      }
      return theme.color.backgroundGreen
    }};
  }
`

export const TransactionInfoText = styled.div`
  width: 100%;
  min-width: 0;
`

export const StyledIconButton = styled(IconButton)`
  margin-left: 1.5rem;
`
