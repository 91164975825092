import { ReactNode } from 'react'
import { Helmet } from 'seo'
import { StyledMain } from 'shared/LayoutMain/LayoutMain.styles'
import { Subtitle, Title } from './PageContainer.styles'

type PageContainerProps = {
  title?: string
  subtitle?: string
  helmetTitle: string
  children: ReactNode
  className?: string
}

export function PageContainer({
  title,
  helmetTitle,
  subtitle,
  children,
  className,
}: PageContainerProps) {
  return (
    <>
      <Helmet title={`${helmetTitle} - Fundid`} />
      <StyledMain id='main' className={className}>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </StyledMain>
    </>
  )
}
