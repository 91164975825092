import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import { useTranslations } from 'translations'
import { AdapterInputCheckbox } from '../../formik/AdapterInputCheckbox/AdapterInputCheckbox.component'

export const marketLimitationFieldValidationSchema = yup
  .boolean()
  .isTrue(() => (
    <FormattedMessage id='formKit.marketLimitationField.validation' />
  ))

export const MarketLimitaionField = () => {
  const t = useTranslations()

  const label = t('formKit.marketLimitationField.label')
  const description = t('formKit.marketLimitationField.description')

  return (
    <Field
      name='marketLimitationsConsent'
      label={label}
      description={description}
      component={AdapterInputCheckbox}
    />
  )
}
