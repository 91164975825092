enum BankAccountsEndpoints {
  GetBankAccounts = '/bank-accounts',
  GenerateFinicityConnectURL = '/bank-accounts/finicity-connect',
  GenerateFinicityConnectVerifyURL = '/bank-accounts/finicity-connect/verify',
}

// BankAccountType defines the type of a bank account (e.g. checking vs savings).
enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

enum BankAccountStatus {
  // BankAccountStatusPendingMicrodeposits means the bank account is pending microdeposit completion,
  // and is not yet verifiable.
  // Once the microdeposits have been completed, the account will be ready for verification.
  PendingMicrodeposits = "PENDING_MICRODEPOSITS",

  // BankAccountStatusPending means the bank account is pending microdeposit verification,
  // and is not yet fully usable.
  // The account may need manual verification if Finicity is unable to verify it automatically.
  // Most notably, this means the full account number cannot yet be retrieved from Finicity.
  PendingVerification = "PENDING_VERIFICATION",

  // BankAccountStatusActive means the bank account is fully usable.
  // Most notably, this means the full account number can be retrieved from Finicity.
  Active = "ACTIVE",

  // BankAccountStatusVerified means the bank account was verified
  // via Finicity AVA. It can be treated like an "Active" bank account now.
  Verified = "VERIFIED",
}

type ProcessorBankAccount = {
  bankAccountID: number // CoreCard MethodName
}

type FinicityBankAccount = {
  accountID: string // Bank account ID
  institutionID: string // Bank ID
  institutionLoginID: number // Bank login ID
}

type BankAccount = {
  id: number
  companyID: number
  userID: number // User that added this bank
  routingNumber: string
  accountNumberDisplay: string
  name: string // e.g. "Chase"
  status: BankAccountStatus
  type: BankAccountType
  processor?: ProcessorBankAccount
  finicity: FinicityBankAccount
}

type GetBankAccountsQueryParams = {
  companyID: number
}

type GetBankAccountsRespData = {
  bankAccounts: BankAccount[]
}

type GenerateFinicityConnectURLReqBody = {
  userID: number
  companyID: number
}

type GenerateFinicityConnectURLRespData = {
  url: string
}

type GenerateFinicityConnectVerifyURLReqBody = {
  userID: number
  companyID: number
  bankAccountID: number
}

type GenerateFinicityConnectVerifyURLRespData = {
  url: string
}

export type {
  BankAccount,
  GetBankAccountsQueryParams,
  GetBankAccountsRespData,
  GenerateFinicityConnectURLReqBody,
  GenerateFinicityConnectURLRespData,
  GenerateFinicityConnectVerifyURLReqBody,
  GenerateFinicityConnectVerifyURLRespData,
}
export { BankAccountsEndpoints, BankAccountType, BankAccountStatus }
