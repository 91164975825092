import { ReactNode } from 'react'
import { Transaction } from 'api'
import { Table } from 'ui'
import { useTranslations } from 'translations'
import { ExpandedTransactionRowContent } from './ExpandedTransactionRowContent.component'
import { useTransactionsTable } from './hooks/useTransactionsTable'

type TransactionsTableProps = {
  header?: string
  transactions: Transaction[]
  isPaginated?: boolean
  Footer?: ReactNode
}

function TransactionsTable({
  header,
  transactions,
  isPaginated = true,
  Footer,
}: TransactionsTableProps) {
  const t = useTranslations()
  const { columns, data } = useTransactionsTable(transactions)

  return (
    <Table
      header={header}
      columns={columns}
      data={data}
      emptyMessage={t('shared.transactionsTable.emptyMessage')}
      isPaginated={isPaginated}
      defaultSorting={{ id: 'date', desc: true }}
      searchBarProps={{
        placeholder: t('shared.transactionsTable.searchBarPlaceholder'),
      }}
      renderExpandedRowContent={({
        original: { id, cardNumberLast4, postedDate },
      }) => (
        <ExpandedTransactionRowContent
          id={id}
          cardNumberLast4={cardNumberLast4}
          postedDate={postedDate}
        />
      )}
      Footer={Footer}
    />
  )
}

export { TransactionsTable }
