import { FundidDate, Name } from './shared'

enum CardsEndpoints {
  ActivateCard = '/credit/cards/activate',
  GetCards = '/credit/cards',
  GetSensitiveCardInfo = '/credit/cards/sensitive',
  UpdateCardStatus = '/credit/cards/status',
}

enum CardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

enum CardStatus {
  NotYetActive = 'NOT_YET_ACTIVATED',
  Active = 'ACTIVE',
  Frozen = 'FROZEN',
  Closed = 'CLOSED',
}

type Card = {
  id: number
  accountID: number
  type: CardType
  status: CardStatus
  cardNumberLast4: string
  expDate: FundidDate
  name: Name
}

type ActivateCardReqBody = {
  accountID: number
  cardID: number
  dateOfBirth: FundidDate
  cardNumberLast4: string
}

type GetCardsQueryParams = {
  accountID: number
}

type GetCardsRespData = {
  cards: Card[]
}

type UpdateCardStatusReqBody = {
  accountID: number
  cardID: number
  status: CardStatus
}

type GetSensitiveCardInfoQueryParams = {
  accountID: number
  cardID: number
}

type GetSensitiveCardInfoRespBody = {
  cardNumber: string
  cvv: string
}

export { CardsEndpoints, CardStatus, CardType }
export type {
  ActivateCardReqBody,
  Card,
  GetCardsQueryParams,
  GetCardsRespData,
  GetSensitiveCardInfoQueryParams,
  GetSensitiveCardInfoRespBody,
  UpdateCardStatusReqBody,
}
