import { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField'
import { ReactNode } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { StyledTextField } from './TextField.styles'

type TextFieldProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  helperText?: string
  required?: boolean
  disabled?: boolean
  select?: boolean
  children?: ReactNode
} & Partial<Pick<MUITextFieldProps, 'InputProps' | 'type'>>

function TextField<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  helperText,
  select,
  disabled,
  required,
  type,
  InputProps,
  children,
}: TextFieldProps<TFieldValues>) {
  const {
    field: { value, ref: inputRef, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <StyledTextField
      label={label}
      value={value}
      helperText={error ? error.message : helperText}
      required={required}
      aria-required={required}
      disabled={disabled}
      aria-disabled={disabled}
      error={!!error}
      select={select}
      type={type}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={inputRef}
      InputProps={InputProps}
    >
      {children}
    </StyledTextField>
  )
}

export type { TextFieldProps }
export { TextField }
