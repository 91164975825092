import styled from 'styled-components'
import { Link } from 'ui'

const StyledLink = styled(Link)`
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;

  transition: background-color 0.3s ease;
  :hover {
    background-color: ${({ theme }) => theme.color.grey[400]};
  }
`

export { StyledLink }
