/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { DateFormat, dateFormatter, useFormatDate } from 'lib/formatter'
import {
  Grant,
  AmountFilter,
  Tag,
  apiGrantToGrant,
  US_STATE_OPTIONS,
  USState,
  amountComparator,
  useFormatAmount,
  AMOUNT_FILTER_OPTIONS,
  isFeaturedComparator,
} from 'lib/models'
import { useGrants } from 'lib/queries'
import {
  Button,
  ButtonVariant,
  ColumnDef,
  TABLE_FILTER_SELECT_FIELD_ANY_OPTION,
  TableFilterOption,
  TableImageCell,
} from 'ui'
import { useTranslations } from 'translations'

type GrantRow = Pick<
  Grant,
  | 'amountFilters'
  | 'amountMin'
  | 'amountMax'
  | 'id'
  | 'name'
  | 'states'
  | 'tags'
  | 'isFeatured'
> & {
  deadline: Date
  logoUrl: string
}

const columnHelper = createColumnHelper<GrantRow>()

function filterFnAmount(
  row: Row<GrantRow>,
  _: string,
  filterValue: Set<AmountFilter>
): boolean {
  // Pass if no filters applied
  if (filterValue.size === 0) {
    return true
  }

  // Pass if amountFilters includes any applied filters
  let pass = false
  filterValue.forEach(val => {
    if (row.original.amountFilters.has(val)) {
      pass = true
    }
  })

  return pass
}

function filterFnStates(
  row: Row<GrantRow>,
  _: string,
  filterValue: USState | typeof TABLE_FILTER_SELECT_FIELD_ANY_OPTION
): boolean {
  if (filterValue === TABLE_FILTER_SELECT_FIELD_ANY_OPTION) {
    return true
  }

  return row.original.states.has(filterValue)
}

function filterFnTags(
  row: Row<GrantRow>,
  _: string,
  filterValue: Set<Tag>
): boolean {
  // Pass if no filters applied
  if (filterValue.size === 0) {
    return true
  }

  // Pass if tagFilters includes any applied filters
  let pass = false
  filterValue.forEach(val => {
    if (row.original.tags.has(val)) {
      pass = true
    }
  })

  return pass
}

function sortingFnDeadline(rowA: Row<GrantRow>, rowB: Row<GrantRow>): number {
  const {
    original: { deadline: deadlineA, isFeatured: isFeaturedA },
  } = rowA
  const {
    original: { deadline: deadlineB, isFeatured: isFeaturedB },
  } = rowB

  return isFeaturedComparator(isFeaturedA, isFeaturedB, () => {
    return deadlineA < deadlineB ? -1 : 1
  })
}

function sortingFnAmount(rowA: Row<GrantRow>, rowB: Row<GrantRow>): number {
  const {
    original: {
      amountMin: amountMinA,
      amountMax: amountMaxA,
      isFeatured: isFeaturedA,
    },
  } = rowA
  const {
    original: {
      amountMin: amountMinB,
      amountMax: amountMaxB,
      isFeatured: isFeaturedB,
    },
  } = rowB

  return isFeaturedComparator(isFeaturedA, isFeaturedB, () => {
    return amountComparator(
      { min: amountMinA, max: amountMaxA },
      { min: amountMinB, max: amountMaxB }
    )
  })
}

function useTagFilterOptions(): TableFilterOption[] {
  const t = useTranslations()

  return [
    {
      label: t('ui.filter.tags.women'),
      value: Tag.Women,
    },
    {
      label: t('ui.filter.tags.poc'),
      value: Tag.PeopleOfColor,
    },
    {
      label: t('ui.filter.tags.government'),
      value: Tag.Government,
    },
  ]
}

const SEARCH_PARAM_KEY_GRANT_ID = 'id'

function useSelectedGrant(grantsById: Map<number, Grant>) {
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedGrant = useMemo(() => {
    const loanId = searchParams.get(SEARCH_PARAM_KEY_GRANT_ID)
    return grantsById.get(Number(loanId))
  }, [searchParams, grantsById])

  const setSelectedGrant = useCallback(
    (id: number) => {
      setSearchParams({
        [SEARCH_PARAM_KEY_GRANT_ID]: String(id),
      })
    },
    [setSearchParams]
  )

  const clearSelectedGrant = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  return {
    selectedGrant,
    setSelectedGrant,
    clearSelectedGrant,
  }
}

function useGrantsTable() {
  const t = useTranslations()
  const formatDate = useFormatDate()
  const formatAmount = useFormatAmount()
  const tagFilterOptions = useTagFilterOptions()

  const { data: grantsData } = useGrants(resp => {
    const grantsById = new Map<number, Grant>()

    const grants = resp.grants.map(apiGrant => {
      const grant = apiGrantToGrant(apiGrant)
      grantsById.set(grant.id, grant)
      return grant
    })

    return {
      grants,
      grantsById,
    }
  })

  const { selectedGrant, setSelectedGrant, clearSelectedGrant } =
    useSelectedGrant(grantsData?.grantsById || new Map())

  const columns: ColumnDef<GrantRow, any>[] = useMemo(() => {
    const columnDefs: ColumnDef<GrantRow, any>[] = [
      {
        accessorKey: 'name',
        header: t('pages.grantMatchProgram.table.grant'),
        enableSorting: false,
        cell: info => (
          <TableImageCell
            label={info.getValue()}
            url={info.row.original.logoUrl}
            chip={
              info.row.original.isFeatured
                ? { label: t('ui.table.featured') }
                : undefined
            }
          />
        ),
      },
      {
        accessorKey: 'deadline',
        header: t('pages.grantMatchProgram.table.deadline'),
        sortingFn: sortingFnDeadline,
        cell: info => formatDate(info.getValue(), DateFormat.MonthDayYear),
      },
      {
        accessorKey: 'tags',
        filterFn: filterFnTags,
        meta: {
          isHidden: true,
          filterConfig: {
            label: t('ui.filter.tags.label'),
            options: tagFilterOptions,
            type: 'checkbox',
          },
        },
      },
      {
        id: 'amount',
        header: t('pages.grantMatchProgram.table.amount'),
        accessorFn: row => formatAmount(row.amountMin, row.amountMax),
        filterFn: filterFnAmount,
        sortingFn: sortingFnAmount,
        cell: info =>
          formatAmount(
            info.row.original.amountMin,
            info.row.original.amountMax
          ),
        meta: {
          align: 'right',
          filterConfig: {
            label: t('ui.filter.amount.label'),
            options: AMOUNT_FILTER_OPTIONS,
            type: 'checkbox',
          },
        },
      },
      columnHelper.display({
        id: 'learnMore',
        cell: info => (
          <Button
            label={t('pages.grantMatchProgram.table.learnMore')}
            variant={ButtonVariant.Tertiary}
            onClick={() => {
              setSelectedGrant(info.row.original.id)
            }}
          />
        ),
        meta: {
          align: 'right',
        },
      }),
      {
        accessorKey: 'states',
        filterFn: filterFnStates,
        meta: {
          isHidden: true,
          filterConfig: {
            label: t('ui.filter.state.label'),
            options: US_STATE_OPTIONS,
            type: 'select',
          },
        },
      },
    ]

    return columnDefs
  }, [tagFilterOptions, formatDate, formatAmount, t, setSelectedGrant])

  const data: GrantRow[] = useMemo(() => {
    return (grantsData?.grants || []).map(
      ({
        amountFilters,
        amountMin,
        amountMax,
        closeDate,
        partner: { logoURL },
        id,
        name,
        states,
        tags,
        isFeatured,
      }) => ({
        amountFilters,
        amountMin,
        amountMax,
        deadline: dateFormatter.toDate(closeDate),
        id,
        logoUrl: logoURL,
        name,
        states,
        tags,
        isFeatured,
      })
    )
  }, [grantsData])

  return {
    columns,
    data,
    selectedGrant,
    setSelectedGrant,
    clearSelectedGrant,
    isLoading: !grantsData,
  }
}

export type { GrantRow }
export { useGrantsTable }
