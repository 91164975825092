import {
  MerchantCategory,
  Transaction,
  TransactionStatus,
  TransactionType,
} from 'api/types/transactions'
import { randomInt } from './shared'

const MOCK_PHYSICAL_CARD_NUMBER_LAST_4 = '3314'
const MOCK_VIRTUAL_CARD_NUMBER_LAST_4 = '9989'

const MOCK_TRANSACTIONS: Transaction[] = [
  {
    id: randomInt(1, 10000),
    amount: 12.22,
    card: { cardNumberLast4: MOCK_PHYSICAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1677601214,
    description: '',
    initialStatusTimestamp: 1677601214,
    merchant: { name: 'Starbucks', category: MerchantCategory.Other },
    status: TransactionStatus.Pending,
    type: TransactionType.Purchase,
  },
  {
    id: randomInt(1, 10000),
    amount: 5.39,
    card: { cardNumberLast4: MOCK_PHYSICAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1677601214,
    description: '',
    initialStatusTimestamp: 1677042000,
    merchant: { name: 'Philz', category: MerchantCategory.Other },
    status: TransactionStatus.Pending,
    type: TransactionType.Purchase,
  },
  {
    id: randomInt(1, 10000),
    amount: -123.12,
    card: { cardNumberLast4: MOCK_VIRTUAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1674536397,
    description: 'Payment - Thank you!',
    initialStatusTimestamp: 1674536397,
    merchant: { name: 'Fundid', category: MerchantCategory.Other },
    status: TransactionStatus.Settled,
    type: TransactionType.Payment,
  },
  {
    id: randomInt(1, 10000),
    amount: 25,
    card: { cardNumberLast4: MOCK_VIRTUAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1671771597,
    description: 'Late payment fee',
    initialStatusTimestamp: 1671771597,
    merchant: { name: 'Fundid', category: MerchantCategory.Other },
    status: TransactionStatus.Settled,
    type: TransactionType.Fee,
  },
  {
    id: randomInt(1, 10000),
    amount: 1123.52,
    card: { cardNumberLast4: MOCK_VIRTUAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1669735379,
    description: 'Purchase',
    initialStatusTimestamp: 1669735379,
    merchant: { name: 'Sketchy mode', category: MerchantCategory.Other },
    status: TransactionStatus.Declined,
    type: TransactionType.Purchase,
  },
  {
    id: randomInt(1, 10000),
    amount: -51.76,
    card: { cardNumberLast4: MOCK_VIRTUAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1669734379,
    description: 'Return',
    initialStatusTimestamp: 1669734379,
    merchant: { name: 'Target', category: MerchantCategory.Other },
    status: TransactionStatus.Settled,
    type: TransactionType.Return,
  },
  {
    id: randomInt(1, 10000),
    amount: 210.88,
    card: { cardNumberLast4: MOCK_PHYSICAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1669734370,
    description: '',
    initialStatusTimestamp: 1669734370,
    merchant: { name: 'IKEA', category: MerchantCategory.Other },
    status: TransactionStatus.Settled,
    type: TransactionType.Purchase,
  },
  {
    id: randomInt(1, 10000),
    amount: 75.0,
    card: { cardNumberLast4: MOCK_PHYSICAL_CARD_NUMBER_LAST_4 },
    currentStatusTimestamp: 1669734170,
    description: 'Annual membership fee',
    initialStatusTimestamp: 1669734170,
    merchant: { name: 'Costco', category: MerchantCategory.Other },
    status: TransactionStatus.Settled,
    type: TransactionType.Other,
  },
]

export { MOCK_TRANSACTIONS }
