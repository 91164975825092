import { AuthPageContainer } from 'shared'
import { useTranslations } from 'translations'
import { Button, ButtonVariant, Link, TextField } from 'ui'
import { RoutesPaths } from 'routing'
import { useForgotPassword } from 'lib/queries'
import { useSnackbars } from 'ui-kit'
import {
  ForgotPasswordFormData,
  useForgotPasswordForm,
} from './useForgotPasswordForm'
import { ButtonContainer } from './ForgotPasswordPage.styles'

function ForgotPasswordPage() {
  const t = useTranslations()
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbars()
  const { control, handleSubmit, getValues } = useForgotPasswordForm()
  const {
    mutate: sendForgotPassword,
    isLoading: isSendingForgotPassword,
    isSuccess: isSuccessSendForgotPassword,
  } = useForgotPassword()

  const onSubmit = ({ email }: ForgotPasswordFormData) => {
    sendForgotPassword(
      { email },
      {
        onSuccess: () =>
          showSuccessSnackbar(t('pages.newForgotPassword.success.snackbar')),
        onError: () => showErrorSnackbar(t('pages.newForgotPassword.error')),
      }
    )
  }

  return (
    <AuthPageContainer
      title={t('pages.newForgotPassword.title')}
      subtitle={
        isSuccessSendForgotPassword
          ? t('pages.newForgotPassword.success.subtitle', {
              email: getValues().email,
            })
          : t('pages.newForgotPassword.subtitle')
      }
      helmetTitle={t('pages.newForgotPassword.seoTitle')}
    >
      {!isSuccessSendForgotPassword && (
        <>
          <TextField
            name='email'
            label={t('pages.newForgotPassword.form.email')}
            control={control}
            required
            type='email'
          />

          <ButtonContainer>
            <Button
              label={t('pages.newForgotPassword.actions.submit')}
              variant={ButtonVariant.Primary}
              sizeVariant='large'
              type='submit'
              isLoading={isSendingForgotPassword}
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonContainer>
        </>
      )}

      <Link variant='caption' href={RoutesPaths.NewLogin}>
        {t('pages.newForgotPassword.actions.returnToLogin')}
      </Link>
    </AuthPageContainer>
  )
}

export { ForgotPasswordPage }
