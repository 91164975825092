import styled, { css } from 'styled-components'

const WIDTH_OPENED_SIDEBAR = '292px'
const WIDTH_CLOSED_SIDEBAR = '88px'

const StyledSidebar = styled.aside<{ isNavOpen: boolean }>`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${({ theme }) => theme.color.primary};
  padding: 2rem 1rem;

  ${({ isNavOpen }) => {
    if (isNavOpen) {
      return css`
        width: ${WIDTH_OPENED_SIDEBAR};
        transition: width 0.2s;
      `
    }
    return css`
      transition: width 0.2s;
      width: ${WIDTH_CLOSED_SIDEBAR};
    `
  }};

  ${({ theme }) => theme.media.minTablet} {
    display: block;
  }
`

export { WIDTH_CLOSED_SIDEBAR, WIDTH_OPENED_SIDEBAR, StyledSidebar }
