import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useBrowserUrl } from 'routing'
import { useTranslations } from 'translations'
import { Button, ButtonVariant } from 'ui'
import { FullScreenLoader, FeedbackPage, IconType, useSnackbars } from 'ui-kit'
import { isAxiosError, isServerError } from 'error-handling'
import { useLogger } from 'logger'
import { RegistrationApi } from 'api-connectors'
import { ActivateAccountSeo } from './ActivateAccountSeo.component'

// it must be declare here - after every setStatus call, there was new reference to activateAccount initialized in the component, which caused doubled api call (useEffect)
const { activateAccount } = RegistrationApi()

export const ActivateAccountPage = () => {
  const [status, setStatus] = useState<'LOADING' | 'SUCCESS' | 'FAIL'>(
    'LOADING'
  )
  const { showSuccessSnackbar } = useSnackbars()
  const [errorParagraph, setErrorParagraph] = useState('')
  const [searchParams] = useSearchParams()
  const { goToLoginPage } = useBrowserUrl()
  const t = useTranslations()
  const { logger } = useLogger()
  const code = searchParams.get('code')

  useEffect(() => {
    if (!code) {
      goToLoginPage()
    }
  }, [code, goToLoginPage])

  useEffect(() => {
    if (!code) return
    const handleActivateAccount = async () => {
      try {
        await activateAccount({ activationCode: code })
        showSuccessSnackbar(t('pages.activateAccountPage.success.snackbar'))
        goToLoginPage()
      } catch (err) {
        if (isAxiosError(err)) {
          if (isServerError(err)) {
            const message = t('errorMessages.SYSTEM_FAILURE')
            setErrorParagraph(message)

            logger.error(message, {
              error: err.response?.data,
            })
          } else {
            const statusCode = err.response?.status

            if (statusCode && statusCode === 404) {
              const message = t('pages.activateAccountPage.fail.content')
              setErrorParagraph(message)
            } else {
              const message = t('errorMessages.UNEXPECTED_ERROR')
              setErrorParagraph(message)
            }
          }
        } else {
          const message = t('errorMessages.UNEXPECTED_ERROR')
          setErrorParagraph(message)
          logger.error(message, {
            error: err,
          })
        }

        setStatus('FAIL')
      }
    }

    handleActivateAccount()
  }, [code, goToLoginPage, logger, showSuccessSnackbar, t])

  if (status === 'LOADING') {
    return <FullScreenLoader />
  }

  const buttonLabel = t('pages.activateAccountPage.button')

  if (status === 'FAIL') {
    return (
      <>
        <ActivateAccountSeo />
        <FeedbackPage
          type='error'
          icon={IconType.error}
          header={t('pages.activateAccountPage.fail.header')}
          paragraph={errorParagraph}
          renderButton={() => {
            return (
              <Button
                label={buttonLabel}
                variant={ButtonVariant.Primary}
                onClick={goToLoginPage}
              />
            )
          }}
        />
      </>
    )
  }

  return null
}
