import { UserStatusDTO } from 'models'
import { StatusValues } from 'api-connectors'

export const canSeeFinancialData = (userStatus?: UserStatusDTO) =>
  userStatus?.flags.includes(StatusValues.SHOW_FINANCIAL_DATA)

export const canActivateCard = (userStatus?: UserStatusDTO) =>
  !userStatus?.flags.includes(StatusValues.CARD_ACTIVATION_DEPLETED) &&
  userStatus?.flags.includes(StatusValues.ACH_CONNECTED) &&
  userStatus?.flags.includes(StatusValues.CARD_NOT_ACTIVE)

export const isApplicationCurrentlyProcessing = (userStatus?: UserStatusDTO) =>
  userStatus?.flags.includes(StatusValues.WIDGET_IN_PROGRESS) &&
  userStatus?.flags.includes(StatusValues.PRELIMINARY_FINISHED)

export const hasAnyACHFlag = (userStatus?: UserStatusDTO) =>
  userStatus?.flags.includes(StatusValues.ACH_IN_PROGRESS) ||
  userStatus?.flags.includes(StatusValues.ACH_CONNECTED) ||
  userStatus?.flags.includes(StatusValues.ACH_FAILED)
