/* eslint-disable react/jsx-props-no-spreading */
import { Chip } from 'ui'
import { Image } from 'ui/Image/Image.component'
import { ChipProps } from 'ui/Chip/Chip.component'
import { TableCell } from './TableCell.component'

type TableImageCellProps = {
  label: string
  url: string
  chip?: ChipProps
}

function TableImageCell({ label, url, chip }: TableImageCellProps) {
  return (
    <TableCell
      label={label}
      leadingElement={
        <Image src={url} width='1.5rem' height='1.5rem' alt='Cell' />
      }
      trailingElement={chip && <Chip {...chip} />}
    />
  )
}

export { TableImageCell }
