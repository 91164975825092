import * as yup from 'yup'
import { useTranslations } from 'translations'
import { FormGrid, VisuallyHiddenFormLegend } from 'ui-kit'
import {
  NameField,
  nameFieldValidationSchema,
} from '../../fields/NameField/NameField.component'
import {
  PrivacyPolicyField,
  privacyPolicyFieldValidationSchema,
} from '../../fields/PrivacyPolicyField/PrivacyPolicyField.component'
import {
  BusinessEmailField,
  businessEmailFieldValidationSchema,
} from '../../fields/BusinessEmailField/BusinessEmailField.component'
import {
  PasswordField,
  passwordFieldValidationSchema,
  passwordFieldValidationSchemaComplexity,
} from '../../fields/PasswordField/PasswordField.component'
import {
  LastNameField,
  lastNameFieldValidationSchema,
} from '../../fields/LastNameField/LastNameField.component'

export interface RegisterFormValues {
  name: string
  lastName: string
  businessEmail: string
  password: string
  privacyPolicyConsent: boolean
}

export const registerFormValidationSchema: yup.ObjectSchema<
  Record<keyof RegisterFormValues, yup.AnySchema>
> = yup.object().shape({
  name: nameFieldValidationSchema,
  lastName: lastNameFieldValidationSchema,
  businessEmail: businessEmailFieldValidationSchema,
  password: passwordFieldValidationSchema.concat(
    passwordFieldValidationSchemaComplexity
  ),
  privacyPolicyConsent: privacyPolicyFieldValidationSchema,
})

export const registerFormInitialValues: RegisterFormValues = {
  name: '',
  lastName: '',
  businessEmail: '',
  password: '',
  privacyPolicyConsent: false,
}

export const RegisterFormFieldSet = () => {
  const t = useTranslations()
  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.registerFormFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <FormGrid.Row>
          <NameField />
        </FormGrid.Row>
        <FormGrid.Row>
          <LastNameField />
        </FormGrid.Row>
        <FormGrid.Row>
          <BusinessEmailField />
        </FormGrid.Row>
        <FormGrid.Row>
          <PasswordField />
        </FormGrid.Row>
        <FormGrid.Row style={{ paddingLeft: '0.25rem' }}>
          <PrivacyPolicyField />
        </FormGrid.Row>
      </FormGrid.Grid>
    </fieldset>
  )
}
