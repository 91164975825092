import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { useState } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { StyledTextField } from '../TextField/TextField.styles'

type DatePickerProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  required?: boolean
  disableFuture?: boolean
  disablePast?: boolean
}

function DatePicker<TFieldValues extends FieldValues>({
  name,
  label,
  control,
  required,
  disableFuture,
  disablePast,
}: DatePickerProps<TFieldValues>) {
  const [isOpen, setIsOpen] = useState(false)

  const {
    field: { value, ref: inputRef, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <MUIDatePicker
      label={label}
      value={value || null}
      disableFuture={disableFuture}
      disablePast={disablePast}
      onChange={onChange}
      inputRef={inputRef}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderInput={params => (
        <StyledTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          error={!!error}
          helperText={error ? error.message : undefined}
          required={required}
        />
      )}
    />
  )
}

export { DatePicker }
