import * as yup from 'yup'
import { useTranslations } from 'translations'
import { FormGrid, VisuallyHiddenFormLegend } from 'ui-kit'
import {
  BusinessEmailField,
  businessEmailFieldValidationSchema,
} from '../../fields/BusinessEmailField/BusinessEmailField.component'

export interface ForgotPasswordFormValues {
  businessEmail: string
}

export const forgotPasswordFormValidationSchema: yup.ObjectSchema<
  Record<keyof ForgotPasswordFormValues, yup.AnySchema>
> = yup.object().shape({
  businessEmail: businessEmailFieldValidationSchema,
})

export const forgotPasswordFormInitialValues: ForgotPasswordFormValues = {
  businessEmail: '',
}

export const ForgotPasswordFormFieldSet = () => {
  const t = useTranslations()
  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.forgotPasswordFormFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <FormGrid.Row>
          <BusinessEmailField />
        </FormGrid.Row>
      </FormGrid.Grid>
    </fieldset>
  )
}
