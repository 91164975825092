import { BusinessEntity } from 'api'

const BUSINESS_ENTITY_TO_READABLE: Record<BusinessEntity, string> = {
  [BusinessEntity.Corporation]: 'Corporation',
  [BusinessEntity.LLC]: 'LLC',
  [BusinessEntity.NonProfit]: 'Nonprofit',
  [BusinessEntity.Partnership]: 'Partnership',
  [BusinessEntity.SoleProprietorship]: 'Sole proprietorship',
  [BusinessEntity.Other]: 'Other',
}

const BUSINESS_ENTITY_OPTIONS = Object.values(BusinessEntity).map(value => ({
  label: BUSINESS_ENTITY_TO_READABLE[value],
  value,
}))

export { BusinessEntity, BUSINESS_ENTITY_OPTIONS }
