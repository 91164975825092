import React from 'react'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { ThemeProvider as ThemeProviderMui } from '@mui/material'
import { theme } from './ThemeProvider.consts'

interface ThemeProps {
  children: React.ReactNode
}

export function ThemeProvider({ children }: ThemeProps) {
  return (
    <ThemeProviderStyled theme={theme}>
      <ThemeProviderMui theme={theme}>{children}</ThemeProviderMui>
    </ThemeProviderStyled>
  )
}
