import styled from 'styled-components'

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`

const LogInLinkContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`

export { ButtonContainer, LogInLinkContainer }
