import { ChangeEvent, useCallback, useMemo } from 'react'
import { StyledFormLabel, StyledTextField, StyledMenuItem } from 'ui'
import { FilteredColumn, TableFilterOption } from '../types'

type TableFilterSelectFieldProps = {
  label: string
  filterOptions: TableFilterOption[]
} & Pick<FilteredColumn<unknown>, 'getFilterValue' | 'setFilterValue'>

const TABLE_FILTER_SELECT_FIELD_ANY_OPTION =
  'TABLE_FILTER_SELECT_FIELD_ANY_OPTION'

function TableFilterSelectField({
  label: fieldLabel,
  filterOptions,
  getFilterValue,
  setFilterValue,
}: TableFilterSelectFieldProps) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilterValue(e.target.value)
    },
    [setFilterValue]
  )

  const options = useMemo(() => {
    return [
      {
        label: 'Any',
        value: TABLE_FILTER_SELECT_FIELD_ANY_OPTION,
      },
    ].concat(filterOptions)
  }, [filterOptions])

  return (
    <>
      <StyledFormLabel>{fieldLabel}</StyledFormLabel>
      <StyledTextField
        label=''
        value={getFilterValue() || TABLE_FILTER_SELECT_FIELD_ANY_OPTION}
        onChange={onChange}
        select
      >
        {options.map(({ label, value }) => (
          <StyledMenuItem key={value} value={value}>
            {label}
          </StyledMenuItem>
        ))}
      </StyledTextField>
    </>
  )
}

export { TableFilterSelectField, TABLE_FILTER_SELECT_FIELD_ANY_OPTION }
