import { useMemo } from 'react'
import { Offer } from 'api'
import {
  PartnerDetailsModal,
  PartnerDetailsModalLabelProps,
  PartnerDetailsModalSectionProps,
} from 'shared'
import { useTranslations } from 'translations'
import { IconType } from 'ui'
import { OFFER_CATEGORY_TO_READABLE } from 'lib/models'

type OfferDetailsModalProps = {
  offer: Offer | undefined
  onClose: () => void
}

function OfferDetailsModal({ offer, onClose }: OfferDetailsModalProps) {
  const t = useTranslations()

  const labels: PartnerDetailsModalLabelProps[] = useMemo(() => {
    if (!offer) {
      return []
    }

    return [
      {
        label: OFFER_CATEGORY_TO_READABLE[offer.category],
        iconType: IconType.House,
      },
    ]
  }, [offer])

  const sections: PartnerDetailsModalSectionProps[] = useMemo(() => {
    if (!offer) {
      return []
    }

    const { name, partner } = offer

    return [
      {
        header: t('pages.growthPartners.modal.offer'),
        iconType: IconType.Heart,
        description: name,
      },
      {
        header: t('pages.growthPartners.modal.aboutThePartner'),
        iconType: IconType.Disclosure,
        description: partner.about,
      },
    ]
  }, [offer, t])

  return (
    <PartnerDetailsModal
      isOpen={!!offer}
      onClose={onClose}
      title={offer?.partner.name || ''}
      logoUrl={offer?.partner.logoURL || ''}
      labels={labels}
      sections={sections}
      button={{
        label: t('pages.growthPartners.modal.getOffer'),
        externalUrl: offer?.url || '',
      }}
    />
  )
}

export { OfferDetailsModal }
