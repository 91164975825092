import { Form, Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import {
  codeNumberInitialValues,
  IdentityCodeVerificationFieldSet,
  identityCodeVerificationFieldSetValidationSchema,
  CodeNumberFormValues,
} from 'form-kit'
import { useTranslations } from 'translations'
import { LayoutOneColumnAndHeader, Typography } from 'ui-kit'
import { Button, ButtonVariant } from 'ui'
import {
  useMfaEnable,
  useMfaRemember,
  useMfaResend,
  useMfaVerify,
} from 'models'
import {
  ParagraphWrapper,
  StyledButtonsWrapper,
} from './IdentityVerification.styles'
import { useCensoredEmail } from './IdentityVerification.utils'
import { EmergencyIdentityVerification } from './EmergencyIdentityVerification.component'
import { EmergencyCodes } from './EmergencyCodes'

export interface MfaData {
  isSetup?: boolean
  email: string

  onVerified?: () => Promise<void>
}

type IdentityVerificationProps = MfaData

export const IdentityVerification = ({
  email,
  isSetup = false,
  onVerified,
}: IdentityVerificationProps) => {
  const t = useTranslations()
  const { mutateAsync: verifyAsync } = useMfaVerify()
  const { mutateAsync: enableAsync } = useMfaEnable()
  const { mutateAsync: rememberAsync } = useMfaRemember()
  const { mutate: resend, isLoading: isResendLoading } = useMfaResend()

  const [isEmergencyVisible, setEmergencyVisible] = useState(false)
  const [mfaCodes, setMfaCodes] = useState<string[] | null>(null)
  const censoredEmail = useCensoredEmail(email)

  const handleFormSubmit = async (data: CodeNumberFormValues) => {
    if (isSetup) {
      const response = await enableAsync({
        receivedCode: data.codeNumber || '',
      })
      setMfaCodes(response.data.emergencyCodes)
    } else {
      await verifyAsync({ receivedCode: data.codeNumber || '' })
    }

    if (data.saveDevice) {
      await rememberAsync()
    }

    if (!isSetup) {
      await onVerified?.()
    }
  }

  if (mfaCodes) {
    return <EmergencyCodes codes={mfaCodes} onVerified={onVerified} />
  }

  if (isEmergencyVisible) {
    return (
      <EmergencyIdentityVerification
        onBack={() => setEmergencyVisible(false)}
        onVerified={onVerified}
      />
    )
  }

  return (
    <LayoutOneColumnAndHeader>
      <Typography.H1MainLayout>
        <FormattedMessage id='pages.loginPage.mfa.header' />
      </Typography.H1MainLayout>
      <ParagraphWrapper>
        <FormattedMessage
          id={
            isSetup
              ? 'pages.loginPage.mfa.codeIntroSetup'
              : 'pages.loginPage.mfa.codeIntro'
          }
          values={{ email: censoredEmail }}
        />
      </ParagraphWrapper>
      <Formik
        initialValues={codeNumberInitialValues}
        onSubmit={handleFormSubmit}
        validateOnChange={false}
        validationSchema={identityCodeVerificationFieldSetValidationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <IdentityCodeVerificationFieldSet />
              <StyledButtonsWrapper>
                <Button
                  label={t('pages.loginPage.mfa.confirmButton')}
                  variant={ButtonVariant.Primary}
                  sizeVariant='large'
                  type='submit'
                  isLoading={isSubmitting}
                />
              </StyledButtonsWrapper>
            </Form>
          )
        }}
      </Formik>
      <Button
        label={t('pages.loginPage.mfa.resendCode')}
        variant={ButtonVariant.Text}
        isLoading={isResendLoading}
        onClick={() => resend({ email })}
      />
      {!isSetup ? (
        <Button
          label={t('pages.loginPage.mfa.useEmergencyCode')}
          variant={ButtonVariant.Text}
          onClick={() => setEmergencyVisible(true)}
        />
      ) : null}
    </LayoutOneColumnAndHeader>
  )
}
