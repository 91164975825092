import styled from 'styled-components'
import { PageContainer } from 'shared'

const StyledCardFundidWrapper = styled.div`
  width: 16rem;
  max-width: 100%;
  margin: 2rem auto;
`

const StyledPageContainer = styled(PageContainer)`
  min-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
`

export { StyledCardFundidWrapper, StyledPageContainer }
