import { MutationCache, QueryCache, QueryClient } from 'react-query'
import { ReactNode } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history'
import {
  FontStyles,
  GlobalStyles,
  ResetStyles,
  ThemeProvider,
  SnackbarProvider,
} from 'ui-kit'
import { TranslationsProvider } from 'translations/'
import { LoggerProvider, Logger, CompositeLogger, ConsoleLogger } from 'logger'
import { getEnvVariable } from 'utils'
import { RoutesProvider } from 'routing'
import { axiosInstance, ReactQueryProvider, sessionStore } from 'api-connectors'
import { AuthenticationProvider } from 'authentication'
import { HelmetProvider } from 'seo'
import { captureSentryException } from 'lib/sentry'
import { ApiClientProvider, SessionStoreProvider } from 'lib/context'
import { ApiClient } from 'api'
import { SessionStore } from 'lib/sessionStore'
import { defaultHistory } from './AppProviders.consts'

interface AppProvidersProps {
  children: ReactNode
  logger?: Logger
  history?: History
  queryClient?: QueryClient
  apiClient?: ApiClient
  sessionStore?: SessionStore
}

export function AppProviders(props: AppProvidersProps) {
  const {
    history = defaultHistory,
    children,
    logger = new CompositeLogger().add(new ConsoleLogger()),
    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          useErrorBoundary: true,
        },
        mutations: {},
      },
      queryCache: new QueryCache({
        onError: err => {
          captureSentryException(err as Error)
        },
      }),
      mutationCache: new MutationCache({
        onError: err => {
          captureSentryException(err as Error)
        },
      }),
    }),

    apiClient = new ApiClient({
      baseUrl: getEnvVariable('REACT_APP_FUNDID_BACKEND_V2_BASE_URL'),
      client: axiosInstance,
      // eslint-disable-next-line react/destructuring-assignment
      sessionStore: props.sessionStore || sessionStore,
    }),
    sessionStore: defaultSessionStore,
  } = props

  return (
    <LoggerProvider logger={logger}>
      <ReactQueryProvider queryClient={queryClient}>
        <SessionStoreProvider
          sessionStore={defaultSessionStore || sessionStore}
        >
          <ApiClientProvider apiClient={apiClient}>
            {/* <AuthProvider> */}
            <HelmetProvider>
              <TranslationsProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ThemeProvider>
                    {/* Do not change the order of global styles */}
                    <ResetStyles />
                    <FontStyles />
                    <GlobalStyles />
                    <AuthenticationProvider sessionStore={sessionStore}>
                      <RoutesProvider history={history}>
                        <SnackbarProvider>{children}</SnackbarProvider>
                      </RoutesProvider>
                    </AuthenticationProvider>
                  </ThemeProvider>
                </LocalizationProvider>
              </TranslationsProvider>
            </HelmetProvider>
            {/* </AuthProvider> */}
          </ApiClientProvider>
        </SessionStoreProvider>
      </ReactQueryProvider>
    </LoggerProvider>
  )
}
