export const hasPropertyBusinessEmail = (
  state: unknown
): state is Required<{ businessEmail: string }> => {
  if (typeof state === 'object' && state !== null) {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty('businessEmail')) {
      return true
    }
  }
  return false
}
