import styled, { css } from 'styled-components'
import { IconButton, LogoComponent } from 'ui-kit'
import { CoreCardCardStatus } from 'api-connectors'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 20/12.25;
  flex-shrink: 0;

  ${({ theme }) => theme.media.minTablet} {
    min-height: 12.25rem;
    max-width: 20rem;
    margin: 0.25rem;
  }
`

export const UnscrambledWrapper = styled(Wrapper)`
  padding: 0;
`

export const UnscrambledCardImage = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`

export const PlaceholderWrapper = styled(Wrapper)<{ $isClickable?: boolean }>`
  background: ${({ theme }) => theme.color.background};
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.primary};
  gap: 1rem;
  font-size: 1rem;
  border: 2px solid transparent;
  ${({ $isClickable, theme }) =>
    $isClickable &&
    css`
      cursor: pointer;
      &:focus {
        outline-width: 1px;
        outline-style: solid;
        outline-offset: 0.25rem;
        outline-color: ${theme.color.primary};
      }
    `}
`

export const Container = styled.div<{ $variant: CoreCardCardStatus }>`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  ${({ theme, $variant }) => {
    if ($variant === CoreCardCardStatus.INACTIVE) {
      return css`
        color: ${theme.color.white};
        background-color: ${theme.color.green[400]};
        border: 2px solid ${theme.color.green[500]};
      `
    }
    if ($variant === CoreCardCardStatus.FROZEN) {
      return css`
        color: ${theme.color.green[700]};
        background-color: ${theme.color.backgroundBlue};
        border: 2px solid ${theme.color.aliceBlue[800]};
      `
    }

    return css`
      color: ${theme.color.white};
      background-color: ${theme.color.primary};
      border: 2px solid ${theme.color.primary};
    `
  }};
`

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const BrandRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0 0 1.5rem;
`

export const CardNumberRow = styled.div`
  display: flex;
  padding: 0 3.25rem 2rem 1.5rem;
  justify-content: space-between;
  width: 100%;
`

export const CardNumberPart = styled.span`
  font-size: 1.125rem;
`

export const CardInfoRow = styled.div`
  display: flex;
  padding: 0 0.5rem 1.5rem 1.5rem;
  justify-content: space-between;
  min-height: 3.5rem;
`

export const CardSecurity = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const SecurityCell = styled.div`
  display: flex;
  flex-direction: column;
`

export const SecurityLabel = styled.p`
  font-size: 0.8125rem;
  letter-spacing: -0.0025em;
  line-height: 1rem;
`
export const SecurityValue = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
`

export const StyledLogo = styled(LogoComponent)`
  width: 4rem;
  height: auto;
  path {
    fill: currentColor;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const VariantLabel = styled.div`
  padding: 0.5rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 5rem;
  line-height: 1rem;
`

export const DeactivatedLabel = styled(VariantLabel)`
  background-color: ${({ theme }) => theme.color.red.error};
`

export const FrozenLabel = styled(VariantLabel)`
  background-color: ${({ theme }) => theme.color.aliceBlue[700]};
  color: ${({ theme }) => theme.color.green[700]};
  margin-right: 1.68rem;
`

export const ShowCardDetailsButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.color.backgroundGreen};
  color: ${({ theme }) => theme.color.background};
  margin-right: 1.68rem;
  &.Mui-focusVisible {
    outline: ${({ theme }) => theme.color.backgroundGreen} solid 1px;
  }
`

export const HideCardDetailsButton = styled(ShowCardDetailsButton)`
  position: absolute;
  bottom: 1.75rem;
  margin: 0;
  right: 2.43rem;
`

export const PhysicalContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  ${StyledLogo} {
    width: 10rem;
    height: auto;
  }

  ${VariantLabel} {
    margin: 0;
  }

  ${({ theme, $variant }) => {
    if ($variant === CoreCardCardStatus.FROZEN) {
      return css`
        color: ${theme.color.aliceBlue[800]};
      `
    }
    return ''
  }}
`
