/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import { rest } from 'msw'
import { getEnvVariable } from 'utils'
import { FeatureFlags, StatusValues, UserStatusResponse } from 'api-connectors'

const baseUrl = getEnvVariable('REACT_APP_FUNDID_BACKEND_BASE_URL')

export const authHandlerBase = [
  rest.get(`${baseUrl}/auth/status`, async (req, res, ctx) => {
    return res(ctx.status(201), ctx.json({ data: { flags: [] } }))
  }),
]

export const authHandler = [
  rest.get(`${baseUrl}/auth/status`, async (req, res, ctx) => {
    return res(
      ctx.delay(100),
      ctx.status(201),
      ctx.json({
        data: {
          flags: [StatusValues.GRANT_MATCH_PROGRAM_FINISHED],
          businessEmail: 'test@test.com',
          name: 'Name',
          lastName: 'LastName',
          id: 'abc-123',
          address: {
            state: 'Alabama',
            city: 'Poznan',
            street1: 'Poznanska',
            street2: 'Poznanska 2',
            zip: '123',
          },
        },
      })
    )
  }),
]

export const authHandlerWithFlags = (
  data: Partial<UserStatusResponse['data']> = {}
) => [
  rest.get(`${baseUrl}/auth/status`, async (req, res, ctx) => {
    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: {
          flags: [],
          featureFlags: [],
          businessEmail: 'test@test.com',
          name: 'Name',
          lastName: 'LastName',
          id: 'abc-123',
          ...data,
          address: {
            state: 'Alabama',
            city: 'Poznan',
            street1: 'Poznanska',
            street2: 'Poznanska 2',
            zip: '123',
            ...data.address,
          },
        },
        statusCode: 200,
      })
    )
  }),
]

export const authHandlerOnceWithFlags = ({
  flags = [],
  featureFlags = [],
}: {
  flags?: StatusValues[]
  featureFlags?: FeatureFlags[]
} = {}) => [
  rest.get(`${baseUrl}/auth/status`, async (req, res, ctx) => {
    return res.once(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: {
          flags,
          featureFlags,
          businessEmail: 'test@test.com',
          name: 'Name',
          lastName: 'LastName',
          id: 'abc-123',
          address: {
            state: 'Alabama',
            street2: 'Poznanska 2',
            street1: 'Poznanska',
            zip: '123',
          },
        },
        statusCode: 200,
      })
    )
  }),
]

export const authHandlerFailRefreshSuccess = [
  rest.get(`${baseUrl}/auth/status`, async (req, res, ctx) => {
    return res(ctx.status(405))
  }),
  rest.post(`${baseUrl}/auth/refreshToken`, async (req, res, ctx) => {
    return res(ctx.status(201))
  }),
]

export const authHandlerFailRefreshFail = [
  rest.get(`${baseUrl}/auth/status`, async (req, res, ctx) => {
    return res(ctx.status(405))
  }),
  rest.post(`${baseUrl}/auth/refreshToken`, async (req, res, ctx) => {
    return res(ctx.status(405))
  }),
]
