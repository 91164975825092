import {
  Account,
  PaymentAmountType,
  PaymentFrequency,
  PaymentSchedule,
  PaymentScheduleStatus,
} from 'api'
import { Translate } from 'translations'

function getPaymentFrequencyTranslation(
  t: Translate<string>,
  frequency: PaymentFrequency
) {
  switch (frequency) {
    case PaymentFrequency.Monthly:
      return t('domain.paymentSchedules.frequency.autopay')
    case PaymentFrequency.OneTime:
      return t('domain.paymentSchedules.frequency.oneTime')
    default:
      return ''
  }
}

function getPaymentAmountTypeTranslation(
  t: Translate<string>,
  type: PaymentAmountType
) {
  switch (type) {
    case PaymentAmountType.CurrentBalance:
      return t('domain.currentBalance')
    case PaymentAmountType.MinDue:
      return t('domain.minDue')
    case PaymentAmountType.RemainingStatementBalance:
      return t('domain.statementBalance')
    default:
      return ''
  }
}

function isUpcomingPayment(paymentSchedule: PaymentSchedule) {
  return paymentSchedule.status === PaymentScheduleStatus.Active
}

function getAutopayAmount(autopaySchedule: PaymentSchedule, account: Account) {
  switch (autopaySchedule.amountType) {
    case PaymentAmountType.CurrentBalance:
      return account.currentBalance
    case PaymentAmountType.MinDue:
      return account.paymentDue?.minimum || 0
    case PaymentAmountType.RemainingStatementBalance:
      return account.lastStatement?.remainingBalance || 0
    case PaymentAmountType.Fixed:
      return autopaySchedule.fixedAmount
    default:
      return 0
  }
}

function isActiveAutopaySchedule(ps: PaymentSchedule) {
  return (
    ps.frequency === PaymentFrequency.Monthly &&
    ps.status === PaymentScheduleStatus.Active
  )
}

export {
  isUpcomingPayment,
  getAutopayAmount,
  getPaymentFrequencyTranslation,
  getPaymentAmountTypeTranslation,
  isActiveAutopaySchedule,
}
