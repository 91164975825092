import { Stack, StackProps } from '@mui/material'
import styled from 'styled-components'

const SVGContainer = styled((props: StackProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Stack {...props} justifyContent='center' />
))`
  height: 160px;
`

export { SVGContainer }
