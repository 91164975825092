import styled from 'styled-components'
import { Alert } from '@mui/material'
import { Typography } from 'ui-kit'

export const StyledAlert = styled(Alert)`
  ${Typography.paragraphBody}
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadowVariants.selectableTile};
  display: flex;
  align-items: center;
  &.MuiAlert-standardSuccess {
    background-color: ${({ theme }) => theme.color.backgroundGreen};
  }
  &.MuiAlert-standardError {
    // Errors should be displayed in orange, requested by client
    background-color: ${({ theme }) => theme.color.accent};
  }
  &.MuiAlert-standardWarning {
    background-color: ${({ theme }) => theme.color.pumpkin[600]};
  }
  &.MuiAlert-standardInfo {
    background-color: ${({ theme }) => theme.color.aliceBlue[700]};
    color: ${({ theme }) => theme.color.green[700]};
    .MuiAlert-icon {
      color: ${({ theme }) => theme.color.green[700]};
    }
  }
  .MuiAlert-icon {
    color: ${({ theme }) => theme.color.white};
  }
  .MuiAlert-message {
    padding: 0;
  }
  .MuiAlert-action {
    padding: 0 0 0 1rem;
    display: flex;
    align-items: center;
  }
`
