import styled from 'styled-components'

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }
`

export const Row = styled.div`
  width: 100%;
`

export const CTAWrapper = styled.div`
  margin-top: 3rem;
  padding: 0;
  width: 100%;

  ${({ theme }) => theme.media.minTablet} {
    padding: 0 3rem;
  }
`
