/* eslint-disable react/jsx-props-no-spreading */
import { StyledList } from './NavLinks.styles'
import { useNav } from '../NavProvider/NavProvider.component'
import {
  SidebarExpandableListItem,
  SidebarExpandableListItemProps,
} from '../SidebarListItem/SidebarExpandableListItem.component'
import {
  SidebarListItem,
  SidebarListItemProps,
} from '../SidebarListItem/SidebarListItem.component'

type NavLinksProps = {
  sidebarListItems: (SidebarListItemProps | SidebarExpandableListItemProps)[]
}

function isSidebarExpandableListItem(
  item: SidebarListItemProps | SidebarExpandableListItemProps
): item is SidebarExpandableListItemProps {
  return !!(item as SidebarExpandableListItemProps).subLinks
}

function NavLinks({ sidebarListItems }: NavLinksProps) {
  const { isNavOpen } = useNav()

  return (
    <StyledList isNavOpen={isNavOpen}>
      {sidebarListItems.map(item => {
        return isSidebarExpandableListItem(item) ? (
          <SidebarExpandableListItem key={item.label} {...item} />
        ) : (
          <SidebarListItem key={item.label} {...item} />
        )
      })}
    </StyledList>
  )
}

export type { NavLinksProps }
export { NavLinks }
