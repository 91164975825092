import * as yup from 'yup'
import { Name } from 'api'
import { useTranslations } from 'translations'

const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 63

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/

const stringFormatter = {
  capitalizeFirstLetter: (val: string): string => {
    if (val.length <= 1) {
      return val.toUpperCase()
    }

    return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
  },

  displayLastFourDigits: (lastFourDigits: string): string => {
    return `•• ${lastFourDigits}`
  },

  displayName: (name: Name): string => {
    const { first, middle, last } = name
    return [first, middle, last].filter(s => !!s).join(' ')
  },
}

function usePasswordValidation() {
  const t = useTranslations()

  return (
    yup
      .string()
      // validate min password length
      .min(PASSWORD_MIN_LENGTH, t('ui.form.validation.password.minLength'))
      // validate max password length
      .max(PASSWORD_MAX_LENGTH, t('ui.form.validation.password.maxLength'))
      // validate no white space
      .test(
        'noWhiteSpace',
        t('ui.form.validation.password.noWhiteSpace'),
        (value: string | undefined) => !/\s/g.test(String(value))
      )
      // validate required characters
      .matches(PASSWORD_REGEX, t('ui.form.validation.password.regex'))
      // validate required
      .required(
        t('ui.form.validation.required', {
          field: t('ui.form.validation.password.label'),
        })
      )
  )
}

export { stringFormatter, usePasswordValidation }
