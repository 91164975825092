import * as yup from 'yup'
import { FormGrid } from 'ui-kit'
import {
  MarketLimitaionField,
  marketLimitationFieldValidationSchema,
} from '../../fields/MarketLimitationField/MarketLimitationField.component'

interface ConsentFormValues {
  marketLimitationsConsent: boolean
}

export const consentFieldSetValidationSchema: yup.ObjectSchema<
  Record<keyof ConsentFormValues, yup.AnySchema>
> = yup.object().shape({
  marketLimitationsConsent: marketLimitationFieldValidationSchema,
})

export const consentFormInitialValues: ConsentFormValues = {
  marketLimitationsConsent: false,
}

export const ConsentFormFieldSet = () => {
  return (
    <fieldset>
      <FormGrid.Row style={{ marginBottom: '1.5rem' }}>
        <MarketLimitaionField />
      </FormGrid.Row>
    </fieldset>
  )
}
