import * as yup from 'yup'
import { useTranslations } from 'translations'
import { FormGrid, VisuallyHiddenFormLegend } from 'ui-kit'
import {
  passwordFieldValidationSchema,
  PasswordField,
  passwordFieldValidationSchemaComplexity,
} from '../../fields/PasswordField/PasswordField.component'

export interface SetNewPasswordFormValues {
  password: string
}

export const setNewPasswordFormValidationSchema: yup.ObjectSchema<
  Record<keyof SetNewPasswordFormValues, yup.AnySchema>
> = yup.object().shape({
  password: passwordFieldValidationSchema.concat(
    passwordFieldValidationSchemaComplexity
  ),
})

export const setNewPasswordFormInitialValues: SetNewPasswordFormValues = {
  password: '',
}

export const SetNewPasswordFormFieldSet = () => {
  const t = useTranslations()
  return (
    <fieldset>
      <VisuallyHiddenFormLegend>
        {t('formKit.setNewPasswordFormFieldSet.legend')}
      </VisuallyHiddenFormLegend>
      <FormGrid.Grid>
        <FormGrid.Row>
          <PasswordField />
        </FormGrid.Row>
      </FormGrid.Grid>
    </fieldset>
  )
}
