import styled from 'styled-components'
import { paragraphCaptions } from 'ui-kit/Typography/Typography.styles'

export const StyledInputHint = styled.p`
  ${paragraphCaptions};
  font-size: 0.85rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.color.primary};
  margin-top: 0.25rem;
  margin-left: 1rem;
`
