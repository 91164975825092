import { ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthentication } from 'authentication'
import { RoutesPaths } from 'routing'

interface BlockAuthenticatedProps {
  children: ReactElement
}

export const BlockAuthenticated = (props: BlockAuthenticatedProps) => {
  const { children } = props
  const { getSession } = useAuthentication()
  const location = useLocation()

  if (getSession()) {
    return (
      <Navigate to={RoutesPaths.Dashboard} state={{ from: location }} replace />
    )
  }

  return children
}
