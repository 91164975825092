import { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconType } from '../Icons/Icon.component'
import { LayoutOneColumnAndHeader } from '../LayoutOneColumnAndHeader/LayoutOneColumnAndHeader.component'
import * as Typography from '../Typography/Typography.styles'

type IconWrapperVariant = 'error' | 'success'

const StyledIconWrapper = styled.div<{ type: IconWrapperVariant }>`
  width: 4.5rem;
  height: 4.5rem;
  ${({ type, theme }) => {
    if (type === 'error') {
      return css`
        background-color: ${theme.color.red.error};
      `
    }
    return css`
      background-color: ${theme.color.primary};
    `
  }}

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 3.5rem auto;
`
const StyledParagraph = styled(Typography.P)`
  margin-bottom: 1.75rem;
  ${({ theme }) => theme.media.minTablet} {
    margin-bottom: 3rem;
  }
`

const StyledH1 = styled(Typography.H1)`
  margin-bottom: 1rem;
  ${({ theme }) => theme.media.minTablet} {
    margin-bottom: 1.5rem;
  }
`

export interface FeedbackPageProps {
  icon?: IconType
  header: string
  paragraph?: string
  type?: IconWrapperVariant
  renderButton?: () => ReactElement
}

export const FeedbackPage = (props: FeedbackPageProps) => {
  const { icon, header, paragraph, type = 'success', renderButton } = props

  return (
    <LayoutOneColumnAndHeader>
      {icon ? (
        <StyledIconWrapper data-testid='icon' type={type}>
          <Icon type={icon} />
        </StyledIconWrapper>
      ) : null}
      <StyledH1>{header}</StyledH1>
      {paragraph && <StyledParagraph>{paragraph}</StyledParagraph>}
      {renderButton && renderButton()}
    </LayoutOneColumnAndHeader>
  )
}
