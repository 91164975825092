import { ReactNode } from 'react'
import { IntlProvider } from 'react-intl'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { flattenMessages } from '../utils/flattenMessages.utils'
import en from '../en.json'

const messages = flattenMessages(en)

export function TranslationsProvider({ children }: { children: ReactNode }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <IntlProvider messages={messages} locale='en-US' defaultLocale='en-US'>
        {children}
      </IntlProvider>
    </LocalizationProvider>
  )
}
