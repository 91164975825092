import styled from 'styled-components'
import { Typography, TypographyProps } from 'ui'

const Title = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='h1' />
))`
  margin-bottom: 1.5rem;
`

const Subtitle = styled((props: Omit<TypographyProps, 'variant'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography {...props} variant='body1' />
))`
  margin-bottom: 1.5rem;
`

export { Title, Subtitle }
