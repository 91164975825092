import styled from 'styled-components'
import { Card } from 'ui'

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 24rem;

  ${({ theme }) => theme.media.minTablet} {
    width: auto;
    max-width: auto;
  }
`

export { StyledCard }
