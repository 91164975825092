import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useUserStatus } from 'models'

// eslint-disable-next-line no-multi-assign,no-underscore-dangle
const HUBSPOT_ANALYTICS = (window._hsq = window._hsq || [])

export const useIdentifyUser = () => {
  const { userStatus } = useUserStatus()

  useEffect(() => {
    HUBSPOT_ANALYTICS.push([
      'identify',
      {
        email: userStatus?.businessEmail || '',
      },
    ])
  }, [userStatus?.businessEmail])
}

export const useTrackPageView = () => {
  const location = useLocation()
  useEffect(() => {
    HUBSPOT_ANALYTICS.push(['setPath', location.pathname])
    HUBSPOT_ANALYTICS.push(['trackPageView'])
  }, [location.pathname])
}

export const useHubspotTracking = () => {
  useIdentifyUser()
  useTrackPageView()
}
