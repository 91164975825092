import { Outlet } from 'react-router-dom'
import { Loader } from 'ui'
import { useSidebarListItems } from 'shared/Navbar/NavLinks/useSidebarListItems'
import { StyledContainer, StyledOutletContainer } from './LayoutMain.styles'
import { SkipToContentLink } from '../SkipToContentLink/SkipToContentLink.component'
import {
  NavProvider,
  useNav,
} from '../Navbar/NavProvider/NavProvider.component'
import { NavDesktop } from '../Navbar/NavDesktop/NavDesktop.component'
import { NavMobile } from '../Navbar/NavMobile/NavMobile.component'

function LayoutMainInner() {
  const { isNavOpen } = useNav()
  const { isLoading, sidebarListItems } = useSidebarListItems()

  if (isLoading) {
    return <Loader />
  }

  return (
    <StyledContainer isNavOpen={isNavOpen}>
      <SkipToContentLink />
      <NavDesktop sidebarListItems={sidebarListItems} />
      <NavMobile sidebarListItems={sidebarListItems} />
      <StyledOutletContainer>
        <Outlet />
      </StyledOutletContainer>
    </StyledContainer>
  )
}

function LayoutMain() {
  return (
    <NavProvider>
      <LayoutMainInner />
    </NavProvider>
  )
}

export { LayoutMain }
