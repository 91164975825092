import { FundidDate } from './shared'

enum AccountsEndpoints {
  GetAccount = '/credit/accounts',
}

enum AccountStatus {
  Active = 'ACTIVE',
  Frozen = 'FROZEN',
  Closed = 'CLOSED',
}

type LastStatement = {
  balance: number
  remainingBalance: number
  closeDate: FundidDate
}

type NextStatement = {
  closeDate: FundidDate
}

type PaymentDue = {
  date: FundidDate
  minimum: number
}

type Account = {
  id: number
  userID: number
  creditLimit: number
  currentBalance: number
  availableCredit: number
  status: AccountStatus
  lastStatement?: LastStatement
  nextStatement?: NextStatement
  paymentDue?: PaymentDue
}

type GetAccountQueryParams = {
  id: number
}

type GetAccountRespData = {
  account: Account
}

export { AccountsEndpoints, AccountStatus }
export type { Account, GetAccountQueryParams, GetAccountRespData }
