import { Address, FundidDate } from 'api/types/shared'

enum MockAddressType {
  Empty = 'EMPTY',
  PartialOne = 'PARTIAL_ONE',
  PartialTwo = 'PARTIAL_TWO',
  PartialThree = 'PARTIAL_THREE',
  PartialFour = 'PARTIAL_FOUR',
  PartialFive = 'PARTIAL_FIVE',
  Complete = 'COMPLETE',
}

const MOCK_ADDRESS: Record<MockAddressType, Address> = {
  [MockAddressType.Empty]: {
    line1: '',
    line2: '',
    city: '',
    stateISO2Code: '',
    countryISO2Code: '',
    postalCode: '',
  },
  [MockAddressType.PartialOne]: {
    line1: '123 ABC St',
    line2: '',
    city: 'San Francisco',
    stateISO2Code: 'CA',
    postalCode: '12345',
    countryISO2Code: '',
  },
  [MockAddressType.PartialTwo]: {
    line1: '',
    line2: '123 ABC St',
    city: 'San Francisco',
    stateISO2Code: 'CA',
    postalCode: '12345',
    countryISO2Code: '',
  },
  [MockAddressType.PartialThree]: {
    line1: '',
    line2: '123 ABC St',
    city: '',
    stateISO2Code: 'CA',
    postalCode: '12345',
    countryISO2Code: '',
  },
  [MockAddressType.PartialFour]: {
    line1: '',
    line2: '',
    city: 'San Francisco',
    stateISO2Code: '',
    postalCode: '12345',
    countryISO2Code: 'US',
  },
  [MockAddressType.PartialFive]: {
    line1: '',
    line2: '',
    city: '',
    stateISO2Code: 'CA',
    postalCode: '12345',
    countryISO2Code: 'US',
  },
  [MockAddressType.Complete]: {
    line1: '123 ABC St',
    line2: 'Apt 7',
    city: 'San Francisco',
    stateISO2Code: 'CA',
    postalCode: '12345',
    countryISO2Code: 'US',
  },
}

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function mockFundidDate(): FundidDate {
  return {
    year: randomInt(2000, 2023),
    month: randomInt(1, 12),
    day: randomInt(1, 28),
  }
}

export { MOCK_ADDRESS, mockFundidDate, randomInt }
