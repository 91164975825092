import { useQuery, UseQueryResult } from 'react-query'
import { useApiClient } from 'lib/context'
import { isNil } from 'lib/utils'
import { Account, ApiError, GetAccountRespData } from 'api'
import { accountsKeys } from './queryKeys'
import { useAccountId } from './useAccountId'
import { errQueryMissingDependencies } from '../queryError'

function useAccount<T = Account>(select?: (resp: GetAccountRespData) => T) {
  const apiClient = useApiClient()
  const { data: accountId } = useAccountId()

  const queryKey = accountsKeys.byId(accountId)

  return useQuery({
    queryKey,
    enabled: !isNil(accountId),
    queryFn: () => {
      if (isNil(accountId)) {
        return errQueryMissingDependencies(queryKey, { accountId })
      }

      return apiClient.getAccount({
        id: accountId,
      })
    },
    select: resp => (select ? select(resp) : resp.account),
  }) as UseQueryResult<typeof select extends undefined ? Account : T, ApiError>
}

export { useAccount }
