/* eslint-disable consistent-return */
import styled, { css } from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import {
  paragraphBody,
  paragraphCaptions,
} from '../../Typography/Typography.styles'

export const StyledInputSelect = styled.div`
  position: relative;
  ${paragraphCaptions};

  // default

  .MuiSelect-select {
    padding: 0.75rem 1rem;
  }

  fieldset {
    border-radius: 0.5rem;
    border-color: ${({ theme }) => theme.color.backgroundGreen};
    span {
      ${paragraphCaptions};
    }
  }

  // focused

  && .Mui-focused {
    fieldset {
      border-width: 2px;
      border-color: ${({ theme }) => theme.color.primary};
    }
  }

  // hovered

  && .MuiOutlinedInput-root {
    &:hover {
      fieldset {
        border-color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  // error

  && .Mui-error {
    fieldset {
      border-color: ${({ theme }) => theme.color.red.error};
    }
  }
`

export const StyledPlaceholder = styled.em`
  ${paragraphBody}
`

export const StyledLabel = styled.label<{
  hasError: boolean
  isHidden?: boolean
}>`
  position: absolute;
  top: 0;
  left: 1rem;
  transform: translateY(-50%);
  ${paragraphCaptions};

  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        color: ${theme.color.red.error};
      `
    }
  }}

  ${({ isHidden }) => {
    return (
      isHidden &&
      css`
        display: none;
      `
    )
  }}
`

export const StyledMenuItem = styled(MenuItem)`
  ${paragraphBody};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.color.backgroundBlue};
  }
`

export const DisplayValue = styled.div`
  ${paragraphBody};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
