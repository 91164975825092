import { useQuery, UseQueryResult } from 'react-query'
import { ApiError, GetOffersRespData, Offer } from 'api'
import { useApiClient } from 'lib/context'
import { offerKeys } from './queryKeys'

function useOffers<T = Offer[]>(select?: (resp: GetOffersRespData) => T) {
  const apiClient = useApiClient()

  return useQuery({
    queryKey: offerKeys.all,
    queryFn: () => apiClient.getOffers(),
    select: resp => (select ? select(resp) : resp.offers),
  }) as UseQueryResult<typeof select extends undefined ? Offer[] : T, ApiError>
}

export { useOffers }
