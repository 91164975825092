import * as yup from 'yup'
import { useEffect, useMemo } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useBankAccountOptions, useOneTimePaymentOptions } from 'lib/queries'
import { SelectOption } from 'ui'
import { PaymentAmountType } from 'api'
import { useTranslations } from 'translations'

type OneTimePaymentFormData = {
  amountType: PaymentAmountType
  bankAccountId: string
  paymentDate: Date
}

type UseOneTimePaymentFormType = {
  form: UseFormReturn<OneTimePaymentFormData>
} & (
  | {
      oneTimePaymentOptions: SelectOption[]
      bankAccountOptions: SelectOption[]
      isLoading: false
    }
  | {
      oneTimePaymentOptions: undefined
      bankAccountOptions: undefined
      isLoading: true
    }
)

function getDefaultAmountType(
  oneTimePaymentAmountsByType: Map<PaymentAmountType, number>
) {
  if (oneTimePaymentAmountsByType.has(PaymentAmountType.CurrentBalance)) {
    return PaymentAmountType.CurrentBalance
  }

  if (
    oneTimePaymentAmountsByType.has(PaymentAmountType.RemainingStatementBalance)
  ) {
    return PaymentAmountType.RemainingStatementBalance
  }

  if (oneTimePaymentAmountsByType.has(PaymentAmountType.MinDue)) {
    return PaymentAmountType.MinDue
  }

  return ''
}

// Creates a date that represents the start of the current day.
function getMinPaymentDate() {
  const d = new Date()
  d.setHours(0, 0, 0, 0)
  return d
}

function useOneTimePaymentForm(): UseOneTimePaymentFormType {
  const t = useTranslations()
  const schema: yup.SchemaOf<OneTimePaymentFormData> = useMemo(
    () =>
      yup
        .object({
          amountType: yup
            .mixed()
            .oneOf(Object.values(PaymentAmountType))
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('shared.oneTimePaymentButton.modal.form.amount'),
              })
            ),
          bankAccountId: yup
            .string()
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('shared.oneTimePaymentButton.modal.form.payFrom'),
              })
            ),
          paymentDate: yup
            .date()
            // Handles undefined case
            .typeError(
              t('ui.form.validation.required', {
                field: t('shared.oneTimePaymentButton.modal.form.paymentDate'),
              })
            )
            // Validate date in the future
            .min(
              getMinPaymentDate(),
              t('ui.form.validation.invalid', {
                field: t('shared.oneTimePaymentButton.modal.form.paymentDate'),
              })
            )
            // Validate required
            .required(
              t('ui.form.validation.required', {
                field: t('shared.oneTimePaymentButton.modal.form.paymentDate'),
              })
            ),
        })
        .required(),
    [t]
  )
  const { data: oneTimePaymentOptionsData } = useOneTimePaymentOptions()
  const { data: bankAccountOptions } = useBankAccountOptions()
  const form = useForm<OneTimePaymentFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      paymentDate: new Date(),
    },
  })

  // set default amountType
  useEffect(() => {
    if (!oneTimePaymentOptionsData || !!form.getValues().amountType) {
      return
    }

    const defaultAmountType = getDefaultAmountType(
      oneTimePaymentOptionsData.oneTimePaymentAmountsByType
    )
    if (defaultAmountType) {
      form.setValue('amountType', defaultAmountType)
    }
  }, [oneTimePaymentOptionsData, form])

  // set default bankAccountId
  useEffect(() => {
    if (!bankAccountOptions || !!form.getValues().bankAccountId) {
      return
    }

    if (bankAccountOptions.length > 0) {
      form.setValue('bankAccountId', bankAccountOptions[0].value)
    }
  }, [bankAccountOptions, form])

  const isLoading = !oneTimePaymentOptionsData || !bankAccountOptions

  return isLoading
    ? {
        form,
        isLoading,
        oneTimePaymentOptions: undefined,
        bankAccountOptions: undefined,
      }
    : {
        form,
        isLoading,
        oneTimePaymentOptions: oneTimePaymentOptionsData.oneTimePaymentOptions,
        bankAccountOptions,
      }
}

export type { OneTimePaymentFormData }
export { useOneTimePaymentForm }
