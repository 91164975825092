import { LogoPanelMobile } from './components/LogoPanelMobile.component'
import { NavMobileWrapper } from './NavMobile.styles'
import { NavLinks, NavLinksProps } from '../NavLinks/NavLinks.component'
import { useNav } from '../NavProvider/NavProvider.component'
import { ProfileSidebarListItem } from '../SidebarListItem/ProfileSidebarListItem.component'

type NavMobileProps = NavLinksProps

function NavMobile({ sidebarListItems }: NavMobileProps) {
  const { isNavOpen } = useNav()

  return (
    <NavMobileWrapper isNavOpen={isNavOpen}>
      <LogoPanelMobile />
      {isNavOpen && (
        <>
          <NavLinks sidebarListItems={sidebarListItems} />
          <ProfileSidebarListItem />
        </>
      )}
    </NavMobileWrapper>
  )
}

export { NavMobile }
