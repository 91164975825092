import styled, { css } from 'styled-components'

const TopSidebarLogoPanel = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isNavOpen }) =>
    isNavOpen ? 'space-between' : 'center'};
  margin-bottom: 2rem;
  padding: 0 0.5rem;
`

const RotatingIconWrapper = styled.button<{ isNavOpen: boolean }>`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  ${({ isNavOpen }) => {
    if (isNavOpen) {
      return css`
        svg {
          transform: rotate(0);
        }
      `
    }
    return css`
      svg {
        transform: rotate(180deg);
      }
    `
  }};
`

export { TopSidebarLogoPanel, RotatingIconWrapper }
