import { createGlobalStyle } from 'styled-components'

// Overrides the default style for the Finicity Connect iFrame
const FinicityConnectStyle = createGlobalStyle`
	#finicityConnectIframe{
	z-index: 1001 !important; // above mobile nav bar
    }
`

export { FinicityConnectStyle }
