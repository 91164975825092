import { AuthPageContainer } from 'shared'
import { useTranslations } from 'translations'
import { RoutesPaths } from 'routing'
import { useResendActivationCode, useSignUp } from 'lib/queries'
import { Button, ButtonVariant, Link, TextField, Typography } from 'ui'
import { useSnackbars } from 'ui-kit'
import { SignUpFormData, useSignUpForm } from './useSignUpForm'
import { ButtonContainer, LogInLinkContainer } from './SignUpPage.styles'

function SignUpPage() {
  const t = useTranslations()
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbars()
  const { control, handleSubmit, getValues } = useSignUpForm()
  const {
    mutate: signUp,
    isLoading: isSigningUp,
    isSuccess: isSuccessSigningUp,
  } = useSignUp()
  const { mutate: resendActivationCode, isLoading: isResendingActivationCode } =
    useResendActivationCode()

  const onSubmit = ({
    firstName,
    lastName,
    email,
    password,
  }: SignUpFormData) => {
    signUp(
      {
        email,
        password,
        name: {
          first: firstName,
          last: lastName,
        },
      },
      {
        onSuccess: () =>
          showSuccessSnackbar(t('pages.newSignUp.success.snackbar')),
        onError: () => showErrorSnackbar(t('pages.newSignUp.error')),
      }
    )
  }

  const resendVerificationEmail = () => {
    resendActivationCode(
      {
        email: getValues().email,
      },
      {
        onSuccess: () =>
          showSuccessSnackbar(
            t('pages.newSignUp.actions.resendVerificationEmail.success')
          ),
        onError: () =>
          showErrorSnackbar(
            t('pages.newSignUp.actions.resendVerificationEmail.error')
          ),
      }
    )
  }

  return (
    <AuthPageContainer
      title={t(`pages.newSignUp.${isSuccessSigningUp ? 'success.' : ''}title`)}
      subtitle={
        isSuccessSigningUp
          ? t('pages.newSignUp.success.subtitle', { email: getValues().email })
          : undefined
      }
      helmetTitle={t('pages.newSignUp.seoTitle')}
    >
      {isSuccessSigningUp ? (
        <Button
          label={t('pages.newSignUp.actions.resendVerificationEmail.cta')}
          variant={ButtonVariant.Tertiary}
          isLoading={isResendingActivationCode}
          onClick={resendVerificationEmail}
        />
      ) : (
        <>
          <TextField
            name='firstName'
            label={t('pages.newSignUp.form.firstName')}
            control={control}
            required
          />
          <TextField
            name='lastName'
            label={t('pages.newSignUp.form.lastName')}
            control={control}
            required
          />
          <TextField
            name='email'
            label={t('pages.newSignUp.form.email')}
            control={control}
            required
            type='email'
          />
          <TextField
            name='password'
            label={t('pages.newSignUp.form.password')}
            control={control}
            required
            type='password'
          />

          <ButtonContainer>
            <Button
              label={t('pages.newSignUp.actions.createAccount')}
              variant={ButtonVariant.Primary}
              sizeVariant='large'
              type='submit'
              isLoading={isSigningUp}
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonContainer>

          <LogInLinkContainer>
            <Typography variant='caption'>
              {t('pages.newSignUp.actions.login.text')}
              <Link variant='caption' href={RoutesPaths.NewLogin}>
                {t('pages.newSignUp.actions.login.link')}
              </Link>
            </Typography>
          </LogInLinkContainer>
        </>
      )}
    </AuthPageContainer>
  )
}

export { SignUpPage }
